import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { AdminAuthContext } from "../../context/adminAuth";

import { petTypes, useForm } from "../../util/hooks";
import TextInputField from "./TextInputField";

import {
  EDIT_BREED_MUTATION,
  FETCH_RECOMMENDED_BREEDS,
} from "../../util/gqlFunctions";

function EditBreedForm({ targetBreed, setTargetBreed, navigate }) {
  const [errors, setErrors] = useState({});
  const { admin } = useContext(AdminAuthContext);

  const { values, setValues, onChange } = useForm(editBreedCallback, {
    breedId: targetBreed.id,
    logoFile: null,
    ...targetBreed,
    fieldsToEdit: [],
  });

  const [editBreed, { loading: loadingEditBreed }] = useMutation(
    EDIT_BREED_MUTATION,
    {
      refetchQueries: admin
        ? [
            {
              query: FETCH_RECOMMENDED_BREEDS,
              variables: { pageSize: 20, seenIds: [] },
            },
          ]
        : [],
      update(_, { data: { editBreed: editC } }) {
        setValues({
          ...values,
          ...editC,
          logoFile: null,
          fieldsToEdit: [],
        });

        setErrors({});
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: admin ? adminClient : customerClient,
    }
  );

  function editBreedCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    editBreed();
  }

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetBreed
    ) {
      setValues({
        ...values,
        fieldsToEdit: [],
        [name]: targetBreed[name],
      });
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }
  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetBreed(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetBreed(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Edit breed
        </p>
        <p className="text-gray-500 w-full leading-tight ">Edit breed info</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start mt-6">
          <div className="flex items-center justify-between text-sm sm:text-base">
            <label htmlFor="petTypeId" className="font-semibold text-gray-700 ">
              Pet type{" "}
              {errors.petTypeId && (
                <span className="text-red-500">{errors.petTypeId}</span>
              )}
            </label>
          </div>
          <div
            className={`bg-blue-50 mt-2 mb-4 border-blue-500 text-left shadow-sm rounded-lg space-x-1 px-2 py-1 border transition-all flex items-center justify-start focus:border-indigo-500 focus:ring-indigo-500`}
          >
            <p>{petTypes[values.petTypeId]}</p>
          </div>

          <div className="flex w-full items-start justify-start space-x-4 sm:space-x-8">
            <TextInputField
              title={"Breed"}
              name="name"
              type="text"
              error={errors.name}
              disabled={loadingEditBreed}
              onChange={onChange}
              maxLen={100}
              setFieldsToEdit={setFieldsToEdit}
              fieldsToEdit={values.fieldsToEdit}
              editFunc={editBreed}
              value={values.name}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditBreedForm;
