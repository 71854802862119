import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";

function AdminPetActivityBox({
  index,

  petActivity,
  petActivityIndexToScrollIntoView,

  setPetActivityIndexInView,
  setTargetPetActivity,
  disabled,
}) {
  const orgRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (index === petActivityIndexToScrollIntoView) {
      orgRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [petActivityIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setPetActivityIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setPetActivityIndexInView]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isLinkCopied && resetInterval) {
      timeout = setTimeout(() => setIsLinkCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied, resetInterval]);

  return (
    <div ref={orgRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex items-center space-x-2 justify-start w-full truncate">
          {petActivity.logoUrl && (
            <img
              src={petActivity.logoUrl}
              className="h-16 short:h-12 aspect-square object-cover object-center origin-center rounded-md mr-2 short:mr-1"
              alt={petActivity.name}
            />
          )}
          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {petActivity.name}
            </p>
            <p className=" text-gray-500 leading-tight">
              Edited {moment(petActivity.lastEditedAt).fromNow()}
            </p>
          </div>

          <a
            href={"/activity/" + petActivity.shortForm}
            target="_blank"
            rel="noreferrer"
            className="bg-black shrink-0 transition-all w-12 hover:bg-opacity-50 aspect-square rounded-full "
          >
            <svg
              className="p-3 fill-current text-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5V19H19V12H21V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H12V5H5ZM14 5V3H21V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z"
              />
            </svg>
          </a>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
          <p>ID</p>
          <p>{petActivity.id}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Short form</p>
          <p>{petActivity.shortForm}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p># of pets</p>
          <p>{petActivity.numOfPets}</p>
        </div>
        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p># of posts </p>
          <p>{petActivity.numOfPosts}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Prize</p>
          <p>{petActivity.postPrizeCoins}</p>
        </div>

        <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-2 w-full rounded-lg border-gray-200 border">
          <p>Status</p>
          <p>
            {petActivity.status === -1
              ? "Removed"
              : petActivity.status === 0
              ? "Editing"
              : "LIVE"}
          </p>
        </div>

        {/* {petActivity.status <= 4 && petActivity.status !== -2 && ( */}
        <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
          <button
            // onClick={copyTrackingLink}
            onClick={(e) => {
              e.preventDefault();
              setTargetPetActivity(index);
            }}
            className={` ${
              petActivity.status === 0 ? "border-x" : ""
            } flex-1 flex items-center justify-center relative font-semibold border-gray-200 hover:bg-gray-50 transition-all`}
          >
            <p className={`py-3`}>Edit</p>
          </button>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default AdminPetActivityBox;
