import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { brandContactClient, customerClient } from "../../GraphqlApolloClients";
import {
  CREATE_REVIEW_MUTATION,
  FETCH_SHOULD_ASK_FOR_REVIEW,
} from "../../util/gqlFunctions";

import { useForm } from "../../util/hooks";
import SubmitButton from "./SubmitButton";
import TextInputField from "./TextInputField";

function TNReviewForm({
  toggleReviewForm,

  targetSeller,
  toggleReviewSentForm,
}) {
  const [errors, setErrors] = useState({});

  const { onChange, values, setValues } = useForm(createReviewCallback, {
    reviewMsg: "",
    stars: 1,
    siteId: 1,
  });

  const [createReview, { loading: loadingCreateReview }] = useMutation(
    CREATE_REVIEW_MUTATION,
    {
      refetchQueries: [{ query: FETCH_SHOULD_ASK_FOR_REVIEW }],
      update(_, { data: { createReview: review } }) {
        // toggleReviewForm();
        toggleReviewSentForm();
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetSeller ? brandContactClient : customerClient,
    }
  );

  function createReviewCallback(e) {
    e.preventDefault();
    createReview();
  }

  return (
    <div
      className={`fixed pt-12 xshort:pt-12 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          toggleReviewForm();
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-20 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl  short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            toggleReviewForm();
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Feedback
        </p>
        <p className="text-gray-500 w-full text-lg short:text-sm short:leading-tight leading-tight">
          Please share your thoughts about The Lucky Pet. As an early user,{" "}
          <b>your reviews have a direct impact!</b>
        </p>

        <div className="w-full flex mb-4 flex-col items-start justify-start space-y-4 mt-4">
          <div className="flex flex-col items-start justify-start">
            <label
              htmlFor={"stars"}
              className={`flex-shrink-0 sm:text-base text-sm font-semibold text-gray-700`}
            >
              Rating{" "}
              {errors.stars && (
                <span className="text-red-500 sm:text-base text-sm short:hidden">
                  {errors.stars}
                </span>
              )}
            </label>
            <div className="flex w-full mt-1 space-x-2 font-semibold text-sm items-center justify-center">
              <div
                className="flex items-center justify-center space-x-2 "
                onMouseLeave={(e) => {
                  e.preventDefault();
                  setValues({ ...values, hoveredStars: values.stars });
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, stars: 1 });
                  }}
                  onMouseOver={(e) => {
                    e.preventDefault();
                    if (values.hoveredStars !== 1) {
                      setValues({ ...values, hoveredStars: 1 });
                    }
                  }}
                  className={` ${
                    values.hoveredStars >= 1
                      ? "text-yellow-400 hover:text-yellow-500"
                      : "text-gray-200"
                  }  hover:text-yellow-400`}
                >
                  <svg
                    className="h-10 fill-current  transition-all"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, stars: 2 });
                  }}
                  onMouseOver={(e) => {
                    e.preventDefault();
                    if (values.hoveredStars !== 2) {
                      setValues({ ...values, hoveredStars: 2 });
                    }
                  }}
                  className={` ${
                    values.hoveredStars >= 2
                      ? "text-yellow-400 hover:text-yellow-500"
                      : "text-gray-200"
                  }  hover:text-yellow-400 `}
                >
                  <svg
                    className="h-10 fill-current  transition-all"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, stars: 3 });
                  }}
                  onMouseOver={(e) => {
                    e.preventDefault();
                    if (values.hoveredStars !== 3) {
                      setValues({ ...values, hoveredStars: 3 });
                    }
                  }}
                  className={` ${
                    values.hoveredStars >= 3
                      ? "text-yellow-400 hover:text-yellow-500"
                      : "text-gray-200"
                  }  hover:text-yellow-400 `}
                >
                  <svg
                    className="h-10 fill-current  transition-all"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, stars: 4 });
                  }}
                  onMouseOver={(e) => {
                    e.preventDefault();
                    if (values.hoveredStars !== 4) {
                      setValues({ ...values, hoveredStars: 4 });
                    }
                  }}
                  className={` ${
                    values.hoveredStars >= 4
                      ? "text-yellow-400 hover:text-yellow-500"
                      : "text-gray-200"
                  }  hover:text-yellow-400 `}
                >
                  <svg
                    className="h-10 fill-current  transition-all"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" />
                  </svg>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, stars: 5 });
                  }}
                  onMouseOver={(e) => {
                    e.preventDefault();
                    if (values.hoveredStars !== 5) {
                      console.log("hovered");
                      setValues({ ...values, hoveredStars: 5 });
                    }
                  }}
                  className={` ${
                    values.hoveredStars >= 5
                      ? "text-yellow-400 hover:text-yellow-500"
                      : "text-gray-200"
                  }  hover:text-yellow-400 `}
                >
                  <svg
                    className="h-10 fill-current  transition-all"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <TextInputField
            title="Review"
            name="reviewMsg"
            type="multiline"
            value={values.reviewMsg}
            maxLen={200}
            onChange={onChange}
            error={errors.reviewMsg}
            disabled={loadingCreateReview}
          />
        </div>

        <div className="flex-1"></div>
        <SubmitButton
          onClickFunc={createReviewCallback}
          disabled={loadingCreateReview}
          title={"Submit"}
          errors={errors}
        />
      </form>
    </div>
  );
}

export default TNReviewForm;
