import React, { useState } from "react";
import { useForm } from "../../util/hooks";

import { CardElement } from "@stripe/react-stripe-js";

import { useMutation } from "@apollo/client";

import CardInputField from "./CardInputField";

import { customerClient } from "../../GraphqlApolloClients";
import SubmitButton from "./SubmitButton";

import {
  FETCH_CUSTOMER,
  FETCH_PET,
  FETCH_PET_SUBSCRIPTION,
  FETCH_PETS_BY_SUBSCRIPTION,
  PROCESS_PET_SUB_PAYMENT_RESULT,
  REMOVE_PET_FROM_SUB_PLAN_MUTATION,
  UPDATE_PET_SUB_MUTATION,
} from "../../util/gqlFunctions";

function BuyPetSubscriptionForm({
  stripe,
  elements,
  targetCustomer,
  togglePaymentSuccessfulForm,
  petSubscriptionType,
  toggleBuySubscriptionForm,
  questionId,
  petSubscription,
  toggleProInviteCodeForm,
  subPopupReason,
  isHigher,
  isOnCompleteRegistrationPage,
}) {
  const [errors, setErrors] = useState({});

  const { values, setValues } = useForm(buySubscriptionCallback, {
    questionId,
    useNewCard: true,
    petSubscriptionType: petSubscriptionType || 0,
    inviteCode: "",
  });

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [cardElemIsFocused, setCardElemIsFocused] = useState(false);
  const [loadingSubPurchase, setLoadingSubPurchase] = useState(false);

  const [processPetSubscriptionPaymentResult] = useMutation(
    PROCESS_PET_SUB_PAYMENT_RESULT,
    {
      refetchQueries: [
        { query: FETCH_PET },
        { query: FETCH_PET_SUBSCRIPTION, variables: { status: 1 } },
        { query: FETCH_PETS_BY_SUBSCRIPTION },
      ],

      update(
        proxy,
        { data: { processPetSubscriptionPaymentResult: subPurchaseComplete } }
      ) {
        setLoadingSubPurchase(false);

        if (subPurchaseComplete.status === 1) {
          setErrors({});

          togglePaymentSuccessfulForm();
        }
      },
      onError(err) {
        console.log(err);
        setLoadingSubPurchase(false);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: customerClient,
    }
  );

  const [updatePetSubscription] = useMutation(UPDATE_PET_SUB_MUTATION, {
    refetchQueries: [
      { query: FETCH_PET },
      { query: FETCH_PET_SUBSCRIPTION, variables: { status: 1 } },
      { query: FETCH_PETS_BY_SUBSCRIPTION },
    ],

    async update(proxy, { data: { updatePetSubscription: custSub } }) {
      if (custSub.paymentIntentClientSecret) {
        const result = await stripe.confirmCardPayment(
          custSub.paymentIntentClientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );

        if (result.error) {
          setErrors({
            ...errors,
            paymentError: result.error.message,
          });
          setLoadingSubPurchase(false);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            processPetSubscriptionPaymentResult({
              variables: {
                petSubscriptionId: custSub.id,
              },
            });
          } else {
            setErrors({
              ...errors,
              paymentError: "- Invalid",
            });
            setLoadingSubPurchase(false);
          }
        }
      } else {
        setLoadingSubPurchase(false);

        setErrors({});

        toggleBuySubscriptionForm(null);
      }
    },
    onError(err) {
      console.log(err);
      setLoadingSubPurchase(false);

      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });

  function buySubscriptionCallback() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoadingSubPurchase(false);

      return;
    }

    if (!isCardComplete) {
      setErrors({
        paymentError: isCardComplete ? null : "- Required",
        ...errors,
      });
      setLoadingSubPurchase(false);

      return;
    }

    setLoadingSubPurchase(true);

    updatePetSubscription({
      variables: {
        petSubscriptionType: values.petSubscriptionType,
        useNewCard: values.useNewCard,
        wantsToBuy: true,
      },
    });
  }

  const proBenefits = [
    "Free undos",
    "Add up to ",
    "Play offline",
    "No pop-up ads",
    "Premium items",
    "Chance to win weekly Lucky Pet Gift",
  ];

  const [
    removePetFromSubscriptionPlan,
    { loading: loadingRemovePetFromSubscriptionPlan },
  ] = useMutation(REMOVE_PET_FROM_SUB_PLAN_MUTATION, {
    refetchQueries: [
      { query: FETCH_CUSTOMER },
      { query: FETCH_PET_SUBSCRIPTION, variables: { status: 1 } },
      { query: FETCH_PETS_BY_SUBSCRIPTION },
    ],
    update(_, { data: { toggleFriend: toggledFriend } }) {
      setValues({ ...values, petId: null });
    },
    onError(err) {
      console.log(err);
      setValues({ ...values, petId: null });
    },
    variables: values,
    client: customerClient,
  });

  return (
    <>
      <div
        className={`fixed ${
          isHigher ? "pt-12" : "pt-14"
        } sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center xshort:pt-12`}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});

            toggleBuySubscriptionForm(null);
          }}
          className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeIn flex items-center justify-center inset-0 absolute backdrop-blur-sm"
          disabled={loadingRemovePetFromSubscriptionPlan || loadingSubPurchase}
        />

        <form
          // onSubmit={buyBandCallback}
          className={`overflow-x-hidden ${
            targetCustomer.isPaidPetSubscriber
              ? "h-full"
              : "xshort:h-full pt-8 short:pt-6"
          } sm:h-auto max-h-full relative flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-4 pb-6 sm:p-8 sm:pb-0 bg-white shadow-xl rounded-b-none sm:rounded-3xl`}
        >
          {isOnCompleteRegistrationPage && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setErrors({});
                toggleBuySubscriptionForm(null);
              }}
              disabled={
                loadingRemovePetFromSubscriptionPlan || loadingSubPurchase
              }
              className="block absolute bg-gray-50 hover:bg-gray-100 transition-all sm:top-4 sm:right-4 top-2 p-2 rounded-full right-2 z-40"
            >
              <svg
                className="w-3 fill-current text-gray-600"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
              </svg>
            </button>
          )}
          {targetCustomer.isPaidPetSubscriber && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setErrors({});
                toggleBuySubscriptionForm(null);
              }}
              disabled={
                loadingRemovePetFromSubscriptionPlan || loadingSubPurchase
              }
              className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
            >
              <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
            </button>
          )}

          <p className="text-gray-700 text-2xl short:text-xl text-center font-bold w-full leading-tight short:leading-tight">
            Lucky Pet Pro
          </p>

          <p className="text-gray-500 w-full text-center mb-4 px-4 leading-tight mt-1">
            {subPopupReason ? subPopupReason + " " : ""}
            {targetCustomer.isPaidPetSubscriber && petSubscription
              ? "Edit Plan"
              : "Please pick the plan that is right for you and unlock everything!"}
          </p>

          <div className="w-full mb-4">
            {targetCustomer.isPaidPetSubscriber &&
              targetCustomer.petSubscriptionType !== 0 &&
              petSubscription && <></>}
            {petSubscription && petSubscription.petId !== targetCustomer.id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValues({
                    ...values,
                    petSubscriptionType: petSubscription.petSubscriptionType,
                  });
                }}
                className={`${
                  values.petSubscriptionType ===
                  petSubscription.petSubscriptionType
                    ? values.petSubscriptionType === 0
                      ? "border-dark-blue  bg-dark-blue text-gray-200"
                      : values.petSubscriptionType === 1
                      ? "border-orange-500  bg-orange-500 text-gray-200"
                      : values.petSubscriptionType === 2
                      ? "border-fuchsia-600  bg-fuchsia-600 text-gray-200"
                      : "border-lime-600  bg-lime-600 text-gray-200"
                    : "border-gray-200 hover:bg-gray-50 text-gray-400"
                } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2.5 py-2 rounded-md border`}
                disabled={loadingSubPurchase}
              >
                <div className="w-full flex items-center justify-between">
                  <p className=" text-sm sm:text-base truncate">
                    {petSubscription.petSubscriptionType === 0
                      ? "Individual"
                      : petSubscription.petSubscriptionType === 1
                      ? "Duo"
                      : petSubscription.petSubscriptionType === 2
                      ? "Family"
                      : "Club"}
                    {targetCustomer.isPaidPetSubscriber &&
                    targetCustomer.petSubscriptionType ===
                      petSubscription.petSubscriptionType
                      ? " - Current"
                      : ""}
                  </p>
                  <svg
                    className={`${
                      values.petSubscriptionType ===
                      petSubscription.petSubscriptionType
                        ? "opacity-100 stroke-lime-600 text-white "
                        : "text-gray-200 opacity-0 "
                    } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6.5" cy="6.5" r="6.5" />
                    <path d="M3 6.72727L5.5 9L10 4" />
                  </svg>
                </div>
                <p
                  className={`text-xl ${
                    values.petSubscriptionType ===
                    petSubscription.petSubscriptionType
                      ? "text-white"
                      : "text-gray-800"
                  } font-bold`}
                >
                  FREE for you
                </p>
              </button>
            ) : (
              <div className="grid grid-cols-2 gap-2 w-full">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, petSubscriptionType: 0 });
                  }}
                  className={`${
                    values.petSubscriptionType === 0
                      ? "border-dark-blue  bg-dark-blue text-gray-200"
                      : "border-gray-200 hover:bg-gray-50 text-gray-400"
                  } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                  disabled={loadingSubPurchase}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                      Individual
                      {targetCustomer.isPaidPetSubscriber &&
                      targetCustomer.petSubscriptionType === 0
                        ? " - Current"
                        : ""}
                    </p>
                    <svg
                      className={`${
                        values.petSubscriptionType === 0
                          ? "opacity-100 stroke-dark-blue text-white "
                          : "text-gray-200 opacity-0 "
                      } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" />
                    </svg>
                  </div>
                  <p
                    className={`text-xl short:text-lg short:leading-tight leading-tight ${
                      values.petSubscriptionType === 0
                        ? "text-white"
                        : "text-gray-800"
                    }`}
                  >
                    US$
                    {targetCustomer.petSubscriptionType === 0 &&
                    targetCustomer.curSubscriptionPrice
                      ? (targetCustomer.curSubscriptionPrice / 100).toFixed(2)
                      : "4.19"}
                    /mo
                  </p>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, petSubscriptionType: 2 });
                  }}
                  className={`${
                    values.petSubscriptionType === 2
                      ? "border-fuchsia-600  bg-fuchsia-600 text-gray-200"
                      : "border-gray-200 hover:bg-gray-50 text-gray-400"
                  } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                  disabled={loadingSubPurchase}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                      Family
                      {targetCustomer.isPaidPetSubscriber &&
                      targetCustomer.petSubscriptionType === 2
                        ? " - Current"
                        : ""}
                    </p>
                    <svg
                      className={`${
                        values.petSubscriptionType === 2
                          ? "opacity-100 stroke-fuchsia-600 text-white "
                          : "text-gray-200 opacity-0 "
                      } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" />
                    </svg>
                  </div>
                  <p
                    className={`text-xl short:text-lg short:leading-tight leading-tight ${
                      values.petSubscriptionType === 2
                        ? "text-white"
                        : "text-gray-800"
                    }`}
                  >
                    US$
                    {targetCustomer.petSubscriptionType === 2 &&
                    targetCustomer.curSubscriptionPrice
                      ? (targetCustomer.curSubscriptionPrice / 100).toFixed(2)
                      : "9.19"}
                    /mo
                  </p>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, petSubscriptionType: 1 });
                  }}
                  className={`${
                    values.petSubscriptionType === 1
                      ? "border-orange-500  bg-orange-500 text-gray-200"
                      : "border-gray-200 hover:bg-gray-50 text-gray-400"
                  } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                  disabled={loadingSubPurchase}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                      Duo
                      {targetCustomer.isPaidPetSubscriber &&
                      targetCustomer.petSubscriptionType === 1
                        ? " - Current"
                        : ""}
                    </p>
                    <svg
                      className={`${
                        values.petSubscriptionType === 1
                          ? "opacity-100 stroke-orange-500 text-white "
                          : "text-gray-200 opacity-0 "
                      } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" />
                    </svg>
                  </div>
                  <p
                    className={`text-xl short:text-lg short:leading-tight leading-tight ${
                      values.petSubscriptionType === 1
                        ? "text-white"
                        : "text-gray-800"
                    }`}
                  >
                    US$
                    {targetCustomer.petSubscriptionType === 1 &&
                    targetCustomer.curSubscriptionPrice
                      ? (targetCustomer.curSubscriptionPrice / 100).toFixed(2)
                      : "7.49"}
                    /mo
                  </p>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, petSubscriptionType: 3 });
                  }}
                  className={`${
                    values.petSubscriptionType === 3
                      ? "border-lime-600  bg-lime-600 text-gray-200"
                      : "border-gray-200 hover:bg-gray-50 text-gray-400"
                  } transition-all group relative text-left w-full shadow-sm flex-1 px-3 short:px-2 py-2 rounded-md border`}
                  disabled={loadingSubPurchase}
                >
                  <div className="w-full flex items-center justify-between">
                    <p className="font-bold text-sm sm:text-base leading-tight sm:leading-tight">
                      Club
                      {targetCustomer.isPaidPetSubscriber &&
                      targetCustomer.petSubscriptionType === 3
                        ? " - Current"
                        : ""}
                    </p>
                    <svg
                      className={`${
                        values.petSubscriptionType === 3
                          ? "opacity-100 stroke-lime-600 text-white "
                          : "text-gray-200 opacity-0 "
                      } opacity-0 group-hover:opacity-100 transition-all h-4  fill-current `}
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6.5" cy="6.5" r="6.5" />
                      <path d="M3 6.72727L5.5 9L10 4" />
                    </svg>
                  </div>
                  <p
                    className={`text-xl short:text-lg short:leading-tight leading-tight ${
                      values.petSubscriptionType === 3
                        ? "text-white"
                        : "text-gray-800"
                    }`}
                  >
                    US$
                    {targetCustomer.petSubscriptionType === 3 &&
                    targetCustomer.curSubscriptionPrice
                      ? (targetCustomer.curSubscriptionPrice / 100).toFixed(2)
                      : "99"}
                    /mo
                  </p>
                </button>
              </div>
            )}
            {targetCustomer.isPaidPetSubscriber ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setValues({ ...values, petSubscriptionType: -1 });
                }}
                className={`${
                  values.petSubscriptionType === -1
                    ? "border-red-500 "
                    : "border-gray-200 hover:bg-gray-50"
                } transition-all mt-2 group relative text-left w-full shadow-sm flex-1 px-3 short:px-2.5 py-2 rounded-md border focus:border-red-500 focus:ring-red-500`}
                disabled={loadingSubPurchase}
              >
                <div className="w-full flex items-center justify-between">
                  <p
                    className={`${
                      values.petSubscriptionType === -1
                        ? "text-red-800"
                        : "text-gray-400"
                    } text-sm sm:text-base truncate`}
                  >
                    {petSubscription &&
                    petSubscription.petId !== targetCustomer.id
                      ? "Leave Lucky Pet Plan"
                      : "Cancel subscription"}
                  </p>
                  <svg
                    className={`${
                      values.petSubscriptionType === -1
                        ? "text-red-500 opacity-100"
                        : "text-gray-200 opacity-0 "
                    } group-hover:opacity-100 transition-all h-4 fill-current `}
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6.5" cy="6.5" r="6.5" />
                    <path d="M3 6.72727L5.5 9L10 4" stroke="white" />
                  </svg>
                </div>
              </button>
            ) : (
              <></>
            )}

            {/* {values.petSubscriptionType === -1 ? (
            <div className="flex flex-col items-start w-full justify-start px-2 mt-2 text-gray-600">
              <p>&#8227; You will not be charged anymore</p>
              <p>
                &#8227; You can still use your remaining balance for discounts
              </p>
              <p>
                &#8227; You will not be able to play The Lucky Pet
                for credits or lives
              </p>
              <p>&#8227; You will be missed 😿</p>
            </div>
          ) : ( */}
            <div className="flex text-base flex-col items-start w-full justify-start px-1 sm:px-2 mt-2 text-gray-600">
              {/* <li>You will only be charged for month 1 today</li> */}
              {/* <p>&#8227; No pop-up ads during battle and focus modes</p> */}
              {/* <p>
                &#8227; Play focus mode and async battles offline, without Wi-Fi
              </p> */}
              {proBenefits.map((proBenefit, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between w-full short:text-sm"
                >
                  <p>
                    {proBenefit}{" "}
                    {index === 1
                      ? values.petSubscriptionType === 0
                        ? "7 pets"
                        : values.petSubscriptionType === 3
                        ? "30 pets per account"
                        : "7 pets per account"
                      : ""}
                  </p>
                  {values.petSubscriptionType === -1 ? (
                    <svg
                      className="h-3 mr-0.5 fill-current"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                    </svg>
                  ) : (
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  )}
                </div>
              ))}
              {/* <p>
                + Get unlimited skips and unlimited questions of the same
                category on focus mode
              </p> */}
              {/* <p>+ Save your drafts and bookmarks in the cloud</p> */}

              {/* <p>+ See who viewed your content</p> */}
              {petSubscription &&
              petSubscription.petId !== targetCustomer.id ? (
                <>
                  <div className="flex items-center justify-between w-full text-black">
                    <p className="font-semibold">
                      {values.petSubscriptionType === -1
                        ? "You will no longer have access to Pro"
                        : "Free access to Lucky Pet Pro"}
                    </p>
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  </div>
                </>
              ) : values.petSubscriptionType === -1 ? (
                <>
                  <div className="flex items-center justify-between w-full text-black">
                    <p className="font-semibold">
                      {petSubscription && petSubscription.numOfMembers > 1
                        ? petSubscription.numOfMembers + " people"
                        : "You"}{" "}
                      will no longer have access to Pro
                    </p>
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  </div>
                </>
              ) : values.petSubscriptionType === 0 ? (
                <>
                  {/* <p>
                    &#8227; Use credits in-store or{" "}
                    <span className="font-semibold">
                      cash out with PayPal at $20
                    </span>
                  </p> */}
                </>
              ) : values.petSubscriptionType === 1 ? (
                <>
                  <div className="flex items-center justify-between w-full text-black short:text-sm">
                    <p className="font-semibold">
                      2 Pro accounts under one roof
                    </p>
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  </div>
                </>
              ) : values.petSubscriptionType === 2 ? (
                <>
                  <div className="flex items-center justify-between w-full text-black short:text-sm">
                    <p className="font-semibold">
                      Up to 6 Pro accounts under one roof
                    </p>
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between w-full text-black short:text-sm">
                    <p className="font-semibold">
                      Up to 30 Pro accounts under one entity
                    </p>
                    <svg
                      className="h-3 fill-current"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.79508 10.875L1.62508 6.70498L0.205078 8.11498L5.79508 13.705L17.7951 1.70498L16.3851 0.294983L5.79508 10.875Z" />
                    </svg>
                  </div>
                </>
              )}
              {/* <p>
                &#8227; Use your Lucky Pet credits to buy amazing products and
                powerups
              </p> */}
              {/* {values.petSubscriptionType === 1 ? (
                <>
                  <p>
                    &#8227; Get 1 extra life everytime you win 2 TNs in a row
                  </p>
                  <p>&#8227; Change your answer before timer runs out</p>
                  <p>&#8227; Get 5% discount on powerups</p>
                  <p>&#8227; Surrender and get 30% of predicted TN win</p>
                  <p>&#8227; Get 50% discount on delivery fees</p>

                  <p>&#8227; Get access to new features and games first</p>
                </>
              ) : (
                <></>
              )} */}
              {/* <p>
                &#8227; Credits you won as a free user will not be available for
                cashout
              </p> */}
              {/* <p>&#8227; Billed weekly - cancel any time</p> */}
            </div>
          </div>

          {!targetCustomer.isPaidPetSubscriber ? (
            <div className="w-full mb-6">
              <label
                htmlFor="paymentInfo"
                className=" sm:text-base text-sm font-semibold text-gray-700"
              >
                Payment info{" "}
                {errors && errors.paymentError && (
                  <span className="text-red-500">{errors.paymentError}</span>
                )}
              </label>

              <CardInputField
                cardElemIsFocused={cardElemIsFocused}
                setCardElemIsFocused={setCardElemIsFocused}
                error={errors && errors.paymentError}
                setIsCardComplete={setIsCardComplete}
                disabled={false}
                title="Payment Info"
              />
            </div>
          ) : (
            <></>
          )}
          {/* <div className="flex-1"></div> */}
          {petSubscription &&
          petSubscription.petSubscriptionType === values.petSubscriptionType ? (
            <SubmitButton
              onClickFunc={(e) => {
                e.preventDefault();
                if (
                  targetCustomer.isPaidPetSubscriber &&
                  petSubscription &&
                  targetCustomer.id !== petSubscription.petId
                ) {
                  removePetFromSubscriptionPlan({
                    variables: { petId: targetCustomer.id },
                  });
                } else if (targetCustomer.isPaidPetSubscriber) {
                  setLoadingSubPurchase(true);

                  updatePetSubscription();
                } else {
                  buySubscriptionCallback();
                }
              }}
              disabled={true}
              isNotLoading={true}
              title={"Confirm"}
              errors={errors}
            />
          ) : (
            <SubmitButton
              onClickFunc={(e) => {
                e.preventDefault();
                if (
                  targetCustomer.isPaidPetSubscriber &&
                  petSubscription &&
                  targetCustomer.id !== petSubscription.petId
                ) {
                  removePetFromSubscriptionPlan({
                    variables: { petId: targetCustomer.id },
                  });
                } else if (targetCustomer.isPaidPetSubscriber) {
                  setLoadingSubPurchase(true);

                  updatePetSubscription();
                } else {
                  buySubscriptionCallback();
                }
              }}
              disabled={loadingSubPurchase}
              title={"Confirm"}
              errors={errors}
            />
          )}
          {targetCustomer.isPaidPetSubscriber ? (
            <p className="text-center w-full text-gray-600 mt-4 mb-0 sm:mb-6 text-base">
              Have a question?{" "}
              <a
                href="mailto:info@thelucky.pet"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:text-black transition-all"
              >
                Contact support
              </a>
            </p>
          ) : (
            <p className="text-center w-full text-gray-600 mt-4 mb-0 sm:mb-6 text-base">
              Have a pro code?{" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  toggleProInviteCodeForm();
                }}
                disabled={
                  loadingRemovePetFromSubscriptionPlan || loadingSubPurchase
                }
                className="text-blue-600 hover:text-black transition-all"
              >
                Click here
              </button>
            </p>
          )}
        </form>
      </div>
    </>
  );
}

export default BuyPetSubscriptionForm;
