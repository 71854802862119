import React from "react";

export default function Logo({ dimensions }) {
  return (
    <svg
      className={`${dimensions || "w-4"} fill-current aspect-square`}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6667 38.9559C31.4166 37.8781 39 29.6122 39 19.575C39 8.8054 30.2696 0.0749512 19.5 0.0749512C8.73045 0.0749512 0 8.8054 0 19.575C0 29.6122 7.58344 37.8781 17.3333 38.9559V32.2443C14.4032 31.324 12.2778 28.5866 12.2778 25.3527C12.2778 21.364 15.5113 18.1305 19.5 18.1305C23.4887 18.1305 26.7222 21.364 26.7222 25.3527C26.7222 28.5866 24.5968 31.324 21.6667 32.2443V38.9559Z"
      />
    </svg>
  );
}
