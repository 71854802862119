import React from "react";
import { useNavigate } from "react-router-dom";

export default function SellerNav({ page, showComingSoon, setShowComingSoon }) {
  const navigate = useNavigate();
  return (
    <div className="w-64 hidden lg:flex items-center justify-start flex-col p-4 bg-white rounded-xl">
      <button
        onClick={() => navigate("/business/dashboard")}
        className={`${
          page === "Home" ? "bg-gray-100" : "hover:bg-gray-200"
        } w-full space-x-2 rounded p-2 transition-all flex items-center justify-start`}
      >
        <svg
          className="w-4 stroke-gray-500"
          viewBox="0 0 40 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 42V22H26V42M2 16L20 2L38 16V38C38 39.0609 37.5786 40.0783 36.8284 40.8284C36.0783 41.5786 35.0609 42 34 42H6C4.93913 42 3.92172 41.5786 3.17157 40.8284C2.42143 40.0783 2 39.0609 2 38V16Z"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="">Home</p>
      </button>
      <button
        onClick={() => navigate("/business/ads")}
        className={`${
          page === "Ads" ? "bg-gray-100" : "hover:bg-gray-200"
        } w-full space-x-2 rounded p-2 transition-all flex items-center justify-start`}
      >
        <svg className="w-4 fill-gray-500" viewBox="0 0 24 24">
          <path d="M14.207 19.781l-1.871 1.817c-.376.365-.921.493-1.419.334l-3.895-1.222c-.482-.154-.847-.553-.956-1.049l-.582-2.631 2.095.662.203.922c.073.328.314.592.633.695l2.112.656c.331.106.692.021.942-.222l.641-.623 2.097.661zm9.776-8.59c-.224-5.597-2.639-8.798-4.502-8.095-1.566.595-11.761 4.822-16.179 6.65-.32.132-.605.185-.86.185-.736 0-1.23-.437-1.657-.646-1.091 1.43-1.034 4.393.111 5.777.441-.307.969-.829 1.768-.829.195 0 .407.031.639.104 4.432 1.405 15.218 4.804 16.838 5.3 2.055.629 4.065-2.843 3.842-8.446zm-20.076 1.239c-.415-.132-.606-.52.159-.836 0 0 10.01-4.143 13.177-5.438-1.164 3.018-1.073 7.447.304 10.576-3.959-1.244-10.391-3.272-13.64-4.302zm17.063 4.729c-.173.296-.588.335-.812.072-2.105-2.472-2.325-9.001-.41-11.558.21-.278.63-.263.819.026 1.796 2.752 1.883 8.923.403 11.46z" />
        </svg>

        <p className="">Ads</p>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowComingSoon(true);
        }}
        className={`${
          page === "Products" ? "bg-gray-100" : "hover:bg-gray-200"
        } opacity-40 w-full space-x-2 rounded p-2 transition-all flex items-center justify-start`}
      >
        <svg
          className="w-4 stroke-gray-500"
          viewBox="0 0 40 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.54 11.92L20 22.02L37.46 11.92M20 42.16V22M38 30V14C37.9993 13.2985 37.8141 12.6096 37.4631 12.0023C37.112 11.395 36.6075 10.8907 36 10.54L22 2.53999C21.3919 2.18891 20.7021 2.00409 20 2.00409C19.2979 2.00409 18.6081 2.18891 18 2.53999L4 10.54C3.39253 10.8907 2.88796 11.395 2.53692 12.0023C2.18589 12.6096 2.00072 13.2985 2 14V30C2.00072 30.7014 2.18589 31.3904 2.53692 31.9977C2.88796 32.605 3.39253 33.1093 4 33.46L18 41.46C18.6081 41.8111 19.2979 41.9959 20 41.9959C20.7021 41.9959 21.3919 41.8111 22 41.46L36 33.46C36.6075 33.1093 37.112 32.605 37.4631 31.9977C37.8141 31.3904 37.9993 30.7014 38 30Z"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="">{showComingSoon ? "Coming soon..." : "Products"}</p>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowComingSoon(true);
        }}
        className={`${
          page === "Orders" ? "bg-gray-100" : "hover:bg-gray-200"
        } opacity-40 w-full space-x-2 rounded p-2 transition-all flex items-center justify-start`}
      >
        <svg
          className="w-4 stroke-gray-500"
          viewBox="0 0 40 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 10L8 2H32L38 10M2 10V38C2 39.0609 2.42143 40.0783 3.17157 40.8284C3.92172 41.5786 4.93913 42 6 42H34C35.0609 42 36.0783 41.5786 36.8284 40.8284C37.5786 40.0783 38 39.0609 38 38V10M2 10H38M28 18C28 20.1217 27.1571 22.1566 25.6569 23.6569C24.1566 25.1571 22.1217 26 20 26C17.8783 26 15.8434 25.1571 14.3431 23.6569C12.8429 22.1566 12 20.1217 12 18"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="">{showComingSoon ? "Coming soon..." : "Orders"}</p>
      </button>

      <a
        href="mailto:info@thelucky.pet"
        className={`${
          page === "Support" ? "bg-gray-100" : "hover:bg-gray-200"
        } w-full space-x-2 rounded p-2 transition-all flex items-center justify-start`}
      >
        <svg
          className="w-4 fill-gray-500"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 1.16667C10.2165 1.16667 12.8333 3.7835 12.8333 7C12.8333 10.2165 10.2165 12.8333 7 12.8333C3.7835 12.8333 1.16667 10.2165 1.16667 7C1.16667 3.7835 3.7835 1.16667 7 1.16667ZM7 0C3.13425 0 0 3.13425 0 7C0 10.8657 3.13425 14 7 14C10.8657 14 14 10.8657 14 7C14 3.13425 10.8657 0 7 0ZM7.72917 9.91667C7.72917 10.3192 7.40308 10.6458 7 10.6458C6.59808 10.6458 6.27083 10.3192 6.27083 9.91667C6.27083 9.51417 6.59808 9.1875 7 9.1875C7.40308 9.1875 7.72917 9.51417 7.72917 9.91667ZM8.54175 4.0845C8.18708 3.72517 7.658 3.52742 7.05367 3.52742C5.782 3.52742 4.9595 4.43158 4.9595 5.83158H6.13258C6.13258 4.96475 6.61617 4.65733 7.02975 4.65733C7.39958 4.65733 7.79217 4.90292 7.82542 5.3725C7.86158 5.86658 7.59792 6.11742 7.26425 6.43475C6.44058 7.21817 6.42542 7.59733 6.42892 8.45775H7.5985C7.59092 8.07042 7.616 7.756 8.14392 7.18725C8.53883 6.76142 9.03 6.23175 9.03992 5.42442C9.04633 4.88542 8.87425 4.42167 8.54175 4.0845Z" />
        </svg>

        <p className="">Support</p>
      </a>
    </div>
  );
}
