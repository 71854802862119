import React, { useCallback, useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useNavigate, useParams } from "react-router";

import { useMutation, useQuery } from "@apollo/client";

import Confetti from "react-confetti";

import { customerClient } from "../../GraphqlApolloClients";

import { useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuyPetSubscriptionForm from "../../components/creator/BuyPetSubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";

import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import MiniNavBar from "../../components/creator/MiniNavBar";
import MultiMediaInputField from "../../components/creator/MultiMediaInputField";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import SelectActivityWSearch from "../../components/creator/SelectActivityWSearch";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import { CustomerAuthContext } from "../../context/customerAuth";
import {
  CREATE_PET_POST_MUTATION,
  FETCH_ACTIVITY_BY_SHORT_FORM,
  FETCH_CUSTOMER,
  FETCH_PET,
} from "../../util/gqlFunctions";

function CreatePost(props) {
  usePageTracking();

  let navigate = useNavigate();
  const size = useWindowSize();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const [errors, setErrors] = useState({});
  const { shortForm } = useParams();

  useEffect(() => {
    if (!customer) {
      navigate("/login");
    }
  }, [customer, navigate]);

  const { data: { getPetActivityByShortForm: targetActivity } = {} } = useQuery(
    FETCH_ACTIVITY_BY_SHORT_FORM,
    {
      variables: { shortForm: shortForm || "" },
    }
  );

  useEffect(() => {
    document.title = "Post | The Lucky Pet";
  }, []);

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  const [initializedActivity, setInitializedActivity] = useState(false);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });

  const { data: { getPetByCustomer: targetPet } = {} } = useQuery(FETCH_PET, {
    client: customerClient,
  });

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;

  const [previewMediaNames, setPreviewMediaNames] = useState([]);
  const [previewMediaURLs, setPreviewMediaURLs] = useState([]);
  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const { values, setValues, onChange, onChangeMedia } = useForm(
    createPetPostCallback,
    {
      interestingFact: "",
      mediaFiles: [],
      typeOfPost: 1,
      petActivityId: targetActivity?.id || "",
      mediaUrls: [],
    }
  );

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  useEffect(() => {
    if (!initializedActivity && targetActivity) {
      setValues({
        ...values,
        petActivityId: targetActivity.id,
        activityLogoUrl: targetActivity.logoUrl,
        activityName: targetActivity.name,
      });
      setInitializedActivity(true);
    }
  }, [setValues, targetActivity, initializedActivity, values]);

  const [petSubscriptionType, setPetSubscriptionType] = useState(null);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const stripe = useStripe();
  const elements = useElements();

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out thelucky.pet\n\n";

    textToShare += "It's really fun!!\n\n";

    textToShare += "#TheLuckyPet";

    if (navigator.share) {
      navigator
        .share({
          title: "Battle on Lucky Pet!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && targetPet) {
      setShouldPlaySoundEffects(
        targetPet.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, targetPet]);

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);

  function createPetPostCallback(e) {
    e.preventDefault();

    createPetPost();
  }

  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);

  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const toggleBuySubscriptionForm = (petSubscriptionType) => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setPetSubscriptionType(petSubscriptionType);

    if (petSubscriptionType === null) {
      setShouldOpenReviewForm(true);
    } else {
      setShouldOpenReviewForm(false);
    }
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setPetSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPetSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const [createPetPost, { loading: loadingCreatePetPost }] = useMutation(
    CREATE_PET_POST_MUTATION,
    {
      refetchQueries: [{ query: FETCH_PET }],
      update(_, { data: { createPetPost: createdPetPost } }) {
        setValues({
          ...values,
          interestingFact: "",
          mediaFiles: [],
          typeOfPost: 1,
          petActivityId: targetActivity?.id || "",
          mediaUrls: [],
        });

        setErrors({});

        navigate("/pet/" + targetPet.username);
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          if (!targetPet) {
            navigate("/register");
          }

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: customerClient,
    }
  );

  return (
    <div className="w-screen overflow-x-hidden h-full max-h-screen bg-light-blue">
      {shouldShowMiniNavBar && targetPet && (
        <MiniNavBar
          targetPet={targetPet}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetPet={targetPet}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && targetPet ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetPet={targetPet}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {petSubscriptionType !== null && stripe && elements && targetPet ? (
        <BuyPetSubscriptionForm
          stripe={stripe}
          elements={elements}
          targetPet={targetPet}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          petSubscriptionType={petSubscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          // subPopupReason={""}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetPet && (
        <CodeForm
          title="Lucky Pet Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetPet={targetPet}
            message={"Welcome to Lucky Pet Pro, " + targetPet.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetPet.petSubscriptionType === 0
                ? "Individual"
                : targetPet.petSubscriptionType === 1
                ? "Duo"
                : targetPet.petSubscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetPet={targetPet}
            message={"Welcome to Lucky Pet Pro, " + targetPet.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetPet={targetPet}
            message={
              "Tokens added to stash. Good luck, " + targetPet.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetPet={targetPet}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetPet && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetPet={targetPet}
        />
      )}
      <div
        className={`relative z-10 overflow-hidden flex flex-col h-screen max-h-screen short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 lg:pb-5`}
      >
        <TopBar
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetPet={targetPet}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          targetCustomer={targetCustomer}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="CreatePost"
          values={values}
          setValues={setValues}
        />
        <div className="flex pb-4 h-full flex-col w-full overflow-y-auto z-50 items-center text-left rounded-xl justify-start flex-1">
          <label
            htmlFor="username"
            className="font-semibold text-left w-full text-black sm:text-2xl text-xl "
          >
            Upload{" "}
            {errors.mediaFiles ? (
              <span className="text-red-600">{errors.mediaFiles}</span>
            ) : (
              <></>
            )}
          </label>
          <p className="mb-2 w-full text-left text-gray-600 sm:text-lg ">
            Max 10 photos.{" "}
            {targetPet && !targetPet.isPaidPetSubscriber ? (
              <span>
                Get{" "}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleBuySubscriptionForm(0);
                  }}
                  className="text-dark-blue underline cursor-pointer hover:text-black transition-all"
                >
                  Pro
                </span>{" "}
                to upload videos.
              </span>
            ) : (
              <></>
            )}
          </p>
          {/* <p className="mb-2 w-full text-left text-gray-600 sm:text-lg ">
            Get{" "}
            <span className="text-dark-blue underline cursor-pointer hover:text-black transition-all">
              Lucky Pet Pro
            </span>{" "}
            to upload videos.
          </p> */}

          <MultiMediaInputField
            name="mediaFiles"
            onChangeMedia={onChangeMedia}
            value={values.mediaUrls}
            setValues={setValues}
            previewMediaURLs={previewMediaURLs}
            setPreviewMediaURLs={setPreviewMediaURLs}
            values={values}
            previewMediaNames={previewMediaNames}
            setPreviewMediaNames={setPreviewMediaNames}
            disabled={loadingCreatePetPost}
            shouldEdit={false}
            error={errors.imageFiles}
            pet={targetPet}
          />

          <label
            htmlFor="activity"
            className="font-semibold mt-4 text-left w-full text-black sm:text-2xl text-xl "
          >
            Activity{" "}
            {errors.petActivityId ? (
              <span className="text-red-600">{errors.petActivityId}</span>
            ) : (
              <></>
            )}
          </label>
          <p className="mb-2 w-full text-left text-gray-600 sm:text-lg ">
            Can't find the activity?{" "}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleReviewForm();
              }}
              className="text-dark-blue underline cursor-pointer hover:text-black transition-all"
            >
              Request here
            </span>
            .
          </p>

          {/* <textarea
            type="text"
            rows={1}
            id="searchTerm"
            placeHolder="Swimming"
            className={`text-left shrink-0 mb-2 text-xl sm:text-2xl disabled:bg-white mt-1 focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
            value={values.searchTerm}
            onChange={onChange}
            disabled={loadingPetInfo}
          /> */}
          <SelectActivityWSearch
            values={values}
            setValues={setValues}
            error={errors?.petActivityId || null}
            disabled={loadingCreatePetPost}
            defaultActivity={targetActivity}
          />

          <label
            htmlFor="username"
            className="font-semibold mt-2 text-left w-full text-black sm:text-2xl text-xl "
          >
            Description{" "}
            {errors.interestingFact ? (
              <span className="text-red-600">{errors.interestingFact}</span>
            ) : (
              <></>
            )}
          </label>
          <p className="mb-2 w-full text-left text-gray-600 sm:text-lg ">
            Only{" "}
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (targetPet && targetPet.isPaidPetSubscriber) {
                  toggleBuySubscriptionForm(0);
                } else {
                }
              }}
              className={`${
                targetPet && targetPet.isPaidPetSubscriber
                  ? ""
                  : " text-dark-blue cursor-pointer   hover:text-black underline"
              } transition-all`}
            >
              Pro
            </span>{" "}
            members can see this.
          </p>

          <textarea
            type="text"
            rows={3}
            name="interestingFact"
            id="interestingFact"
            placeholder="This was so much fun..."
            className={`text-left shrink-0 text-xl sm:text-2xl disabled:bg-white mt-1 focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
            value={values.interestingFact}
            onChange={onChange}
            disabled={loadingCreatePetPost}
          />
        </div>

        <button
          disabled={loadingCreatePetPost || !targetPet}
          onClick={createPetPostCallback}
          className="w-full relative p-3 uppercase overflow-hidden py-4 short:py-2 disabled:bg-red-600/50 hover:bg-red-700 transition-all rounded-full font-bold bg-red-600 text-white  text-2xl short:text-lg  sm:text-2xl"
        >
          <div
            className={`${
              !loadingCreatePetPost
                ? "motion-safe:animate-fadeOutUpFast"
                : "motion-safe:animate-fadeDownFast"
            } bg-black absolute inset-0 flex items-center justify-center`}
          >
            <svg
              className={`w-6 motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>
          <p
            className={`${
              loadingCreatePetPost
                ? "motion-safe:animate-fadeOutDownFast"
                : "motion-safe:animate-fadeUpFast"
            } h-full`}
          >
            Post Memo
          </p>
        </button>
        {targetPet?.numOfPetPosts >= 1 ? (
          <p className="text-center leading-tight w-full text-gray-600 mt-4 sm:mb-0 mb-2 text-base">
            Your memo will only last 24 hours{" "}
            {targetPet && targetPet.isPaidPetSubscriber ? (
              " unless it earns 100 coins."
            ) : (
              <span>
                Get{" "}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleBuySubscriptionForm(0);
                  }}
                  className="text-dark-blue underline cursor-pointer hover:text-black transition-all"
                >
                  Lucky Pet Pro
                </span>{" "}
                to unlock core memories which last forever if they earn 100
                coins.
              </span>
            )}
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CreatePost;
