import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";
import SubmitButton from "./SubmitButton";
import TagsInput from "./TagsInput";

import { CREATE_PUZZLE_MUTATION, FETCH_PUZZLES } from "../../util/gqlFunctions";
import AdMediaInputField from "./AdMediaInputField";
import TextInputField from "./TextInputField";

function MakePuzzleForm({ setShouldOpenMakePuzzleForm }) {
  const [errors, setErrors] = useState({});

  const { onChange, values, onChangeNumber, setValues, onChangeMedia } =
    useForm(createPuzzleCallback, {
      name: "",
      shortForm: "",
      logoFile: null,
      accentColor: 0,
      interestingFact: "",
      tags: [],
      shouldIncludeInTN: true,
      shouldIncludeInIntro: true,
      prizeCoins: 10,
      rows: 3,
      cols: 3,
    });

  const [previewImage0URL, setPreviewImage0URL] = useState("");
  const [previewImage1URL, setPreviewImage1URL] = useState("");

  const [createPuzzle, { loading: loadingCreatePuzzle }] = useMutation(
    CREATE_PUZZLE_MUTATION,
    {
      refetchQueries: [{ query: FETCH_PUZZLES }],
      update(_, { data: { createPuzzle: createdPuzzle } }) {
        setValues({
          ...values,
          ...createdPuzzle,
        });

        setShouldOpenMakePuzzleForm(false);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function createPuzzleCallback(e) {
    e.preventDefault();
    createPuzzle();
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenMakePuzzleForm(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenMakePuzzleForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Add puzzle
        </p>
        <p className="text-gray-500 w-full leading-tight short:text-sm">
          This will allow people to start solving
        </p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-4">
          <TextInputField
            title={"Title"}
            name="title"
            type="text"
            error={errors.title}
            disabled={loadingCreatePuzzle}
            onChange={onChange}
            maxLen={100}
            value={values.title}
          />

          <div className="w-full">
            <div className="flex mb-2 items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="image0File"
                className="font-semibold text-gray-700 "
              >
                Images (670x1000){" "}
                {errors.image0File && (
                  <span className="text-red-500">{errors.image0File}</span>
                )}
              </label>
            </div>
            <div
              className={`w-full h-full flex space-x-4 items-center snap-x snap-mandatory justify-start`}
            >
              <div className="w-full  overflow-hidden  rounded h-full border border-gray-300 aspect-[.67]">
                <AdMediaInputField
                  title="Image0"
                  name="image0File"
                  onChangeMedia={onChangeMedia}
                  value={values.image0Url}
                  shouldNotEdit={false}
                  previewPhotoURL={previewImage0URL}
                  setPreviewPhotoURL={setPreviewImage0URL}
                  values={values}
                  error={errors.image0File}
                />
              </div>
              <div className="w-full overflow-hidden   rounded h-full border border-gray-300 aspect-[.67]">
                <AdMediaInputField
                  title="Image1"
                  name="image1File"
                  onChangeMedia={onChangeMedia}
                  value={values.image1Url}
                  shouldNotEdit={false}
                  previewPhotoURL={previewImage1URL}
                  setPreviewPhotoURL={setPreviewImage1URL}
                  values={values}
                  error={errors.image1File}
                />
              </div>
            </div>
          </div>

          <TextInputField
            title="Rows"
            name="rows"
            type="number"
            min={1}
            max={10}
            value={values.rows}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.rows}
            disabled={loadingCreatePuzzle}
          />
          <TextInputField
            title="Columns"
            name="cols"
            type="number"
            min={1}
            max={10}
            value={values.cols}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.cols}
            disabled={loadingCreatePuzzle}
          />

          <TextInputField
            title="Interesting fact"
            name="interestingFact"
            type="multiline"
            value={values.interestingFact}
            onChange={onChange}
            error={errors.interestingFact}
            disabled={loadingCreatePuzzle}
            maxLen={200}
            rows={"3"}
          />

          <TagsInput
            title="Tags"
            name="tags"
            type="multiline"
            value={values.tags}
            values={values}
            setValues={setValues}
            error={errors.tags}
            disabled={loadingCreatePuzzle}
            maxTags={20}
          />

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="prizeCoins"
                className="font-semibold text-gray-700 "
              >
                Prize{" "}
                {errors.prizeCoins && (
                  <span className="text-red-500">{errors.prizeCoins}</span>
                )}
              </label>
            </div>
            <div className="flex mt-1 overflow-x-auto items-center justify-start space-x-2">
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((prizeCoins) => (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, prizeCoins: prizeCoins });
                  }}
                  className={`${
                    values.prizeCoins === prizeCoins
                      ? "bg-blue-50 border-blue-500 "
                      : "hover:bg-gray-50 border-gray-200 "
                  }  shadow-sm rounded-lg space-x-1 px-2 py-1 border transition-all flex items-center justify-start focus:border-indigo-500 focus:ring-indigo-500`}
                >
                  <svg
                    className="w-5"
                    viewBox="0 0 800 800"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M400 800C608.641 800 777.778 630.863 777.778 422.222C777.778 213.581 608.641 44.4443 400 44.4443C191.36 44.4443 22.2227 213.581 22.2227 422.222C22.2227 630.863 191.36 800 400 800Z"
                      fill="#F4900C"
                    />
                    <path
                      d="M400 755.556C608.641 755.556 777.778 586.419 777.778 377.778C777.778 169.137 608.641 0 400 0C191.36 0 22.2227 169.137 22.2227 377.778C22.2227 586.419 191.36 755.556 400 755.556Z"
                      fill="#FFCC4D"
                    />
                    <path
                      d="M400 711.111C571.822 711.111 711.111 571.822 711.111 400C711.111 228.178 571.822 88.8887 400 88.8887C228.178 88.8887 88.8887 228.178 88.8887 400C88.8887 571.822 228.178 711.111 400 711.111Z"
                      fill="#FFE8B6"
                    />
                    <path
                      d="M400 688.889C571.822 688.889 711.111 549.6 711.111 377.778C711.111 205.956 571.822 66.667 400 66.667C228.178 66.667 88.8887 205.956 88.8887 377.778C88.8887 549.6 228.178 688.889 400 688.889Z"
                      fill="#FFAC33"
                    />
                    <path
                      d="M399.02 148C338.02 148 279.623 172.232 236.674 215.365C193.725 258.499 169.742 317 170.002 378C170.262 438.998 194.745 497.502 238.059 540.635C281.376 583.767 339.982 608 400.98 608C372.86 608 345.334 599.66 321.885 584.038C298.439 568.415 280.12 546.208 269.249 520.228C258.375 494.249 255.439 465.659 260.807 438.079C266.176 410.498 279.61 385.165 299.408 365.28C319.209 345.396 344.486 331.854 372.042 326.368C399.6 320.882 428.2 323.698 454.225 334.459C480.251 345.221 502.532 363.444 518.256 386.826C533.98 410.206 542.435 437.698 542.553 465.819C542.714 503.527 527.89 539.691 501.338 566.355C474.789 593.019 438.69 608 400.98 608C461.98 608 520.377 583.767 563.326 540.635C606.275 497.502 630.258 438.998 629.998 378C629.738 317 605.255 258.499 561.941 215.365C518.624 172.232 460.021 148 399.02 148Z"
                      fill="#FFD983"
                    />
                  </svg>
                  <p>{prizeCoins}</p>
                </button>
              ))}
            </div>
          </div>

          <SubmitButton
            onClickFunc={createPuzzleCallback}
            disabled={loadingCreatePuzzle}
            title={"SUBMIT"}
            errors={errors}
          />
        </div>
      </form>
    </div>
  );
}

export default MakePuzzleForm;
