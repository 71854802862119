import React, { useContext, useEffect, useState } from "react";

// import  usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
// import  Confetti from "react-confetti";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { adminClient } from "../../GraphqlApolloClients";
import Footer from "../../components/creator/Footer";
import { AdminAuthContext } from "../../context/adminAuth";
// import  LivestreamAnswerBox from "../../components/creator/LivestreamAnswerBox";
import AdminProductBox from "../../components/creator/AdminProductBox";
import EditProduct from "../../components/creator/EditProduct";

import AdminAdBox from "../../components/creator/AdminAdBox";
import AdminBrandContactBox from "../../components/creator/AdminBrandContactBox";
import AdminBreedBox from "../../components/creator/AdminBreedBox";
import AdminCityBox from "../../components/creator/AdminCityBox";
import AdminCustCashoutBox from "../../components/creator/AdminCustCashoutBox";
import AdminPetActivityBox from "../../components/creator/AdminPetActivityBox";
import AdminPetPostBox from "../../components/creator/AdminPetPostBox";
import AdminTNReviewBox from "../../components/creator/AdminTNReviewBox";
import CreateBreedForm from "../../components/creator/CreateBreedForm";
import CreateCityForm from "../../components/creator/CreateCityForm";
import EditBreedForm from "../../components/creator/EditBreedForm";
import EditCityForm from "../../components/creator/EditCityForm";
import EditPetActivityForm from "../../components/creator/EditPetActivityForm";
import EditSellerAd from "../../components/creator/EditSellerAd";

import AdminPetBox from "../../components/creator/AdminPetBox";
import AdminPuzzleBox from "../../components/creator/AdminPuzzleBox";
import EditPuzzleForm from "../../components/creator/EditPuzzleForm";
import MakePetActivityForm from "../../components/creator/MakePetActivityForm";
import MakePuzzleForm from "../../components/creator/MakePuzzleForm";
import TopBar from "../../components/creator/TopBar";
import {
  CREATE_AD_MUTATION,
  CREATE_PRODUCT,
  FETCH_ADMIN,
  FETCH_ALL_ADS,
  FETCH_ALL_SEARCHES,
  FETCH_BRAND_CONTACTS,
  FETCH_CUSTOMER_STRIPE_SUBS,
  FETCH_PENDING_CASHOUTS,
  FETCH_PET_ACTIVITIES,
  FETCH_PET_POSTS,
  FETCH_PETS,
  FETCH_PRODUCTS,
  FETCH_PUZZLES,
  FETCH_RECOMMENDED_BREEDS,
  FETCH_RECOMMENDED_CITIES,
  FETCH_REVIEWS,
  TOGGLE_SEARCH_TERM_CHECKED_BY_ADMIN_MUTATION,
} from "../../util/gqlFunctions";

function AdminDashboard(props) {
  // usePageTracking();
  const { typeOfData } = useParams();

  let navigate = useNavigate();
  const { admin, logoutAdmin: logout } = useContext(AdminAuthContext);

  useEffect(() => {
    document.title = "Admin Dash | The Lucky Pet";
  }, []);

  const { data: { getAdmin: adminInfo } = {}, loading: loadingAdminInfo } =
    useQuery(FETCH_ADMIN, {
      client: adminClient,
    });

  const [checkedIsAdminLoggedIn, setCheckedIsAdminLoggedIn] = useState(false);

  useEffect(() => {
    if (admin && !loadingAdminInfo && !adminInfo && !checkedIsAdminLoggedIn) {
      logout();
      setCheckedIsAdminLoggedIn(true);
    }
  }, [admin, loadingAdminInfo, adminInfo, logout, checkedIsAdminLoggedIn]);

  useEffect(() => {
    if (!admin) {
      navigate("/adminLogin");
    }
  }, [admin, navigate]);

  const [petIndexToScrollIntoView, setPetIndexToScrollIntoView] =
    useState(null);
  const [petIndexInView, setPetIndexInView] = useState(0);

  const [
    luckyBrandContactIndexToScrollIntoView,
    setLuckyBrandContactIndexToScrollIntoView,
  ] = useState(null);
  const [luckyBrandContactIndexInView, setLuckyBrandContactIndexInView] =
    useState(0);

  const { data: { getPets: pets } = {}, loading: loadingPets } = useQuery(
    FETCH_PETS,
    {
      client: adminClient,
    }
  );

  const {
    data: { getBrandContacts: brandContacts } = {},
    loading: loadingBrandContacts,
  } = useQuery(FETCH_BRAND_CONTACTS, {
    client: adminClient,
  });

  const {
    data: { getAllSearches: allSearches } = {},
    // loading: loadingAllSearches,
  } = useQuery(FETCH_ALL_SEARCHES, {
    client: adminClient,
    variables: { pageSize: 10, seenIds: [], isCheckedByAdmin: false },
  });

  const [errors, setErrors] = useState({});

  const {
    data: { getReviews: pendingTNReviews } = {},
    loading: loadingTNReviews,
  } = useQuery(FETCH_REVIEWS, {
    client: adminClient,
  });

  // const {
  //   data: { getForumAdminRequests: forumAdminRequests } = {},
  //   loading: loadingForumAdminRequests,
  // } = useQuery(FETCH_FORUM_ADMIN_REQUESTS, {
  //   client: adminClient,
  //   variables: { seenIds: [], pageSize: 20 },
  // });

  const [tnReviewIndexToScrollIntoView, setTriviaReviewIndexToScrollIntoView] =
    useState(null);
  const [triviaReviewIndexInView, setTriviaReviewIndexInView] = useState(0);

  const {
    data: { getPendingCashouts: pendingCashouts } = {},
    loading: loadingPendingCashouts,
  } = useQuery(FETCH_PENDING_CASHOUTS, {
    client: adminClient,
  });

  const [cashoutIndexToScrollIntoView, setCashoutIndexToScrollIntoView] =
    useState(null);
  const [cashoutIndexInView, setCashoutIndexInView] = useState(0);

  const [targetProduct, setTargetProduct] = useState(null);

  const { data: { getProducts: products } = {}, loading: loadingProducts } =
    useQuery(FETCH_PRODUCTS, {
      client: adminClient,
    });

  const [productIndexToScrollIntoView, setProductIndexToScrollIntoView] =
    useState(null);
  const [productIndexInView, setProductIndexInView] = useState(0);

  const [shouldOpenCreateCityForm, setShouldOpenCreateCityForm] =
    useState(false);

  const [shouldOpenCreateBreedForm, setShouldOpenCreateBreedForm] =
    useState(false);

  const [createProduct, { loading: loadingCreateProduct }] = useMutation(
    CREATE_PRODUCT,
    {
      refetchQueries: [{ query: FETCH_PRODUCTS }],
      update(_, { data: { createProduct: product } }) {},
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
    }
  );

  const [
    toggleSearchTermCheckedByAdmin,
    { loading: loadingToggleSearchTermCheckedByAdmin },
  ] = useMutation(TOGGLE_SEARCH_TERM_CHECKED_BY_ADMIN_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_ALL_SEARCHES,
        variables: { pageSize: 10, seenIds: [], isCheckedByAdmin: false },
      },
    ],
    update(_, { data }) {},
    onError(err) {
      console.log(targetPetPost);
      console.log(err);
      console.log(errors);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    client: adminClient,
  });

  const [targetAd, setTargetAd] = useState(null);

  const { data: { getAllAds: ads } = {}, loading: loadingAds } = useQuery(
    FETCH_ALL_ADS,
    {
      client: adminClient,
      variables: { siteId: 1 },
    }
  );

  const [adIndexToScrollIntoView, setAdIndexToScrollIntoView] = useState(null);
  const [adIndexInView, setAdIndexInView] = useState(0);

  const [createAd, { loading: loadingCreateAd }] = useMutation(
    CREATE_AD_MUTATION,
    {
      refetchQueries: [{ query: FETCH_ALL_ADS, variables: { siteId: 1 } }],
      update(_, { data: { createAd: ad } }) {},
      onError(err) {
        console.log(err);
        // setValues({ ...values, shouldDelete: false });
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: adminClient,
      variables: { siteId: 1 },
    }
  );

  const { data: { getAllPuzzles: puzzles } = {}, loading: loadingPuzzles } =
    useQuery(FETCH_PUZZLES, {
      client: adminClient,
    });

  const {
    data: { getPetActivities: petActivities } = {},
    loading: loadingPetActivities,
  } = useQuery(FETCH_PET_ACTIVITIES, {
    client: adminClient,
  });

  const [cityIndexInView, setCityIndexInView] = useState(0);
  const [cityIndexToScrollIntoView, setCityIndexToScrollIntoView] =
    useState(null);
  const {
    data: { getRecommendedCitiesBySearch: cities } = {},
    loading: loadingCities,
  } = useQuery(FETCH_RECOMMENDED_CITIES, {
    client: adminClient,
    variables: {
      seenIds: [],
      pageSize: 20,
    },
  });
  const [breedIndexInView, setBreedIndexInView] = useState(0);
  const [breedIndexToScrollIntoView, setBreedIndexToScrollIntoView] =
    useState(null);
  const {
    data: { getRecommendedBreedsBySearch: breeds } = {},
    loading: loadingBreeds,
  } = useQuery(FETCH_RECOMMENDED_BREEDS, {
    client: adminClient,
    variables: {
      seenIds: [],
      pageSize: 20,
    },
  });

  const { data: { getAllPetPosts: petPosts } = {}, loading: loadingPetPosts } =
    useQuery(FETCH_PET_POSTS, {
      client: adminClient,
      variables: {
        seenIds: [],
        pageSize: 10,
      },
      // pollInterval: 5000,
      // fetchPolicy: "cache-and-network",
    });

  const [targetPetActivity, setTargetPetActivity] = useState(null);
  const [targetPetPost, setTargetPetPost] = useState(null);
  const [targetPuzzle, setTargetPuzzle] = useState(null);

  const [
    petActivityIndexToScrollIntoView,
    setPetActivityIndexToScrollIntoView,
  ] = useState(null);
  const [petActivityIndexInView, setPetActivityIndexInView] = useState(0);
  const [shouldOpenMakePetActivityForm, setShouldOpenMakePetActivityForm] =
    useState(false);

  const [puzzleIndexToScrollIntoView, setPuzzleIndexToScrollIntoView] =
    useState(null);
  const [puzzleIndexInView, setPuzzleIndexInView] = useState(0);
  const [shouldOpenMakePuzzleForm, setShouldOpenMakePuzzleForm] =
    useState(false);

  const [petPostIndexToScrollIntoView, setPetPostIndexToScrollIntoView] =
    useState(null);
  const [petPostIndexInView, setPetPostIndexInView] = useState(0);

  const {
    data: { getCustomerStripeSubscriptions: custStripeSubscriptions } = {},
    loading: loadingCustStripeSubscriptions,
  } = useQuery(FETCH_CUSTOMER_STRIPE_SUBS, {
    client: adminClient,
    variables: { status: "past_due" },
  });

  if (custStripeSubscriptions && !loadingCustStripeSubscriptions) {
    console.log(custStripeSubscriptions);
  }

  // const [forumIndexToScrollIntoView, setForumIndexToScrollIntoView] =
  //   useState(null);
  // const [forumIndexInView, setForumIndexInView] = useState(0);

  // const [targetContent, setTargetContent] = useState(null);
  const [typeOfView, setTypeOfView] = useState(typeOfData || "pets");
  const [targetCity, setTargetCity] = useState(null);
  const [targetBreed, setTargetBreed] = useState(null);

  return (
    <div
      // ref={pageTopRef}
      className="w-screen relative overflow-x-hidden h-full min-h-screen bg-light-blue"
    >
      {targetProduct !== null &&
        products &&
        products.length > 0 &&
        products[targetProduct] && (
          <EditProduct
            targetProduct={products[targetProduct]}
            setTargetProduct={setTargetProduct}
          />
        )}

      {targetAd !== null && ads && ads.length > 0 && ads[targetAd] && (
        <EditSellerAd
          isAdmin={true}
          targetAd={ads[targetAd]}
          setTargetAd={setTargetAd}
        />
      )}

      {shouldOpenMakePetActivityForm ? (
        <MakePetActivityForm
          setShouldOpenMakePetActivityForm={setShouldOpenMakePetActivityForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenMakePuzzleForm ? (
        <MakePuzzleForm
          setShouldOpenMakePuzzleForm={setShouldOpenMakePuzzleForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenCreateCityForm ? (
        <CreateCityForm
          setShouldOpenCreateCityForm={setShouldOpenCreateCityForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenCreateBreedForm ? (
        <CreateBreedForm
          setShouldOpenCreateBreedForm={setShouldOpenCreateBreedForm}
        />
      ) : (
        <></>
      )}

      {targetPuzzle !== null &&
        puzzles &&
        puzzles.length > 0 &&
        puzzles[targetPuzzle] && (
          <EditPuzzleForm
            targetPuzzle={puzzles[targetPuzzle]}
            setTargetPuzzle={setTargetPuzzle}
          />
        )}

      {targetPetActivity !== null &&
        petActivities &&
        petActivities.length > 0 &&
        petActivities[targetPetActivity] && (
          <EditPetActivityForm
            targetPetActivity={petActivities[targetPetActivity]}
            setTargetPetActivity={setTargetPetActivity}
          />
        )}

      {targetCity !== null && cities && cities.length > 0 && (
        <EditCityForm
          targetCity={targetCity}
          setTargetCity={setTargetCity}
          navigate={navigate}
        />
      )}

      {targetBreed !== null && cities && cities.length > 0 && (
        <EditBreedForm
          targetCity={targetCity}
          setTargetCity={setTargetCity}
          targetBreed={targetBreed}
          navigate={navigate}
          setTargetBreed={setTargetBreed}
        />
      )}

      <div className="relative min-h-screen z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0 ">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Admin Dash"
        />

        {/* <div className="relative mb-16">
          <button
            onClick={(e) => {
              e.preventDefault();
              setLuckyMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                luckyMonthlySusbcriptionRevenueSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingAdminInfo ||
              loadingLuckyMonthlySubscriptionRevenueSummary ||
              luckyMonthlySusbcriptionRevenueSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setLuckyMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                luckyMonthlySusbcriptionRevenueSummaryIndexInView + 1
              );
            }}
            disabled={
              luckyMonthlySubscriptionRevenueSummary &&
              luckyMonthlySusbcriptionRevenueSummaryIndexInView >=
                luckyMonthlySubscriptionRevenueSummary.length - 1
                ? true
                : loadingAdminInfo ||
                  loadingLuckyMonthlySubscriptionRevenueSummary
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {adminInfo &&
              luckyMonthlySubscriptionRevenueSummary &&
              luckyMonthlySubscriptionRevenueSummary.length > 0 &&
              luckyMonthlySubscriptionRevenueSummary.map(
                (luckyMonthlySubscriptionEarning, index) => (
                  <AdminMonthlySubscriptionEarningBox
                    key={index}
                    index={index}
                    luckyMonthlySubscriptionEarning={
                      luckyMonthlySubscriptionEarning
                    }
                    adminInfo={adminInfo}
                    luckyMonthlySubscriptionRevenueSummaryIndexToScrollIntoView={
                      luckyMonthlySubscriptionRevenueSummaryIndexToScrollIntoView
                    }
                    setLuckyMonthlySubscriptionRevenueSummaryIndexInView={
                      setLuckyMonthlySubscriptionRevenueSummaryIndexInView
                    }
                    disabled={
                      loadingAdminInfo ||
                      loadingLuckyMonthlySubscriptionRevenueSummary
                    }
                  />
                )
              )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {luckyMonthlySubscriptionRevenueSummary &&
                luckyMonthlySubscriptionRevenueSummary.length > 1 &&
                luckyMonthlySubscriptionRevenueSummary.map(
                  (luckyMonthlySubscriptionEarning, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setLuckyMonthlySubscriptionRevenueSummaryIndexToScrollIntoView(
                          index
                        );
                      }}
                      className={`${
                        index ===
                        luckyMonthlySusbcriptionRevenueSummaryIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  )
                )}
            </div>
          </div>
        </div> */}

        {/* <div className="relative mb-16">
          <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
            Job revenue summary{" "}
            {luckyMonthlyJobRevenueSummary &&
            luckyMonthlyJobRevenueSummary.length > 0
              ? "(" + luckyMonthlyJobRevenueSummary.length + ")"
              : ""}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setLuckyMonthlyJobRevenueSummaryIndexToScrollIntoView(
                luckyMonthlyJobRevenueSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingAdminInfo ||
              loadingLuckyMonthlyJobRevenueSummary ||
              luckyMonthlyJobRevenueSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setLuckyMonthlyJobRevenueSummaryIndexToScrollIntoView(
                luckyMonthlyJobRevenueSummaryIndexInView + 1
              );
            }}
            disabled={
              luckyMonthlyJobRevenueSummary &&
              luckyMonthlyJobRevenueSummaryIndexInView >=
                luckyMonthlyJobRevenueSummary.length - 1
                ? true
                : loadingAdminInfo || loadingLuckyMonthlyJobRevenueSummary
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {adminInfo &&
              luckyMonthlyJobRevenueSummary &&
              luckyMonthlyJobRevenueSummary.length > 0 &&
              luckyMonthlyJobRevenueSummary.map(
                (luckyMonthlyJobEarning, index) => (
                  <AdminMonthlyJobEarningBox
                    key={index}
                    index={index}
                    luckyMonthlyJobEarning={luckyMonthlyJobEarning}
                    adminInfo={adminInfo}
                    luckyMonthlyJobRevenueSummaryIndexToScrollIntoView={
                      luckyMonthlyJobRevenueSummaryIndexToScrollIntoView
                    }
                    setLuckyMonthlyJobRevenueSummaryIndexToScrollIntoView={
                      setLuckyMonthlyJobRevenueSummaryIndexToScrollIntoView
                    }
                    setLuckyMonthlyJobRevenueSummaryIndexInView={
                      setLuckyMonthlyJobRevenueSummaryIndexInView
                    }
                    disabled={
                      loadingAdminInfo || loadingLuckyMonthlyJobRevenueSummary
                    }
                  />
                )
              )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {luckyMonthlyJobRevenueSummary &&
                luckyMonthlyJobRevenueSummary.length > 1 &&
                luckyMonthlyJobRevenueSummary.map(
                  (luckyMonthlySubscriptionEarning, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setLuckyMonthlyJobRevenueSummaryIndexToScrollIntoView(
                          index
                        );
                      }}
                      className={`${
                        index === luckyMonthlyJobRevenueSummaryIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  )
                )}
            </div>
          </div>
        </div> */}

        <div className="flex short:space-x-1 mb-6 space-x-2 overflow-x-auto items-center short:text-sm text-lg bg-white/20 w-full rounded-full short:px-1 short:py-1 px-2 py-2 shrink-0 overflow-y-hidden">
          {["Pets", "Sellers", "Games"].map((typeOView, index) => (
            <button
              key={typeOView}
              onClick={(e) => {
                e.preventDefault();
                setTypeOfView(typeOView.toLowerCase());
                navigate("/adminDashboard/" + typeOView.toLowerCase());
              }}
              className={`${
                typeOfView === typeOView.toLowerCase()
                  ? "bg-white text-black"
                  : "text-white "
              } text-black font-medium short:px-2 px-4 w-full py-1 hover:bg-white hover:text-black transition-all rounded-full`}
            >
              {typeOView}
            </button>
          ))}
        </div>

        {typeOfView === "pets" &&
          pendingCashouts &&
          pendingCashouts.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Cashouts{" "}
                {pendingCashouts && pendingCashouts.length > 0
                  ? "(" + pendingCashouts.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCashoutIndexToScrollIntoView(cashoutIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingPendingCashouts ||
                  cashoutIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCashoutIndexToScrollIntoView(cashoutIndexInView + 1);
                }}
                disabled={
                  pendingCashouts &&
                  cashoutIndexInView >= pendingCashouts.length - 1
                    ? true
                    : loadingAdminInfo || loadingPendingCashouts
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingCashouts &&
                  pendingCashouts.length > 0 &&
                  pendingCashouts.map((cashout, index) => (
                    <AdminCustCashoutBox
                      key={index}
                      index={index}
                      cashout={cashout}
                      adminInfo={adminInfo}
                      cashoutIndexToScrollIntoView={
                        cashoutIndexToScrollIntoView
                      }
                      setCashoutIndexInView={setCashoutIndexInView}
                      setCashoutIndexToScrollIntoView={
                        setCashoutIndexToScrollIntoView
                      }
                      cashoutIndexInView={cashoutIndexInView}
                      disabled={loadingAdminInfo || loadingPendingCashouts}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingCashouts &&
                    pendingCashouts.length > 1 &&
                    pendingCashouts.map((cashout, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setCashoutIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === cashoutIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )}

        {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Searches{" "}
              {allSearches && allSearches.length > 0
                ? "(" + allSearches.length + ")"
                : ""}
            </p>

            <div className="w-full bg-white p-2 rounded-xl">
              {allSearches && allSearches.length > 0 ? (
                allSearches.map((savedSearch, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full space-x-2 hover:bg-blue-100 transition-all p-2"
                  >
                    <p className="flex-1">
                      {savedSearch.searchTerm} -{" "}
                      {moment(savedSearch.lastSearchedAt).fromNow()}
                    </p>
                    <p>{savedSearch.count}</p>
                    <button
                      className=" text-black hover:text-red-600 transition-all"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleSearchTermCheckedByAdmin({
                          variables: {
                            searchTerm: savedSearch.searchTerm,
                          },
                        });
                      }}
                      disabled={loadingToggleSearchTermCheckedByAdmin}
                    >
                      <svg
                        className="w-2.5  fill-current"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                      </svg>
                    </button>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Pets {pets && pets.length > 0 ? "(" + pets.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPetIndexToScrollIntoView(petIndexInView - 1);
              }}
              disabled={loadingAdminInfo || loadingPets || petIndexInView <= 0}
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setPetIndexToScrollIntoView(petIndexInView + 1);
              }}
              disabled={
                pets && petIndexInView >= pets.length - 1
                  ? true
                  : loadingAdminInfo || loadingPets
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                pets &&
                pets.length > 0 &&
                pets.map((pet, index) => (
                  <AdminPetBox
                    key={index}
                    index={index}
                    navigate={navigate}
                    pet={pet}
                    adminInfo={adminInfo}
                    petIndexToScrollIntoView={petIndexToScrollIntoView}
                    setPetIndexInView={setPetIndexInView}
                    setPetIndexToScrollIntoView={setPetIndexToScrollIntoView}
                    petIndexInView={petIndexInView}
                    disabled={loadingAdminInfo || loadingPets}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                {pets &&
                  pets.length > 1 &&
                  pets.map((pet, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPetIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === petIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Sellers{" "}
              {brandContacts && brandContacts.length > 0
                ? "(" + brandContacts.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setLuckyBrandContactIndexToScrollIntoView(
                  luckyBrandContactIndexInView - 1
                );
              }}
              disabled={
                loadingAdminInfo ||
                loadingBrandContacts ||
                luckyBrandContactIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setLuckyBrandContactIndexToScrollIntoView(
                  luckyBrandContactIndexInView + 1
                );
              }}
              disabled={
                brandContacts &&
                luckyBrandContactIndexInView >= brandContacts.length - 1
                  ? true
                  : loadingAdminInfo || loadingBrandContacts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                brandContacts &&
                brandContacts.length > 0 &&
                brandContacts.map((luckyBrandContact, index) => (
                  <AdminBrandContactBox
                    key={index}
                    index={index}
                    navigate={navigate}
                    luckyBrandContact={luckyBrandContact}
                    adminInfo={adminInfo}
                    luckyBrandContactIndexToScrollIntoView={
                      luckyBrandContactIndexToScrollIntoView
                    }
                    setLuckyBrandContactIndexInView={
                      setLuckyBrandContactIndexInView
                    }
                    setLuckyBrandContactIndexToScrollIntoView={
                      setLuckyBrandContactIndexToScrollIntoView
                    }
                    luckyBrandContactIndexInView={luckyBrandContactIndexInView}
                    disabled={loadingAdminInfo || loadingBrandContacts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                {brandContacts &&
                  brandContacts.length > 1 &&
                  brandContacts.map((luckyBrandContact, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setLuckyBrandContactIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === luckyBrandContactIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Puzzles{" "}
              {puzzles && puzzles.length > 0 ? "(" + puzzles.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPuzzleIndexToScrollIntoView(puzzleIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingPuzzles || puzzleIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setPuzzleIndexToScrollIntoView(puzzleIndexInView + 1);
              }}
              disabled={
                puzzles && puzzleIndexInView >= puzzles.length - 1
                  ? true
                  : loadingAdminInfo || loadingPuzzles
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                puzzles &&
                puzzles.length > 0 &&
                puzzles.map((puzzle, index) => (
                  <AdminPuzzleBox
                    key={index}
                    index={index}
                    puzzle={puzzle}
                    adminInfo={adminInfo}
                    puzzleIndexToScrollIntoView={puzzleIndexToScrollIntoView}
                    setPuzzleIndexInView={setPuzzleIndexInView}
                    setPuzzleIndexToScrollIntoView={
                      setPuzzleIndexToScrollIntoView
                    }
                    setTargetPuzzle={setTargetPuzzle}
                    puzzleIndexInView={puzzleIndexInView}
                    disabled={loadingAdminInfo || loadingPuzzles}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {puzzles &&
                  puzzles.length > 1 &&
                  puzzles.map((topic, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPuzzleIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === puzzleIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakePuzzleForm(!shouldOpenMakePuzzleForm);
                }}
                disabled={loadingPuzzles}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  New level
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Activities{" "}
              {petActivities && petActivities.length > 0
                ? "(" + petActivities.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPetActivityIndexToScrollIntoView(petActivityIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo ||
                loadingPetActivities ||
                petActivityIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setPetActivityIndexToScrollIntoView(petActivityIndexInView + 1);
              }}
              disabled={
                petActivities &&
                petActivityIndexInView >= petActivities.length - 1
                  ? true
                  : loadingAdminInfo || loadingPetActivities
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                petActivities &&
                petActivities.length > 0 &&
                petActivities.map((petActivity, index) => (
                  <AdminPetActivityBox
                    key={index}
                    index={index}
                    petActivity={petActivity}
                    adminInfo={adminInfo}
                    petActivityIndexToScrollIntoView={
                      petActivityIndexToScrollIntoView
                    }
                    setPetActivityIndexInView={setPetActivityIndexInView}
                    setPetActivityIndexToScrollIntoView={
                      setPetActivityIndexToScrollIntoView
                    }
                    setTargetPetActivity={setTargetPetActivity}
                    petActivityIndexInView={petActivityIndexInView}
                    disabled={loadingAdminInfo || loadingPetActivities}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {petActivities &&
                  petActivities.length > 1 &&
                  petActivities.map((topic, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPetActivityIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === petActivityIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakePetActivityForm(
                    !shouldOpenMakePetActivityForm
                  );
                }}
                disabled={loadingPetActivities}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add activity
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Posts{" "}
              {petPosts && petPosts.length > 0
                ? "(" + petPosts.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPetPostIndexToScrollIntoView(petPostIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingPetPosts || petPostIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setPetPostIndexToScrollIntoView(petPostIndexInView + 1);
              }}
              disabled={
                petPosts && petPostIndexInView >= petPosts.length - 1
                  ? true
                  : loadingAdminInfo || loadingPetPosts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                petPosts &&
                petPosts.length > 0 &&
                petPosts.map((petPost, index) => (
                  <AdminPetPostBox
                    key={index}
                    navigate={navigate}
                    index={index}
                    petPost={petPost}
                    adminInfo={adminInfo}
                    petPostIndexToScrollIntoView={petPostIndexToScrollIntoView}
                    setPetPostIndexInView={setPetPostIndexInView}
                    setPetPostIndexToScrollIntoView={
                      setPetPostIndexToScrollIntoView
                    }
                    setTargetPetPost={setTargetPetPost}
                    petPostIndexInView={petPostIndexInView}
                    disabled={loadingAdminInfo || loadingPetPosts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {petPosts &&
                  petPosts.length > 1 &&
                  petPosts.map((petPost, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setPetPostIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === petPostIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )}
        {/* 
        {typeOfView === "games" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Game clips{" "}
              {gameClips && gameClips.length > 0
                ? "(" + gameClips.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setGameClipIndexToScrollIntoView(gameClipIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingGameClips || gameClipIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setGameClipIndexToScrollIntoView(gameClipIndexInView + 1);
              }}
              disabled={
                gameClips && gameClipIndexInView >= gameClips.length - 1
                  ? true
                  : loadingAdminInfo || loadingGameClips
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                gameClips &&
                gameClips.length > 0 &&
                gameClips.map((gameClip, index) => (
                  <AdminGameClipBox
                    key={index}
                    index={index}
                    gameClip={gameClip}
                    adminInfo={adminInfo}
                    gameClipIndexToScrollIntoView={
                      gameClipIndexToScrollIntoView
                    }
                    setGameClipIndexInView={setGameClipIndexInView}
                    setGameClipIndexToScrollIntoView={
                      setGameClipIndexToScrollIntoView
                    }
                    setTargetPetActivity={setTargetPetActivity}
                    gameClipIndexInView={gameClipIndexInView}
                    disabled={loadingAdminInfo || loadingGameClips}
                    setTargetContent={setTargetContent}
                    targetContent={targetContent}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {gameClips &&
                  gameClips.length > 1 &&
                  gameClips.map((gameClip, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setGameClipIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === gameClipIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeGameClipForm(!shouldOpenMakeGameClipForm);
                }}
                disabled={loadingGameClips}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add game clip
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}

        {/* {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Organizations{" "}
              {orgs && orgs.length > 0 ? "(" + orgs.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOrgIndexToScrollIntoView(orgIndexInView - 1);
              }}
              disabled={loadingAdminInfo || loadingOrgs || orgIndexInView <= 0}
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setOrgIndexToScrollIntoView(orgIndexInView + 1);
              }}
              disabled={
                orgs && orgIndexInView >= orgs.length - 1
                  ? true
                  : loadingAdminInfo || loadingOrgs
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                orgs &&
                orgs.length > 0 &&
                orgs.map((org, index) => (
                  <AdminOrgBox
                    key={index}
                    index={index}
                    org={org}
                    adminInfo={adminInfo}
                    orgIndexToScrollIntoView={orgIndexToScrollIntoView}
                    setOrgIndexInView={setOrgIndexInView}
                    setOrgIndexToScrollIntoView={setOrgIndexToScrollIntoView}
                    setTargetOrg={setTargetOrg}
                    orgIndexInView={orgIndexInView}
                    disabled={loadingAdminInfo || loadingOrgs}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {orgs &&
                  orgs.length > 1 &&
                  orgs.map((org, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOrgIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === orgIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeOrgForm(!shouldOpenMakeOrgForm);
                }}
                disabled={loadingOrgs}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add organization
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}
        {/* {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Forums{" "}
              {forums && forums.length > 0 ? "(" + forums.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setForumIndexToScrollIntoView(forumIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingForums || forumIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setForumIndexToScrollIntoView(forumIndexInView + 1);
              }}
              disabled={
                forums && forumIndexInView >= forums.length - 1
                  ? true
                  : loadingAdminInfo || loadingForums
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                forums &&
                forums.length > 0 &&
                forums.map((forum, index) => (
                  <AdminForumBox
                    key={index}
                    index={index}
                    forum={forum}
                    adminInfo={adminInfo}
                    forumIndexToScrollIntoView={forumIndexToScrollIntoView}
                    setForumIndexInView={setForumIndexInView}
                    setForumIndexToScrollIntoView={
                      setForumIndexToScrollIntoView
                    }
                    setTargetForum={setTargetForum}
                    forumIndexInView={forumIndexInView}
                    disabled={loadingAdminInfo || loadingForums}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {forums &&
                  forums.length > 1 &&
                  forums.map((org, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setForumIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === forumIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenMakeForumForm(!shouldOpenMakeForumForm);
                }}
                disabled={loadingForums}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add forum
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )} */}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Ads {ads && ads.length > 0 ? "(" + ads.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setAdIndexToScrollIntoView(adIndexInView - 1);
              }}
              disabled={loadingAdminInfo || loadingAds || adIndexInView <= 0}
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setAdIndexToScrollIntoView(adIndexInView + 1);
              }}
              disabled={
                ads && adIndexInView >= ads.length - 1
                  ? true
                  : loadingAdminInfo || loadingAds
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                ads &&
                ads.length > 0 &&
                ads.map((ad, index) => (
                  <AdminAdBox
                    key={index}
                    index={index}
                    // downloadScreenshots={downloadScreenshots}
                    ad={ad}
                    adminInfo={adminInfo}
                    adIndexToScrollIntoView={adIndexToScrollIntoView}
                    setAdIndexInView={setAdIndexInView}
                    setAdIndexToScrollIntoView={setAdIndexToScrollIntoView}
                    setTargetAd={setTargetAd}
                    adIndexInView={adIndexInView}
                    disabled={loadingAdminInfo || loadingAds}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {ads &&
                  ads.length > 1 &&
                  ads.map((ad, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setAdIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === adIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  createAd();
                }}
                disabled={loadingCreateAd}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Create ad
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  {loadingCreateAd ? (
                    <svg
                      className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className=" text-white fill-current p-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "sellers" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Products{" "}
              {products && products.length > 0
                ? "(" + products.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setProductIndexToScrollIntoView(productIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingProducts || productIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setProductIndexToScrollIntoView(productIndexInView + 1);
              }}
              disabled={
                products && productIndexInView >= products.length - 1
                  ? true
                  : loadingAdminInfo || loadingProducts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                products &&
                products.length > 0 &&
                products.map((product, index) => (
                  <AdminProductBox
                    key={index}
                    index={index}
                    // downloadScreenshots={downloadScreenshots}
                    product={product}
                    adminInfo={adminInfo}
                    productIndexToScrollIntoView={productIndexToScrollIntoView}
                    setProductIndexInView={setProductIndexInView}
                    setProductIndexToScrollIntoView={
                      setProductIndexToScrollIntoView
                    }
                    setTargetProduct={setTargetProduct}
                    productIndexInView={productIndexInView}
                    disabled={loadingAdminInfo || loadingProducts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {products &&
                  products.length > 1 &&
                  products.map((product, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setProductIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === productIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  createProduct();
                }}
                disabled={loadingCreateProduct}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add product
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  {loadingCreateProduct ? (
                    <svg
                      className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.636 3.636A9 9 0 0119 10"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className=" text-white fill-current p-3"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  )}
                </div>
              </button>
            </div>
          </div>
        )}
        {/* 
        {typeOfView === "Players" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Customer products{" "}
              {allCustomerProducts && allCustomerProducts.length > 0
                ? "(" + allCustomerProducts.length + ")"
                : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCustomerProductIndexToScrollIntoView(
                  customerProductIndexInView - 1
                );
              }}
              disabled={
                loadingAdminInfo ||
                loadingAllCustomerProducts ||
                customerProductIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setCustomerProductIndexToScrollIntoView(
                  customerProductIndexInView + 1
                );
              }}
              disabled={
                allCustomerProducts &&
                customerProductIndexInView >= allCustomerProducts.length - 1
                  ? true
                  : loadingAdminInfo || loadingAllCustomerProducts
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                allCustomerProducts &&
                allCustomerProducts.length > 0 &&
                allCustomerProducts.map((customerProduct, index) => (
                  <AdminCustomerProductBox
                    key={index}
                    index={index}
                    customerProduct={customerProduct}
                    adminInfo={adminInfo}
                    customerProductIndexToScrollIntoView={
                      customerProductIndexToScrollIntoView
                    }
                    setCustomerProductIndexInView={
                      setCustomerProductIndexInView
                    }
                    setCustomerProductIndexToScrollIntoView={
                      setCustomerProductIndexToScrollIntoView
                    }
                    // setTargetCustomerProduct={setTargetCustomerProduct}
                    customerProductIndexInView={customerProductIndexInView}
                    disabled={loadingAdminInfo || loadingAllCustomerProducts}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {allCustomerProducts &&
                  allCustomerProducts.length > 1 &&
                  allCustomerProducts.map((customerProduct, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCustomerProductIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === customerProductIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>
            </div>
          </div>
        )} */}

        {/* {typeOfView === "pets" &&
          pendingTNWins &&
          pendingTNWins.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Wins{" "}
                {pendingTNWins && pendingTNWins.length > 0
                  ? "(" + pendingTNWins.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaWinIndexToScrollIntoView(triviaWinIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo || loadingTNWins || triviaWinIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaWinIndexToScrollIntoView(triviaWinIndexInView + 1);
                }}
                disabled={
                  pendingTNWins &&
                  triviaWinIndexInView >= pendingTNWins.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNWins
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNWins &&
                  pendingTNWins.length > 0 &&
                  pendingTNWins.map((triviaWin, index) => (
                    <AdminTNWinBox
                      key={index}
                      index={index}
                      triviaWin={triviaWin}
                      adminInfo={adminInfo}
                      triviaWinIndexToScrollIntoView={
                        triviaWinIndexToScrollIntoView
                      }
                      setTriviaWinIndexInView={setTriviaWinIndexInView}
                      setTriviaWinIndexToScrollIntoView={
                        setTriviaWinIndexToScrollIntoView
                      }
                      triviaWinIndexInView={triviaWinIndexInView}
                      disabled={loadingAdminInfo || loadingTNWins}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNWins &&
                    pendingTNWins.length > 1 &&
                    pendingTNWins.map((triviaWin, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaWinIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaWinIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {/* {typeOfView === "Players" &&
          pendingTNBailOuts &&
          pendingTNBailOuts.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Surrenders{" "}
                {pendingTNBailOuts && pendingTNBailOuts.length > 0
                  ? "(" + pendingTNBailOuts.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setBailOutIndexToScrollIntoView(bailOutIndexInView - 1);
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNBailOuts ||
                  bailOutIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setBailOutIndexToScrollIntoView(bailOutIndexInView + 1);
                }}
                disabled={
                  pendingTNBailOuts &&
                  bailOutIndexInView >= pendingTNBailOuts.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNBailOuts
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNBailOuts &&
                  pendingTNBailOuts.length > 0 &&
                  pendingTNBailOuts.map((bailOut, index) => (
                    <AdminTNBailOutBox
                      key={index}
                      index={index}
                      bailOut={bailOut}
                      adminInfo={adminInfo}
                      bailOutIndexToScrollIntoView={
                        bailOutIndexToScrollIntoView
                      }
                      setBailOutIndexInView={setBailOutIndexInView}
                      setBailOutIndexToScrollIntoView={
                        setBailOutIndexToScrollIntoView
                      }
                      bailOutIndexInView={bailOutIndexInView}
                      disabled={loadingAdminInfo || loadingTNBailOuts}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNBailOuts &&
                    pendingTNBailOuts.length > 1 &&
                    pendingTNBailOuts.map((bailOut, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setBailOutIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === bailOutIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {/* {typeOfView === "pets" &&
          pendingTNShares &&
          pendingTNShares.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Trivia Shares{" "}
                {pendingTNShares && pendingTNShares.length > 0
                  ? "(" + pendingTNShares.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaShareIndexToScrollIntoView(
                    triviaShareIndexInView - 1
                  );
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNShares ||
                  triviaShareIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaShareIndexToScrollIntoView(
                    triviaShareIndexInView + 1
                  );
                }}
                disabled={
                  pendingTNShares &&
                  triviaShareIndexInView >= pendingTNShares.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNShares
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNShares &&
                  pendingTNShares.length > 0 &&
                  pendingTNShares.map((triviaShare, index) => (
                    <AdminTNShareBox
                      key={index}
                      index={index}
                      triviaShare={triviaShare}
                      adminInfo={adminInfo}
                      triviaShareIndexToScrollIntoView={
                        triviaShareIndexToScrollIntoView
                      }
                      setTriviaShareIndexInView={setTriviaShareIndexInView}
                      setTriviaShareIndexToScrollIntoView={
                        setTriviaShareIndexToScrollIntoView
                      }
                      triviaShareIndexInView={triviaShareIndexInView}
                      disabled={loadingAdminInfo || loadingTNShares}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNShares &&
                    pendingTNShares.length > 1 &&
                    pendingTNShares.map((triviaShare, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaShareIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaShareIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )} */}

        {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Breeds{" "}
              {breeds && breeds.length > 0 ? "(" + breeds.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setBreedIndexToScrollIntoView(breedIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingBreeds || breedIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setBreedIndexToScrollIntoView(breedIndexInView + 1);
              }}
              disabled={
                breeds && breedIndexInView >= breeds.length - 1
                  ? true
                  : loadingAdminInfo || loadingBreeds
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                breeds &&
                breeds.length > 0 &&
                breeds.map((breed, index) => (
                  <AdminBreedBox
                    key={index}
                    index={index}
                    breed={breed}
                    adminInfo={adminInfo}
                    breedIndexToScrollIntoView={breedIndexToScrollIntoView}
                    setBreedIndexInView={setBreedIndexInView}
                    setBreedIndexToScrollIntoView={
                      setBreedIndexToScrollIntoView
                    }
                    setTargetPetActivity={setTargetPetActivity}
                    breedIndexInView={breedIndexInView}
                    disabled={loadingAdminInfo || loadingBreeds}
                    setTargetBreed={setTargetBreed}
                    targetBreed={targetBreed}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {breeds &&
                  breeds.length > 1 &&
                  breeds.map((breed, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setBreedIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === breedIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenCreateBreedForm(!shouldOpenCreateBreedForm);
                }}
                disabled={loadingBreeds}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add breed
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}

        {typeOfView === "pets" && (
          <div className="relative mb-16">
            <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
              Cities{" "}
              {cities && cities.length > 0 ? "(" + cities.length + ")" : ""}
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setCityIndexToScrollIntoView(cityIndexInView - 1);
              }}
              disabled={
                loadingAdminInfo || loadingCities || cityIndexInView <= 0
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
              </svg>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setCityIndexToScrollIntoView(cityIndexInView + 1);
              }}
              disabled={
                cities && cityIndexInView >= cities.length - 1
                  ? true
                  : loadingAdminInfo || loadingCities
                  ? true
                  : false
              }
              className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
            >
              <svg
                className="text-gray-400 fill-current mx-auto pl-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
              </svg>
            </button>
            <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
              {adminInfo &&
                cities &&
                cities.length > 0 &&
                cities.map((city, index) => (
                  <AdminCityBox
                    key={index}
                    index={index}
                    city={city}
                    adminInfo={adminInfo}
                    cityIndexToScrollIntoView={cityIndexToScrollIntoView}
                    setCityIndexInView={setCityIndexInView}
                    setCityIndexToScrollIntoView={setCityIndexToScrollIntoView}
                    setTargetPetActivity={setTargetPetActivity}
                    cityIndexInView={cityIndexInView}
                    disabled={loadingAdminInfo || loadingCities}
                    setTargetCity={setTargetCity}
                    targetCity={targetCity}
                  />
                ))}
            </div>

            <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
              <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
                {cities &&
                  cities.length > 1 &&
                  cities.map((city, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setCityIndexToScrollIntoView(index);
                      }}
                      className={`${
                        index === cityIndexInView
                          ? "bg-blue-500"
                          : "bg-gray-300 hover:bg-blue-300"
                      } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                      key={index}
                    ></button>
                  ))}
              </div>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShouldOpenCreateCityForm(!shouldOpenCreateCityForm);
                }}
                disabled={loadingCities}
                className="flex items-center shrink-0 justify-between group space-x-4"
              >
                <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                  Add city
                </p>

                <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}
        {typeOfView === "pets" &&
          pendingTNReviews &&
          pendingTNReviews.length > 0 && (
            <div className="relative mb-16">
              <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
                Reviews{" "}
                {pendingTNReviews && pendingTNReviews.length > 0
                  ? "(" + pendingTNReviews.length + ")"
                  : ""}
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaReviewIndexToScrollIntoView(
                    triviaReviewIndexInView - 1
                  );
                }}
                disabled={
                  loadingAdminInfo ||
                  loadingTNReviews ||
                  triviaReviewIndexInView <= 0
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pr-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
                </svg>
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTriviaReviewIndexToScrollIntoView(
                    triviaReviewIndexInView + 1
                  );
                }}
                disabled={
                  pendingTNReviews &&
                  triviaReviewIndexInView >= pendingTNReviews.length - 1
                    ? true
                    : loadingAdminInfo || loadingTNReviews
                    ? true
                    : false
                }
                className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
              >
                <svg
                  className="text-gray-400 fill-current mx-auto pl-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
                </svg>
              </button>
              <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
                {adminInfo &&
                  pendingTNReviews &&
                  pendingTNReviews.length > 0 &&
                  pendingTNReviews.map((tnReview, index) => (
                    <AdminTNReviewBox
                      key={index}
                      index={index}
                      tnReview={tnReview}
                      adminInfo={adminInfo}
                      tnReviewIndexToScrollIntoView={
                        tnReviewIndexToScrollIntoView
                      }
                      setTriviaReviewIndexInView={setTriviaReviewIndexInView}
                      setTriviaReviewIndexToScrollIntoView={
                        setTriviaReviewIndexToScrollIntoView
                      }
                      triviaReviewIndexInView={triviaReviewIndexInView}
                      disabled={loadingAdminInfo || loadingTNReviews}
                    />
                  ))}
              </div>

              <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
                <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
                  {pendingTNReviews &&
                    pendingTNReviews.length > 1 &&
                    pendingTNReviews.map((tnReview, index) => (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setTriviaReviewIndexToScrollIntoView(index);
                        }}
                        className={`${
                          index === triviaReviewIndexInView
                            ? "bg-blue-500"
                            : "bg-gray-300 hover:bg-blue-300"
                        } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                        key={index}
                      ></button>
                    ))}
                </div>
              </div>
            </div>
          )}
      </div>

      <Footer navigate={navigate} logout={logout} customer={admin} />
    </div>
  );
}

export default AdminDashboard;
