import React from "react";

import { useQuery } from "@apollo/client";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_PET_ACTIVITY_STATS } from "../../util/gqlFunctions";

function PetActivityBox({
  navigate,
  petActivity,
  setTargetPetActivity,
  targetCustomer,
  targetGuestCustomer,
  isOnPetActivityPage,
}) {
  const { data: { getPetActivityStatsByPetActivity: petActivityStats } = {} } =
    useQuery(FETCH_PET_ACTIVITY_STATS, {
      client: customerClient,
      variables: {
        petActivityId: petActivity ? petActivity.id : "",
      },
    });

  return (
    <div
      onClick={(e) => {
        navigate("/activity/" + petActivity.shortForm);
      }}
      className={`${
        isOnPetActivityPage ? "shadow-md" : ""
      } hide-button-flash w-full cursor-pointer p-3 pr-6 short:p-2 short:pr-4 hover:bg-gray-100/90 transition-all bg-lighter-blue rounded-xl`}
    >
      <div className="flex items-center justify-start space-x-4 short:space-x-3">
        <div className="rounded-xl bg-gray-200 h-14 aspect-square">
          <img
            src={petActivity.logoUrl}
            className="h-14 aspect-square rounded-md mr-2 object-cover"
            alt={petActivity.name}
          />
        </div>
        <div className="truncate flex-1">
          <p className="text-lg leading-tight truncate font-semibold">
            {petActivity.name}
          </p>
          <div className="flex leading-tight items-center justify-start text-gray-700">
            <svg
              className="h-4 fill-current text-gray-500"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9579 10.0833C14.0104 10.0833 17.4268 15.6961 17.9539 18.073C18.481 20.4508 17.0409 22.4079 14.7602 21.8423C13.7592 21.5939 11.7792 20.6442 11.0001 20.6589C10.1521 20.6736 8.81931 21.4252 7.71198 21.8066C5.2764 22.6462 3.38806 20.6305 4.03615 18.073C4.74565 15.2762 8.14281 10.0833 10.9579 10.0833ZM20.8515 6.05915C21.9405 6.55323 22.3447 8.64415 21.671 10.89C21.0256 13.0359 19.5351 14.3788 17.4946 13.453C15.7246 12.6491 15.5165 10.5398 16.6751 8.62215C17.843 6.69073 19.7616 5.56415 20.8515 6.05915ZM0.714148 6.70632C-0.242853 7.37457 -0.257519 9.45265 0.789314 11.4867C1.79031 13.4301 3.45956 14.4531 5.25165 13.2009C6.80631 12.1146 6.63031 10.054 5.17648 8.42048C3.71348 6.7769 1.67115 6.03807 0.714148 6.70632ZM15.0536 0.0219826C16.2663 0.252066 17.1811 2.24765 17.0537 4.66215C16.9309 6.9694 15.7658 8.67898 13.4952 8.24815C11.5253 7.87507 10.8021 5.81257 11.496 3.6089C12.1936 1.38965 13.8417 -0.208101 15.0536 0.0219826ZM5.61648 0.0751493C4.45415 0.488566 3.85648 2.60148 4.35423 4.9674C4.82998 7.22882 6.2444 8.73857 8.42148 7.96398C10.3098 7.29298 10.7076 5.1434 9.68373 3.07265C8.65248 0.986316 6.77881 -0.338267 5.61648 0.0751493Z"
              />
            </svg>

            <p className="ml-1 text-sm">{petActivity.numOfPets}</p>

            <svg
              className="h-4 ml-2 fill-current"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.65257 13C10.043 13 12.7915 10.2515 12.7915 6.86106C12.7915 3.47064 10.043 0.722168 6.65257 0.722168C3.26215 0.722168 0.513672 3.47064 0.513672 6.86106C0.513672 10.2515 3.26215 13 6.65257 13Z"
                fill="#F4900C"
              />
              <path
                d="M6.65257 12.2778C10.043 12.2778 12.7915 9.52931 12.7915 6.1389C12.7915 2.74848 10.043 0 6.65257 0C3.26215 0 0.513672 2.74848 0.513672 6.1389C0.513672 9.52931 3.26215 12.2778 6.65257 12.2778Z"
                fill="#FFCC4D"
              />
              <path
                d="M6.65224 11.5555C9.44435 11.5555 11.7078 9.29201 11.7078 6.4999C11.7078 3.70779 9.44435 1.44434 6.65224 1.44434C3.86013 1.44434 1.59668 3.70779 1.59668 6.4999C1.59668 9.29201 3.86013 11.5555 6.65224 11.5555Z"
                fill="#FFE8B6"
              />
              <path
                d="M6.65224 11.1946C9.44435 11.1946 11.7078 8.93117 11.7078 6.13906C11.7078 3.34695 9.44435 1.0835 6.65224 1.0835C3.86013 1.0835 1.59668 3.34695 1.59668 6.13906C1.59668 8.93117 3.86013 11.1946 6.65224 11.1946Z"
                fill="#FFAC33"
              />
              <path
                d="M6.63662 2.40527C5.64537 2.40527 4.69642 2.79904 3.99849 3.49996C3.30057 4.20088 2.91084 5.15152 2.91507 6.14277C2.9193 7.134 3.31714 8.08468 4.021 8.78559C4.7249 9.4865 5.67725 9.88028 6.66846 9.88028C6.21152 9.88028 5.76422 9.74476 5.38318 9.49089C5.00218 9.23703 4.70449 8.87616 4.52784 8.45399C4.35114 8.03182 4.30342 7.56724 4.39066 7.11906C4.4779 6.67087 4.6962 6.2592 5.01793 5.93608C5.33969 5.61296 5.75044 5.39291 6.19822 5.30376C6.64604 5.21461 7.11079 5.26036 7.53371 5.43523C7.95662 5.61011 8.3187 5.90624 8.57421 6.2862C8.82972 6.66613 8.96712 7.11286 8.96904 7.56984C8.97165 8.18259 8.73075 8.77026 8.29928 9.20354C7.86786 9.63683 7.28125 9.88028 6.66846 9.88028C7.65972 9.88028 8.60867 9.4865 9.30659 8.78559C10.0045 8.08468 10.3942 7.134 10.39 6.14277C10.3858 5.15152 9.98794 4.20088 9.28409 3.49996C8.58018 2.79904 7.62788 2.40527 6.63662 2.40527Z"
                fill="#FFD983"
              />
            </svg>
            <p className="ml-1 text-sm">{petActivity.postPrizeCoins}</p>
          </div>
        </div>

        <svg
          className={`w-8 fill-current ${
            petActivityStats && petActivityStats.totalPosts > 0
              ? "text-blue-800"
              : "text-light-blue/40"
          }`}
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 0C9.856 0 0 9.856 0 22C0 34.144 9.856 44 22 44C34.144 44 44 34.144 44 22C44 9.856 34.144 0 22 0ZM22 39.6C12.298 39.6 4.4 31.702 4.4 22C4.4 12.298 12.298 4.4 22 4.4C31.702 4.4 39.6 12.298 39.6 22C39.6 31.702 31.702 39.6 22 39.6ZM17.6 26.774L32.098 12.276L35.2 15.4L17.6 33L8.8 24.2L11.902 21.098L17.6 26.774Z"
          />
        </svg>
      </div>
    </div>
  );
}

export default PetActivityBox;
