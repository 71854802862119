import React, { useContext, useEffect, useState } from "react";
import lucky from "../../images/lucky.png";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import SubmitButton from "../../components/creator/SubmitButton";
// import  { AuthContext } from "../../context/auth";
import LoginFooter from "../../components/creator/LoginFooter";
import TopBar from "../../components/creator/TopBar";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import { LOGIN_BRAND_CONTACT } from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function LoginSeller(props) {
  usePageTracking();

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Seller Login | The Lucky Pet";
  }, []);

  const { brandContact, loginBrandContact: loginSellerContext } = useContext(
    BrandContactAuthContext
  );

  useEffect(() => {
    if (brandContact) {
      navigate("/business/dashboard");
    }
  }, [brandContact, navigate]);

  const { onChange, values } = useForm(loginBrandContactCallback, {
    email: "",
    password: "",
  });

  const [loginBrandContact, { loading }] = useMutation(LOGIN_BRAND_CONTACT, {
    update(_, { data: { loginBrandContact: brandContactData } }) {
      loginSellerContext(brandContactData);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
  });

  function loginBrandContactCallback(e) {
    e.preventDefault();
    loginBrandContact();
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-orange-500">
      <img
        src={lucky}
        alt="Lucky the Kitten"
        className="shrink-0 sm:w-auto w-64 absolute right-0 hidden sm:block bottom-0 inset-auto"
      />
      <div
        className={`short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl grow-0 relative h-full z-10 flex flex-col items-center justify-start mx-auto xl:px-0 px-4 pb-4 lg:pb-0 min-h-screen`}
      >
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Login"
          isSeller={true}
        />
        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Sign in to business account
          </p>

          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            <TextInputField
              title="Email or username"
              name="email"
              type="email"
              error={errors.email || errors.name}
              disabled={loading}
              onChange={onChange}
              value={values.email}
            />

            <TextInputField
              title="Password"
              name="password"
              type="password"
              error={errors.password}
              disabled={loading}
              onChange={onChange}
              value={values.password}
              showForgotPasswordLink={"/resetSellerPassword"}
              navigate={navigate}
            />
          </div>

          <SubmitButton
            onClickFunc={loginBrandContactCallback}
            disabled={loading}
            title={"Continue"}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            Don't have an account?{" "}
            <button
              onClick={(e) => {
                navigate("/registerSeller");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Sign up
            </button>
          </p>
        </div>

        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default LoginSeller;
