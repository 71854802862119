import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

const welcomeBackMessages = ["Welcome back!", "You're back!", "Missed you!"];

const welcomeMsgInd = Math.floor(Math.random() * welcomeBackMessages.length);

function EmptyPuzzleBox({
  title,
  caption,
  activityName,
  loadingRecommendedQs,
  index,
  question,
  questionIdToScrollIntoView,
  setIndexVisible,
  indexVisible,
}) {
  const [isCaptionCopied, setIsCaptionCopied] = useState(false);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const [hasCaptionBeenCopiedBefore, setHasCaptionBeenCopiedBefore] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied) {
      timeout = setTimeout(() => setIsCaptionCopied(false), 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied]);
  const shareEndOfPsResult = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = activityName
      ? "#LuckyPet 🥹 " + activityName
      : "#LuckyPet 🥹";

    if (navigator.share) {
      navigator
        .share({
          title: `I finished all the ${activityName} questions on Lucky Pet!`,

          text: textToShare,
          // url: "http://worldphraze.com/",
          // url:
          //   playersPhraze && playersPhraze.isEligibleForPrize
          //     ? "W/" + playersPhraze.id
          //     : "phraze",
        })
        .then(() => {
          setHasCaptionBeenCopiedBefore(true);
          setIsCaptionCopied(true);

          setIsSharingToApps(true);
        })
        .catch((error) => {
          setIsSharingToApps(false);
          navigator.clipboard.writeText(textToShare);
          // navigator.clipboard.writeText(textToShare + " worldphraze.com");
          setIsCaptionCopied(true);
          setHasCaptionBeenCopiedBefore(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setHasCaptionBeenCopiedBefore(true);

      setIsCaptionCopied(true);
    }
  };

  const questionRef = useRef();

  useEffect(() => {
    if (
      question &&
      question.id === questionIdToScrollIntoView &&
      !loadingRecommendedQs
    ) {
      questionRef.current.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "center",
      });
    }
  }, [questionIdToScrollIntoView, question, index, loadingRecommendedQs]);

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (isBoxVisible && index !== indexVisible && !loadingRecommendedQs) {
      setIndexVisible(index);
    }
  }, [
    index,
    indexVisible,
    setIndexVisible,

    isBoxVisible,

    loadingRecommendedQs,
  ]);

  return (
    <div
      ref={questionRef}
      className="w-full h-full select-none snap-start snap-always shrink-0"
    >
      <div
        ref={boxRef}
        className={`bg-white relative flex px-3 sm:px-8 sm:pt-7 pt-3 rounded-2xl sm:rounded-3xl flex-col items-center justify-center shrink-0 w-full h-full grow-0`}
      >
        <div className="w-full flex z-40 space-x-4 px-1 sm:px-0 items-center justify-center">
          <div className="w-1/5 flex items-center justify-start space-x-2">
            <div className="text-gray-600 flex items-center justify-start space-x-1.5 sm:space-x-3">
              <svg
                className="h-4 fill-current 2xl:h-6 short:h-3 "
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.9579 10.0833C14.0104 10.0833 17.4268 15.6961 17.9539 18.073C18.481 20.4508 17.0409 22.4079 14.7602 21.8423C13.7592 21.5939 11.7792 20.6442 11.0001 20.6589C10.1521 20.6736 8.81931 21.4252 7.71198 21.8066C5.2764 22.6462 3.38806 20.6305 4.03615 18.073C4.74565 15.2762 8.14281 10.0833 10.9579 10.0833ZM20.8515 6.05915C21.9405 6.55323 22.3447 8.64415 21.671 10.89C21.0256 13.0359 19.5351 14.3788 17.4946 13.453C15.7246 12.6491 15.5165 10.5398 16.6751 8.62215C17.843 6.69073 19.7616 5.56415 20.8515 6.05915ZM0.714148 6.70632C-0.242853 7.37457 -0.257519 9.45265 0.789314 11.4867C1.79031 13.4301 3.45956 14.4531 5.25165 13.2009C6.80631 12.1146 6.63031 10.054 5.17648 8.42048C3.71348 6.7769 1.67115 6.03807 0.714148 6.70632ZM15.0536 0.0219826C16.2663 0.252066 17.1811 2.24765 17.0537 4.66215C16.9309 6.9694 15.7658 8.67898 13.4952 8.24815C11.5253 7.87507 10.8021 5.81257 11.496 3.6089C12.1936 1.38965 13.8417 -0.208101 15.0536 0.0219826ZM5.61648 0.0751493C4.45415 0.488566 3.85648 2.60148 4.35423 4.9674C4.82998 7.22882 6.2444 8.73857 8.42148 7.96398C10.3098 7.29298 10.7076 5.1434 9.68373 3.07265C8.65248 0.986316 6.77881 -0.338267 5.61648 0.0751493Z"
                />
              </svg>
              <p className="text-sm short:text-xs 2xl:text-xl font-semibold">
                31
              </p>
            </div>
          </div>

          <div className="w-full group truncate sm:text-xl lg:text-lg 2xl:text-2xl text-sm">
            <p
              className={`text-blue-600 font-semibold w-full truncate transition-all uppercase focus:outline-none tracking-wide whitespace-nowrap text-center`}
            >
              {title || welcomeBackMessages[welcomeMsgInd]}
            </p>
          </div>

          <div className="w-1/5 flex items-center justify-end space-x-2">
            <div
              className={`text-gray-700 flex items-center justify-end space-x-1.5 transition-all sm:space-x-3 `}
            >
              <svg
                className={`h-4 2xl:h-6 short:h-3 stroke-current`}
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.39392 14.0809L7 13.912L6.60608 14.0809L1 16.4835V2C1 1.45228 1.45228 1 2 1H12C12.5477 1 13 1.45228 13 2V16.4835L7.39392 14.0809Z"
                  strokeWidth="2"
                />
              </svg>

              <p className="text-sm 2xl:text-lg short:text-xs font-semibold">
                13
              </p>
            </div>
          </div>
        </div>

        <div className="justify-center aspect-[.67] sm:aspect-auto h-full transition-all w-full z-40 flex relative flex-col items-center space-y-1 flex-1 py-4">
          <p
            className={`${
              caption ? "" : "invisible"
            }  transition-all leading-tight sm:leading-tight overflow-y-auto max-h-24 sm:max-h-36 lg:max-h-28 select-none text-base sm:text-lg z-20 2xl:text-2xl font-semibold text-center max-w-xs px-2`}
          >
            {caption || "How are you doing today?"}
          </p>
        </div>

        {caption ? (
          <div className="space-y-2 sm:space-y-3 short:space-y-1.5 flex z-40 flex-col items-center justify-center w-full">
            {/* <div
              className={`border-gray-300 invisible py-4 2xl:py-6 short:py-2 xshort:py-1.5 px-5 sm:px-8 short:px-3 focus:outline-none hide-button-flash rounded-full transition-colors border-2 overflow-hidden shadow-sm flex items-center relative justify-between w-full`}
            >
              <p
                className={`text-xl invisible text-gray-800 short:text-base text-left truncate select-none 2xl:text-3xl leading-tight font-semibold z-20`}
              >
                Thanks sis!
              </p>
            </div> */}

            <button
              onClick={(e) => {
                shareEndOfPsResult(e);
              }}
              type="button"
              className={`${
                isCaptionCopied ? " motion-safe:animate-popUp" : " "
              } relative text-xl sm:text-2xl sm:py-3 lg:py-1 2xl:py-4 lg:text-xl 2xl:text-2xl overflow-hidden shadow-sm  group w-full font-bold uppercase flex transition-all items-center justify-center bg-blue-600 text-white rounded-full pl-4 pr-3 py-1`}
            >
              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeOutUpFast"
                    : "hidden"
                } pb-2 text-black bg-blue-300 inset-0 pt-2.5 w-full absolute`}
              >
                {isSharingToApps ? "Shared!" : "Copied"}
              </p>

              <p
                className={`${
                  isCaptionCopied
                    ? "motion-safe:animate-fadeOutDownFast"
                    : hasCaptionBeenCopiedBefore
                    ? "motion-safe:animate-fadeUpFast"
                    : ""
                } py-2 short:py-1`}
              >
                Share
              </p>
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-6 sm:flex h-10 shrink-0 gap-2 sm:gap-3 w-full mt-2 items-center justify-center">
            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="fill-current text-gray-600 w-4 m-auto"
                viewBox="0 0 32 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16.4143 1.55556C12.2716 1.55556 8.51979 3.09556 5.62775 5.6L0 0V14H14.0694L8.41036 8.36889C10.5833 6.56445 13.3503 5.44444 16.4143 5.44444C21.9482 5.44444 26.6536 9.03778 28.2951 14L32 12.7867C29.8271 6.26889 23.6834 1.55556 16.4143 1.55556Z" />
              </svg>
            </button>
            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="stroke-current text-gray-600 h-5 m-auto"
                viewBox="0 0 53 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.5 41V35M26.5 24.5V18.5M26.5 8V2M2.0018 32L2 11H15.7812V32H2.0018ZM50.9982 11L51 32H37.2188V11H50.9982Z"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="stroke-current text-gray-600 h-5 m-auto"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 7.9999V19.9999M2 19.9999H14M2 19.9999L11.28 11.2799C14.0418 8.52274 17.6249 6.73784 21.4894 6.19413C25.3538 5.65042 29.2903 6.37737 32.7057 8.26543C36.1211 10.1535 38.8303 13.1004 40.4252 16.6621C42.0202 20.2238 42.4144 24.2074 41.5484 28.0126C40.6825 31.8178 38.6034 35.2386 35.6243 37.7594C32.6452 40.2802 28.9275 41.7646 25.0314 41.9888C21.1354 42.2131 17.272 41.165 14.0233 39.0027C10.7747 36.8403 8.31679 33.6807 7.02 29.9999"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square hidden sm:block rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="stroke-current text-gray-600 h-5 m-auto"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 38H34C36.2091 38 38 36.2091 38 34V6C38 3.79086 36.2091 2 34 2H6C3.79086 2 2 3.79086 2 6V34C2 36.2091 3.79086 38 6 38ZM6 38L28 16L38 26M16 13C16 14.6569 14.6569 16 13 16C11.3431 16 10 14.6569 10 13C10 11.3431 11.3431 10 13 10C14.6569 10 16 11.3431 16 13Z"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="stroke-current text-gray-600 h-5 m-auto"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M45.9998 8.00003V20M45.9998 20H33.9998M45.9998 20L36.7398 11.28C33.981 8.5195 30.3997 6.7304 26.5357 6.18231C22.6716 5.63423 18.7341 6.35686 15.3165 8.24131C11.8988 10.1258 9.18625 13.0699 7.58748 16.6302C5.98871 20.1904 5.59037 24.1738 6.45249 27.9801C7.31461 31.7865 9.39047 35.2095 12.3673 37.7334C15.3441 40.2573 19.0605 41.7453 22.9566 41.9732C26.8527 42.2011 30.7173 41.1566 33.9681 38.997C37.2189 36.8375 39.6797 33.6799 40.9798 30"
                  stroke="#1E1E1E"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="stroke-current text-gray-600 h-5 m-auto"
                viewBox="0 0 43 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 26.5L8 26.5M18.5 26.5L24.5 26.5M35 26.5L41 26.5M11 2.0018L32 2L32 15.7812L11 15.7812L11 2.0018ZM32 50.9982L11 51L11 37.2187L32 37.2187L32 50.9982Z"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <button
              disabled={true}
              className="h-full w-full sm:w-auto sm:aspect-square rounded-md border-gray-400 sm:rounded-lg border transition-all"
            >
              <svg
                className="fill-current text-gray-600 h-5 m-auto"
                viewBox="0 0 27 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.5 0.571289C6.05571 0.571289 0 6.627 0 14.0713C0 18.6613 2.295 22.692 5.78571 25.1413V29.4999C5.78571 30.5606 6.65357 31.4284 7.71429 31.4284H19.2857C20.3464 31.4284 21.2143 30.5606 21.2143 29.4999V25.1413C24.705 22.692 27 18.6613 27 14.0713C27 6.627 20.9443 0.571289 13.5 0.571289ZM7.71451 37.2142C7.71451 38.2749 8.58237 39.1427 9.64308 39.1427H17.3574C18.4181 39.1427 19.2859 38.2749 19.2859 37.2142V35.2856H7.71451V37.2142ZM17.357 23.1356L18.9963 21.9784C21.5998 20.1656 23.1427 17.2149 23.1427 14.0713C23.1427 8.74845 18.8227 4.42845 13.4998 4.42845C8.17699 4.42845 3.85699 8.74845 3.85699 14.0713C3.85699 17.2149 5.39985 20.1656 8.00342 21.9784L9.64271 23.1356V27.5713H17.357V23.1356Z"
                />
              </svg>
            </button>
          </div>
        )}

        {caption ? (
          <></>
        ) : (
          <div
            className={`w-full flex-1 short:hidden sm:hidden mt-2 flex items-center justify-center space-x-2 h-24`}
          >
            <div className="border border-gray-400 w-full h-full rounded-md"></div>
            <div className="border border-gray-400 aspect-[0.67] shrink-0 h-full rounded-md overflow-hidden"></div>
            <div className="border border-gray-400 w-full h-full rounded-md"></div>
          </div>
        )}
        <div className="w-full z-40 short:text-sm flex items-center justify-between space-x-2 rounded-lg">
          <button
            disabled={true}
            className="opacity-50 text-gray-600  hide-button-flash focus:outline-none w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex transition-colors items-center justify-start short:space-x-1 space-x-2"
          >
            <svg
              className="h-5 2xl:h-7 short:h-4 fill-current shrink-0"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6668 0.833374H1.75016C1.246 0.833374 0.833496 1.24587 0.833496 1.75004V14.5834L4.50016 10.9167H13.6668C14.171 10.9167 14.5835 10.5042 14.5835 10V1.75004C14.5835 1.24587 14.171 0.833374 13.6668 0.833374ZM12.7502 2.66671V9.08337H3.73933L2.66683 10.1559V2.66671H12.7502ZM16.4168 4.50004H18.2502C18.7543 4.50004 19.1668 4.91254 19.1668 5.41671V19.1667L15.5002 15.5H5.41683C4.91266 15.5 4.50016 15.0875 4.50016 14.5834V12.75H16.4168V4.50004Z"
              ></path>
            </svg>
            <p className="leading-none">19</p>
          </button>
          {/* {caption ? (
            <></>
          ) : (
            <p className="bg-dark-blue w-20 rounded-full text-center text-white">
              <span className="opacity-0">1</span>
            </p>
          )} */}
          <div className="text-gray-600 w-1/5 px-2 pb-3 pt-2 sm:py-4 xshort:py-2 flex items-center justify-end short:space-x-1 space-x-2">
            <svg
              className="h-4 short:h-3.5 2xl:h-6 fill-current shrink-0"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 4.5V0.5L18 7.5L11 14.5V10.4C6 10.4 2.5 12 0 15.5C1 10.5 4 5.5 11 4.5Z"></path>
            </svg>
            <p className="leading-none">1</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmptyPuzzleBox;
