import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { adminClient, customerClient } from "../../GraphqlApolloClients";
import { AdminAuthContext } from "../../context/adminAuth";

import { petTypes, useForm } from "../../util/hooks";
import TextInputField from "./TextInputField";

import {
  CREATE_BREED_MUTATION,
  FETCH_RECOMMENDED_BREEDS,
} from "../../util/gqlFunctions";
import SubmitButton from "./SubmitButton";

function CreateBreedForm({ setShouldOpenCreateBreedForm }) {
  const [errors, setErrors] = useState({});
  const { admin } = useContext(AdminAuthContext);
  const { values, setValues, onChange } = useForm(createBreedCallback, {
    name: "",
    petTypeId: "1",
    logoFile: null,
    province: "",
    provinceShortForm: "",
    country: "",
    countryShortForm: "",
  });

  const [createBreed, { loading: loadingCreateBreed }] = useMutation(
    CREATE_BREED_MUTATION,
    {
      refetchQueries: admin
        ? [
            {
              query: FETCH_RECOMMENDED_BREEDS,
              variables: { pageSize: 20, seenIds: [] },
            },
          ]
        : [],
      update(_, { data: { createBreed: createdC } }) {
        setValues({
          ...values,
          ...createdC,
          logoFile: null,
        });

        setErrors({});
        setShouldOpenCreateBreedForm(false);
      },
      onError(err) {
        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: admin ? adminClient : customerClient,
    }
  );

  function createBreedCallback(e) {
    e.preventDefault();
    e.stopPropagation();
    createBreed();
  }

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setShouldOpenCreateBreedForm(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setShouldOpenCreateBreedForm(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          Create breed
        </p>
        <p className="text-gray-500 w-full leading-tight ">Enter breed info</p>

        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="petTypeId"
                className="font-semibold text-gray-700 "
              >
                Pet type{" "}
                {errors.petTypeId && (
                  <span className="text-red-500">{errors.petTypeId}</span>
                )}
              </label>
            </div>
            <div className="grid grid-cols-3 mt-1 gap-2">
              {Object.entries(petTypes).map(([petTypeId, petTypeName]) => (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({ ...values, petTypeId: petTypeId });
                  }}
                  className={`${
                    values.petTypeId === petTypeId
                      ? "bg-blue-50 border-blue-500 "
                      : "hover:bg-gray-50 border-gray-200 "
                  } text-left shadow-sm rounded-lg space-x-1 px-2 py-1 border transition-all flex items-center justify-start focus:border-indigo-500 focus:ring-indigo-500`}
                >
                  <p>{petTypeName}</p>
                </button>
              ))}
            </div>
          </div>

          <TextInputField
            title={"Name"}
            name="name"
            type="text"
            error={errors.name}
            disabled={loadingCreateBreed}
            onChange={onChange}
            maxLen={100}
            value={values.name}
          />

          <SubmitButton
            onClickFunc={createBreedCallback}
            disabled={loadingCreateBreed}
            title={"SUBMIT"}
            errors={errors}
          />
        </div>
      </form>
    </div>
  );
}

export default CreateBreedForm;
