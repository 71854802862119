import { gql } from "@apollo/client";

export const ADD_FCM_TOKEN_MUTATION = gql`
  mutation addFCMToken($fcmToken: String!) {
    addFCMToken(fcmToken: $fcmToken) {
      id
      activeFCMTokens
    }
  }
`;

export const SEND_PASSWORD_RESET_CODE_PET = gql`
  mutation sendPetResetPasswordCode(
    $email: String
    $phone: String
    $usingPhone: Boolean
  ) {
    sendPetResetPasswordCode(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
    )
  }
`;

export const RESET_PET_PASSWORD_MUTATION = gql`
  mutation resetPetPassword(
    $passwordResetCode: String!
    $newPassword: String!
    $email: String
    $phone: String
    $usingPhone: Boolean
  ) {
    resetPetPassword(
      passwordResetCode: $passwordResetCode
      newPassword: $newPassword
      email: $email
      phone: $phone
      usingPhone: $usingPhone
    ) {
      id
      token
      name
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      email
      unverifiedEmail
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phoneCountryShortForm
      phoneCountryCode
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      activeChatIds
      archivedChatIds
      deletedChatIds
      areSoundEffectsMuted
      recentPetActivityIds
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      forumTopicIds
      orgIds
      orgSectionIds
      forumIds
      requestedOrgId
      unverifiedOrgEmail
      verifiedOrgEmails
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      country
      cityId
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      rejectedCustomerIds
      followingIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      isActive
      deleteReason
      shouldDeleteAt
      createdAt
    }
  }
`;
export const TOGGLE_ADD_FRIEND_TO_QUIZ_MUTATION = gql`
  mutation toggleAddFriendToQuiz($quizId: String!, $friendId: String!) {
    toggleAddFriendToQuiz(quizId: $quizId, friendId: $friendId)
  }
`;
export const FETCH_PRODUCT_BY_ID = gql`
  query getProductById($productId: String!) {
    getProductById(productId: $productId) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
      adType
      adVideoUrl
    }
  }
`;

export const FETCH_TRIVIA_NIGHT_BY_ID = gql`
  query getTriviaNightById($triviaNightId: String!) {
    getTriviaNightById(triviaNightId: $triviaNightId) {
      id
      dayNum
      randomTopicLogoUrls
      gameTimingType
      totalNumOfPlayers
      numOfWinners
      numOfEligibleForPrizeWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      betAmountPerPlayer
      prizeCoins
      prizePerPlayer
      isForRealMoney
      nextQStartsAt
      nextQTopic
      nextQTopicLogoUrl
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      is1hReminderSent
      is5minReminderSent
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      country
      topicId
      topicIds
      topicNames
      numOfShares
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      nextGameAt
      endDate
    }
  }
`;
export const FETCH_RECENT_SEARCHES = gql`
  query getRecentSearchesByUser(
    $pageSize: Int!
    $status: Int
    $placeOfSearch: Int
    $customerId: String
    $guestCustomerId: String
  ) {
    getRecentSearchesByUser(
      pageSize: $pageSize
      status: $status
      placeOfSearch: $placeOfSearch
      customerId: $customerId
      guestCustomerId: $guestCustomerId
    ) {
      id

      searchTerm
    }
  }
`;
export const FETCH_CUSTOMER_BY_ID = gql`
  query getCustomerById($customerId: String!) {
    getCustomerById(customerId: $customerId) {
      id
      token
      name
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      email
      unverifiedEmail
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phoneCountryShortForm
      phoneCountryCode
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle

      activeChatIds
      archivedChatIds
      deletedChatIds
      areSoundEffectsMuted
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      forumTopicIds
      orgIds
      orgSectionIds
      forumIds
      requestedOrgId
      unverifiedOrgEmail
      verifiedOrgEmails
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      cityId
      country
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      rejectedCustomerIds
      followingIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      isActive
      deleteReason
      shouldDeleteAt
      createdAt
    }
  }
`;
export const FETCH_USER_ADDRESSES = gql`
  query getUserAddresses($userId: String) {
    getUserAddresses(userId: $userId) {
      id
      userId
      streetAddress
      city
      province
      postalCode
      country
      line2
      specialDeliveryInstructions
      createdAt
      lastEditedAt
      isPrimary
    }
  }
`;

export const PROCESS_BUY_PET_COIN_BUNDLE_PAYMENT_RESULT = gql`
  mutation processBuyPetCoinBundlePaymentResult(
    $petCoinBundlePurchaseId: String!
  ) {
    processBuyPetCoinBundlePaymentResult(
      petCoinBundlePurchaseId: $petCoinBundlePurchaseId
    ) {
      id
      petId
      purchaseId
      numOfCoins
      subTotal
      netAmount
      stripeFee
      luckyProfit
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;

export const PROCESS_BUY_COIN_BUNDLE_PAYMENT_RESULT = gql`
  mutation processBuyCoinBundlePaymentResult($coinBundlePurchaseId: String!) {
    processBuyCoinBundlePaymentResult(
      coinBundlePurchaseId: $coinBundlePurchaseId
    ) {
      id
      gotOnTriviaNightId
      gotOnQuizId
      gotAfterQuestionId
      customerId
      numOfCoins
      subTotal
      netAmount
      stripeFee
      tknightProfit
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;

export const UPDATE_PET_SUB_MUTATION = gql`
  mutation updatePetSubscription(
    $wantsToBuy: Boolean
    $useNewCard: Boolean
    $subType: Int
  ) {
    updatePetSubscription(
      wantsToBuy: $wantsToBuy
      useNewCard: $useNewCard
      subType: $subType
    ) {
      id
      petId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberPetIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;

export const UPDATE_CUST_SUB_MUTATION = gql`
  mutation updateCustomerSubscription(
    $subType: Int
    $useNewCard: Boolean
    $wantsToBuy: Boolean
  ) {
    updateCustomerSubscription(
      subType: $subType
      useNewCard: $useNewCard
      wantsToBuy: $wantsToBuy
    ) {
      id
      customerId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      platform
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      paymentStartedAt
      paymentConfirmedAt
      memberCustomerIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;

export const REQUEST_CASHOUT_MUTATION = gql`
  mutation requestCashout($amountRequested: Int!) {
    requestCashout(amountRequested: $amountRequested) {
      id
      customerId
      amountCashedOut
      paypalPayoutItemId
      status
      requestedAt
      completedAt
    }
  }
`;

export const PROCESS_PET_SUB_PAYMENT_RESULT = gql`
  mutation processPetSubscriptionPaymentResult($petSubscriptionId: String!) {
    processPetSubscriptionPaymentResult(PetSubscriptionId: $petSubscriptionId) {
      id
      petId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberPetIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;
export const PROCESS_CUST_SUB_PAYMENT_RESULT = gql`
  mutation processCustomerSubscriptionPaymentResult(
    $customerSubscriptionId: String!
  ) {
    processCustomerSubscriptionPaymentResult(
      customerSubscriptionId: $customerSubscriptionId
    ) {
      id
      customerId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberCustomerIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;

export const BUY_PET_COIN_BUNDLE_MUTATION = gql`
  mutation buyPetCoinBundle(
    $numOfCoins: Int!
    $isFromApp: Boolean
    $useNewCard: Boolean
  ) {
    buyPetCoinBundle(
      numOfCoins: $numOfCoins
      isFromApp: $isFromApp
      useNewCard: $useNewCard
    ) {
      id
      petId
      purchaseId
      numOfCoins
      subTotal
      netAmount
      stripeFee
      luckyProfit
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;

export const BUY_COIN_BUNDLE_MUTATION = gql`
  mutation buyCoinBundle(
    $numOfCoins: Int!
    $triviaNightId: String
    $quizId: String
    $questionId: String
    $useNewCard: Boolean
    $isFromApp: Boolean
  ) {
    buyCoinBundle(
      numOfCoins: $numOfCoins
      triviaNightId: $triviaNightId
      quizId: $quizId
      questionId: $questionId
      useNewCard: $useNewCard
      isFromApp: $isFromApp
    ) {
      id
      gotOnTriviaNightId
      gotOnQuizId
      gotAfterQuestionId
      customerId
      numOfCoins
      subTotal
      netAmount
      stripeFee
      tknightProfit
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;

export const DEACTIVATE_CUSTOMER = gql`
  mutation deactivateCustomer($customerId: String!, $deleteReason: String) {
    deactivateCustomer(customerId: $customerId, deleteReason: $deleteReason) {
      id
      email
    }
  }
`;
export const PAY_AND_JOIN_EVENT_MUTATION = gql`
  mutation payAndJoinEvent(
    $triviaNightId: String
    $quizId: String
    $country: String
  ) {
    payAndJoinEvent(
      triviaNightId: $triviaNightId
      quizId: $quizId
      country: $country
    ) {
      id
      customerId
      guestCustomerId
      triviaNightId
      quizId
      status
      viewedAt
      joinedAt
      subscriptionType
      viewedAfterQuestion
      token
    }
  }
`;

export const REMOVE_FCM_TOKEN_MUTATION = gql`
  mutation removeFCMToken($fcmToken: String!) {
    removeFCMToken(fcmToken: $fcmToken) {
      id
      activeFCMTokens
    }
  }
`;

export const REMOVE_ALL_FCM_TOKENS_MUTATION = gql`
  mutation removeAllFCMTokens {
    removeAllFCMTokens {
      id
    }
  }
`;

export const FETCH_PET_FRIEND_BY_USERNAME = gql`
  query getPetFriendByUsername($username: String!) {
    getPetFriendByUsername(username: $username) {
      id
      name
      profilePicUrl
    }
  }
`;
export const FETCH_STOCK_SOLD_BY_PRODUCT = gql`
  query getStockSoldByProductId($productId: String!) {
    getStockSoldByProductId(productId: $productId)
  }
`;
export const FETCH_JOINED_PLAYERS_BY_EVENT = gql`
  query getJoinedPlayersByEvent($quizId: String, $triviaNightId: String) {
    getJoinedPlayersByEvent(quizId: $quizId, triviaNightId: $triviaNightId) {
      profilePicUrl
    }
  }
`;

export const FETCH_TRIVIA_WIN_BY_CUSTOMER = gql`
  query getTriviaWinByCustomer($quizId: String, $triviaNightId: String) {
    getTriviaWinByCustomer(quizId: $quizId, triviaNightId: $triviaNightId) {
      id
      customerId
      guestCustomerId
      quizId
      triviaNightId
      numOfLivesOriginally
      numOfExtraLivesUsed
      status
      createdAt
      isForCredits
      subscriptionType
      prizeAddedToAccountAt
    }
  }
`;
export const FETCH_CUR_SELECTED_ANSWER = gql`
  query getCurSelectedAnswer($triviaNightId: String, $quizId: String) {
    getCurSelectedAnswer(triviaNightId: $triviaNightId, quizId: $quizId) {
      id
      customerId
      quizId
      triviaNightId
      questionId
      answerSelected
      isCorrect
      timeTaken
      dayNum
      otherCustomerIdsWSameOptions
      createdAt
      lastEditedAt
      deltaXP
    }
  }
`;
export const FETCH_RELEVANT_PRODUCT_ADS = gql`
  query getRelevantProductAds {
    getRelevantProductAds {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;

export const FETCH_RELEVANT_AD = gql`
  query getRelevantAd(
    $format: Int!
    $targetInterests: [String]
    $targetGender: Int
    $targetLocation: String
    $targetAgeRange: Int
    $targetProductCategory: String
    $siteId: Int!
  ) {
    getRelevantAd(
      format: $format
      targetInterests: $targetInterests
      targetGender: $targetGender
      targetLocation: $targetLocation
      targetAgeRange: $targetAgeRange
      targetProductCategory: $targetProductCategory
      siteId: $siteId
    ) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      squareAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      link
      createdAt
    }
  }
`;

export const FETCH_AD_BOOKMARK_BY_CUSTOMER = gql`
  query getAdBookmarkByCustomer($adId: String!) {
    getAdBookmarkByCustomer(adId: $adId) {
      id
      adId
      customerId
      triviaNightId
      quizId
      productId
      chatId
      status
      createdAt
      lastToggledAt
      viewedAfterQuestion
    }
  }
`;

export const FETCH_CAN_USE_EXTRA_LIFE = gql`
  query getNumOfExtraLivesNeeded($triviaNightId: String, $quizId: String) {
    getNumOfExtraLivesNeeded(triviaNightId: $triviaNightId, quizId: $quizId)
  }
`;

export const FETCH_PLAYER_BATTLE_TOPIC_STATS = gql`
  query getPlayerBattleTopicStatsByBattleAndTopic(
    $topicId: String
    $quizId: String
    $triviaNightId: String
  ) {
    getPlayerBattleTopicStatsByBattleAndTopic(
      topicId: $topicId
      quizId: $quizId
      triviaNightId: $triviaNightId
    ) {
      id
      quizId
      triviaNightId
      topicId
      customerId
      guestCustomerId
      levelNumBefore
      levelNumAfter
      totalQsAnsweredCorrectBefore
      totalQsAnsweredCorrectAfter
      totalExtraLivesUsedBefore
      totalExtraLivesUsedAfter
      totalQsAnsweredBefore
      totalQsAnsweredAfter
      numOfBattlesWonBefore
      numOfBattlesWonAfter
      numOfBattlesPlayedBefore
      numOfBattlesPlayedAfter
      battleWinStreakBefore
      battleWinStreakAfter
      maxBattleWinStreakBefore
      maxBattleWinStreakAfter
      accuracyBefore
      accuracyAfter
      coinsEarnedBefore
      coinsEarned
      topicRankBefore
      topicRankAfter
      avrTimeTakenPerCorrectAnsBefore
      avrTimeTakenPerCorrectAnsAfter
      avrTimeTakenPerAnsBefore
      avrTimeTakenPerAnsAfter
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_IS_OUT_OF_LIVES = gql`
  query getIsOutOfLives($quizId: String, $triviaNightId: String) {
    getIsOutOfLives(quizId: $quizId, triviaNightId: $triviaNightId)
  }
`;

export const FETCH_CUR_QUESTION = gql`
  query getCurQuestion($quizId: String, $triviaNightId: String) {
    getCurQuestion(quizId: $quizId, triviaNightId: $triviaNightId) {
      id
      triviaNightId
      index
      topicName
      topicId
      topicShortForm
      topicLogoUrl
      creatorProfilePicUrl
      creatorName
      questionMediaUrl
      answerMediaUrl
      createdByCustomerId
      createdByGuestCustomerId
      questionDesc
      option1
      option2
      option3
      option0
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      questionType
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_IMPRESSIVE_RANKING = gql`
  query getImpressiveRanking($triviaNightId: String, $quizId: String) {
    getImpressiveRanking(triviaNightId: $triviaNightId, quizId: $quizId)
  }
`;

export const FETCH_SELECTED_ANSWER_STATS = gql`
  query getSelectedAnswerStatsByQuestion(
    $questionId: String!
    $quizId: String
    $triviaNightId: String
  ) {
    getSelectedAnswerStatsByQuestion(
      questionId: $questionId
      quizId: $quizId
      triviaNightId: $triviaNightId
    ) {
      numOfOption0s
      numOfOption1s
      numOfOption2s
      numOfOption3s
      totalAnswers
      correctAnswerIndex
    }
  }
`;

export const FETCH_QUIZ_BY_ID = gql`
  query getQuizById($quizId: String) {
    getQuizById(quizId: $quizId) {
      id
      friendIds
      questionIds
      totalNumOfPlayers
      numOfWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      hostGuestCustomerId
      betAmountPerPlayer
      prizeCoins
      prizePerPlayer
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      lastQuizId
      sharedLinkOnCreation
      numOfShares
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      nextQTopic
      isMultiTopic
      nextQStartsAt
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      topicIds
      topicNames
      mainTopicLevel
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      visibilityType
      gameTimingType
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      endDate
    }
  }
`;
export const TOGGLE_FOLLOW_BUSINESS_MUTATION = gql`
  mutation toggleFollowBusiness($businessId: String!) {
    toggleFollowBusiness(businessId: $businessId) {
      id
      accountOwnerBrandContactId
      adminBrandContactIds
      stripeConnectId
      logoUrl
      name
      shortDesc
      productsDesc
      accentColor
      website
      email
      phone
      typeOfBusiness
      businessStructure
      sentShoutoutRequest
      categories
      streetAddress
      city
      province
      postalCode
      country
      line2
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      youtubeHandle
      lastEditedAt
      createdAt
    }
  }
`;
export const FETCH_PRODUCTS_BY_BUSINESS = gql`
  query getProductsByBiz($businessId: String!) {
    getProductsByBiz(businessId: $businessId) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;

export const MOVE_PRODUCT_IMAGE_IN_PRODUCT_MUTATION = gql`
  mutation moveProductImageInProduct(
    $productId: String!
    $targetIndex: Int!
    $moveDirection: Int!
  ) {
    moveProductImageInProduct(
      productId: $productId
      targetIndex: $targetIndex
      moveDirection: $moveDirection
    ) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($questionId: String!) {
    deleteQuestion(questionId: $questionId)
  }
`;
export const EDIT_PET_ACTIVITY_MUTATION = gql`
  mutation editPetActivity(
    $petActivityId: String!
    $fieldsToEdit: [String]!
    $name: String
    $shortForm: String
    $logoFile: Upload
    $accentColor: Int
    $description: String
    $tags: [String]
    $shouldIncludeInIntro: Boolean
    $postPrizeCoins: Int
  ) {
    editPetActivity(
      petActivityId: $petActivityId
      fieldsToEdit: $fieldsToEdit
      name: $name
      shortForm: $shortForm
      logoFile: $logoFile
      accentColor: $accentColor
      description: $description
      tags: $tags
      shouldIncludeInIntro: $shouldIncludeInIntro
      postPrizeCoins: $postPrizeCoins
    ) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const EDIT_TOPIC_MUTATION = gql`
  mutation editTopic(
    $topicId: String!
    $fieldsToEdit: [String]!
    $name: String
    $shortForm: String
    $logoFile: Upload
    $accentColor: Int
    $description: String
    $tags: [String]
    $minimumBet: Int
    $shouldIncludeInTN: Boolean
    $shouldIncludeInIntro: Boolean
  ) {
    editTopic(
      topicId: $topicId
      fieldsToEdit: $fieldsToEdit
      name: $name
      shortForm: $shortForm
      logoFile: $logoFile
      accentColor: $accentColor
      description: $description
      tags: $tags
      minimumBet: $minimumBet
      shouldIncludeInTN: $shouldIncludeInTN
      shouldIncludeInIntro: $shouldIncludeInIntro
    ) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      sentForReviewAt
      createdAt
      shouldIncludeInTN
      shouldIncludeInIntro
      lastEditedAt
    }
  }
`;

export const SEND_PRODUCT_FOR_APPROVAL_MUTATION = gql`
  mutation sendProductForApproval($productId: String!, $useNewCard: Boolean!) {
    sendProductForApproval(productId: $productId, useNewCard: $useNewCard) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const PROCESS_PRODUCT_LISTING_PAYMENT_RESULT = gql`
  mutation processProductListingPaymentResult($productId: String!) {
    processProductListingPaymentResult(productId: $productId) {
      id
      status
    }
  }
`;
export const EDIT_PRODUCT_MUTATION = gql`
  mutation editProduct(
    $fieldsToEdit: [String]!
    $productId: String!
    $shortDesc: String
    $unitDescription: String
    $condition: String
    $title: String
    $highlights: [String]
    $deliveryAndReturnPolicies: [String]
    $moreDetails: String
    $faqs: [String]
    $shippingFromCountry: String
    $weight: Int
    $skuNum: String
    $breadth: Int
    $height: Int
    $width: Int
    $categories: [String]
    $usualPrice: Int
    $tags: [String]
    $usualLink: String
    $startSaleAt: DateTime
    $discountPercentage: Int
    $originalStock: Int
    $saleLength: String
    $adType: Int
    $adVideoFile: Upload
  ) {
    editProduct(
      fieldsToEdit: $fieldsToEdit
      productId: $productId
      shortDesc: $shortDesc
      unitDescription: $unitDescription
      title: $title
      highlights: $highlights
      deliveryAndReturnPolicies: $deliveryAndReturnPolicies
      moreDetails: $moreDetails
      faqs: $faqs
      condition: $condition
      shippingFromCountry: $shippingFromCountry
      weight: $weight
      skuNum: $skuNum
      breadth: $breadth
      height: $height
      width: $width
      categories: $categories
      usualPrice: $usualPrice
      tags: $tags
      usualLink: $usualLink
      startSaleAt: $startSaleAt
      discountPercentage: $discountPercentage
      originalStock: $originalStock
      saleLength: $saleLength
      adType: $adType
      adVideoFile: $adVideoFile
    ) {
      id
      businessId
      title
      condition
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
      adType
      adVideoUrl
    }
  }
`;
export const EDIT_ORG_SECTION_MUTATION = gql`
  mutation editOrgSection(
    $orgSectionId: String!
    $fieldsToEdit: [String]!
    $name: String
    $type: Int
    $logoFile: Upload
  ) {
    editOrgSection(
      orgSectionId: $orgSectionId
      fieldsToEdit: $fieldsToEdit
      name: $name
      type: $type
      logoFile: $logoFile
    ) {
      id
      orgId
      orgName
      orgShortForm
      name
      logoUrl
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      type
      websiteLink
      numOfMembers
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const EDIT_ORG_MUTATION = gql`
  mutation editOrganization(
    $orgId: String!
    $fieldsToEdit: [String]!
    $name: String
    $shortForm: String
    $city: String
    $province: String
    $country: String
    $type: Int
    $logoFile: Upload
    $accentColor: Int
    $description: String
    $emailSuffixes: [String]
  ) {
    editOrganization(
      orgId: $orgId
      fieldsToEdit: $fieldsToEdit
      name: $name
      shortForm: $shortForm
      city: $city
      province: $province
      country: $country
      type: $type
      logoFile: $logoFile
      accentColor: $accentColor

      description: $description
      emailSuffixes: $emailSuffixes
    ) {
      id
      requestedByCustomerId
      name
      shortForm
      topicIds
      accentColor
      websiteLink
      emailSuffixes
      city
      province
      country
      description
      logoUrl
      numOfMembers
      status
      type
      rejectionReason
      sentForReviewAt
      lastRejectedAt
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_PRODUCT_MEDIA_BY_BUSINESS = gql`
  query getProductMediaByBusiness($businessId: String!) {
    getProductMediaByBusiness(businessId: $businessId) {
      id
      businessId
      brandContactId
      productId
      caption
      index
      type
      mediaLink
      lastEditedAt
      uploadedAt
    }
  }
`;
export const FETCH_PRODUCT_REVIEWS_BY_BUSINESS = gql`
  query getProductReviews($productId: String!, $status: Int) {
    getProductReviews(productId: $productId, status: $status) {
      id
      customerId
      businessId
      productId
      index
      reviewMsg
      status
      stars
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_BUSINESS_BY_ID = gql`
  query getBusinessById($businessId: String!) {
    getBusinessById(businessId: $businessId) {
      id
      accountOwnerBrandContactId
      adminBrandContactIds
      sentShoutoutRequest
      stripeConnectId
      logoUrl
      name
      shortDesc
      productsDesc
      accentColor
      website
      email
      phone
      typeOfBusiness
      businessStructure
      categories
      streetAddress
      city
      province
      postalCode
      country
      line2
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      youtubeHandle
      lastEditedAt
      createdAt
    }
  }
`;
export const QUIZ_UPDATED_SUBSCRIPTION = gql`
  subscription quizUpdated($quizId: String!) {
    quizUpdated(quizId: $quizId) {
      id
      friendIds
      questionIds
      totalNumOfPlayers
      numOfWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      hostGuestCustomerId
      betAmountPerPlayer
      prizeCoins
      prizePerPlayer
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      isMultiTopic
      lastQuizId
      sharedLinkOnCreation
      numOfShares
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      nextQTopic
      nextQStartsAt
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      topicIds
      topicNames
      mainTopicLevel
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      visibilityType
      gameTimingType
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      endDate
    }
  }
`;
export const FETCH_ALL_PLAYER_BATTLE_STATS_BY_BATTLE = gql`
  query getAllPlayerBattleStatsByBattle(
    $quizId: String
    $triviaNightId: String
  ) {
    getAllPlayerBattleStatsByBattle(
      quizId: $quizId
      triviaNightId: $triviaNightId
    ) {
      customerId
      guestCustomerId
      cName
      cUsername
      gUsername
      cCountry
      gCountry
      cProfilePicUrl
      gProfilePicUrl
      numOfQsAnsweredCorrectly
      totalTimeTakenForCorrectAnsThisTime
      subscriptionType
      isPaidSubscriber
    }
  }
`;
export const FETCH_TRIVIA_VIEW_COUNT = gql`
  query getTriviaViewCount($triviaNightId: String, $quizId: String) {
    getTriviaViewCount(triviaNightId: $triviaNightId, quizId: $quizId)
  }
`;

export const LOG_CONTENT_DOWNLOAD_MUTATION = gql`
  mutation logContentDownload($contentId: String!) {
    logContentDownload(contentId: $contentId) {
      id
      customerId
      contentId
      topicIds
      createdAt
    }
  }
`;

export const LOG_TRIVIA_SHARE_MUTATION = gql`
  mutation logTriviaShare(
    $type: Int!
    $triviaNightId: String
    $quizId: String
    $orgId: String
    $forumId: String
    $topicId: String
  ) {
    logTriviaShare(
      type: $type
      triviaNightId: $triviaNightId
      quizId: $quizId
      orgId: $orgId
      forumId: $forumId
      topicId: $topicId
    ) {
      id
      customerId
      triviaNightId
      quizId
      topicId
      orgId
      forumId
      type
      createdAt
      status
      rejectedAt
      approvedAt
    }
  }
`;

export const REJOIN_GAME_WITH_EXTRA_LIVES_MUTATION = gql`
  mutation rejoinGameWithExtraLives($quizId: String, $triviaNightId: String) {
    rejoinGameWithExtraLives(quizId: $quizId, triviaNightId: $triviaNightId)
  }
`;

export const SEND_TRIVIA_VIEW_MUTATION = gql`
  mutation sendTriviaView($triviaNightId: String!) {
    sendTriviaView(triviaNightId: $triviaNightId) {
      customerId
      triviaNightId
      viewedAt
      viewedAfterQuestion
    }
  }
`;

export const FETCH_TRIVIA_CHEAT_BY_CUSTOMER = gql`
  query getQCheatByCustomer(
    $questionId: String!
    $quizId: String
    $triviaNightId: String
  ) {
    getQCheatByCustomer(
      questionId: $questionId
      quizId: $quizId
      triviaNightId: $triviaNightId
    ) {
      id
      customerId
      guestCustomerId
      triviaNightId
      quizId
      cheatedOnQuestion
      cheatingType
      cheatedAt
    }
  }
`;

export const FETCH_CUSTOMER_BAILOUT_BY_EVENT = gql`
  query getCustomerBailOutByEvent($quizId: String, $triviaNightId: String) {
    getCustomerBailOutByEvent(quizId: $quizId, triviaNightId: $triviaNightId) {
      id
      customerId
      triviaNightId
      afterQId
      afterQIndex
      bailOutPrize
      bailedOutAt
    }
  }
`;
export const SEND_TRIVIA_CHEAT_MUTATION = gql`
  mutation sendTriviaCheat(
    $questionId: String!
    $triviaNightId: String
    $quizId: String
  ) {
    sendTriviaCheat(
      questionId: $questionId
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      id
      customerId
      guestCustomerId
      triviaNightId
      quizId
      questionViewId
      cheatedOnQuestion
      cheatingType
      cheatedAt
    }
  }
`;

export const FETCH_TN_RESULTS_FORMATTED = gql`
  query getTNResultsFormatted($triviaNightId: String, $quizId: String) {
    getTNResultsFormatted(triviaNightId: $triviaNightId, quizId: $quizId) {
      questionDesc
      emoji
      isCorrect
    }
  }
`;
export const FETCH_ANS_OPTIONS = gql`
  query getAnsOptions {
    getAnsOptions {
      id
      triviaNightId
      questionId
      optionCustomerId
      optionCustomerName

      optionCustomerUsername
      optionCustomerPFPUrl
      questionType
      createdByCustomerId
      assignedToCustomers
      orgSectionId
      index
      createdAt
    }
  }
`;
export const POST_VIEW_UPDATED_SUBSCRIPTION = gql`
  subscription petPostViewUpdated($petPostId: String!, $customerId: String) {
    petPostViewUpdated(petPostId: $petPostId, customerId: $customerId) {
      id
      customerId
      petPostId
      petActivityId
      subscriptionType
      status
      viewedAt
      typeOfView
    }
  }
`;
export const FETCH_TOPIC = gql`
  query getTopicById($topicId: String!) {
    getTopicById(topicId: $topicId) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl

      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      shouldIncludeInTN
      shouldIncludeInIntro
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const TRIVIA_NIGHT_UPDATED_SUBSCRIPTION = gql`
  subscription triviaNightUpdated($triviaNightId: String!) {
    triviaNightUpdated(triviaNightId: $triviaNightId) {
      id
      dayNum
      randomTopicLogoUrls
      gameTimingType
      totalNumOfPlayers
      numOfWinners
      numOfEligibleForPrizeWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      betAmountPerPlayer
      prizeCoins
      prizePerPlayer
      isForRealMoney
      nextQStartsAt
      nextQTopic
      nextQTopicLogoUrl
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      is1hReminderSent
      is5minReminderSent
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      country
      topicId
      topicIds
      topicNames
      numOfShares
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      nextGameAt
      endDate
    }
  }
`;

export const FETCH_BREED_BY_ID = gql`
  query getBreedById($breedId: String!) {
    getBreedById(breedId: $breedId) {
      id
      createdByPetId
      name
      petTypeId
      numOfPets
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_BREEDS_BY_IDS = gql`
  query getBreedsByIds($breedIds: [String]!) {
    getBreedsByIds(breedIds: $breedIds) {
      id
      createdByPetId
      name
      petTypeId
      numOfPets
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_CITY_BY_ID = gql`
  query getCityById($cityId: String!) {
    getCityById(cityId: $cityId) {
      id
      createdByCustomerId
      name
      numOfPlayers
      logoUrl
      province
      provinceShortForm
      country
      countryShortForm
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_CITY_BY_NAME = gql`
  query getCityByName($cityName: String!) {
    getCityByName(cityName: $cityName) {
      id
      createdByCustomerId
      name
      numOfPlayers
      logoUrl
      province
      provinceShortForm
      country
      countryShortForm
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_RECOMMENDED_CITIES = gql`
  query getRecommendedCitiesBySearch(
    $pageSize: Int!
    $seenIds: [String]!
    $searchTerm: String
  ) {
    getRecommendedCitiesBySearch(
      pageSize: $pageSize
      seenIds: $seenIds
      searchTerm: $searchTerm
    ) {
      id
      createdByCustomerId
      name
      numOfPlayers
      logoUrl
      province
      provinceShortForm
      country
      countryShortForm
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const RESTART_PUZZLE_MUTATION = gql`
  mutation restartPuzzle($puzzleViewId: String!) {
    restartPuzzle(puzzleViewId: $puzzleViewId)
  }
`;

export const SEND_PUZZLE_VIEW_MUTATION = gql`
  mutation sendPuzzleView($puzzleId: String!) {
    sendPuzzleView(puzzleId: $puzzleId) {
      id
      customerId
      guestCustomerId
      puzzleId
      status
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      viewedAt
      lastMovedAt
      solvedAt
      token
    }
  }
`;

export const LOG_PUZZLE_SHARE_MUTATION = gql`
  mutation logPuzzleShare($puzzleId: String!, $type: Int) {
    logPuzzleShare(puzzleId: $puzzleId, type: $type) {
      id
      customerId
      puzzleId
      type
      createdAt
      status
    }
  }
`;

export const RECORD_MOVE_PIECE_MUTATION = gql`
  mutation recordMovePiece(
    $puzzleViewId: String!
    $pieceId: String
    $actionType: String!
    $swappedPieceId: String
    $newRotation: Int
    $newFlipX: Int
    $newFlipY: Int
  ) {
    recordMovePiece(
      puzzleViewId: $puzzleViewId
      pieceId: $pieceId
      actionType: $actionType
      swappedPieceId: $swappedPieceId
      newRotation: $newRotation
      newFlipX: $newFlipX
      newFlipY: $newFlipY
    ) {
      id
      customerId
      guestCustomerId
      puzzleId
      status
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      numOfMoves
      numOfUndos
      viewedAt
      lastMovedAt
      solvedAt
    }
  }
`;

export const RECORD_UNDO_LAST_MOVE_MUTATION = gql`
  mutation recordUndoLastMove($puzzleViewId: String!) {
    recordUndoLastMove(puzzleViewId: $puzzleViewId) {
      id
      customerId
      guestCustomerId
      puzzleId
      pieceId
      swappedPieceId
      actionType
      prevRotation
      prevFlipX
      prevFlipY
      newRotation
      newFlipX
      newFlipY
    }
  }
`;

export const FETCH_PUZZLE_VIEW_BY_CUSTOMER = gql`
  query getPuzzleViewByCustomer($puzzleId: String!) {
    getPuzzleViewByCustomer(puzzleId: $puzzleId) {
      id
      customerId
      guestCustomerId
      puzzleId
      status
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      numOfMoves
      numOfUndos
      viewedAt
      lastMovedAt
      solvedAt
    }
  }
`;

export const FETCH_RECOMMENDED_PUZZLES = gql`
  query getRecommendedPuzzles(
    $pageSize: Int!
    $seenIds: [String]
    $clickedPuzzleId: String
  ) {
    getRecommendedPuzzles(
      pageSize: $pageSize
      seenIds: $seenIds
      clickedPuzzleId: $clickedPuzzleId
    ) {
      id
      title
      level
      image0Url
      image1Url
      interestingFact
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      rows
      cols
      prizeCoins
      tags
      rNumOfPlayers
      rNumOfWinners
      rAvrNumOfMoves
      lowestNumOfMoves
      rNumOfComments
      rNumOfShares
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_RECOMMENDED_PET_POSTS = gql`
  query getRecommendedPetPosts(
    $searchTerm: String
    $petActivityShortForm: String
    $pageSize: Int!
    $seenIds: [String]
    $clickedPetPostId: String
  ) {
    getRecommendedPetPosts(
      searchTerm: $searchTerm
      petActivityShortForm: $petActivityShortForm
      pageSize: $pageSize
      seenIds: $seenIds
      clickedPetPostId: $clickedPetPostId
    ) {
      id
      petActivityName
      petActivityId
      petActivityShortForm
      petActivityLogoUrl
      petProfilePicUrl
      petUsername
      petName
      mediaUrls
      petId
      customerId
      taggedPetIds
      description
      rAvrTimeWatched
      rNetVote
      rNumOfFollows
      rNumOfVotes
      rUpvotePercentage
      rNumOfViews
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      typeOfPost
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const CREATE_PET_POST_MUTATION = gql`
  mutation createPetPost(
    $petActivityId: String!
    $description: String
    $interestingFact: String
    $typeOfPost: Int
    $mediaFiles: [Upload]
  ) {
    createPetPost(
      petActivityId: $petActivityId
      description: $description
      interestingFact: $interestingFact
      typeOfPost: $typeOfPost
      mediaFiles: $mediaFiles
    ) {
      id
      petActivityName
      petActivityId
      petActivityShortForm
      petActivityLogoUrl
      petProfilePicUrl
      petName
      mediaUrls
      petId
      customerId
      taggedPetIds
      description
      rAvrTimeWatched
      rNetVote
      rNumOfFollows
      rNumOfVotes
      rUpvotePercentage
      rNumOfViews
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      typeOfPost
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_PET = gql`
  query getPetByCustomer {
    getPetByCustomer {
      id
      email
      unverifiedEmail
      activeFCMTokens
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      token
      isReal
      name
      ownerCustomerIds
      gender
      birthday
      username
      profilePicUrl
      accentColor
      city
      country
      cityId
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfActivitiesCompleted
      numOfPetPosts
      curPostStreak
      maxPostStreak
      recentPetActivityIds
      recentlyViewedAdIds
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToContacts
      isSuggestingAccountToLinkSharers
      shouldSyncContacts
      lastReviewedAt
      areSoundEffectsMuted
      curSubscriptionPrice
      balance
      totalPointsUsed
      totalEarnings
      coinsEarnedForPosts
      shouldReceivePostReminders
      remindersToReceive
      referralCode
      isCommentMod
      curRegistrationStep
      blockedPetIds
      activeChatIds
      archivedChatIds
      deletedChatIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      isActive
      deleteReason
      shouldDeleteAt
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_POST_RATING_BY_CUST_SUMMARY = gql`
  query getPetPostRatingByCustomer($petPostId: String!) {
    getPetPostRatingByCustomer(petPostId: $petPostId) {
      id
      petId
      customerId
      petPostId
      vote
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query getCustomer {
    getCustomer {
      id
      token
      name
      email
      isActive
      isPrivate
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      isSharingOnlineStatus
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phone
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      areSoundEffectsMuted
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      orgIds
      forumIds
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      cityId
      country
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      rejectedCustomerIds
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      followingIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      highestPuzzleLevel
      lowestPuzzleLevel
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      createdAt
    }
  }
`;

export const PQUIZ_UPDATED_SUBSCRIPTION = gql`
  subscription personalQuizUpdated($personalQuizId: String!) {
    personalQuizUpdated(personalQuizId: $personalQuizId) {
      id
      quizId
      questionIds
      customerId
      guestCustomerId
      nextQStartsAt
      nextQTopic
      nextQLastsXSeconds
      lastQStartedAt
      isMultiTopic
      curQEndsAt
      curAnsEndsAt
      lastCompletedQIndex
      lastCompletedQ
      curQIndex
      visibilityType
      gameTimingType
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      endDate
    }
  }
`;

export const FETCH_PERSONAL_QUIZ_BY_QUIZ_ID = gql`
  query getPersonalQuizByQuizId($quizId: String!) {
    getPersonalQuizByQuizId(quizId: $quizId) {
      id
      quizId
      questionIds
      customerId
      guestCustomerId
      nextQStartsAt
      nextQTopic
      nextQLastsXSeconds
      lastQStartedAt
      isMultiTopic
      curQEndsAt
      curAnsEndsAt
      lastCompletedQIndex
      lastCompletedQ
      curQIndex
      visibilityType
      gameTimingType
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      endDate
    }
  }
`;

export const REJECT_ORG_MUTATION = gql`
  mutation rejectOrg($orgId: String!, $rejectionReason: String!) {
    rejectOrg(orgId: $orgId, rejectionReason: $rejectionReason) {
      id
    }
  }
`;

export const REJECT_ORG_SECTION_MUTATION = gql`
  mutation rejectOrgSection($orgSectionId: String!, $rejectionReason: String!) {
    rejectOrgSection(
      orgSectionId: $orgSectionId
      rejectionReason: $rejectionReason
    ) {
      id
    }
  }
`;

export const EDIT_REVIEW_MUTATION = gql`
  mutation editReview(
    $reviewId: String!
    $fieldsToEdit: [String]
    $reviewMsg: String
    $index: Int
    $status: Int
  ) {
    editReview(
      reviewId: $reviewId
      fieldsToEdit: $fieldsToEdit
      reviewMsg: $reviewMsg
      index: $index
      status: $status
    ) {
      id
      customerId
      index
      reviewMsg
      status
      stars
      createdAt
      lastEditedAt
    }
  }
`;
export const DELETE_TRIVIA_NIGHT = gql`
  mutation deleteTriviaNight($triviaNightId: String!) {
    deleteTriviaNight(triviaNightId: $triviaNightId)
  }
`;

export const SEND_TRIVIA_NIGHT_REMINDER_MUTATION = gql`
  mutation sendTriviaNightReminder($triviaNightId: String!) {
    sendTriviaNightReminder(triviaNightId: $triviaNightId)
  }
`;

export const GIVE_LIFE_TO_EVERY1_THAT_ANSWERED_X_MUTATION = gql`
  mutation giveExtraLifeToEveryoneThatAnsweredX($optionX: Int!) {
    giveExtraLifeToEveryoneThatAnsweredX(optionX: $optionX)
  }
`;

export const CREATE_QUESTIONS_FOR_TOPIC_MUTATION = gql`
  mutation createQuestionsForTopic($topicShortForm: String!) {
    createQuestionsForTopic(topicShortForm: $topicShortForm) {
      id
      triviaNightId
      index
      topicName
      topicId
      topicShortForm
      topicLogoUrl
      creatorProfilePicUrl
      creatorName
      questionMediaUrl
      answerMediaUrl
      createdByCustomerId
      createdByGuestCustomerId
      questionDesc
      option1
      option2
      option3
      option0
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      questionType
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const APPROVE_TN_SHARE = gql`
  mutation approveTriviaShare($triviaShareId: String!) {
    approveTriviaShare(triviaShareId: $triviaShareId) {
      customerId
      triviaNightId
      type

      createdAt
      status
      rejectedAt
      approvedAt
    }
  }
`;
export const REJECT_TN_SHARE = gql`
  mutation rejectTriviaShare($triviaShareId: String!) {
    rejectTriviaShare(triviaShareId: $triviaShareId) {
      customerId
      triviaNightId
      type

      createdAt
      status
      rejectedAt
      approvedAt
    }
  }
`;

export const REJECT_PRODUCT_MUTATION = gql`
  mutation rejectProduct($productId: String!, $rejectionReason: String!) {
    rejectProduct(productId: $productId, rejectionReason: $rejectionReason) {
      id
      businessId
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const ACCEPT_ORG_SECTION_MUTATION = gql`
  mutation acceptOrgSection($orgSectionId: String!) {
    acceptOrgSection(orgSectionId: $orgSectionId) {
      id
    }
  }
`;
export const START_PRODUCT_SALE_MUTATION = gql`
  mutation startProductSale($productId: String!) {
    startProductSale(productId: $productId) {
      id
      businessId
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;

export const CLOSE_PRODUCT_SALE_MUTATION = gql`
  mutation closeProductSale($productId: String!) {
    closeProductSale(productId: $productId) {
      id
      businessId
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const CANCEL_PRODUCT_SALE_MUTATION = gql`
  mutation cancelProductSale(
    $productId: String!
    $cancellationReason: String!
  ) {
    cancelProductSale(
      productId: $productId
      cancellationReason: $cancellationReason
    ) {
      id
      businessId
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const APPROVE_PRODUCT_MUTATION = gql`
  mutation approveProduct($productId: String!) {
    approveProduct(productId: $productId) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const ACCEPT_ORG_MUTATION = gql`
  mutation acceptOrg($orgId: String!) {
    acceptOrg(orgId: $orgId) {
      id
    }
  }
`;

export const END_AD_CAMPAIGN_MUTATION = gql`
  mutation endAdCampaign($adId: String!) {
    endAdCampaign(adId: $adId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;

export const FETCH_RELATED_ACTIVITIES = gql`
  query getRelatedPetActivities($petActivityId: String!, $seenIds: [String]!) {
    getRelatedPetActivities(petActivityId: $petActivityId, seenIds: $seenIds) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_PET_ACTIVITY = gql`
  query getPetActivityById($petActivityId: String!) {
    getPetActivityById(petActivityId: $petActivityId) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_PET_ACTIVITY_STATS = gql`
  query getPetActivityStatsByPetActivity($petActivityId: String!) {
    getPetActivityStatsByPetActivity(petActivityId: $petActivityId) {
      id
      customerId
      guestCustomerId
      petId
      petActivityId
      totalPosts
      coinsEarned
    }
  }
`;
export const FETCH_PUZZLES = gql`
  query getAllPuzzles($status: Int, $seenIds: [String], $pageSize: Int) {
    getAllPuzzles(status: $status, seenIds: $seenIds, pageSize: $pageSize) {
      id
      title
      level
      image0Url
      image1Url
      tags
      prizeCoins
      interestingFact
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      rows
      cols
      rNumOfPlayers
      rNumOfWinners
      rAvrNumOfMoves
      lowestNumOfMoves
      rNumOfComments
      rNumOfShares
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const CREATE_PUZZLE_MUTATION = gql`
  mutation createPuzzle(
    $image0File: Upload!
    $image1File: Upload!
    $title: String!
    $rows: Int!
    $cols: Int!
    $interestingFact: String
    $prizeCoins: Int!
    $tags: [String]
  ) {
    createPuzzle(
      image0File: $image0File
      image1File: $image1File
      title: $title
      rows: $rows
      cols: $cols
      interestingFact: $interestingFact
      prizeCoins: $prizeCoins
      tags: $tags
    ) {
      id
      title
      level
      image0Url
      image1Url
      interestingFact
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      rows
      cols
      rNumOfPlayers
      rNumOfWinners
      rAvrNumOfMoves
      lowestNumOfMoves
      rNumOfComments
      rNumOfShares
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const EDIT_PUZZLE_MUTATION = gql`
  mutation editPuzzle(
    $puzzleId: String!
    $fieldsToEdit: [String]!
    $title: String
    $interestingFact: String
    $image0File: Upload
    $image1File: Upload
    $rows: Int
    $cols: Int
    $level: Int
    $status: Int
    $prizeCoins: Int
    $tags: [String]
  ) {
    editPuzzle(
      puzzleId: $puzzleId
      fieldsToEdit: $fieldsToEdit
      title: $title
      interestingFact: $interestingFact
      image0File: $image0File
      image1File: $image1File
      rows: $rows
      cols: $cols
      level: $level
      status: $status
      prizeCoins: $prizeCoins
      tags: $tags
    ) {
      id
      title
      level
      image0Url
      image1Url
      interestingFact
      pieces {
        id
        row
        col
        rotation
        flipX
        flipY
      }
      rows
      cols
      prizeCoins
      tags
      rNumOfPlayers
      rNumOfWinners
      rAvrNumOfMoves
      lowestNumOfMoves
      rNumOfComments
      rNumOfShares
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_PET_ACTIVITIES = gql`
  query getPetActivities {
    getPetActivities {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_ALL_ADS = gql`
  query getAllAds($siteId: Int) {
    getAllAds(siteId: $siteId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;
export const EDIT_AD_MUTATION = gql`
  mutation editAd(
    $fieldsToEdit: [String]!
    $adId: String!
    $productId: String
    $format: Int
    $shortDesc: String
    $frontPageBannerFile: Upload
    $title: String
    $audioAdFile: Upload
    $wideHorAdFile: Upload
    $narrowHorAdFile: Upload
    $regularHorAdFile: Upload
    $shortHorAdFile: Upload
    $narrowVerAdFile: Upload
    $regularVerAdFile: Upload
    $squareAdFile: Upload
    $campaignName: String
    $wideVerAdFile: Upload
    $shortVerAdFile: Upload
    $objectiveType: Int
    $categories: [String]
    $targetInterests: [String]
    $dailyBudget: Int
    $link: String
    # $numOfDaysOfCampaign: Int
    $targetAgeRanges: [Int]
    $targetGender: Int
    $targetPlaces: [String] # $campaignStartsAt: DateTime
  ) {
    editAd(
      fieldsToEdit: $fieldsToEdit
      adId: $adId
      productId: $productId
      shortDesc: $shortDesc
      format: $format
      frontPageBannerFile: $frontPageBannerFile
      title: $title
      audioAdFile: $audioAdFile
      wideHorAdFile: $wideHorAdFile
      narrowHorAdFile: $narrowHorAdFile
      regularHorAdFile: $regularHorAdFile
      shortHorAdFile: $shortHorAdFile
      narrowVerAdFile: $narrowVerAdFile
      regularVerAdFile: $regularVerAdFile
      squareAdFile: $squareAdFile
      campaignName: $campaignName
      wideVerAdFile: $wideVerAdFile
      shortVerAdFile: $shortVerAdFile
      objectiveType: $objectiveType
      categories: $categories
      targetInterests: $targetInterests
      dailyBudget: $dailyBudget
      link: $link
      # numOfDaysOfCampaign: $numOfDaysOfCampaign
      targetAgeRanges: $targetAgeRanges
      targetGender: $targetGender
      targetPlaces: $targetPlaces #  campaignStartsAt: $campaignStartsAt
    ) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      # numOfDaysOfCampaign
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      endedAt
    }
  }
`;
export const PROCESS_AD_LISTING_PAYMENT_RESULT = gql`
  mutation processAdListingPaymentResult($adId: String!) {
    processAdListingPaymentResult(adId: $adId) {
      id
      status
    }
  }
`;

export const SEND_AD_FOR_APPROVAL_MUTATION = gql`
  mutation sendAdForApproval($adId: String!, $useNewCard: Boolean!) {
    sendAdForApproval(adId: $adId, useNewCard: $useNewCard) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      # numOfDaysOfCampaign
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      endedAt
    }
  }
`;
export const FETCH_ADS_BY_BIZ = gql`
  query getAdsByBiz($businessId: String!) {
    getAdsByBiz(businessId: $businessId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      squareAdUrl
      regularVerAdUrl
      shortVerAdUrl
      categories
      targetInterests
      dailyBudget
      # numOfDaysOfCampaign
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rNumOfClicks
      rNumOfViews
      rejectedAt
      endedAt
    }
  }
`;
export const FETCH_PRODUCTS_BY_BIZ = gql`
  query getProductsByBiz($businessId: String!) {
    getProductsByBiz(businessId: $businessId) {
      id
      businessId

      businessName
      businessLogoUrl

      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const CREATE_AD_MUTATION = gql`
  mutation createAd($businessId: String, $siteId: Int!) {
    createAd(businessId: $businessId, siteId: $siteId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      #numOfDaysOfCampaign
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      endedAt
    }
  }
`;

export const FETCH_ACTIVITY_BY_SHORT_FORM = gql`
  query getPetActivityByShortForm($shortForm: String!) {
    getPetActivityByShortForm(shortForm: $shortForm) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_TOPIC_BY_SHORT_FORM = gql`
  query getTopicByShortForm($shortForm: String!) {
    getTopicByShortForm(shortForm: $shortForm) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      shouldIncludeInTN
      shouldIncludeInIntro
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const VERIFY_ORG_EMAIL_MUTATION = gql`
  mutation verifyOrgEmail($verificationCode: String!, $orgId: String!) {
    verifyOrgEmail(verificationCode: $verificationCode, orgId: $orgId) {
      id
      token
      name
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      email
      unverifiedEmail
      lowestPuzzleLevel
      highestPuzzleLevel
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phoneCountryShortForm
      phoneCountryCode
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      activeChatIds
      archivedChatIds
      deletedChatIds
      areSoundEffectsMuted
      recentPetActivityIds
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      forumTopicIds
      orgIds
      orgSectionIds
      forumIds
      requestedOrgId
      unverifiedOrgEmail
      verifiedOrgEmails
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      country
      cityId
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      petSubscriptionType
      isPaidPetSubscriber
      petSubscriptionPausedAt
      isRetryPetSubPaymentEmailSent
      retryPetSubPaymentEmailSentAt
      currentPetId
      rejectedCustomerIds
      followingIds
      followingPetIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      numOfPetsBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      isActive
      deleteReason
      shouldDeleteAt
      createdAt
    }
  }
`;

export const FETCH_RANKED_FRIENDS = gql`
  query getRankedFriends(
    $timeRange: String!
    $sortBy: String!
    $groupType: String!
    $orgId: String
    $cityId: String
    $country: String
    $seenIds: [String]
    $topicId: String
    $petActivityId: String
    $triviaNightId: String
    $quizId: String
  ) {
    getRankedFriends(
      timeRange: $timeRange
      sortBy: $sortBy
      groupType: $groupType
      orgId: $orgId
      cityId: $cityId
      country: $country
      seenIds: $seenIds
      topicId: $topicId
      petActivityId: $petActivityId
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      id
      name
      username
      twitterHandle
      profilePicUrl
      numOfTKLsPlayed
      numOfBattlesPlayed
      country
      numOfTKLsWon
      numOfBattlesWon
      balance
      totalEarnings
      curFocusStreak
      maxFocusStreak
      curBattleWinStreak
      maxBattleWinStreak
      curTKLWinStreak
      maxTKLWinStreak
      lastActiveAt
      followingIds
      followingPetIds
      subscriptionType
      isPaidSubscriber
      isPaidPetSubscriber
      totalQsAnsweredCorrect
      totalQsAnswered
      accuracy
      availableExtraLivesCount
      avgAccuracy
      avgTimeTakenForCorrectAns
    }
  }
`;

export const FETCH_ARE_FRIEND_REQUESTS_AVAILABLE = gql`
  query areFriendRequestsAvailable {
    areFriendRequestsAvailable
  }
`;

export const SEND_ORG_EMAIL_VERIFICATION_CODE_MUTATION = gql`
  mutation sendOrgEmailVerificationCode($orgId: String!, $email: String!) {
    sendOrgEmailVerificationCode(orgId: $orgId, email: $email) {
      id
      token
      name
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      email
      unverifiedEmail
      lowestPuzzleLevel
      highestPuzzleLevel
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phoneCountryShortForm
      phoneCountryCode
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      activeChatIds
      archivedChatIds
      deletedChatIds
      areSoundEffectsMuted
      recentPetActivityIds
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      forumTopicIds
      orgIds
      orgSectionIds
      forumIds
      requestedOrgId
      unverifiedOrgEmail
      verifiedOrgEmails
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      country
      cityId
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      petSubscriptionType
      isPaidPetSubscriber
      petSubscriptionPausedAt
      isRetryPetSubPaymentEmailSent
      retryPetSubPaymentEmailSentAt
      currentPetId
      rejectedCustomerIds
      followingIds
      followingPetIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      numOfPetsBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      isActive
      deleteReason
      shouldDeleteAt
      createdAt
    }
  }
`;

export const FETCH_ORG_BY_NAME_FOR_URL = gql`
  query getOrgByNameForUrl($nameForUrl: String!) {
    getOrgByNameForUrl(nameForUrl: $nameForUrl) {
      id
      requestedByCustomerId
      name
      shortForm
      nameForUrl
      accentColor
      websiteLink
      description
      city
      province
      emailSuffixes
      country
      logoUrl
      numOfMembers
      status
      type
      rejectionReason
      sentForReviewAt
      lastRejectedAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_RECOMMENDED_QUESTIONS_BY_TOPIC = gql`
  query getRecommendedQuestions(
    $pageSize: Int!
    $topicShortForm: String
    $seenIds: [String]
    $clickedQuestionId: String
    $orgNameForUrl: String
  ) {
    getRecommendedQuestions(
      pageSize: $pageSize
      topicShortForm: $topicShortForm
      seenIds: $seenIds
      clickedQuestionId: $clickedQuestionId
      orgNameForUrl: $orgNameForUrl
    ) {
      id
      topicName
      topicId
      topicLogoUrl
      topicShortForm
      creatorProfilePicUrl
      createdByCustomerId
      createdByGuestCustomerId
      creatorName
      questionDesc
      option1
      option2
      option3
      option0
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      questionType
      status
      createdAt
      lastEditedAt
      questionMediaUrl
      answerMediaUrl
      interestingFact
    }
  }
`;
export const FETCH_LIVES_COUNT_BY_CUSTOMER = gql`
  query getLivesCountByCustomer(
    $customerId: String
    $guestCustomerId: String
    $petId: String
  ) {
    getLivesCountByCustomer(
      customerId: $customerId
      guestCustomerId: $guestCustomerId
      petId: $petId
    )
  }
`;
export const FETCH_SHOULD_ASK_FOR_REVIEW = gql`
  query getShouldAskForReview($askingFromLoc: Int, $siteId: Int!) {
    getShouldAskForReview(askingFromLoc: $askingFromLoc, siteId: $siteId)
  }
`;

export const FETCH_TRIVIA_VIEW_BY_CUSTOMER = gql`
  query getTriviaViewByCustomer($triviaNightId: String, $quizId: String) {
    getTriviaViewByCustomer(triviaNightId: $triviaNightId, quizId: $quizId) {
      id
      customerId
      guestCustomerId
      triviaNightId
      quizId
      status
      viewedAt
      joinedAt

      subscriptionType
      viewedAfterQuestion
    }
  }
`;

export const FETCH_UNIQUE_QS_ANSWERED_CORRECTLY = gql`
  query getNumOfUniqueQsAnsweredCorrectly($topicId: String!) {
    getNumOfUniqueQsAnsweredCorrectly(topicId: $topicId)
  }
`;

export const FETCH_PLAYER_TOPIC_STATS = gql`
  query getPlayerTopicStatsByTopic($topicId: String!) {
    getPlayerTopicStatsByTopic(topicId: $topicId) {
      id
      customerId
      guestCustomerId
      topicId
      levelNum
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesWon
      numOfBattlesPlayed
      curBattleWinStreak
      maxBattleWinStreak
      curFocusStreak
      maxFocusStreak
      accuracy
      coinsEarned
    }
  }
`;

export const FETCH_FORUM_ADMINS_BY_FORUM = gql`
  query getForumAdminsByForum($forumId: String!) {
    getForumAdminsByForum(forumId: $forumId) {
      id
      name
      username
      twitterHandle
      profilePicUrl
      numOfTKLsPlayed
      numOfBattlesPlayed
      country
      numOfTKLsWon
      numOfBattlesWon
      balance
      curFocusStreak
      curBattleWinStreak
      maxBattleWinStreak
      curTKLWinStreak
      maxTKLWinStreak
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      lastActiveAt
      followingIds
      subscriptionType
      isPaidSubscriber
      totalQsAnsweredCorrect
      totalQsAnswered
      accuracy
      availableExtraLivesCount
    }
  }
`;

export const EDIT_PET_POST_MUTATION = gql`
  mutation editPetPost(
    $petPostId: String!
    $fieldsToEdit: [String]!
    $description: String
    $interestingFact: String
    $status: Int
  ) {
    editPetPost(
      petPostId: $petPostId
      fieldsToEdit: $fieldsToEdit
      description: $description
      interestingFact: $interestingFact
      status: $status
    ) {
      id
      petActivityName
      petUsername
      petActivityId
      petActivityShortForm
      petActivityLogoUrl
      petProfilePicUrl
      petName
      mediaUrls
      petId
      customerId
      taggedPetIds
      description
      rAvrTimeWatched
      rNetVote
      rNumOfFollows
      rNumOfVotes
      rUpvotePercentage
      rNumOfViews
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      typeOfPost
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_PET_POSTS = gql`
  query getAllPetPosts($status: Int, $seenIds: [String], $pageSize: Int) {
    getAllPetPosts(status: $status, seenIds: $seenIds, pageSize: $pageSize) {
      id
      petActivityName
      petActivityId
      petActivityShortForm
      petActivityLogoUrl
      petProfilePicUrl
      petUsername
      petName
      mediaUrls
      petId
      customerId
      taggedPetIds
      description
      rAvrTimeWatched
      rNetVote
      rNumOfFollows
      rNumOfVotes
      rUpvotePercentage
      rNumOfViews
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      typeOfPost
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_RECOMMENDED_BREEDS = gql`
  query getRecommendedBreedsBySearch(
    $pageSize: Int!
    $seenIds: [String]!
    $searchTerm: String
    $petTypeId: String
  ) {
    getRecommendedBreedsBySearch(
      pageSize: $pageSize
      seenIds: $seenIds
      searchTerm: $searchTerm
      petTypeId: $petTypeId
    ) {
      id
      createdByPetId
      name
      petTypeId
      numOfPets
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_RECOMMENDED_PET_FRIENDS = gql`
  query getRecommendedPetFriends(
    $searchTerm: String
    $selectedPetFriendIds: [String]
    $seenIds: [String]
    $emails: [String]
    $phoneNumbers: [String]
  ) {
    getRecommendedPetFriends(
      searchTerm: $searchTerm
      selectedPetFriendIds: $selectedPetFriendIds
      seenIds: $seenIds
      emails: $emails
      phoneNumbers: $phoneNumbers
    ) {
      id
      token
      name
      gender
      username
      profilePicUrl
      accentColor
      country
      followingIds
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfPetPosts
      curPostStreak
      maxPostStreak
      balance
      totalEarnings
      shouldReceivePostReminders
      remindersToReceive
      isActive
      subscriptionType
      isPaidSubscriber
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_RECOMMENDED_FRIENDS = gql`
  query getRecommendedFriends(
    $searchTerm: String
    $selectedFriendIds: [String]
    $seenIds: [String]
    $emails: [String]
    $phoneNumbers: [String]
    $orgId: String
  ) {
    getRecommendedFriends(
      searchTerm: $searchTerm
      selectedFriendIds: $selectedFriendIds
      seenIds: $seenIds
      emails: $emails
      phoneNumbers: $phoneNumbers
      orgId: $orgId
    ) {
      id
      name
      username
      twitterHandle
      profilePicUrl
      numOfTKLsPlayed
      numOfBattlesPlayed
      country
      numOfTKLsWon
      numOfBattlesWon
      balance
      curFocusStreak
      curBattleWinStreak
      maxBattleWinStreak
      curTKLWinStreak
      maxTKLWinStreak
      totalEarnings
      lastActiveAt
      followingIds
      subscriptionType
      isPaidSubscriber
      totalQsAnsweredCorrect
      totalQsAnswered
      accuracy
      availableExtraLivesCount
    }
  }
`;

export const SEND_NOTIF_CLICK_MUTATION = gql`
  mutation sendNotificationClick($notificationId: String!) {
    sendNotificationClick(notificationId: $notificationId) {
      id
      customerId
      triviaNightId
      quizId
      personalQuizId
      friendId
      triviaWinId
      message
      topicId
      topicName
      friendName
      friendUsername
      type
      status
      createdAt
      clickedAt
      archivedAt
    }
  }
`;
export const TOGGLE_JOIN_ORG_MUTATION = gql`
  mutation toggleJoinOrg($orgId: String!) {
    toggleJoinOrg(orgId: $orgId) {
      id
      orgIds
    }
  }
`;
export const TOGGLE_FRIEND_MUTATION = gql`
  mutation toggleFriend($username: String!) {
    toggleFriend(username: $username) {
      followingIds
    }
  }
`;

export const TOGGLE_PET_FRIEND_MUTATION = gql`
  mutation togglePetFriend($username: String!) {
    togglePetFriend(username: $username) {
      followingIds
    }
  }
`;

export const REJECT_FRIEND_REQUEST_MUTATION = gql`
  mutation rejectFriendRequest($username: String!) {
    rejectFriendRequest(username: $username) {
      followingIds
    }
  }
`;

export const CREATE_PET_ACTIVITY_MUTATION = gql`
  mutation createPetActivity(
    $name: String!
    $shortForm: String!
    $description: String!
    $tags: [String]!
    $shouldIncludeInIntro: Boolean!
    $postPrizeCoins: Int!
    $accentColor: Int!
    $logoFile: Upload
  ) {
    createPetActivity(
      name: $name
      shortForm: $shortForm
      description: $description
      tags: $tags
      shouldIncludeInIntro: $shouldIncludeInIntro
      postPrizeCoins: $postPrizeCoins
      accentColor: $accentColor
      logoFile: $logoFile
    ) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const CREATE_TOPIC_MUTATION = gql`
  mutation createTopic(
    $name: String!
    $shortForm: String!
    $accentColor: Int!
    $description: String!
    $tags: [String]!
    $minimumBet: Int!
    $logoFile: Upload
    $shouldIncludeInTN: Boolean!
    $shouldIncludeInIntro: Boolean!
  ) {
    createTopic(
      name: $name
      shortForm: $shortForm
      accentColor: $accentColor
      description: $description
      tags: $tags
      minimumBet: $minimumBet
      logoFile: $logoFile
      shouldIncludeInTN: $shouldIncludeInTN
      shouldIncludeInIntro: $shouldIncludeInIntro
    ) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      sentForReviewAt
      shouldIncludeInTN
      shouldIncludeInIntro
      createdAt
      lastEditedAt
    }
  }
`;

export const CREATE_ORG_SECTION_MUTATION = gql`
  mutation createOrgSection(
    $orgId: String!
    $name: String!
    $type: Int!
    $logoFile: Upload
    $websiteLink: String
  ) {
    createOrgSection(
      orgId: $orgId
      name: $name
      type: $type
      logoFile: $logoFile
      websiteLink: $websiteLink
    ) {
      id
      orgId
      orgName
      orgShortForm
      name
      logoUrl
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      type
      websiteLink

      numOfMembers
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const CREATE_ORG_MUTATION = gql`
  mutation createOrganization(
    $name: String!
    $shortForm: String!
    $city: String!
    $province: String!
    $country: String!
    $type: Int!
    $websiteLink: String!
    $emailSuffixes: [String]!
    $logoFile: Upload
    $description: String
  ) {
    createOrganization(
      name: $name
      shortForm: $shortForm
      city: $city
      province: $province
      country: $country
      type: $type
      emailSuffixes: $emailSuffixes
      websiteLink: $websiteLink
      logoFile: $logoFile
      description: $description
    ) {
      id
      requestedByCustomerId
      name
      shortForm
      websiteLink
      nameForUrl
      description
      city
      province
      country
      emailSuffixes
      logoUrl
      numOfMembers
      status
      type
      rejectionReason
      sentForReviewAt
      lastRejectedAt
      createdAt
      lastEditedAt
    }
  }
`;
export const CREATE_FORUM_MUTATION = gql`
  mutation createForum(
    $title: String!
    $orgId: String!
    $accentColor: Int!
    $type: Int!
    $topicId: String
    $logoFile: Upload
    $section: String
    $term: String
    $adminCustomerIds: [String]
    $folders: [String]
  ) {
    createForum(
      title: $title
      orgId: $orgId
      accentColor: $accentColor
      type: $type
      topicId: $topicId
      logoFile: $logoFile
      section: $section
      term: $term
      adminCustomerIds: $adminCustomerIds
      folders: $folders
    ) {
      id
      orgId
      title
      topicId
      section
      term
      logoUrl
      accentColor
      adminCustomerIds
      folders
      numOfMembers
      status
      type
      createdAt
      lastEditedAt
      archivedAt
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      sentForReviewAt
    }
  }
`;
export const CREATE_CONTENT_MUTATION = gql`
  mutation createContent(
    $title: String
    $videoFile: Upload
    $typeOfVideo: Int
    $topicNames: [String]
    $tiktokClipUrl: String
    $instagramClipUrl: String
    $youtubeClipUrl: String
  ) {
    createContent(
      title: $title
      videoFile: $videoFile
      typeOfVideo: $typeOfVideo
      topicNames: $topicNames
      tiktokClipUrl: $tiktokClipUrl
      instagramClipUrl: $instagramClipUrl
      youtubeClipUrl: $youtubeClipUrl
    ) {
      id
      title
      topicIds
      topicNames
      videoUrl
      typeOfVideo
      creatorProfilePicUrl
      creatorName
      createdByCustomerId
      createdByGuestCustomerId
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      tiktokClipUrl
      youtubeClipUrl
      instagramClipUrl
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const HIDE_COMMENT_MUTATION = gql`
  mutation hideComment($commentId: String!) {
    hideComment(commentId: $commentId) {
      message
    }
  }
`;

export const FETCH_COMMENTS_BY_EVENT_QUERY = gql`
  query getCommentsByEvent(
    $seenIds: [String]!
    $pageSize: Int!
    $productId: String
    $triviaNightId: String
    $chatId: String
    $quizId: String
    $questionId: String
  ) {
    getCommentsByEvent(
      seenIds: $seenIds
      pageSize: $pageSize
      productId: $productId
      triviaNightId: $triviaNightId
      chatId: $chatId
      quizId: $quizId
      questionId: $questionId
    ) {
      id
      userId
      statsForUserId
      typeOfCommentor
      quizId
      attachedQuizId
      chatId
      readByUserIds
      commentorProfilePicUrl
      triviaNightId
      productId
      message
      commentRepliedToId
      repliedToPFPUrl
      repliedToUserId
      repliedToType
      questionId
      deltaXP
      highlightedPrefix
      xpType
      repliedToUsername
      commentorUsername
      subscriptionType
      status
      createdAt
      hiddenByModId
      hiddenAt
    }
  }
`;
export const FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY = gql`
  query getSuggestedMessagesByEvent(
    $seenIds: [String]!
    $pageSize: Int!
    $quizId: String
    $chatId: String
    $triviaNightId: String
    $productId: String
    $questionViewId: String
  ) {
    getSuggestedMessagesByEvent(
      seenIds: $seenIds
      pageSize: $pageSize
      quizId: $quizId
      chatId: $chatId
      triviaNightId: $triviaNightId
      productId: $productId
      questionViewId: $questionViewId
    )
  }
`;
export const FETCH_CUSTOMER_PAYMENT_METHOD_DETAILS = gql`
  query getCustomerPaymentMethodDetails($customerId: String) {
    getCustomerPaymentMethodDetails(customerId: $customerId) {
      stripePaymentMethodId
      expMonth
      expYear
      brand
      last4
      name
      isPrimaryPaymentMethod
    }
  }
`;

export const FETCH_CUR_TRIVIA_NIGHT = gql`
  query getCurTriviaNight {
    getCurTriviaNight {
      id
      dayNum
      gameTimingType
      totalNumOfPlayers
      numOfWinners
      numOfEligibleForPrizeWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      prizeCoins
      prizePerPlayer
      isForRealMoney
      nextQStartsAt
      nextQTopic
      nextQTopicLogoUrl
      nextQLastsXSeconds
      randomTopicLogoUrls
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      is1hReminderSent
      is5minReminderSent
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      country
      topicId
      topicIds
      topicNames
      numOfShares
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      nextGameAt
      endDate
      betAmountPerPlayer
    }
  }
`;

export const FETCH_RECOMMENDED_FORUMS = gql`
  query getRecommendedForumsBySearch(
    $pageSize: Int!
    $seenIds: [String]!
    $orgId: String
    $searchTerm: String
    $showJoinedOnly: Boolean
  ) {
    getRecommendedForumsBySearch(
      pageSize: $pageSize
      seenIds: $seenIds
      orgId: $orgId
      searchTerm: $searchTerm
      showJoinedOnly: $showJoinedOnly
    ) {
      id
      orgId
      orgName
      orgShortForm
      title
      topicId
      section
      term
      nameForUrl
      logoUrl
      accentColor
      adminCustomerIds
      folders
      numOfMembers
      status
      type
      createdAt
      lastEditedAt
      archivedAt
    }
  }
`;
export const FETCH_PRODUCT_VIEW_BY_CUSTOMER = gql`
  query getProductViewByCustomer($productId: String!) {
    getProductViewByCustomer(productId: $productId) {
      customerId
      productId
      viewedAt
    }
  }
`;
export const FETCH_PRODUCT_VIEW_COUNT = gql`
  query getProductViewCount($productId: String!) {
    getProductViewCount(productId: $productId)
  }
`;
export const FETCH_COMMENT_COUNT = gql`
  query getCommentCountByEvent(
    $productId: String
    $triviaNightId: String
    $quizId: String
  ) {
    getCommentCountByEvent(
      productId: $productId
      triviaNightId: $triviaNightId
      quizId: $quizId
    )
  }
`;

export const FETCH_CUSTOMER_PRODUCT_BY_PRODUCT = gql`
  query getCustomerProductByProductId($productId: String!) {
    getCustomerProductByProductId(productId: $productId) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;
export const FETCH_FORUM_ADMIN_REQUESTS = gql`
  query getForumAdminRequests(
    $status: Int
    $seenIds: [String]
    $pageSize: Int
  ) {
    getForumAdminRequests(
      status: $status
      seenIds: $seenIds
      pageSize: $pageSize
    ) {
      id
      customerId
      forumId
      status
      acceptedByCustomerId
      createdAt
      lastSentAt
    }
  }
`;
export const FETCH_ALL_SEARCHES = gql`
  query getAllSearches(
    $status: Int
    $placeOfSearch: Int
    $pageSize: Int
    $isCheckedByAdmin: Boolean
    $seenIds: [String]
  ) {
    getAllSearches(
      status: $status
      placeOfSearch: $placeOfSearch
      pageSize: $pageSize
      isCheckedByAdmin: $isCheckedByAdmin
      seenIds: $seenIds
    ) {
      count
      searchTerm
      lastSearchedAt
    }
  }
`;

export const TOGGLE_SEARCH_TERM_CHECKED_BY_ADMIN_MUTATION = gql`
  mutation toggleSearchTermCheckedByAdmin($searchTerm: String!) {
    toggleSearchTermCheckedByAdmin(searchTerm: $searchTerm)
  }
`;
export const CREATE_COMMENT_MUTATION = gql`
  mutation createComment(
    $triviaNightId: String
    $productId: String
    $chatId: String
    $quizId: String
    $attachedQuizId: String
    $questionId: String
    $commentRepliedToId: String
    $message: String
  ) {
    createComment(
      triviaNightId: $triviaNightId
      productId: $productId
      chatId: $chatId
      quizId: $quizId
      attachedQuizId: $attachedQuizId
      questionId: $questionId
      commentRepliedToId: $commentRepliedToId
      message: $message
    ) {
      id
      userId
      statsForUserId
      typeOfCommentor
      quizId
      attachedQuizId
      chatId
      readByUserIds
      commentorProfilePicUrl
      triviaNightId
      productId
      message
      commentRepliedToId
      repliedToPFPUrl
      repliedToUserId
      repliedToType
      questionId
      deltaXP
      xpType
      repliedToUsername
      commentorUsername
      subscriptionType
      highlightedPrefix
      status
      createdAt
      hiddenByModId
      hiddenAt
    }
  }
`;

export const FETCH_RECOMMENDED_TOPICS = gql`
  query getRecommendedTopicsBySearch(
    $pageSize: Int!
    $seenIds: [String]!
    $searchTerm: String
    $categoryShortForm: String
    $orgId: String
  ) {
    getRecommendedTopicsBySearch(
      pageSize: $pageSize
      seenIds: $seenIds
      searchTerm: $searchTerm
      categoryShortForm: $categoryShortForm
      orgId: $orgId
    ) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      shouldIncludeInTN
      shouldIncludeInIntro
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_PETS_BY_CUSTOMER = gql`
  query getPetsByCustomer {
    getPetsByCustomer {
      id
      email
      unverifiedEmail
      activeFCMTokens
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      token
      isReal
      name
      ownerCustomerIds
      gender
      birthday
      username
      profilePicUrl
      accentColor
      city
      country
      cityId
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfActivitiesCompleted
      numOfPetPosts
      curPostStreak
      maxPostStreak
      recentPetActivityIds
      recentlyViewedAdIds
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToContacts
      isSuggestingAccountToLinkSharers
      shouldSyncContacts
      lastReviewedAt
      areSoundEffectsMuted
      curSubscriptionPrice
      balance
      totalPointsUsed
      totalEarnings
      coinsEarnedForPosts
      shouldReceivePostReminders
      remindersToReceive
      referralCode
      isCommentMod
      curRegistrationStep
      blockedPetIds
      activeChatIds
      archivedChatIds
      deletedChatIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      isActive
      deleteReason
      shouldDeleteAt
      lastActiveAt
      createdAt
    }
  }
`;
export const TOGGLE_MUTE_SOUND_EFFECTS_MUTATION = gql`
  mutation toggleMuteSoundEffects {
    toggleMuteSoundEffects
  }
`;

export const FETCH_CUSTOMER_ORGS = gql`
  query getCustomerOrgs {
    getCustomerOrgs {
      id
      requestedByCustomerId
      name
      shortForm
      websiteLink
      description
      nameForUrl
      city
      emailSuffixes
      province
      country
      logoUrl
      numOfMembers
      status
      type
      rejectionReason
      sentForReviewAt
      lastRejectedAt
      createdAt
      lastEditedAt
    }
  }
`;
export const REGISTER_BRAND_CONTACT = gql`
  mutation registerBrandContact(
    $email: String!
    $name: String!
    $businessName: String!
    $siteId: Int!
    $password: String!
    $country: String
    $businessWeb: String
  ) {
    registerBrandContact(
      email: $email
      name: $name
      businessName: $businessName
      siteId: $siteId
      password: $password
      country: $country
      businessWeb: $businessWeb
    ) {
      id
      token
      username
      name
      email
      accentColor
      balance
      birthday
      gender
      phone
      country
      profilePicUrl
      createdOnSiteId
      followingBusinessIds
      stripeCustomerId
      revenueCatAppUserId
      passwordResetLastRequestedAt
      passwordResetAt
      primaryStripePaymentMethodId
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      lastActiveAt
      createdAt
    }
  }
`;

export const REGISTER_PET = gql`
  mutation registerPet(
    $password: String!
    $isReal: Boolean!
    $referralCode: String!
    $name: String!
    $joinedFor: Int!
    $usingPhone: Boolean
    $email: String
    $phone: String
    $phoneCountryCode: String
    $phoneCountryShortForm: String
    $country: String
    $productJoinedFor: String
  ) {
    registerPet(
      password: $password
      isReal: $isReal
      referralCode: $referralCode
      name: $name
      joinedFor: $joinedFor
      usingPhone: $usingPhone
      email: $email
      phone: $phone
      phoneCountryCode: $phoneCountryCode
      phoneCountryShortForm: $phoneCountryShortForm
      country: $country
      productJoinedFor: $productJoinedFor
    ) {
      id
      email
      unverifiedEmail
      activeFCMTokens
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      token
      name
      ownerCustomerIds
      gender
      birthday
      username
      profilePicUrl
      accentColor
      city
      country
      cityId
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfPetPosts
      curPostStreak
      maxPostStreak
      recentPetActivityIds
      recentlyViewedAdIds
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToContacts
      isSuggestingAccountToLinkSharers
      shouldSyncContacts
      lastReviewedAt
      areSoundEffectsMuted
      curSubscriptionPrice
      balance
      totalPointsUsed
      totalEarnings
      coinsEarnedForPosts
      shouldReceivePostReminders
      remindersToReceive
      referralCode
      isCommentMod
      curRegistrationStep
      blockedPetIds
      activeChatIds
      archivedChatIds
      deletedChatIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      isActive
      deleteReason
      shouldDeleteAt
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastActiveAt
      createdAt
    }
  }
`;

export const REGISTER_CUSTOMER = gql`
  mutation registerCustomer(
    $usingPhone: Boolean
    $email: String
    $password: String!
    $phone: String
    $phoneCountryCode: String
    $phoneCountryShortForm: String
    $referralCode: String!
    $joinedFor: Int!
    $country: String
    $name: String
    $productJoinedFor: String
    $siteId: Int!
  ) {
    registerCustomer(
      usingPhone: $usingPhone
      email: $email
      password: $password
      phone: $phone
      phoneCountryCode: $phoneCountryCode
      phoneCountryShortForm: $phoneCountryShortForm
      referralCode: $referralCode
      siteId: $siteId
      name: $name
      joinedFor: $joinedFor
      country: $country
      productJoinedFor: $productJoinedFor
    ) {
      id
      token
      email
    }
  }
`;
export const FETCH_PETS_BY_SUBSCRIPTION = gql`
  query getFriendsByPetSubscription {
    getFriendsByPetSubscription {
      id
      token
      name
      gender
      username
      profilePicUrl
      accentColor
      country
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfPetPosts
      curPostStreak
      maxPostStreak
      balance
      totalEarnings
      shouldReceivePostReminders
      remindersToReceive
      isActive
      subscriptionType
      isPaidSubscriber
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_PET_SUBSCRIPTION = gql`
  query getPetSubscription($status: Int!) {
    getPetSubscription(status: $status) {
      id
      petId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberPetIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;
export const LOGIN_PET_MUTATION = gql`
  mutation loginPet(
    $password: String!
    $email: String
    $phone: String
    $usingPhone: Boolean
  ) {
    loginPet(
      password: $password
      email: $email
      phone: $phone
      usingPhone: $usingPhone
    )
  }
`;
export const VERIFY_PET = gql`
  mutation verifyPet(
    $verificationCode: String
    $phone: String
    $email: String
    $usingPhone: Boolean
  ) {
    verifyPet(
      verificationCode: $verificationCode
      phone: $phone
      email: $email
      usingPhone: $usingPhone
    )
  }
`;
export const FETCH_CUSTOMER_SUBSCRIPTION = gql`
  query getCustomerSubscription($status: Int!) {
    getCustomerSubscription(status: $status) {
      id
      customerId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      paymentStartedAt
      ephemeralKey
      paymentConfirmedAt
      memberCustomerIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;
export const CREATE_GUEST_CUSTOMER_MUTATION = gql`
  mutation createGuestCustomer(
    $joinedFor: Int!
    $country: String
    $triviaNightId: String
    $quizId: String
    $productId: String
  ) {
    createGuestCustomer(
      joinedFor: $joinedFor
      country: $country
      triviaNightId: $triviaNightId
      quizId: $quizId
      productId: $productId
    ) {
      id
      token
      name
      username
      profilePicUrl
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      country
      areSoundEffectsMuted
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      quizJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      accuracy
      availableExtraLivesCount
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      convertedToCustomerId
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      lastReviewedAt
      createdAt
    }
  }
`;

export const FETCH_RELATED_TOPICS = gql`
  query getRelatedTopics($topicId: String!, $seenIds: [String]!) {
    getRelatedTopics(topicId: $topicId, seenIds: $seenIds) {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet
      shouldIncludeInTN
      shouldIncludeInIntro
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const ACTIVE_NOTIFICATION_QUERY = gql`
  query getActiveNotifications($type: Int) {
    getActiveNotifications(type: $type) {
      id
      customerId
      triviaNightId
      quizId
      personalQuizId
      friendId
      triviaWinId
      message
      type
      status
      createdAt
      clickedAt
      archivedAt

      topicId
      topicName
      friendName
      friendUsername
    }
  }
`;

export const CREATE_PRODUCT_MEDIA = gql`
  mutation createProductMedia(
    $businessId: String!
    $index: Int!
    $productId: String
    $caption: String
    $mediaFile: Upload
  ) {
    createProductMedia(
      businessId: $businessId
      index: $index
      productId: $productId
      caption: $caption
      mediaFile: $mediaFile
    ) {
      id
      businessId
      brandContactId
      productId
      caption
      index
      type
      mediaLink
      lastEditedAt
      uploadedAt
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress(
    $streetAddress: String!
    $city: String!
    $province: String!
    $postalCode: String!
    $country: String!
    $line2: String
    $specialDeliveryInstructions: String
  ) {
    createAddress(
      streetAddress: $streetAddress
      city: $city
      province: $province
      postalCode: $postalCode
      country: $country
      line2: $line2
      specialDeliveryInstructions: $specialDeliveryInstructions
    ) {
      userId
      streetAddress
      city
      province
      postalCode
      country
      line2
      specialDeliveryInstructions
      createdAt
      lastEditedAt
      isPrimary
    }
  }
`;
export const CREATE_OR_JOIN_QUIZ_MUTATION = gql`
  mutation createOrJoinQuiz(
    $topicIds: [String]!
    $gameTimingType: Int!
    $friendIds: [String]
    $shouldShareLinkInChat: Boolean
    $lastQuizId: String
    $country: String #$timezone: String!
    $isMultiTopic: Boolean!
  ) {
    createOrJoinQuiz(
      topicIds: $topicIds
      gameTimingType: $gameTimingType
      friendIds: $friendIds
      shouldShareLinkInChat: $shouldShareLinkInChat
      lastQuizId: $lastQuizId
      country: $country #     timezone: $timezone
      isMultiTopic: $isMultiTopic
    ) {
      id
      friendIds
      questionIds
      totalNumOfPlayers
      numOfWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      hostGuestCustomerId
      betAmountPerPlayer
      prizeCoins
      prizePerPlayer
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      lastQuizId
      isMultiTopic
      sharedLinkOnCreation
      numOfShares
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      nextQTopic
      nextQStartsAt
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      topicIds
      topicNames
      mainTopicLevel
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      visibilityType
      gameTimingType
      createdAt
      lastEditedAt
      releaseDate
      token
    }
  }
`;

export const FETCH_BET_AMOUNTS_BY_TOPIC = gql`
  query getBetAmountsByTopicForCustomer($topicId: String!) {
    getBetAmountsByTopicForCustomer(topicId: $topicId) {
      amount
      desc
      type
    }
  }
`;

export const COMMENTS_SUBSCRIPTION = gql`
  subscription commentAdded($eventId: String!) {
    commentAdded(eventId: $eventId) {
      id
      userId
      statsForUserId
      typeOfCommentor
      quizId
      attachedQuizId
      chatId
      readByUserIds
      commentorProfilePicUrl
      triviaNightId
      productId
      message
      commentRepliedToId
      repliedToPFPUrl
      repliedToUserId
      repliedToType
      questionId
      deltaXP
      xpType
      repliedToUsername
      commentorUsername
      subscriptionType
      highlightedPrefix
      status
      createdAt
      hiddenByModId
      hiddenAt
    }
  }
`;
export const FETCH_AVG_LEVEL_IN_ORG = gql`
  query getAvgLevelInOrg($orgId: String!) {
    getAvgLevelInOrg(orgId: $orgId)
  }
`;
export const FETCH_RANKED_CITIES = gql`
  query getRankedCities(
    $timeRange: String!
    $sortBy: String!
    $topicId: String
    $triviaNightId: String
    $quizId: String
  ) {
    getRankedCities(
      timeRange: $timeRange
      sortBy: $sortBy
      topicId: $topicId
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      _id
      cityName
      countryName
      logoUrl
      totalEarnings
      maxFocusStreak
      avgAccuracy
      totalNumOfBattlesWon
      totalNumOfTKLsWon
      avgLivesCount
      avgTimeTakenForCorrectAns
    }
  }
`;

export const FETCH_RANKED_COUNTRIES = gql`
  query getRankedCountries(
    $timeRange: String!
    $sortBy: String!
    $topicId: String
    $quizId: String
    $triviaNightId: String
  ) {
    getRankedCountries(
      timeRange: $timeRange
      sortBy: $sortBy
      topicId: $topicId
      quizId: $quizId
      triviaNightId: $triviaNightId
    ) {
      _id
      totalEarnings
      maxFocusStreak
      avgAccuracy
      totalNumOfBattlesWon
      totalNumOfTKLsWon
      avgLivesCount
      avgTimeTakenForCorrectAns
    }
  }
`;
export const CREATE_REVIEW_MUTATION = gql`
  mutation createReview($reviewMsg: String!, $stars: Int!, $siteId: Int!) {
    createReview(reviewMsg: $reviewMsg, stars: $stars, siteId: $siteId) {
      id
      customerId
      index
      reviewMsg
      status
      stars
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_RECOMMENDED_TOPIC_TITLES = gql`
  query getRecommendedTopicTitles($searchTerm: String, $topicNames: [String]) {
    getRecommendedTopicTitles(searchTerm: $searchTerm, topicNames: $topicNames)
  }
`;

export const ACCEPT_CUST_SUB_PLAN_INVITE = gql`
  mutation acceptCustomerSubscriptionPlanInvite($inviteCode: String!) {
    acceptCustomerSubscriptionPlanInvite(inviteCode: $inviteCode)
  }
`;

export const ACCEPT_PET_SUB_PLAN_INVITE = gql`
  mutation acceptPetSubscriptionPlanInvite($inviteCode: String!) {
    acceptPetSubscriptionPlanInvite(inviteCode: $inviteCode)
  }
`;

export const CREATE_AD_VIEW_MUTATION = gql`
  mutation createAdView(
    $adId: String!
    $viewedAfterQuestion: String
    $triviaNightId: String
    $quizId: String
    $productId: String
    $chatId: String
  ) {
    createAdView(
      adId: $adId

      viewedAfterQuestion: $viewedAfterQuestion
      triviaNightId: $triviaNightId
      quizId: $quizId
      productId: $productId
      chatId: $chatId
    ) {
      id
      adId
      customerId
      guestCustomerId
      triviaNightId
      quizId
      productId
      chatId
      status
      viewedAt
      viewedAfterQuestion
    }
  }
`;
export const SEND_AD_CLICK_MUTATION = gql`
  mutation sendAdClick($adViewId: String!) {
    sendAdClick(adViewId: $adViewId) {
      id
      adId
      customerId
      guestCustomerId
      triviaNightId
      quizId
      productId
      chatId
      status
      viewedAt

      viewedAfterQuestion
      clickedAt
    }
  }
`;
export const TOGGLE_AD_BOOKMARK_MUTATION = gql`
  mutation toggleBookmarkAd(
    $adBookmarkId: String
    $adId: String
    $viewedAfterQuestion: String
    $triviaNightId: String
    $quizId: String
    $productId: String
    $chatId: String
    $questionId: String
    $adViewId: String
  ) {
    toggleBookmarkAd(
      adBookmarkId: $adBookmarkId
      adId: $adId
      viewedAfterQuestion: $viewedAfterQuestion
      triviaNightId: $triviaNightId
      quizId: $quizId
      productId: $productId
      chatId: $chatId
      questionId: $questionId
      adViewId: $adViewId
    ) {
      id
      adId
      customerId
      triviaNightId
      quizId
      productId
      chatId
      status
      createdAt
      lastToggledAt
      viewedAfterQuestion
    }
  }
`;

export const TOGGLE_PAUSE_PERSONAL_QUIZ_MUTATION = gql`
  mutation togglePausePersonalQuiz(
    $personalQuizId: String!
    $secondsLeftAtPause: Int!
  ) {
    togglePausePersonalQuiz(
      personalQuizId: $personalQuizId
      secondsLeftAtPause: $secondsLeftAtPause
    )
  }
`;
export const VERIFY_CUSTOMER = gql`
  mutation verifyCustomer(
    $verificationCode: String
    $email: String
    $phone: String
    $usingPhone: Boolean
    $siteId: Int!
  ) {
    verifyCustomer(
      verificationCode: $verificationCode
      email: $email
      phone: $phone
      usingPhone: $usingPhone
      siteId: $siteId
    ) {
      id
      email
      token
      phone
      phoneCountryCode
      phoneCountryShortForm
    }
  }
`;

export const RESEND_PET_VERIFICATION_CODE = gql`
  mutation resendPetVerificationCode(
    $email: String
    $phone: String
    $usingPhone: Boolean
  ) {
    resendPetVerificationCode(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
    )
  }
`;
export const RESEND_VERIFICATION_CODE = gql`
  mutation resendVerificationCode(
    $email: String
    $phone: String
    $usingPhone: Boolean
    $siteId: Int!
  ) {
    resendVerificationCode(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
      siteId: $siteId
    )
  }
`;

export const BUY_POWER_UP_MUTATION = gql`
  mutation buyPowerUp(
    $powerUpId: Int!
    $useNewCard: Boolean
    $questionId: String
    $triviaNightId: String
  ) {
    buyPowerUp(
      powerUpId: $powerUpId
      useNewCard: $useNewCard
      questionId: $questionId
      triviaNightId: $triviaNightId
    ) {
      id
      gotOnTriviaNightId
      gotAfterQuestionId
      customerId
      powerUpId
      subTotal
      netAmount
      tokensUsed
      stripeFee
      tknightProfit
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;

export const REMOVE_PET_FROM_SUB_PLAN_MUTATION = gql`
  mutation removePetFromSubscriptionPlan($petId: String!) {
    removePetFromSubscriptionPlan(petId: $petId) {
      id
      petId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberPetIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;
export const REMOVE_MEMBER_FROM_SUB_PLAN_MUTATION = gql`
  mutation removeCustomerFromSubscriptionPlan($customerId: String!) {
    removeCustomerFromSubscriptionPlan(customerId: $customerId) {
      id
      customerId
      stripeSubscriptionId
      stripePriceId
      subscriptionType
      status
      paymentIntentId
      paymentIntentClientSecret
      ephemeralKey
      platform
      paymentStartedAt
      paymentConfirmedAt
      memberCustomerIds
      numOfMembers
      maxNumOfMembers
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion(
    $triviaNightId: String
    $topicNames: [String]
    $questionDesc: String!
    $questionMediaFile: Upload
    $answerMediaFile: Upload
    $interestingFact: String
    $option1: String
    $option2: String
    $option0: String
    $option3: String
    $questionType: Int!
  ) {
    createQuestion(
      triviaNightId: $triviaNightId
      topicNames: $topicNames
      questionDesc: $questionDesc
      questionMediaFile: $questionMediaFile
      answerMediaFile: $answerMediaFile
      interestingFact: $interestingFact
      option1: $option1
      option2: $option2
      option0: $option0
      option3: $option3
      questionType: $questionType
    ) {
      id
      triviaNightId
      index
      topicName
      topicId
      topicShortForm
      topicLogoUrl
      creatorProfilePicUrl
      creatorName
      questionMediaUrl
      answerMediaUrl
      createdByCustomerId
      createdByGuestCustomerId
      questionDesc
      option1
      option2
      option3
      option0
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      questionType
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_TOPIC_NAMES_FROM_IDS = gql`
  query getTopicNamesFromTopicIds($topicIds: [String]!) {
    getTopicNamesFromTopicIds(topicIds: $topicIds)
  }
`;
export const FETCH_QUESTIONS = gql`
  query getAllQuestions($status: Int, $seenIds: [String], $pageSize: Int) {
    getAllQuestions(status: $status, seenIds: $seenIds, pageSize: $pageSize) {
      id
      topicName
      topicId
      createdByCustomerId
      createdByGuestCustomerId
      createdByBusinessId
      questionMediaUrl
      answerMediaUrl
      questionDesc
      option1
      option2
      option3
      option0
      hints
      correctAnswer
      questionType
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      status
      createdAt
      lastEditedAt
      interestingFact
    }
  }
`;
export const EDIT_TRIVIA_NIGHT = gql`
  mutation editTriviaNight(
    $triviaNightId: String!
    $fieldsToEdit: [String]!
    $dayNum: Int
    $country: String
    $topicId: String
    $prizeCoins: Int
  ) {
    editTriviaNight(
      triviaNightId: $triviaNightId
      fieldsToEdit: $fieldsToEdit
      dayNum: $dayNum
      country: $country
      topicId: $topicId
      prizeCoins: $prizeCoins
    ) {
      id
      country
      topicId
      status
    }
  }
`;
export const EDIT_QUESTION_MUTATION = gql`
  mutation editQuestion(
    $questionId: String!
    $fieldsToEdit: [String]!
    $index: Int
    $topicName: String
    $questionDesc: String
    $option1: String
    $option2: String
    $option3: String
    $option0: String
    $correctAnswer: Int
    $questionType: Int
    $questionMediaFile: Upload
    $answerMediaFile: Upload
    $interestingFact: String
    $status: Int
  ) {
    editQuestion(
      questionId: $questionId
      fieldsToEdit: $fieldsToEdit
      index: $index
      topicName: $topicName
      questionDesc: $questionDesc
      option1: $option1
      option2: $option2
      option3: $option3
      option0: $option0
      correctAnswer: $correctAnswer
      questionType: $questionType
      questionMediaFile: $questionMediaFile
      answerMediaFile: $answerMediaFile
      interestingFact: $interestingFact
      status: $status
    ) {
      id
      triviaNightId
      index
      topicName
      topicId
      topicShortForm
      topicLogoUrl
      creatorProfilePicUrl
      creatorName
      questionMediaUrl
      answerMediaUrl
      createdByCustomerId
      createdByGuestCustomerId
      questionDesc
      option1
      option2
      option3
      option0
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      questionType
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_CONTENT = gql`
  query getContent(
    $categoryShortForm: String
    $pageSize: Int!
    $searchTerm: String
    $seenIds: [String]
  ) {
    getContent(
      categoryShortForm: $categoryShortForm
      pageSize: $pageSize
      searchTerm: $searchTerm
      seenIds: $seenIds
    ) {
      id
      title
      topicIds
      topicNames
      videoUrl
      typeOfVideo
      creatorProfilePicUrl
      creatorName
      createdByCustomerId
      createdByGuestCustomerId
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      rNumOfDownloads
      tiktokClipUrl
      youtubeClipUrl
      instagramClipUrl
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_REVIEWS = gql`
  query getReviews($siteId: Int) {
    getReviews(siteId: $siteId) {
      id
      customerId
      index
      reviewMsg
      status
      stars
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_CUSTOMER_STRIPE_SUBS = gql`
  query getCustomerStripeSubscriptions($status: String) {
    getCustomerStripeSubscriptions(status: $status) {
      id
      customer
      status
    }
  }
`;
export const FETCH_PENDING_CASHOUTS = gql`
  query getPendingCashouts {
    getPendingCashouts {
      id
      customerId
      amountCashedOut
      paypalPayoutItemId
      status
      requestedAt
      completedAt
    }
  }
`;
export const FETCH_ALL_CUSTOMER_PRODUCTS = gql`
  query getAllCustomerProducts {
    getAllCustomerProducts {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;
export const RESET_CUSTOMER_PFP_MUTATION = gql`
  mutation resetCustomerPFP($customerId: String!) {
    resetCustomerPFP(customerId: $customerId) {
      id
      profilePicUrl
    }
  }
`;

export const RESET_PET_PFP_MUTATION = gql`
  mutation resetPetPFP($petId: String!) {
    resetPetPFP(petId: $petId) {
      id
      profilePicUrl
    }
  }
`;
export const TOGGLE_BLOCK_CUSTOMER_MUTATION = gql`
  mutation toggleBlockCustomer($customerId: String!, $blockedReason: String) {
    toggleBlockCustomer(
      customerId: $customerId
      blockedReason: $blockedReason
    ) {
      isBlocked
    }
  }
`;

export const TOGGLE_BLOCK_BRAND_CONTACT_MUTATION = gql`
  mutation toggleBlockBrandContact(
    $brandContactId: String!
    $blockedReason: String
  ) {
    toggleBlockBrandContact(
      brandContactId: $brandContactId
      blockedReason: $blockedReason
    ) {
      isBlocked
    }
  }
`;
export const UPDATE_CUSTOMER_PRODUCT_STATUS_MUTATION = gql`
  mutation updateCustomerProductDeliveryStatus($customerProductId: String!) {
    updateCustomerProductDeliveryStatus(customerProductId: $customerProductId) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;
export const PAY_MERCHANT_FOR_CUSTOMER_PRODUCT_MUTATION = gql`
  mutation payMerchantForCustomerProduct($customerProductId: String!) {
    payMerchantForCustomerProduct(customerProductId: $customerProductId) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;

export const MAKE_CUSTOMER_FORUM_ADMIN_MUTATION = gql`
  mutation makeCustomerForumAdmin($forumId: String!, $customerId: String!) {
    makeCustomerForumAdmin(forumId: $forumId, customerId: $customerId) {
      id

      adminCustomerIds
      requestedToBeAdminCustomerIds
    }
  }
`;

export const REJECT_FORUM_ADMIN_REQUEST_MUTATION = gql`
  mutation rejectForumAdminRequest($forumAdminRequestId: String!) {
    rejectForumAdminRequest(forumAdminRequestId: $forumAdminRequestId) {
      customerId
      forumId
      status
      acceptedByCustomerId
      createdAt
      lastSentAt
    }
  }
`;

export const REJECT_FORUM_MUTATION = gql`
  mutation rejectForum($forumId: String!, $rejectionReason: String!) {
    rejectForum(forumId: $forumId, rejectionReason: $rejectionReason) {
      id
    }
  }
`;
export const DELETE_CONTENT_MUTATION = gql`
  mutation deleteContent($contentId: String!) {
    deleteContent(contentId: $contentId) {
      id
      topicIds
      topicNames
      videoUrl
      creatorProfilePicUrl
      creatorName
      createdByCustomerId
      createdByGuestCustomerId
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const ACCEPT_FORUM_MUTATION = gql`
  mutation acceptForum($forumId: String!) {
    acceptForum(forumId: $forumId) {
      id
    }
  }
`;
export const ARCHIVE_FORUM_MUTATION = gql`
  mutation archiveForum($forumId: String!) {
    archiveForum(forumId: $forumId) {
      id
      orgId
      nameForUrl
      title
      topicId
      section
      term
      logoUrl
      accentColor
      adminCustomerIds
      folders
      numOfMembers
      status
      type
      createdAt
      lastEditedAt
      archivedAt
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      sentForReviewAt
    }
  }
`;
export const EDIT_FORUM_MUTATION = gql`
  mutation editForum(
    $forumId: String!
    $fieldsToEdit: [String]!
    $title: String
    $topicId: String
    $logoFile: Upload
    $term: String
    $accentColor: Int
    $section: String
    $adminCustomerIds: [String]
    $type: Int
    $folders: [String]
  ) {
    editForum(
      forumId: $forumId
      fieldsToEdit: $fieldsToEdit
      title: $title
      topicId: $topicId
      logoFile: $logoFile
      term: $term
      accentColor: $accentColor
      section: $section
      adminCustomerIds: $adminCustomerIds
      type: $type
      folders: $folders
    ) {
      id
      orgId
      nameForUrl
      title
      topicId
      section
      term
      logoUrl
      accentColor
      adminCustomerIds
      folders
      numOfMembers
      status
      type
      createdAt
      lastEditedAt
      archivedAt
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      sentForReviewAt
    }
  }
`;
export const UNMAKE_CUSTOMER_FORUM_ADMIN_MUTATION = gql`
  mutation unmakeCustomerForumAdmin($forumId: String!, $customerId: String!) {
    unmakeCustomerForumAdmin(forumId: $forumId, customerId: $customerId) {
      adminCustomerIds
    }
  }
`;
export const CANCEL_CUSTOMER_PRODUCT_DELIVERY_MUTATION = gql`
  mutation cancelCustomerProductDelivery(
    $customerProductId: String!
    $cancellationReason: String!
    $refundAmount: Int!
  ) {
    cancelCustomerProductDelivery(
      customerProductId: $customerProductId
      cancellationReason: $cancellationReason
      refundAmount: $refundAmount
    ) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;

export const TOGGLE_CUSTOMER_MOD_MUTATION = gql`
  mutation toggleUserCommentMod($userId: String!) {
    toggleUserCommentMod(userId: $userId)
  }
`;
export const GIVE_EXTRA_LIFE_TO_CUSTOMER_MUTATION = gql`
  mutation giveExtraLifeToCustomer($customerId: String!) {
    giveExtraLifeToCustomer(customerId: $customerId) {
      id

      customerId
    }
  }
`;

export const CONFIRM_CASHOUT_MUTATION = gql`
  mutation confirmCashoutSent($cashoutId: String!) {
    confirmCashoutSent(cashoutId: $cashoutId) {
      id
      customerId
      amountCashedOut
      paypalPayoutItemId
      status
      requestedAt
      completedAt
    }
  }
`;

export const START_AD_CAMPAIGN_MUTATION = gql`
  mutation startAdCampaign($adId: String!) {
    startAdCampaign(adId: $adId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;
export const CANCEL_AD_CAMPAIGN_MUTATION = gql`
  mutation cancelAdCampaign($adId: String!, $cancellationReason: String!) {
    cancelAdCampaign(adId: $adId, cancellationReason: $cancellationReason) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;
export const REJECT_AD_MUTATION = gql`
  mutation rejectAd($adId: String!, $rejectionReason: String!) {
    rejectAd(adId: $adId, rejectionReason: $rejectionReason) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;
export const APPROVE_AD_MUTATION = gql`
  mutation approveAd($adId: String!) {
    approveAd(adId: $adId) {
      id
      productId
      businessId
      businessName
      businessLogoUrl
      brandContactId
      brandContactName
      title
      shortDesc
      campaignName
      objectiveType
      format
      frontPageBannerUrl
      audioAdUrl
      narrowHorAdUrl
      wideHorAdUrl
      regularHorAdUrl
      shortHorAdUrl
      narrowVerAdUrl
      wideVerAdUrl
      regularVerAdUrl
      shortVerAdUrl
      squareAdUrl
      categories
      targetInterests
      dailyBudget
      totalAmountCharged
      link
      targetGender
      targetAgeRanges
      targetPlaces
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      stripeSubscriptionId
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      endedAt
    }
  }
`;

export const CREATE_CITY_MUTATION = gql`
  mutation createCity(
    $name: String!
    $country: String!
    $countryShortForm: String!
    $logoFile: Upload
    $province: String
    $provinceShortForm: String
  ) {
    createCity(
      name: $name
      country: $country
      countryShortForm: $countryShortForm
      logoFile: $logoFile
      province: $province
      provinceShortForm: $provinceShortForm
    ) {
      id
      createdByCustomerId
      name
      numOfPlayers
      logoUrl
      province
      provinceShortForm
      country
      countryShortForm
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const EDIT_CITY_MUTATION = gql`
  mutation editCity(
    $cityId: String!
    $fieldsToEdit: [String]!
    $name: String
    $logoFile: Upload
    $province: String
    $provinceShortForm: String
    $countryShortForm: String
    $country: String
  ) {
    editCity(
      cityId: $cityId
      fieldsToEdit: $fieldsToEdit
      name: $name
      logoFile: $logoFile
      province: $province
      provinceShortForm: $provinceShortForm
      countryShortForm: $countryShortForm
      country: $country
    ) {
      id
      createdByCustomerId
      name
      numOfPlayers
      logoUrl
      province
      provinceShortForm
      country
      countryShortForm
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const EDIT_CONTENT_MUTATION = gql`
  mutation editContent(
    $contentId: String!
    $fieldsToEdit: [String]!
    $title: String
    $videoFile: Upload
    $typeOfVideo: Int
    $tiktokClipUrl: String
    $topicNames: [String]
    $instagramClipUrl: String
    $youtubeClipUrl: String
  ) {
    editContent(
      contentId: $contentId
      fieldsToEdit: $fieldsToEdit
      title: $title
      videoFile: $videoFile
      typeOfVideo: $typeOfVideo
      tiktokClipUrl: $tiktokClipUrl
      topicNames: $topicNames
      instagramClipUrl: $instagramClipUrl
      youtubeClipUrl: $youtubeClipUrl
    ) {
      id
      title
      topicIds
      topicNames
      videoUrl
      typeOfVideo
      creatorProfilePicUrl
      creatorName
      createdByCustomerId
      createdByGuestCustomerId
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      tiktokClipUrl
      youtubeClipUrl
      instagramClipUrl
      status
      createdAt
      lastEditedAt
    }
  }
`;
export const EDIT_ADDRESS_MUTATION = gql`
  mutation editAddress(
    $addressId: String!
    $fieldsToEdit: [String]
    $streetAddress: String
    $city: String
    $province: String
    $postalCode: String
    $country: String
    $line2: String
    $specialDeliveryInstructions: String
    $isPrimary: Boolean
  ) {
    editAddress(
      addressId: $addressId
      fieldsToEdit: $fieldsToEdit
      streetAddress: $streetAddress
      city: $city
      province: $province
      postalCode: $postalCode
      country: $country
      line2: $line2
      specialDeliveryInstructions: $specialDeliveryInstructions
      isPrimary: $isPrimary
    ) {
      id
      userId
      streetAddress
      city
      province
      postalCode
      country
      line2
      specialDeliveryInstructions
      createdAt
      lastEditedAt
      isPrimary
    }
  }
`;
export const DELETE_ADDRESS = gql`
  mutation deleteAddress($addressId: String!) {
    deleteAddress(addressId: $addressId) {
      userId
      streetAddress
      city
      province
      postalCode
      country
      line2
      specialDeliveryInstructions
      createdAt
      lastEditedAt
      isPrimary
    }
  }
`;
export const FETCH_ADMIN = gql`
  query getAdmin {
    getAdmin {
      id
      email
    }
  }
`;
export const UPDATE_QUIZ_STATUS_MUTATION = gql`
  mutation updateQuizStatus($quizId: String, $triviaNightId: String) {
    updateQuizStatus(quizId: $quizId, triviaNightId: $triviaNightId)
  }
`;
export const FETCH_TRIVIA_NIGHTS = gql`
  query getTriviaNights {
    getTriviaNights {
      id
      dayNum
      gameTimingType
      totalNumOfPlayers
      numOfWinners
      numOfEligibleForPrizeWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      prizeCoins
      prizePerPlayer
      isForRealMoney
      nextQStartsAt
      nextQTopic
      nextQTopicLogoUrl
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      is1hReminderSent
      is5minReminderSent
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      country
      topicId
      topicIds
      topicNames
      numOfShares
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      nextGameAt
      endDate
    }
  }
`;
export const FETCH_TOPICS = gql`
  query getTopics {
    getTopics {
      id
      name
      shortForm
      accentColor
      numOfPlayers
      numOfQuestions
      numOfQuizzesMade
      averageAccuracy
      logoUrl
      description
      lastNewQuestionAddedAt
      status
      relatedTopicIds
      tags
      minimumBet

      sentForReviewAt
      createdAt
      lastEditedAt
      shouldIncludeInTN
      shouldIncludeInIntro
    }
  }
`;
export const FETCH_PENDING_TN_SHARES = gql`
  query getTNSharesPendingApproval {
    getTNSharesPendingApproval {
      id
      customerId
      triviaNightId
      type

      createdAt
      status
      rejectedAt
      approvedAt
    }
  }
`;
export const FETCH_PENDING_TN_BAIL_OUTS = gql`
  query getPendingBailOuts {
    getPendingBailOuts {
      id
      customerId
      triviaNightId
      afterQId
      afterQIndex
      bailOutPrize
      bailedOutAt
    }
  }
`;
export const FETCH_PENDING_TN_WINS = gql`
  query getPendingTriviaWins {
    getPendingTriviaWins {
      id
      customerId
      triviaNightId
      numOfLivesOriginally
      numOfExtraLivesUsed
      status
      createdAt
      prizeAddedToAccountAt
    }
  }
`;

export const EDIT_BREED_MUTATION = gql`
  mutation editBreed(
    $breedId: String!
    $fieldsToEdit: [String]!
    $name: String
  ) {
    editBreed(breedId: $breedId, fieldsToEdit: $fieldsToEdit, name: $name) {
      id
      createdByPetId
      name
      petTypeId
      numOfPets
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const CREATE_BREED_MUTATION = gql`
  mutation createBreed($name: String!, $petTypeId: String!) {
    createBreed(name: $name, petTypeId: $petTypeId) {
      id
      createdByPetId
      name
      petTypeId
      numOfPets
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_PETS = gql`
  query getPets {
    getPets {
      id
      email
      unverifiedEmail
      activeFCMTokens
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      token
      name
      ownerCustomerIds
      gender
      birthday
      username
      profilePicUrl
      accentColor
      city
      country
      cityId
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfActivitiesCompleted
      numOfPetPosts
      curPostStreak
      maxPostStreak
      recentPetActivityIds
      recentlyViewedAdIds
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToContacts
      isSuggestingAccountToLinkSharers
      shouldSyncContacts
      lastReviewedAt
      areSoundEffectsMuted
      curSubscriptionPrice
      balance
      totalPointsUsed
      totalEarnings
      coinsEarnedForPosts
      shouldReceivePostReminders
      remindersToReceive
      referralCode
      isCommentMod
      curRegistrationStep
      activeChatIds
      archivedChatIds
      deletedChatIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      isActive
      deleteReason
      shouldDeleteAt
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_BRAND_CONTACTS = gql`
  query getBrandContacts {
    getBrandContacts {
      id
      token
      username
      name
      email
      accentColor
      balance
      birthday
      gender
      phone
      country
      profilePicUrl
      followingBusinessIds
      stripeCustomerId
      revenueCatAppUserId
      passwordResetLastRequestedAt
      passwordResetAt
      primaryStripePaymentMethodId
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      lastActiveAt
      createdAt
    }
  }
`;

export const RESET_BRAND_CONTACT_PFP_MUTATION = gql`
  mutation resetBrandContactPFP($brandContactId: String!) {
    resetBrandContactPFP(brandContactId: $brandContactId) {
      id
      profilePicUrl
    }
  }
`;
export const FETCH_CUSTOMERS = gql`
  query getCustomers {
    getCustomers {
      id
      token
      name
      email
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phone
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle

      areSoundEffectsMuted
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      orgIds
      forumIds
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      cityId
      country
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId

      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      followingIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      createdAt
    }
  }
`;
export const EDIT_BUSINESS = gql`
  mutation editBusiness(
    $businessId: String!
    $fieldsToEdit: [String]!
    $logoFile: Upload
    $name: String
    $shortDesc: String
    $productsDesc: String
    $accentColor: Int
    $email: String
    $website: String
    $phone: String
    $typeOfBusiness: Int
    $businessStructure: String
    $streetAddress: String
    $categories: [String]
    $province: String
    $city: String
    $country: String
    $postalCode: String
    $line2: String
    $instaHandle: String
    $twitterHandle: String
    $tiktokHandle: String
    $youtubeHandle: String
    $redditHandle: String
  ) {
    editBusiness(
      businessId: $businessId
      fieldsToEdit: $fieldsToEdit
      logoFile: $logoFile
      name: $name
      shortDesc: $shortDesc
      productsDesc: $productsDesc
      accentColor: $accentColor
      email: $email
      website: $website
      phone: $phone
      typeOfBusiness: $typeOfBusiness
      businessStructure: $businessStructure

      streetAddress: $streetAddress
      categories: $categories
      province: $province
      city: $city
      country: $country
      postalCode: $postalCode
      line2: $line2
      instaHandle: $instaHandle
      twitterHandle: $twitterHandle
      tiktokHandle: $tiktokHandle
      youtubeHandle: $youtubeHandle
      redditHandle: $redditHandle
    ) {
      id
      accountOwnerBrandContactId
      adminBrandContactIds
      stripeConnectId
      logoUrl
      name
      shortDesc
      productsDesc
      accentColor
      website
      email
      phone
      typeOfBusiness
      businessStructure
      categories
      sentShoutoutRequest
      streetAddress
      city
      province
      postalCode
      country
      line2
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      youtubeHandle
      lastEditedAt
      createdAt
    }
  }
`;
export const FETCH_SELLER = gql`
  query getBrandContact {
    getBrandContact {
      id
      token
      username
      name
      email
      accentColor
      profilePicUrl
      stripeCustomerId
      revenueCatAppUserId
      phone
      passwordResetLastRequestedAt
      passwordResetAt
      primaryStripePaymentMethodId

      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      lastActiveAt
      followingBusinessIds
      createdAt
      country
      birthday
      gender
    }
  }
`;
export const CREATE_PRODUCT = gql`
  mutation createProduct($businessId: String) {
    createProduct(businessId: $businessId) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      adType
      adVideoUrl
      adLastViewedAt
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      paymentIntentId
      paymentIntentClientSecret
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const CREATE_TRIVIA_NIGHT = gql`
  mutation createTriviaNight {
    createTriviaNight {
      id
      dayNum
      gameTimingType
      totalNumOfPlayers
      numOfWinners
      numOfEligibleForPrizeWinners
      numOfNewPlayers
      hostPFPUrl
      hostName
      hostUsername
      hostCustomerId
      prizeCoins
      prizePerPlayer
      nextQStartsAt
      nextQTopic
      nextQTopicLogoUrl
      nextQLastsXSeconds
      lastQStartedAt
      curQEndsAt
      curAnsEndsAt
      is1hReminderSent
      is5minReminderSent
      numOfQs
      lastCompletedQ
      lastCompletedQIndex
      curQIndex
      isBailOutAllowed
      curBailOutPrize
      bailOutAllowedAt
      bailOutUnAllowedAt
      bailOutAllowedAfterQId
      bailOutAllowedAfterQIndex
      bailOutUnAllowedAfterQId
      bailOutUnAllowedAfterQIndex
      status
      country
      topicId
      topicIds
      topicNames
      numOfShares
      totalExtraLivesUsed
      newExtraLifesBought
      newExtraLifeRevenue
      adRevenue
      isPaused
      numOfPauseToggles
      lastPauseToggleAt
      secondsLeftAtPause
      createdAt
      lastEditedAt
      releaseDate
      nextGameAt
    }
  }
`;
export const FETCH_TKNIGHT_MONTHLY_JOB_REVENUE_SUMMARY = gql`
  query getTKnightMonthlyJobRevenueSummary {
    getTKnightMonthlyJobRevenueSummary {
      _id
      totalAmountTKnightEarned
      numOfSuccessfulRevisions
      numOfCompletedJobs
    }
  }
`;
export const FETCH_QUESTIONS_BY_TN_FOR_ADMIN = gql`
  query getQuestionsByTriviaNightForAdmin($triviaNightId: String!) {
    getQuestionsByTriviaNightForAdmin(triviaNightId: $triviaNightId) {
      id
      topicName
      topicId
      topicLogoUrl
      topicShortForm
      createdByCustomerId
      createdByGuestCustomerId
      createdByBusinessId
      creatorProfilePicUrl
      creatorName
      questionMediaUrl
      answerMediaUrl
      questionDesc
      option1
      option2
      option3
      option0
      hints
      correctAnswer
      questionType
      rAccuracy
      rAvrTimeTaken
      rAvrTimeTakenForCorrectAns
      rLeastTimeTakenForCorrectAns
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfAttempts
      rNumOfCorrectAttempts
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      status
      createdAt
      lastEditedAt
      interestingFact
      triviaNightId
      index
    }
  }
`;

export const ADD_PRODUCT_IMAGE_TO_PRODUCT_MUTATION = gql`
  mutation addProductImageToProduct(
    $productImageId: String!
    $productId: String!
  ) {
    addProductImageToProduct(
      productImageId: $productImageId
      productId: $productId
    ) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
    }
  }
`;
export const LOGIN_ADMIN = gql`
  mutation loginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      id
      email
      token
    }
  }
`;

export const CREATE_PRODUCT_IMAGE_MUTATION = gql`
  mutation createProductImage($imageFile: Upload) {
    createProductImage(imageFile: $imageFile) {
      id
      userId
      imageUrl
      createdAt
    }
  }
`;

export const DELETE_PRODUCT_IMAGE_MUTATION = gql`
  mutation deleteProductImage($productImageId: String!) {
    deleteProductImage(productImageId: $productImageId) {
      id
      userId
      imageUrl
      createdAt
    }
  }
`;
export const SEND_PRODUCT_VIEW_MUTATION = gql`
  mutation sendProductView($productId: String!) {
    sendProductView(productId: $productId) {
      customerId
      productId
      viewedAt
    }
  }
`;
export const PROCESS_POWER_UP_PAYMENT_RESULT = gql`
  mutation processPowerUpPaymentResult($powerUpPurchaseId: String!) {
    processPowerUpPaymentResult(powerUpPurchaseId: $powerUpPurchaseId) {
      id
      gotOnTriviaNightId
      gotAfterQuestionId
      customerId
      powerUpId
      subTotal
      netAmount
      tokensUsed
      stripeFee
      tknightProfit
      paymentIntentId
      paymentIntentClientSecret
      status
      paymentStartedAt
      paymentConfirmedAt
    }
  }
`;
export const SEND_POST_GIFT_MUTATION = gql`
  mutation sendPostGift($forumPostId: String!, $type: Int!) {
    sendPostGift(forumPostId: $forumPostId, type: $type)
  }
`;
export const FETCH_PRODUCT_IMAGES_BY_USER = gql`
  query getProductImagesByUser($userId: String) {
    getProductImagesByUser(userId: $userId) {
      id
      userId
      imageUrl
      createdAt
    }
  }
`;
export const FETCH_ALL_PRODUCT_IMAGES = gql`
  query getAllProductImages {
    getAllProductImages {
      id
      userId
      imageUrl
      createdAt
    }
  }
`;
export const FETCH_ORG_BY_ID = gql`
  query getOrgById($orgId: String!) {
    getOrgById(orgId: $orgId) {
      id
      name
      shortForm
      logoUrl
      type
    }
  }
`;

export const FETCH_RANKED_GROUPS = gql`
  query getRankedOrgs(
    $timeRange: String!
    $sortBy: String!
    $topicId: String
    $triviaNightId: String
    $quizId: String
  ) {
    getRankedOrgs(
      timeRange: $timeRange
      sortBy: $sortBy
      topicId: $topicId
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      _id
      totalEarnings
      maxFocusStreak
      avgAccuracy
      totalNumOfBattlesWon
      totalNumOfTKLsWon
      avgLivesCount
      name
      shortForm
      logoUrl
      country
      avgTimeTakenForCorrectAns
    }
  }
`;

export const FETCH_FRIENDS_BY_SUBSCRIPTION = gql`
  query getFriendsBySubscription {
    getFriendsBySubscription {
      id
      name
      username
      twitterHandle
      profilePicUrl
      numOfTKLsPlayed
      numOfBattlesPlayed
      country
      numOfTKLsWon
      numOfBattlesWon
      balance
      curFocusStreak
      curBattleWinStreak
      maxBattleWinStreak
      curTKLWinStreak
      maxTKLWinStreak
      totalEarnings
      lastActiveAt
      followingIds
      subscriptionType
      isPaidSubscriber
      totalQsAnsweredCorrect
      totalQsAnswered
      accuracy
      availableExtraLivesCount
    }
  }
`;

export const FETCH_PET_FRIENDS = gql`
  query getPetFriends($selectedFriendIds: [String]) {
    getPetFriends(selectedFriendIds: $selectedFriendIds) {
      id
      token
      name
      gender
      username
      profilePicUrl
      accentColor
      country
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfPetPosts
      curPostStreak
      maxPostStreak
      balance
      totalEarnings
      shouldReceivePostReminders
      remindersToReceive
      isActive
      subscriptionType
      isPaidSubscriber
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_CUSTOMER_FRIENDS = gql`
  query getCustomerFriends($selectedFriendIds: [String]) {
    getCustomerFriends(selectedFriendIds: $selectedFriendIds) {
      id
      name
      username
      twitterHandle
      profilePicUrl
      numOfTKLsPlayed
      numOfBattlesPlayed
      country
      numOfTKLsWon
      numOfBattlesWon
      balance
      curFocusStreak
      curBattleWinStreak
      maxBattleWinStreak
      curTKLWinStreak
      maxTKLWinStreak
      totalEarnings
      lastActiveAt
      followingIds
      subscriptionType
      isPaidSubscriber
      totalQsAnsweredCorrect
      totalQsAnswered
      accuracy
      availableExtraLivesCount
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation editCustomer(
    $fieldsToEdit: [String]!
    $customerId: String
    $username: String
    $accentColor: Int
    $email: String
    $name: String
    $oldPassword: String
    $birthday: DateTime
    $password: String
    $gender: Int
    $phone: String
    $cityId: String
    $remindersToReceive: [Int]
    $instaHandle: String
    $twitterHandle: String
    $tiktokHandle: String
    $redditHandle: String
    $profilePicFile: Upload
  ) {
    editCustomer(
      fieldsToEdit: $fieldsToEdit
      customerId: $customerId
      username: $username
      accentColor: $accentColor
      email: $email
      name: $name
      oldPassword: $oldPassword
      birthday: $birthday
      password: $password
      gender: $gender
      phone: $phone
      cityId: $cityId
      remindersToReceive: $remindersToReceive
      instaHandle: $instaHandle
      twitterHandle: $twitterHandle
      tiktokHandle: $tiktokHandle
      redditHandle: $redditHandle
      profilePicFile: $profilePicFile
    ) {
      id
      token
      name
      email
      stripeCustomerId
      revenueCatAppUserId
      username
      profilePicUrl
      phone
      phoneCountryCode
      accentColor
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      areSoundEffectsMuted
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      orgIds
      forumIds
      orgSectionIds
      isCommentMod
      mutedForEventIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      remindersToReceive
      referralCode
      ogGuestCustomerId
      passwordResetLastRequestedAt
      passwordResetAt
      city
      cityId
      country
      gender
      birthday
      curSubscriptionPrice
      curRegistrationStep
      primaryStripePaymentMethodId
      isActive
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToLinkSharers
      isSuggestingAccountToContacts
      shouldSyncContacts
      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      activeFCMTokens
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      numOfQuestionAppearances
      accuracy
      availableExtraLivesCount
      lastGiftedExtraLifeDayNum
      numOfBailOuts
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      coinsEarnedForQuestions
      coinsEarnedForPosts
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastReviewedAt
      followingIds
      followingBusinessIds
      numOfPeopleBeingFollowed
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      createdAt
    }
  }
`;
export const LOGIN_BRAND_CONTACT = gql`
  mutation loginBrandContact($email: String!, $password: String!) {
    loginBrandContact(email: $email, password: $password) {
      id
      email
      token
    }
  }
`;
export const LOGIN_CUSTOMER_MUTATION = gql`
  mutation loginCustomer(
    $email: String
    $phone: String
    $usingPhone: Boolean
    $password: String!
  ) {
    loginCustomer(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
      password: $password
    ) {
      id
      phone
      email
      token
      phoneCountryShortForm
      phoneCountryCode
    }
  }
`;

export const FETCH_RECOMMENDED_PET_ACTIVITIES = gql`
  query getRecommendedPetActivitiesBySearch(
    $pageSize: Int!
    $seenIds: [String]!
    $categoryShortForm: String
    $searchTerm: String
  ) {
    getRecommendedPetActivitiesBySearch(
      pageSize: $pageSize
      seenIds: $seenIds
      categoryShortForm: $categoryShortForm
      searchTerm: $searchTerm
    ) {
      id
      name
      shortForm
      accentColor
      numOfPets
      numOfPosts
      numOfShares
      logoUrl
      shouldIncludeInIntro
      description
      lastPostAddedAt
      tags
      postPrizeCoins
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_POST_VIEW_BY_CUSTOMER = gql`
  query getQuestionViewByCustomer($questionId: String) {
    getQuestionViewByCustomer(questionId: $questionId) {
      id
      customerId
      guestCustomerId
      questionId
      topicId
      subscriptionType
      status
      viewedAt
      qEndsAt
      wasTopicFocusStreakMilestone
      wasMainFocusStreakMilestone
      extraLifeAvailabilityEndsAt
      extraLifeAvailabilityEnded
      lastXSeconds
      answerResult
      typeOfFocus
    }
  }
`;

export const LOG_POST_SHARE_MUTATION = gql`
  mutation logPetPostShare(
    $petPostId: String
    $petActivityId: String
    $type: Int
  ) {
    logPetPostShare(
      petPostId: $petPostId
      petActivityId: $petActivityId
      type: $type
    ) {
      id
      petActivityName
      petUsername
      petActivityId
      petActivityShortForm
      petActivityLogoUrl
      petProfilePicUrl
      petName
      mediaUrls
      petId
      customerId
      taggedPetIds
      description
      rAvrTimeWatched
      rNetVote
      rNumOfFollows
      rNumOfVotes
      rUpvotePercentage
      rNumOfViews
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      typeOfPost
      interestingFact
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_GUEST_CUSTOMER = gql`
  query getGuestCustomer {
    getGuestCustomer {
      id

      token
      name
      username
      profilePicUrl
      recentTopicIds
      recentCorrectlyAnsweredQIds
      recentlyViewedAdIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      country
      areSoundEffectsMuted
      joinedFor
      productJoinedFor
      triviaNightJoinedFor
      quizJoinedFor
      topicJoinedFor
      totalQsAnsweredCorrect
      totalQsAnswered
      totalExtraLivesUsed
      numOfBattlesPlayed
      numOfBattlesWon
      curBattleWinStreak
      maxBattleWinStreak
      numOfTKLsPlayed
      numOfTKLsWon
      curTKLWinStreak
      maxTKLWinStreak
      curFocusStreak
      maxFocusStreak
      curShareStreak
      maxShareStreak
      accuracy
      availableExtraLivesCount
      balance
      balanceEarnedAsSubscriber
      totalPointsCashedOut
      totalPointsUsed
      totalEarnings
      convertedToCustomerId
      highestPuzzleLevel
      lowestPuzzleLevel
      lastIncorrectFocusQuestionViewId
      lastActiveAt
      lastReviewedAt
      createdAt
    }
  }
`;

export const EDIT_PET = gql`
  mutation editPet(
    $fieldsToEdit: [String]!
    $petId: String
    $username: String
    $accentColor: Int
    $name: String
    $email: String
    $oldPassword: String
    $password: String
    $birthday: DateTime
    $gender: Int
    $phone: String
    $cityId: String
    $remindersToReceive: [Int]
    $profilePicFile: Upload
    $breedIds: [String]
    $petTypeId: String
  ) {
    editPet(
      fieldsToEdit: $fieldsToEdit
      petId: $petId
      username: $username
      accentColor: $accentColor
      name: $name
      email: $email
      oldPassword: $oldPassword
      password: $password
      birthday: $birthday
      gender: $gender
      phone: $phone
      cityId: $cityId
      remindersToReceive: $remindersToReceive
      profilePicFile: $profilePicFile
      breedIds: $breedIds
      petTypeId: $petTypeId
    ) {
      id
      email
      unverifiedEmail
      activeFCMTokens
      phone
      unverifiedPhone
      phoneVerificationCode
      phoneVerificationLastRequestedAt
      phoneVerifiedAt
      token
      name
      ownerCustomerIds
      gender
      birthday
      username
      profilePicUrl
      accentColor
      city
      country
      cityId
      petType
      petTypeId
      breed
      breedIds
      personalityType
      numOfActivitiesCompleted
      numOfPetPosts
      curPostStreak
      maxPostStreak
      recentPetActivityIds
      recentlyViewedAdIds
      isPrivate
      isSharingOnlineStatus
      isSuggestingAccountToContacts
      isSuggestingAccountToLinkSharers
      shouldSyncContacts
      lastReviewedAt
      areSoundEffectsMuted
      curSubscriptionPrice
      balance
      totalPointsUsed
      totalEarnings
      coinsEarnedForPosts
      shouldReceivePostReminders
      remindersToReceive
      referralCode
      isCommentMod
      curRegistrationStep
      blockedPetIds
      activeChatIds
      archivedChatIds
      deletedChatIds
      isBlocked
      blockedAt
      lastUnblockedAt
      blockedReason
      isActive
      deleteReason
      shouldDeleteAt
      subscriptionType
      isPaidSubscriber
      subscriptionPausedAt
      isRetrySubPaymentEmailSent
      retrySubPaymentEmailSentAt
      lastActiveAt
      createdAt
    }
  }
`;

export const FETCH_NUM_UNCLICKED_NOTIFICATIONS = gql`
  query getNumUnclickedNotifications {
    getNumUnclickedNotifications
  }
`;

export const TOGGLE_PAUSE_QUIZ_MUTATION = gql`
  mutation togglePauseQuiz(
    $secondsLeftAtPause: Int!
    $quizId: String
    $triviaNightId: String
  ) {
    togglePauseQuiz(
      secondsLeftAtPause: $secondsLeftAtPause
      quizId: $quizId
      triviaNightId: $triviaNightId
    )
  }
`;
export const FETCH_FOCUS_STREAK_MILESTONE_BY_CUSTOMER = gql`
  query getFocusStreakMilestone($questionViewId: String!, $topicId: String) {
    getFocusStreakMilestone(
      questionViewId: $questionViewId
      topicId: $topicId
    ) {
      id
      customerId
      guestCustomerId
      focusStreakId
      topicId
      achievedOnQuestionViewId
      country
      city
      numOfAchieversToday
      numOfSameCityAchieversToday
      numOfSameCountryAchieversToday
      numOfLivesUsed
      numOfCorrectAns
      bonusThisTime
      streak
      topicNames
      questionViewIds
      topicIds
      createdAt
    }
  }
`;

export const SEND_PASSWORD_RESET_CODE_SELLER = gql`
  mutation sendBrandContactResetPasswordCode($email: String!, $siteId: Int!) {
    sendBrandContactResetPasswordCode(email: $email, siteId: $siteId)
  }
`;

export const RESET_SELLER_PASSWORD_MUTATION = gql`
  mutation resetBrandContactPassword(
    $email: String!
    $passwordResetCode: String!
    $newPassword: String!
    $siteId: Int!
  ) {
    resetBrandContactPassword(
      email: $email
      passwordResetCode: $passwordResetCode
      newPassword: $newPassword
      siteId: $siteId
    ) {
      token
      email
      id
      name
    }
  }
`;
export const RESET_CUSTOMER_PASSWORD_MUTATION = gql`
  mutation resetCustomerPassword(
    $email: String
    $phone: String
    $usingPhone: Boolean
    $passwordResetCode: String!
    $newPassword: String!
  ) {
    resetCustomerPassword(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
      passwordResetCode: $passwordResetCode
      newPassword: $newPassword
    ) {
      id
      username
      token
      email
    }
  }
`;

export const SEND_PASSWORD_RESET_CODE_CUSTOMER = gql`
  mutation sendCustomerResetPasswordCode(
    $email: String
    $phone: String
    $usingPhone: Boolean
  ) {
    sendCustomerResetPasswordCode(
      email: $email
      phone: $phone
      usingPhone: $usingPhone
    )
  }
`;

export const FETCH_RECOMMENDED_FORUM_POSTS = gql`
  query getRecommendedForumPostsBySearch(
    $pageSize: Int!
    $questionId: String
    $forumId: String
    $chatId: String
    $seenIds: [String]
    $searchTerm: String
    $forumPostRepliedToId: String
    $puzzleId: String
    $headForumPostId: String
    $headReplyId: String
    $sortBy: String
    $isDecreasing: Boolean
    $type: Int
    $folder: String
  ) {
    getRecommendedForumPostsBySearch(
      pageSize: $pageSize
      questionId: $questionId
      forumId: $forumId
      chatId: $chatId
      seenIds: $seenIds
      searchTerm: $searchTerm
      forumPostRepliedToId: $forumPostRepliedToId
      puzzleId: $puzzleId
      headForumPostId: $headForumPostId
      headReplyId: $headReplyId
      sortBy: $sortBy
      isDecreasing: $isDecreasing
      type: $type
      folder: $folder
    ) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;

export const CREATE_FORUM_POST_MUTATION = gql`
  mutation createForumPost(
    $visibility: Int!
    $folders: [String]!
    $details: String!
    $headForumPostId: String
    $puzzleId: String
    $questionId: String
    $forumId: String
    $chatId: String
    $forumPostRepliedToId: String
    $type: Int!
    $title: String
    $mediaFiles: [Upload]
  ) {
    createForumPost(
      visibility: $visibility
      folders: $folders
      details: $details
      headForumPostId: $headForumPostId
      puzzleId: $puzzleId
      questionId: $questionId
      forumId: $forumId
      chatId: $chatId
      forumPostRepliedToId: $forumPostRepliedToId
      type: $type
      title: $title
      mediaFiles: $mediaFiles
    ) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;

export const FETCH_FORUM_POST_BY_NUMBER = gql`
  query getForumPostByNumber(
    $forumId: String
    $postNumber: String!
    $chatId: String
  ) {
    getForumPostByNumber(
      forumId: $forumId
      postNumber: $postNumber
      chatId: $chatId
    ) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;

export const LOG_F_POST_SHARE_MUTATION = gql`
  mutation logForumPostShare($forumPostId: String) {
    logForumPostShare(forumPostId: $forumPostId) {
      id
      customerId
      forumId
      forumPostId
      type
      status
      createdAt
    }
  }
`;
export const FETCH_FORUM_DISCUSSION_POST_BY_F_POST = gql`
  query getForumPostByType($forumPostId: String!, $type: Int!) {
    getForumPostByType(forumPostId: $forumPostId, type: $type) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;
export const FETCH_SELLER_MONTHLY_EARNINGS = gql`
  query getSellerMonthlyEarnings(
    $businessId: String!
    $brandContactId: String
  ) {
    getSellerMonthlyEarnings(
      businessId: $businessId
      brandContactId: $brandContactId
    ) {
      _id
      totalAmountMerchantEarned
      numOfCompletedDeliveries
      numOfProductsSold
    }
  }
`;
export const EDIT_SELLER = gql`
  mutation editBrandContact(
    $brandContactId: String
    $fieldsToEdit: [String]
    $accentColor: Int
    $name: String
    $email: String
    $username: String
    $phone: String
    $oldPassword: String
    $password: String
    $gender: Int
    $birthday: DateTime
    $country: String
    $profilePicFile: Upload
  ) {
    editBrandContact(
      brandContactId: $brandContactId
      fieldsToEdit: $fieldsToEdit
      accentColor: $accentColor
      name: $name
      email: $email
      username: $username
      phone: $phone
      oldPassword: $oldPassword
      password: $password
      gender: $gender
      birthday: $birthday
      country: $country
      profilePicFile: $profilePicFile
    ) {
      id
      token
      username
      name
      email
      accentColor
      birthday
      gender
      phone
      country
      profilePicUrl
      stripeCustomerId
      revenueCatAppUserId
      passwordResetLastRequestedAt
      passwordResetAt
      primaryStripePaymentMethodId

      emailVerificationCode
      emailVerificationLastRequestedAt
      emailVerifiedAt
      lastActiveAt
      createdAt
    }
  }
`;
export const FETCH_BUSINESSES_BY_BRAND_CONTACT = gql`
  query getBusinessesByBrandContact($brandContactId: String) {
    getBusinessesByBrandContact(brandContactId: $brandContactId) {
      id
      accountOwnerBrandContactId
      adminBrandContactIds
      stripeConnectId
      accentColor
      logoUrl
      name
      shortDesc
      productsDesc
      website
      email
      phone
      typeOfBusiness
      businessStructure
      categories
      sentShoutoutRequest
      streetAddress
      city
      province
      postalCode
      country
      line2
      instaHandle
      twitterHandle
      tiktokHandle
      redditHandle
      lastEditedAt
      createdAt
    }
  }
`;
export const FETCH_LIVE_PRODUCTS = gql`
  query getProducts($status: Int) {
    getProducts(status: $status) {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
      adType
      adVideoUrl
    }
  }
`;
export const CHANGE_CUSTOMER_PRODUCT_QUANTITY_MUTATION = gql`
  mutation changeCustomerProductQuantity(
    $customerProductId: String!
    $quantity: Int!
  ) {
    changeCustomerProductQuantity(
      customerProductId: $customerProductId
      quantity: $quantity
    ) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;
export const ADD_PRODUCT_TO_CART_MUTATION = gql`
  mutation addProductToCart($productId: String!) {
    addProductToCart(productId: $productId) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;
export const FETCH_CART_PRICE_SUMMARY = gql`
  query getCartPriceSummary($deliverySpeed: Int!) {
    getCartPriceSummary(deliverySpeed: $deliverySpeed) {
      subTotal
      tax
      deliveryFee
      tokensUsed
      netAmount
      amountToPayMerchants
      stripeFee
      tknightProfit
    }
  }
`;
export const CHECKOUT_CART_MUTATION = gql`
  mutation checkoutCart($deliverySpeed: Int!, $useNewCard: Boolean!) {
    checkoutCart(deliverySpeed: $deliverySpeed, useNewCard: $useNewCard) {
      id
      customerId
      totalItems
      subTotal
      tax
      deliveryFee
      tokensUsed
      netAmount
      refundedAmount
      amountToPayMerchants
      stripeFee
      tknightProfit
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      deliverySpeed
      status
      paymentIntentId
      paymentIntentClientSecret
      createdAt
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
    }
  }
`;

export const FETCH_CUSTOMER_PRODUCTS = gql`
  query getCustomerProducts($status: Int) {
    getCustomerProducts(status: $status) {
      id
      productId
      customerId
      cartId
      quantity
      dropOffAddressId
      status
      refundAmount
      cancellationReason
      addedToCartAt
      removedFromCartAt
      orderedAt
      shippedAt
      outForDeliveryAt
      arrivedAt
      paidMerchantAt
      cancelledAt
      refundedAt
    }
  }
`;

export const PROCESS_CART_PAYMENT_RESULT = gql`
  mutation processCartPaymentResult($cartId: String!) {
    processCartPaymentResult(cartId: $cartId) {
      id
      customerId
      totalItems
      subTotal
      tax
      deliveryFee
      tokensUsed
      netAmount
      refundedAmount
      amountToPayMerchants
      stripeFee
      tknightProfit
      dropOffAddressId
      dropOffStreetAddress
      dropOffCity
      dropOffProvince
      dropOffPostalCode
      dropOffCountry
      dropOffLine2
      dropOffSpecialDeliveryInstructions
      deliverySpeed
      status
      paymentIntentId
      paymentIntentClientSecret
      createdAt
      paymentStartedAt
      paymentConfirmedAt
      lastEditedAt
    }
  }
`;
export const FETCH_PRIMARY_ADDRESS = gql`
  query getUserPrimaryAddress {
    getUserPrimaryAddress {
      id
      userId
      streetAddress
      city
      province
      postalCode
      country
      line2
      specialDeliveryInstructions
      createdAt
      lastEditedAt
      isPrimary
    }
  }
`;

export const FETCH_FORUM_BY_ID = gql`
  query getForumById($forumId: String!) {
    getForumById(forumId: $forumId) {
      id
      orgId
      orgName
      orgShortForm
      nameForUrl
      title
      topicId
      section
      term
      logoUrl
      requestedToBeAdminCustomerIds
      accentColor
      adminCustomerIds
      folders
      numOfShares
      numOfMembers
      status
      type
      createdAt
      lastEditedAt
      archivedAt
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      sentForReviewAt
    }
  }
`;
export const FETCH_RECOMMENDED_ORGS = gql`
  query getRecommendedOrgsBySearch($searchTerm: String) {
    getRecommendedOrgsBySearch(searchTerm: $searchTerm) {
      id
      requestedByCustomerId
      name
      shortForm
      websiteLink
      nameForUrl
      emailSuffixes
      description
      city
      province
      country
      logoUrl
      numOfMembers
      status
      type
      rejectionReason
      sentForReviewAt
      lastRejectedAt
      createdAt
      lastEditedAt
    }
  }
`;
export const HIDE_FORUM_POST_MUTATION = gql`
  mutation hideForumPost($forumPostId: String!) {
    hideForumPost(forumPostId: $forumPostId) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;

export const TOGGLE_BOOKMARK_FORUM_POST_MUTATION = gql`
  mutation toggleBookmarkForumPost($forumPostId: String!) {
    toggleBookmarkForumPost(forumPostId: $forumPostId) {
      id
      forumPostId
      customerId
      forumId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_FORUM_POST_RATING_SUMMARY = gql`
  query getForumPostRatingSummary($forumPostId: String!) {
    getForumPostRatingSummary(forumPostId: $forumPostId) {
      numOfVotes
      netVote
    }
  }
`;

export const RATE_FORUM_POST_MUTATION = gql`
  mutation rateForumPost($forumPostId: String!, $vote: Int!) {
    rateForumPost(forumPostId: $forumPostId, vote: $vote) {
      id
      customerId
      forumPostId
      vote
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_RECOMMENDED_ORG_SECTIONS = gql`
  query getRecommendedOrgSectionsBySearch($searchTerm: String) {
    getRecommendedOrgSectionsBySearch(searchTerm: $searchTerm) {
      id
      orgId
      name
      orgName
      orgShortForm
      logoUrl
      requestedByCustomerId
      rejectionReason
      lastRejectedAt
      type
      websiteLink
      numOfMembers
      status
      sentForReviewAt
      createdAt
      lastEditedAt
    }
  }
`;
export const EDIT_PRODUCT_MEDIA = gql`
  mutation editProductMedia(
    $productMediaId: String!
    $fieldsToEdit: [String]!
    $caption: String
    $index: Int
    $shouldDelete: Boolean
    $mediaFile: Upload
  ) {
    editProductMedia(
      productMediaId: $productMediaId
      fieldsToEdit: $fieldsToEdit
      caption: $caption
      index: $index
      shouldDelete: $shouldDelete
      mediaFile: $mediaFile
    ) {
      id
      businessId
      brandContactId
      productId
      caption
      index
      type
      mediaLink
      lastEditedAt
      uploadedAt
    }
  }
`;
export const EDIT_FORUM_POST_MUTATION = gql`
  mutation editForumPost(
    $forumPostId: String!
    $title: String
    $fieldsToEdit: [String]
    $details: String
    $folders: [String]
    $visibility: Int
    $mediaFiles: [Upload]
    $type: Int
  ) {
    editForumPost(
      forumPostId: $forumPostId
      title: $title
      fieldsToEdit: $fieldsToEdit
      details: $details
      folders: $folders
      visibility: $visibility
      mediaFiles: $mediaFiles
      type: $type
    ) {
      id
      postNumber
      displayName
      numOfEditors
      headForumPostId
      forumPostRepliedToId
      headReplyId
      repliedToCustomerId
      repliedToDisplayName
      type
      typeOfCommentor
      visibility
      title
      details
      questionId
      forumId
      chatId
      puzzleId
      profilePicUrl
      rNumOfViews
      rNetVote
      rNumOfVotes
      rUpvotePercentage
      rNumOfComments
      rNumOfBookmarks
      rNumOfShares
      folders
      mediaUrls
      status
      createdAt
      hiddenByAdminId
      hiddenAt
      lastEditedAt
    }
  }
`;

export const FETCH_POST_GIFTS_BY_FORUM_POST = gql`
  query getPostGiftsByForumPost($forumPostId: String!) {
    getPostGiftsByForumPost(forumPostId: $forumPostId) {
      id
      customerId
      forumPostId
      forumId
      cost
      type
      status
      shouldShow
      givenByCustomer
      givenAt
    }
  }
`;

export const FETCH_FORUM_POST_RATING_BY_CUST_SUMMARY = gql`
  query getForumPostRatingByCustomer($forumPostId: String!) {
    getForumPostRatingByCustomer(forumPostId: $forumPostId) {
      id
      vote
    }
  }
`;

export const FETCH_FORUM_POST_BOOKMARK = gql`
  query getForumPostBookmarkByCustomer($forumPostId: String!) {
    getForumPostBookmarkByCustomer(forumPostId: $forumPostId) {
      id
      forumPostId
      customerId
      forumId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_IS_CUSTOMER_EDITOR_OF_FORUM_POST = gql`
  query getIsCustomerEditorOfForumPost($forumPostId: String!) {
    getIsCustomerEditorOfForumPost(forumPostId: $forumPostId)
  }
`;

export const SEND_FORUM_POST_VIEW_MUTATION = gql`
  mutation sendForumPostView($forumPostId: String!) {
    sendForumPostView(forumPostId: $forumPostId) {
      id
      customerId
      forumPostId
      forumId
      viewedAt
    }
  }
`;
export const FETCH_FORUM_POST_VIEW_BY_CUSTOMER = gql`
  query getForumPostViewByCustomer($forumPostId: String) {
    getForumPostViewByCustomer(forumPostId: $forumPostId) {
      id
      customerId
      forumPostId
      forumId

      viewedAt
    }
  }
`;

export const MAKE_FORUM_ADMIN_REQUEST_MUTATION = gql`
  mutation makeForumAdminRequest($forumId: String!) {
    makeForumAdminRequest(forumId: $forumId) {
      customerId
      forumId
      status
      acceptedByCustomerId
      createdAt
      lastSentAt
    }
  }
`;
export const FETCH_RECOMMENDED_CHATS = gql`
  query getChatsByUser($seenIds: [String]!, $pageSize: Int!, $status: Int!) {
    getChatsByUser(seenIds: $seenIds, pageSize: $pageSize, status: $status) {
      id
      title
      description
      logoUrl
      createdByCustomerId
      isGroupChat
      customerIds
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_CHAT_BY_ID = gql`
  query getChatById($chatId: String!) {
    getChatById(chatId: $chatId) {
      id
      title
      description
      logoUrl
      createdByCustomerId
      isGroupChat
      customerIds
      status
      createdAt
      lastEditedAt
    }
  }
`;

export const SUBMIT_ANSWER_MUTATION = gql`
  mutation submitAnswer(
    $questionId: String!
    $answerSelected: Int!
    $triviaNightId: String
    $questionViewId: String
    $quizId: String
  ) {
    submitAnswer(
      questionId: $questionId
      answerSelected: $answerSelected
      triviaNightId: $triviaNightId
      quizId: $quizId
      questionViewId: $questionViewId
    ) {
      id
      customerId
      quizId
      triviaNightId
      questionViewId
      questionId
      answerSelected
      isCorrect
      timeTaken
      dayNum
      otherCustomerIdsWSameOptions
      createdAt
      lastEditedAt
    }
  }
`;
export const FETCH_ANSWER_BUBBLES_BY_QUESTION_QUERY = gql`
  query getAnswerBubblesByQuestion(
    $questionId: String!
    $triviaNightId: String
    $quizId: String
  ) {
    getAnswerBubblesByQuestion(
      questionId: $questionId
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      id
      customerId
      guestCustomerId
      subscriptionType
      username
      profilePicUrl
      quizId
      triviaNightId
      questionId
      createdAt
      timeTaken
      lastEditedAt
    }
  }
`;

export const ANSWER_ADDED_SUBSCRIPTION = gql`
  subscription answerAdded($eventId: String!, $questionId: String!) {
    answerAdded(eventId: $eventId, questionId: $questionId) {
      id
      customerId
      guestCustomerId
      subscriptionType
      username
      profilePicUrl
      quizId
      triviaNightId
      questionId
      timeTaken
      createdAt
      lastEditedAt
    }
  }
`;

export const TOGGLE_QUESTION_BOOKMARK_MUTATION = gql`
  mutation toggleBookmarkQuestion(
    $questionId: String
    $triviaNightId: String
    $chatId: String
    $questionViewId: String
    $quizId: String
  ) {
    toggleBookmarkQuestion(
      questionId: $questionId
      triviaNightId: $triviaNightId
      chatId: $chatId
      questionViewId: $questionViewId
      quizId: $quizId
    ) {
      id
      questionId
      customerId
      guestCustomerId
      triviaNightId
      quizId
      chatId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_QUESTION_RATING_SUMMARY = gql`
  query getQuestionRatingSummary($questionId: String!) {
    getQuestionRatingSummary(questionId: $questionId) {
      numOfVotes
      netVote
    }
  }
`;

export const SEND_POST_VIEW_MUTATION = gql`
  mutation sendPetPostView($petPostId: String!, $typeOfView: Int!) {
    sendPetPostView(petPostId: $petPostId, typeOfView: $typeOfView) {
      id
      customerId
      petPostId
      petActivityId
      subscriptionType
      status
      viewedAt
      typeOfView
    }
  }
`;
export const TOGGLE_POST_BOOKMARK_MUTATION = gql`
  mutation toggleBookmarkPetPost(
    $chatId: String
    $petPostId: String
    $petPostViewId: String
  ) {
    toggleBookmarkPetPost(
      chatId: $chatId
      petPostId: $petPostId
      petPostViewId: $petPostViewId
    ) {
      id
      petPostId
      petId
      chatId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_POST_BOOKMARK_BY_PET = gql`
  query getPetPostBookmarkByPet($petPostId: String!) {
    getPetPostBookmarkByPet(petPostId: $petPostId) {
      id
      petPostId
      customerId
      chatId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_POST_RATING_SUMMARY = gql`
  query getPetPostRatingSummary($petPostId: String!) {
    getPetPostRatingSummary(petPostId: $petPostId) {
      numOfVotes
      netVote
    }
  }
`;
export const RATE_POST_MUTATION = gql`
  mutation ratePetPost($petPostId: String!, $vote: Int!) {
    ratePetPost(petPostId: $petPostId, vote: $vote) {
      id
      petId
      petPostId
      vote
      createdAt
      lastEditedAt
    }
  }
`;
export const RATE_QUESTION_MUTATION = gql`
  mutation rateQuestion(
    $questionId: String!
    $vote: Int!
    $triviaNightId: String
    $quizId: String
  ) {
    rateQuestion(
      questionId: $questionId
      vote: $vote
      triviaNightId: $triviaNightId
      quizId: $quizId
    ) {
      id
      customerId
      triviaNightId
      quizId
      questionId
      vote
      createdAt
      lastEditedAt
    }
  }
`;

export const FETCH_QUESTION_RATING_BY_CUST_SUMMARY = gql`
  query getQuestionRatingByCustomer($questionId: String!) {
    getQuestionRatingByCustomer(questionId: $questionId) {
      id
      vote
    }
  }
`;
export const FETCH_PRODUCTS = gql`
  query getProducts {
    getProducts {
      id
      businessId
      businessName
      businessLogoUrl
      title
      unitDescription
      shortDesc
      highlights
      moreDetails
      deliveryAndReturnPolicies
      faqs
      shippingFromCountry
      condition
      skuNum
      weight
      breadth
      width
      height
      imageUrls
      categories
      tags
      brandContactId
      brandContactName
      tknightListingFees
      amountForMerchant
      usualLink
      usualPrice
      discount
      discountedPrice
      discountPercentage
      originalStock
      saleLength
      status
      rejectionReason
      cancellationReason
      lastEditedAt
      createdAt
      approvedAt
      rejectedAt
      cancelledAt
      startSaleAt
      endSaleAt
      adType
      adVideoUrl
    }
  }
`;
export const QUESTION_VIEW_UPDATED_SUBSCRIPTION = gql`
  subscription questionViewUpdated(
    $questionId: String!
    $customerId: String
    $guestCustomerId: String
  ) {
    questionViewUpdated(
      questionId: $questionId
      customerId: $customerId
      guestCustomerId: $guestCustomerId
    ) {
      id
      customerId
      guestCustomerId
      questionId
      topicId
      subscriptionType
      status
      viewedAt
      qEndsAt
      wasTopicFocusStreakMilestone
      wasMainFocusStreakMilestone
      extraLifeAvailabilityEndsAt
      extraLifeAvailabilityEnded
      lastXSeconds
      answerResult
      typeOfFocus
    }
  }
`;

export const FETCH_QUESTION_BOOKMARK_BY_CUSTOMER = gql`
  query getQuestionBookmarkByCustomer($questionId: String!) {
    getQuestionBookmarkByCustomer(questionId: $questionId) {
      id
      questionId
      customerId
      guestCustomerId
      triviaNightId
      quizId
      chatId
      status
      createdAt
      lastToggledAt
    }
  }
`;

export const FETCH_SELECTED_ANSWER_BY_QUESTION = gql`
  query getSelectedFQuestionAnswer($questionId: String!) {
    getSelectedFQuestionAnswer(questionId: $questionId) {
      id
      customerId
      guestCustomerId
      quizId
      triviaNightId
      questionId
      answerSelected
      isCorrect
      dayNum
      otherCustomerIdsWSameOptions
      deltaXP
      timeTaken
      createdAt
      lastEditedAt
    }
  }
`;

export const SEND_QUESTION_VIEW_MUTATION = gql`
  mutation sendQuestionView($questionId: String!, $typeOfFocus: Int!) {
    sendQuestionView(questionId: $questionId, typeOfFocus: $typeOfFocus) {
      id
      customerId
      guestCustomerId
      questionId
      subscriptionType
      status
      viewedAt
      qEndsAt
      lastXSeconds
      token
      extraLifeAvailabilityEndsAt
      extraLifeAvailabilityEnded
      answerResult
      typeOfFocus
    }
  }
`;

export const LOG_QUESTION_SHARE_MUTATION = gql`
  mutation logQuestionShare($questionId: String) {
    logQuestionShare(questionId: $questionId) {
      id
      customerId
      questionId
      topicId
      type
      createdAt
      status
    }
  }
`;

export const UPDATE_QUESTION_VIEW_STATUS_MUTATION = gql`
  mutation updateQuestionViewStatus($questionViewId: String!) {
    updateQuestionViewStatus(questionViewId: $questionViewId) {
      id
      customerId
      guestCustomerId
      questionId
      subscriptionType
      status
      viewedAt
      qEndsAt
      extraLifeAvailabilityEndsAt
      extraLifeAvailabilityEnded
      lastXSeconds
      answerResult
    }
  }
`;

export const SAVE_STREAK_WITH_EXTRA_LIFE_MUTATION = gql`
  mutation saveStreakWithExtraLife($questionViewId: String!) {
    saveStreakWithExtraLife(questionViewId: $questionViewId)
  }
`;

export const FETCH_FOCUS_STREAK_MILESTONE_RESULTS_FORMATTED = gql`
  query getFocusStreakMilestoneResultsFormatted(
    $focusStreakMilestoneId: String
  ) {
    getFocusStreakMilestoneResultsFormatted(
      focusStreakMilestoneId: $focusStreakMilestoneId
    ) {
      questionDesc
      emoji
      isCorrect
    }
  }
`;

export const FETCH_QUESTION_VIEW_BY_CUSTOMER = gql`
  query getQuestionViewByCustomer($questionId: String) {
    getQuestionViewByCustomer(questionId: $questionId) {
      id
      customerId
      guestCustomerId
      questionId
      topicId
      subscriptionType
      status
      viewedAt
      qEndsAt
      wasTopicFocusStreakMilestone
      wasMainFocusStreakMilestone
      extraLifeAvailabilityEndsAt
      extraLifeAvailabilityEnded
      lastXSeconds
      answerResult
      typeOfFocus
    }
  }
`;
