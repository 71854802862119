import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_PET } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";

export default function MobileFooter({ navigate, page, finishedLoading }) {
  const location = useLocation();
  // const [lastPage, setLastPage] = useState(
  //   location.state?.from?.pathname
  //     ? location.state?.from?.pathname.split("/")[1]
  //     : null
  // );

  const { data: { getPetByCustomer: targetPet } = {} } = useQuery(FETCH_PET, {
    client: customerClient,
    // fetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   if (location.state?.from?.pathname) {
  //     let tempLastPage = location.state?.from?.pathname;
  //     if (tempLastPage) {
  //       tempLastPage = tempLastPage.split("/")[1];
  //       if (tempLastPage) {
  //         setLastPage(tempLastPage);
  //       }
  //     }
  //   }
  // }, [location]);

  return (
    <div
      className={`w-full text-sm fixed xshort:hidden lg:hidden h-[5.5rem] short:h-[4rem] px-6 bottom-0 bg-gradient-to-t  inset-x-0 z-10 grid grid-cols-3 text-black from-light-blue to-transparent via-light-blue/60`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/puzzles", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "focus" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M21.6385 11.1615C21.3127 10.8355 20.9258 10.5769 20.5001 10.4005C20.0744 10.2241 19.618 10.1333 19.1572 10.1333C18.6963 10.1333 18.24 10.2241 17.8142 10.4005C17.3885 10.5769 17.0017 10.8355 16.6759 11.1615L15.9997 11.8376L15.3236 11.1615C14.6655 10.5034 13.773 10.1337 12.8423 10.1337C11.9117 10.1337 11.0191 10.5034 10.361 11.1615C9.70296 11.8195 9.33325 12.7121 9.33325 13.6427C9.33325 14.5734 9.70296 15.466 10.361 16.124L15.9997 21.7627L21.6385 16.124C21.9644 15.7982 22.223 15.4114 22.3994 14.9857C22.5758 14.5599 22.6666 14.1036 22.6666 13.6427C22.6666 13.1819 22.5758 12.7256 22.3994 12.2998C22.223 11.8741 21.9644 11.4872 21.6385 11.1615Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Home</p>
      </button>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/shop", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "shop" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M10.1333 11.7333L12.1704 9.06665H20.3185L22.3556 11.7333M10.1333 11.7333V21.0666C10.1333 21.4203 10.2764 21.7594 10.5311 22.0095C10.7858 22.2595 11.1312 22.4 11.4914 22.4H20.9975C21.3577 22.4 21.7031 22.2595 21.9578 22.0095C22.2125 21.7594 22.3556 21.4203 22.3556 21.0666V11.7333M10.1333 11.7333H22.3556M18.9605 14.4C18.9605 15.1072 18.6743 15.7855 18.165 16.2856C17.6556 16.7857 16.9648 17.0666 16.2444 17.0666C15.5241 17.0666 14.8333 16.7857 14.3239 16.2856C13.8145 15.7855 13.5284 15.1072 13.5284 14.4"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="leading-none text-center tracking-wide">Shop</p>
      </button> */}
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          navigate("/post", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`bg-yellow-400 rounded-full short:h-6 h-8 `}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M22.1333 16.8381H17.1048V21.8666H15.4286V16.8381H10.4V15.1619H15.4286V10.1333H17.1048V15.1619H22.1333V16.8381Z"
            fill="black"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Memo</p>
      </button> */}

      <button
        onClick={(e) => {
          e.preventDefault();

          navigate("/notifications", { state: { from: location } });
          // navigate("/puzzles", { state: { from: location } });
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <svg
          className={`${
            page === "inbox" ? "bg-lighter-blue" : ""
          } rounded-full short:h-6 h-8 stroke-current`}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
          <path
            d="M23.4667 16H18.9867L17.4933 18.2H14.5067L13.0133 16H8.53333M23.4667 16V20.4C23.4667 20.789 23.3093 21.1621 23.0293 21.4371C22.7492 21.7122 22.3694 21.8667 21.9733 21.8667H10.0267C9.6306 21.8667 9.25077 21.7122 8.97071 21.4371C8.69066 21.1621 8.53333 20.789 8.53333 20.4V16M23.4667 16L20.8907 10.9474C20.767 10.703 20.5764 10.4974 20.3403 10.3536C20.1042 10.2098 19.8319 10.1335 19.5541 10.1334H12.4459C12.168 10.1335 11.8958 10.2098 11.6597 10.3536C11.4235 10.4974 11.233 10.703 11.1093 10.9474L8.53333 16"
            stroke="#1E1E1E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p className="leading-none text-center tracking-wide">Inbox</p>
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (targetPet) {
            navigate("/pet/" + targetPet.username, {
              state: { from: location },
            });
          } else {
            navigate("/signup", { state: { from: location } });
          }
        }}
        className={`flex short:py-2 py-4 flex-col items-center justify-center space-y-1 `}
      >
        <img
          className="short:h-6 h-8 rounded-full border-2 border-black"
          src={targetPet?.profilePicUrl || defaultPicUrl}
          alt={targetPet?.name || "Profile"}
        />

        <p className="leading-none text-center tracking-wide">Pet</p>
      </button>
    </div>
  );
}
