import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Confetti from "react-confetti";
import { useNavigate } from "react-router";
import usePageTracking from "../../util/usePageTracking";

import { useMutation, useQuery } from "@apollo/client";
import { customerClient } from "../../GraphqlApolloClients";
import { defaultPicUrl, useForm, useWindowSize } from "../../util/hooks";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import BuyPetSubscriptionForm from "../../components/creator/BuyPetSubscriptionForm";
import CodeForm from "../../components/creator/CodeForm";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";

import Logo from "../../components/creator/Logo";
import MiniNavBar from "../../components/creator/MiniNavBar";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import {
  EDIT_PET,
  FETCH_BREEDS_BY_IDS,
  FETCH_CITY_BY_ID,
  FETCH_CUSTOMER,
  FETCH_PET,
  FETCH_RECOMMENDED_BREEDS,
} from "../../util/gqlFunctions";

import { CustomerAuthContext } from "../../context/customerAuth";
import lucky from "../../images/lucky.png";
const genders = {
  2: "Non-binary",
  1: "Female",
  0: "Male",
};

const stepToFieldToEdit = {
  0: "name",
  1: "petTypeId",
  2: "breedIds",
  3: "username,birthday,gender",
  4: "cityId",
};

const petTypes = {
  1: "Cat",
  2: "Dog",
  3: "Rabbit",
  4: "Hamster",
  5: "Guinea Pig",
  6: "Fish",
  7: "Bird",
  8: "Reptile",
  9: "Snake",
  10: "Lizard",
  11: "Frog",
  12: "Horse",
  13: "Chinchilla",
  14: "Ferret",
  15: "Hermit Crab",
  16: "Mouse",
  17: "Tarantula",
  18: "Goat",
  19: "Pig",
  20: "Sugar Glider",
  21: "Turtle",
  22: "Gerbil",
  23: "Rat",
  24: "Hedgehog",
};

function EditPet(props) {
  usePageTracking();
  const [errors, setErrors] = useState({});

  let navigate = useNavigate();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);

  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    document.title = "Complete Sign Up | The Lucky Pet";
  }, []);

  const { values, setValues, onChange } = useForm(editPetCallback, {
    searchTerm: "",
    friendId: null,
    name: "",
    birthday: null,
    gender: null,
    petTypeId: null,
    city: "",
    country: "",
    cityId: "",
    displayCity: "",
    fieldsToEdit: [],
    pageSize: 20,
    seenIds: [],
    emails: [],
    phoneNumbers: [],
    breedIds: [],
  });

  const [curStep] = useState(0);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const resetInterval = 1000;

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
  });
  const { data: { getPetByCustomer: targetPet } = {} } = useQuery(FETCH_PET, {
    client: customerClient,
  });

  const {
    data: { getRecommendedBreedsBySearch: recommendedBreeds } = {},

    refetch: refetchRecommendedBreeds,
  } = useQuery(FETCH_RECOMMENDED_BREEDS, {
    client: customerClient,
    variables: values,
  });

  function editPetCallback(e) {
    e.preventDefault();
    editPet({ ...values });
  }

  const [openedProPlanPopupOnce, setOpenedProPlanPopupOnce] = useState(false);
  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);
  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  useEffect(() => {
    if (
      (customer &&
        !loadingCustomer &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomer,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const [editPet, { loading: loadingEditPet }] = useMutation(EDIT_PET, {
    refetchQueries: [
      { query: FETCH_PET },
      { query: FETCH_RECOMMENDED_BREEDS, variables: values },
    ],
    update(_, { data: { editPet: petData } }) {
      setValues({
        ...values,
        name: values?.fieldsToEdit.includes("name")
          ? petData.name
          : values.name,

        email: values?.fieldsToEdit.includes("email")
          ? petData.email
          : values.email,
        phone: values?.fieldsToEdit.includes("phone")
          ? petData.phone
          : values.phone,
        cityId: petData.cityId || "",
        city: petData.city || "",
        country: petData.country || "",
        fieldsToEdit: [],
        oldPassword: "",
        password: "",
        username: values?.fieldsToEdit.includes("username")
          ? petData.username
          : values.username,
        profilePicUrl: petData.profilePicUrl,
        accentColor: petData.accentColor || 0,
        instaHandle: petData.instaHandle || "",
        redditHandle: petData.redditHandle || "",
        twitterHandle: petData.twitterHandle || "",
        tiktokHandle: petData.tiktokHandle || "",
        remindersToReceive: petData.remindersToReceive,
        birthday: petData.birthday || null,
        gender: petData.gender,
      });
      setErrors({});
      if (curStep === 5) {
        toggleBuyPetSubscriptionForm(0);
      } else {
        navigate("/completeRegistration/" + (curStep + 1));
      }
    },
    onError(err) {
      console.log(err);

      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: { ...values },
    client: customerClient,
  });

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);
  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out thelucky.pet\n\n";
    textToShare += "Make an account for your pet.";

    textToShare += "It's really fun!!\n\n";
    // if (targetPet) {
    //   textToShare +=
    //     'Sign up with my extra ❤️ code: "' + targetPet.username + '"\n\n';
    // }
    textToShare += "#TheLuckyPet";

    if (navigator.share) {
      navigator
        .share({
          title: "TheLuckyPet Invite!",
          text: textToShare,
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);
          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const friendRef = useRef();
  const hiRef = useRef();
  const userDetailsRef = useRef();
  const petTypeRef = useRef();
  const breedRef = useRef();
  const cityRef = useRef();

  useEffect(() => {
    if (curStep === 0) {
      if (hiRef.current) {
        hiRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        hiRef.current.focus();
      }
    } else if (curStep === 1) {
      if (petTypeRef.current) {
        petTypeRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        petTypeRef.current.focus();
      }
    } else if (curStep === 2) {
      if (breedRef.current) {
        breedRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        breedRef.current.focus();
      }
    } else if (curStep === 3) {
      if (userDetailsRef.current) {
        userDetailsRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        userDetailsRef.current.focus();
      }
    } else if (curStep === 4) {
      if (cityRef.current) {
        cityRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        cityRef.current.focus();
      }
    } else if (curStep === 5) {
      if (friendRef.current) {
        friendRef.current.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [curStep, openedProPlanPopupOnce]);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [petSubscriptionType, setSubscriptionType] = useState(null);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && targetCustomer) {
      setShouldPlaySoundEffects(
        targetCustomer.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, targetCustomer]);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);

  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);

  const toggleBuyPetSubscriptionForm = (petSubscriptionType) => {
    if (petSubscriptionType === null && !openedProPlanPopupOnce) {
      setOpenedProPlanPopupOnce(true);
    }
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setSubscriptionType(petSubscriptionType);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);
  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };
  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);

    setShouldOpenCoinPurchaseSuccessfulForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };
  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);

    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };
  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };
  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);

    setShouldShowMiniNavBar(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };
  const togglePaymentSuccessfulForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };

  const size = useWindowSize();

  const handleChangeDate = (event) => {
    event.preventDefault();

    // Get the selected date and time in the user's timezone
    const localDate = moment(event.target.value).toDate();

    // Convert the local date and time to UTC
    const utcDate = moment.utc(localDate);

    // Save the UTC date and time to the state
    setValues({ ...values, birthday: utcDate });
  };

  const displayBirthday = () => {
    // Format and display the birthday value in the user's timezone
    return moment(values.birthday).local().format("YYYY-MM-DD");
  };

  const [valsAreInitialized, setValsAreInitialized] = useState(false);

  useEffect(() => {
    if (
      !valsAreInitialized &&
      targetPet &&
      !loadingEditPet &&
      !loadingCustomer
    ) {
      setValues({
        ...values,
        fieldsToEdit:
          stepToFieldToEdit[targetPet.curRegistrationStep]?.split(",") || [],
        name: targetPet.name || "",
        email: targetPet.email || "",
        phone: targetPet.phone,
        cityId: targetPet.cityId || "",
        city: targetPet.city || "",
        country: targetPet.country || "",
        petTypeId: targetPet.petTypeId || "",
        breedIds: targetPet.breedIds || "",
        oldPassword: "",
        username: targetPet.username || "",
        accentColor: targetPet.accentColor,
        profilePicUrl: targetPet.profilePicUrl,
        profilePicFile: null,
        instaHandle: targetPet.instaHandle || "",
        redditHandle: targetPet.redditHandle || "",
        twitterHandle: targetPet.twitterHandle || "",
        tiktokHandle: targetPet.tiktokHandle || "",
        remindersToReceive: targetPet.remindersToReceive,
        birthday: targetPet.birthday || null,
        gender: targetPet.gender,
        petSubscriptionType: targetPet.petSubscriptionType,
      });

      setValsAreInitialized(true);
    }
  }, [
    customer,
    loadingCustomer,
    values,
    setValues,
    targetPet,
    loadingEditPet,
    valsAreInitialized,
  ]);

  const goToNext = (e) => {
    if (
      openedProPlanPopupOnce ||
      (targetPet && targetPet.isPaidPetSubscriber)
    ) {
      navigate("/");
    } else if (curStep >= 5) {
      editPet({
        variables: { ...values, fieldsToEdit: ["curRegistrationStep"] },
      });
    } else {
      editPetCallback(e);
    }
  };

  const { data: { getCityById: targetCity } = {} } = useQuery(
    FETCH_CITY_BY_ID,
    {
      client: customerClient,
      variables: { cityId: values.cityId || "" },
    }
  );

  const { data: { getBreedsByIds: targetBreeds } = {} } = useQuery(
    FETCH_BREEDS_BY_IDS,
    {
      client: customerClient,
      variables: { breedIds: values.breedIds || "" },
    }
  );

  useEffect(() => {
    if (!customer) {
      navigate("/login");
    }
  }, [customer, navigate]);

  return (
    <div className="w-screen overflow-x-hidden h-full max-h-screen bg-light-blue">
      {shouldShowMiniNavBar && targetPet && (
        <MiniNavBar
          targetPet={targetPet}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuyPetSubscriptionForm}
        />
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetPet={targetPet}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {petSubscriptionType !== null && stripe && elements && targetPet ? (
        <BuyPetSubscriptionForm
          stripe={stripe}
          elements={elements}
          targetPet={targetPet}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          petSubscriptionType={petSubscriptionType}
          toggleBuySubscriptionForm={toggleBuyPetSubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          isOnCompleteRegistrationPage={true}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetPet && (
        <CodeForm
          title="Lucky Pet Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetPet={targetPet}
            message={
              targetPet.name
                ? `Welcome to Lucky Pet Pro, ${targetPet.name}!`
                : "Welcome to Lucky Pet Pro!"
            }
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetPet.petSubscriptionType === 0
                ? "Individual"
                : targetPet.petSubscriptionType === 1
                ? "Duo"
                : targetPet.petSubscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetPet={targetPet}
            message={
              targetPet.name
                ? `Welcome to Lucky Pet Pro, ${targetPet.name}!`
                : "Welcome to Lucky Pet Pro!"
            }
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetPet={targetPet}
            message={
              "Tokens added to stash. Good luck, " + targetPet.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"Payment Successful!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetPet && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetPet={targetPet}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}
      {shouldOpenPaymentSuccessfulForm && targetPet && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetPet={targetPet}
        />
      )}

      <div
        className={`relative z-10 overflow-hidden flex flex-col h-screen max-h-screen short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-4 lg:pb-8`}
      >
        <div
          className={`space-x-2 sm:space-x-3 relative z-20 px-2 py-4 sm:px-2 sm:pb-6 text-black flex items-center justify-between w-full`}
        >
          {curStep && curStep > 0 && curStep < 6 && !openedProPlanPopupOnce ? (
            <button
              onClick={(e) => {
                navigate("/completeRegistration/" + (curStep - 1));
              }}
              className="transition-all h-full origin-left px-3 bg-white/30 rounded-full outline-none hover:opacity-50 focus:outline-none"
            >
              <svg
                className="h-3 fill-current"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" />
              </svg>
            </button>
          ) : (
            <button
              onClick={(e) => {
                if (curStep === 5 && openedProPlanPopupOnce) {
                  navigate("/");
                } else {
                  e.preventDefault();
                }
              }}
              className="transition-opacity hover:opacity-50 disabled:hover:opacity-100"
              disabled={
                curStep !== 5 || !openedProPlanPopupOnce || loadingEditPet
              }
            >
              <Logo dimensions={"h-5"} />
            </button>
          )}

          <div className="flex-1"></div>

          <div className=" flex items-center justify-end sm:space-x-4 space-x-2">
            <div className="text-base 2xl:text-2xl text-black overflow-hidden relative flex space-x-2 items-center justify-center font-semibold py-1 leading-tight rounded-full">
              <p className=" font-normal whitespace-nowrap">
                {curStep + 1} / 6
              </p>
            </div>
          </div>
        </div>

        <div className="relative w-full flex-1 flex flex-row gap-6 rounded-lg sm:rounded-none sm:gap-10 snap-x snap-mandatory overflow-hidden my-2 sm:my-0">
          <div
            ref={hiRef}
            className={`snap-center flex px-2 py-2 flex-col items-start justify-start shrink-0 w-full`}
          >
            <label
              htmlFor="name"
              className="font-semibold text-center w-full text-black text-3xl sm:text-2xl "
            >
              Hi {targetPet?.name || "friend"}!
            </label>
            <p className="mb-2 sm:mt-0 mt-2 text-center w-full text-gray-600 text-xl sm:text-lg ">
              My name is Lucky!! I'm so happy you're here :)
            </p>

            {/* <div className="flex-1 grow-0 bg-black flex items-center justify-center"> */}
            <img
              src={lucky}
              className="mx-auto sm:mb-0 mb-auto sm:h-2/3 sm:flex-1"
              alt="Lucky"
            />
            {/* </div> */}
            {/* <img
              src={lucky}
              className="mx-auto  flex-1 sm:mt-auto sm:mb-0 mb-auto"
              alt="Lucky"
            /> */}

            <p className="mb-1 sm:mb-2 shrink-0 sm:mt-0 mt-2 text-center w-full text-gray-600 text-xl sm:text-lg ">
              Let's get you set up! (1 min)
            </p>
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={petTypeRef}
          >
            <label
              htmlFor="birthday"
              className="font-semibold text-black sm:text-2xl text-xl "
            >
              Pet type
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              What kind of animal are you?
            </p>

            <div
              className={`w-full gap-3 lg:gap-4 flex-col items-center justify-center overflow-y-auto grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-1 `}
            >
              {Object.entries(petTypes).map(([petTypeId, petTypeName]) => (
                <button
                  key={petTypeId}
                  onClick={(e) => {
                    e.preventDefault();
                    let petTypeIdStr = `${petTypeId}`;
                    if (values.petTypeId === petTypeIdStr) {
                      setValues({
                        ...values,
                        petTypeId: petTypeIdStr,
                      });
                    } else {
                      setValues({
                        ...values,
                        petTypeId: petTypeIdStr,
                      });
                    }
                  }}
                  className={`px-5 ${
                    values.petTypeId !== `${petTypeId}`
                      ? "flex bg-white"
                      : " bg-yellow-400  hover:bg-opacity-90"
                  } rounded-full text-left text-base sm:text-xl bg-opacity-90 hover:bg-opacity-50 items-center space-x-2 transition-all justify-start overflow-hidden py-2`}
                  disabled={loadingEditPet}
                >
                  <p>{petTypeName}</p>
                </button>
              ))}
            </div>

            {errors && errors.petTypeId ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight  mt-2">
                {errors.petTypeId.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={breedRef}
          >
            <label
              htmlFor="breed"
              className="font-semibold text-black sm:text-2xl text-xl "
            >
              Breed
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              This will help you find activities.
            </p>
            <div
              className={`mb-2 text-xl sm:text-2xl lowercase text-black disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm pl-3 px-2 py-2 rounded-md border-gray-200  bg-white space-x-2 duration-700 transition-height flex items-center justify-start border `}
            >
              <div className="transition-all">
                <svg
                  className="fill-current w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>
              </div>

              <input
                name={"searchTerm"}
                id={"searchTerm"}
                value={values.searchTerm}
                onChange={(e) => {
                  setValues({ ...values, searchTerm: e.target.value });

                  if (curStep === 2) {
                    refetchRecommendedBreeds({
                      searchTerm: e.target.value,
                      pageSize: 20,
                    });
                  } else if (curStep === 5) {
                  } else {
                  }
                }}
                className={`short:text-sm text-black text-lg sm:py-2 leading-tight w-full flex-1 bg-transparent focus:outline-none`}
                placeholder={"Find your breed(s)"}
                maxLength={100}
              />
            </div>

            <div
              className={`w-full gap-3 lg:gap-4 flex-col items-center justify-center overflow-y-auto grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-1 `}
            >
              {targetBreeds && targetBreeds.length > 0 ? (
                targetBreeds.map((breed) => (
                  <button
                    key={breed.id}
                    onClick={(e) => {
                      e.preventDefault();
                      let tempBreedIds = [...values.breedIds];

                      const indexToRemove = tempBreedIds.indexOf(breed.id);

                      if (indexToRemove > -1) {
                        tempBreedIds.splice(indexToRemove, 1);
                      }
                      setValues({
                        ...values,
                        breedIds: tempBreedIds,
                        searchTerm: "",
                      });
                    }}
                    className={`${
                      values &&
                      values.breedIds &&
                      values.breedIds.includes(breed.id)
                        ? "bg-yellow-400 text-black bg-opacity-90"
                        : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                    } w-full shrink-0 px-5 h-10 short:text-sm transition-all rounded-full text-left  whitespace-nowrap py-2 `}
                  >
                    {breed.name}
                  </button>
                ))
              ) : (
                <></>
              )}
              {recommendedBreeds && recommendedBreeds.length > 0 ? (
                recommendedBreeds
                  .filter((breed) => !values?.breedIds.includes(breed.id))
                  .map((breed) => (
                    <button
                      key={breed.id}
                      onClick={(e) => {
                        e.preventDefault();
                        if (values.breedIds.length < 3) {
                          setValues({
                            ...values,
                            breedIds: [...values.breedIds, breed.id],
                            searchTerm: "",
                          });
                        }
                      }}
                      className={`${
                        values &&
                        values.breedIds &&
                        values.breedIds.includes(breed.id)
                          ? "bg-yellow-400 text-black bg-opacity-90"
                          : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                      } w-full shrink-0 px-5 h-10 short:text-sm transition-all rounded-full text-left  whitespace-nowrap py-2 `}
                    >
                      {breed.name}
                    </button>
                  ))
              ) : (
                <></>
              )}
            </div>
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={userDetailsRef}
          >
            <label
              htmlFor="username"
              className="font-semibold text-black sm:text-2xl text-xl "
            >
              Username
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              You can only change this once every 7 days.
            </p>
            <div className="text-xl sm:text-2xl lowercase flex mt-1 w-full text-left shadow-sm px-3 py-2 bg-white rounded-md border-gray-200 border items-center justify-start">
              <label
                htmlFor="username"
                className="pb-1 text-gray-400 mr-0.5 leading-none"
              >
                @
              </label>
              <input
                type={"text"}
                name={"username"}
                id={"username"}
                value={values.username || ""}
                onChange={onChange}
                className={`bg-white disabled:bg-white focus:outline-none flex-1 `}
                // placeholder={"drake"}
                disabled={loadingEditPet}
                maxLength={30}
                autoComplete="nope"
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    goToNext(e);
                  }
                }}
              />
            </div>
            {errors && errors.username ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 text-left leading-tight sm:leading-tight  mt-2">
                {errors.username.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}

            <label
              htmlFor="birthday"
              className="mt-10 font-semibold text-black sm:text-2xl text-xl "
            >
              Birthday
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              This will help you find activities.
            </p>

            <input
              type="date"
              id="birthday"
              min={moment(new Date())
                .subtract(100, "years")
                .format("YYYY-MM-DD")}
              // max={moment(new Date()).subtract(8, "years").format("YYYY-MM-DD")}
              className={`text-left text-xl sm:text-2xl disabled:bg-white mt-1 focus:outline-none w-full shadow-sm px-3 py-2 rounded-md border-gray-200 border `}
              value={displayBirthday()}
              onChange={handleChangeDate}
              disabled={loadingEditPet}
              onKeyDown={(e) => {
                if (e.key === "Tab") {
                  goToNext(e);
                }
              }}
            />

            {errors && errors.birthday ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight  mt-2">
                {errors.birthday.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}

            <label
              htmlFor="gender"
              className="mt-10 font-semibold text-black sm:text-2xl text-xl "
            >
              Gender
            </label>
            <p className="mb-2 text-gray-600 sm:text-lg ">
              This helps improve the app for everyone.
            </p>

            <div
              className={`gap-2 flex-wrap flex items-center justify-center mt-2`}
            >
              {[1, 0].map((option, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    if (values.gender === parseInt(option)) {
                      setValues({
                        ...values,
                        gender: parseInt(option),
                      });
                    } else {
                      setValues({
                        ...values,
                        gender: parseInt(option),
                      });
                    }
                  }}
                  className={`px-5 ${
                    values.gender !== parseInt(option)
                      ? "flex bg-white"
                      : " bg-yellow-400  hover:bg-opacity-90"
                  } rounded-full text-base sm:text-xl bg-opacity-90 hover:bg-opacity-50 items-center space-x-2 transition-all justify-start overflow-hidden py-2`}
                  disabled={loadingEditPet}
                >
                  <p>{genders[option]}</p>
                </button>
              ))}
            </div>

            {errors && errors.gender ? (
              <p className="sm:text-lg font-semibold w-full max-w-sm text-red-400 leading-tight sm:leading-tight  mt-2">
                {errors.gender.replace("- ", "")}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={cityRef}
          >
            <label
              htmlFor="username"
              className="font-semibold text-black sm:text-2xl text-xl "
            >
              City
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              This will help you find activities.
            </p>
            <div
              className={`mb-2 text-xl sm:text-2xl lowercase text-black disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm pl-3 px-2 py-2 rounded-md border-gray-200  bg-white space-x-2 duration-700 transition-height flex items-center justify-start border `}
            >
              <div className="transition-all">
                <svg
                  className="fill-current w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>
              </div>

              <input
                name={"searchTerm"}
                id={"searchTerm"}
                value={values.searchTerm}
                onChange={(e) => {
                  setValues({ ...values, searchTerm: e.target.value });

                  if (curStep === 2) {
                    refetchRecommendedBreeds({
                      searchTerm: e.target.value,
                      pageSize: 20,
                    });
                  } else if (curStep === 5) {
                  } else {
                  }
                }}
                className={`short:text-sm text-black text-lg sm:py-2 leading-tight w-full flex-1 bg-transparent focus:outline-none`}
                placeholder={"Find your city"}
                // disabled={loadingCreateComment}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && e.shiftKey === false) {
                //     e.preventDefault();
                //     createComment();
                //   }
                // }}
                maxLength={100}
              />
            </div>

            {targetCity && (
              <div className="flex shrink-0 text-left group space-x-2 sm:space-x-3 overflow-hidden sm:hover:bg-white/20 transition-all rounded-md sm:hover:shadow-sm items-center w-full justify-start sm:px-2 py-2">
                <div className="bg-opacity-20  bg-white overflow-hidden shrink-0 shadow relative rounded-full">
                  <img
                    src={targetCity.logoUrl || defaultPicUrl}
                    alt={targetCity.name}
                    className={`${
                      targetPet &&
                      values.cityId &&
                      values.cityId === targetCity.id
                        ? "border-yellow-400"
                        : "border-gray-100"
                    } h-10 bg-white border-4 box-border rounded-full object-cover aspect-square`}
                  />
                </div>
                <div className="flex w-full truncate space-y-1 flex-col items-start justify-center">
                  <p className="font-semibold w-full truncate leading-none text-black sm:leading-none sm:text-lg text-base short:text-sm short:leading-none">
                    {targetCity.name}
                  </p>
                  <p className=" text-black text-base sm:text-base short:text-xs short:leading-none leading-tight">
                    {targetCity.country}
                  </p>
                </div>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      cityId: targetCity.id,
                      city: targetCity.name,
                      country: targetCity.country,
                      searchTerm: "",
                    });
                  }}
                  className={`${
                    targetPet &&
                    values.cityId &&
                    values.cityId === targetCity.id
                      ? "bg-yellow-400 text-black bg-opacity-90"
                      : "bg-white text-black hover:bg-opacity-50 bg-opacity-90"
                  } shrink-0 px-4 h-10 short:text-sm transition-all rounded-full font-bold text-center  whitespace-nowrap py-2 `}
                >
                  {targetPet && values.cityId && values.cityId === targetCity.id
                    ? "Selected"
                    : "Select"}
                </button>
              </div>
            )}
            <div className="flex flex-col overflow-y-auto items-start justify-start w-full flex-1"></div>
          </div>

          <div
            className={`snap-center flex px-2 py-2 flex-col overflow-y-auto items-start justify-start shrink-0 w-full`}
            ref={friendRef}
          >
            <label
              htmlFor="username"
              className="font-semibold text-black sm:text-2xl text-xl "
            >
              Friends
            </label>
            <p className="mb-2  text-gray-600 sm:text-lg ">
              Add pets you'd like to follow.{" "}
            </p>
            <div
              className={`mb-2 text-xl sm:text-2xl lowercase text-black disabled:bg-white mt-1 text-left focus:outline-none w-full shadow-sm pl-3 px-2 py-2 rounded-md border-gray-200  bg-white space-x-2 duration-700 transition-height flex items-center justify-start border `}
            >
              <div className="transition-all">
                <svg
                  className="fill-current w-5 short:w-4"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
                  />
                </svg>
              </div>

              <input
                name={"searchTerm"}
                id={"searchTerm"}
                value={values.searchTerm}
                onChange={(e) => {
                  setValues({ ...values, searchTerm: e.target.value });

                  if (curStep === 2) {
                    refetchRecommendedBreeds({
                      searchTerm: e.target.value,
                      pageSize: 20,
                    });
                  } else if (curStep === 5) {
                  } else {
                  }
                }}
                className={`short:text-sm text-black text-lg sm:py-2 leading-tight w-full flex-1 bg-transparent focus:outline-none`}
                placeholder={"Find your friends"}
                // disabled={loadingCreateComment}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter" && e.shiftKey === false) {
                //     e.preventDefault();
                //     createComment();
                //   }
                // }}
                maxLength={100}
              />
            </div>

            <button
              onClick={inviteFriends}
              className={`w-full mb-2 flex rounded-md text-sm sm:text-base ${
                isSharingInviteToApps || isInviteCopied
                  ? "bg-green-200 hover:bg-yellow-400"
                  : "bg-gray-100 hover:bg-opacity-90"
              } transition-all py-1.5 items-center justify-center space-x-1.5`}
            >
              <p className=" leading-tight">
                {isSharingInviteToApps
                  ? "Invite shared!"
                  : isInviteCopied
                  ? "Invite copied!"
                  : "Invite for extra life"}
              </p>
              <svg
                className="h-3 sm:h-4 aspect-square fill-current "
                viewBox="0 0 21 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_8661_576)">
                  <path d="M15 14.1365C14.24 14.1365 13.56 14.4378 13.04 14.9096L5.91 10.743C5.96 10.512 6 10.2811 6 10.0402C6 9.7992 5.96 9.56827 5.91 9.33735L12.96 5.21084C13.5 5.71285 14.21 6.0241 15 6.0241C16.66 6.0241 18 4.67871 18 3.01205C18 1.34538 16.66 0 15 0C13.34 0 12 1.34538 12 3.01205C12 3.25301 12.04 3.48394 12.09 3.71486L5.04 7.84137C4.5 7.33936 3.79 7.02811 3 7.02811C1.34 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34 13.0522 3 13.0522C3.79 13.0522 4.5 12.741 5.04 12.239L12.16 16.4157C12.11 16.6265 12.08 16.8474 12.08 17.0683C12.08 18.6847 13.39 20 15 20C16.61 20 17.92 18.6847 17.92 17.0683C17.92 15.4518 16.61 14.1365 15 14.1365Z"></path>
                </g>
              </svg>
            </button>
            <div className="flex flex-col overflow-y-auto items-start justify-start w-full flex-1"></div>
          </div>
        </div>
        <button
          disabled={
            loadingEditPet ||
            !targetPet ||
            (curStep === 0 && !values.name) ||
            (curStep === 1 && values.petTypeId === null) ||
            (curStep === 2 &&
              (!values.breedIds || values.breedIds.length < 1)) ||
            (curStep === 3 && values.gender === null) ||
            (curStep === 4 && !values.cityId)
          }
          onClick={goToNext}
          className="w-full relative p-3 uppercase overflow-hidden py-4 short:py-2 disabled:opacity-50 hover:bg-red-700 transition-all rounded-full font-bold bg-red-600 text-white  text-2xl short:text-lg  sm:text-2xl"
        >
          <div
            className={`${
              !loadingEditPet
                ? "motion-safe:animate-fadeOutUpFast"
                : "motion-safe:animate-fadeDownFast"
            } bg-black absolute inset-0 flex items-center justify-center`}
          >
            <svg
              className={`w-6 motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>
          <p
            className={`${
              loadingEditPet
                ? "motion-safe:animate-fadeOutDownFast"
                : "motion-safe:animate-fadeUpFast"
            } h-full`}
          >
            {openedProPlanPopupOnce ||
            (targetPet && targetPet.isPaidPetSubscriber)
              ? "Done"
              : "Continue"}
          </p>
        </button>
      </div>
    </div>
  );
}

export default EditPet;
