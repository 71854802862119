import React, { useContext, useEffect, useState } from "react";

import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate, useParams } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import SubmitButton from "../../components/creator/SubmitButton";
import TopBar from "../../components/creator/TopBar";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import {
  RESET_SELLER_PASSWORD_MUTATION,
  SEND_PASSWORD_RESET_CODE_SELLER,
} from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function ResetSellerPassword() {
  usePageTracking();

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { resetCode } = useParams();

  const { brandContact, loginBrandContact: loginBrandContactContext } =
    useContext(BrandContactAuthContext);

  useEffect(() => {
    if (brandContact) {
      navigate("/business/dashboard");
    }
  }, [brandContact, navigate]);

  useEffect(() => {
    document.title = "Reset Password | The Lucky Pet";
  }, []);

  const { onChange, values } = useForm(requestSellerPasswordResetCallback, {
    email: "",
    passwordResetCode: resetCode ? resetCode : "",
    newPassword: "",
    siteId: 1,
  });

  const [isResetCodeEmailSent, setIsResetCodeEmailSent] = useState(
    resetCode ? true : false
  );

  const [sendBrandContactResetPasswordCode, { loading }] = useMutation(
    SEND_PASSWORD_RESET_CODE_SELLER,
    {
      update(_, { data: { sendBrandContactResetPasswordCode } }) {
        setErrors({});
        setIsResetCodeEmailSent(true);
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    }
  );

  const [resetBrandContactPassword, { loading: loadingResetPassword }] =
    useMutation(RESET_SELLER_PASSWORD_MUTATION, {
      update(_, { data: { resetBrandContactPassword: customerData } }) {
        loginBrandContactContext(customerData);
        navigate("/business/dashboard");
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    });

  function requestSellerPasswordResetCallback(e) {
    e.preventDefault();
    sendBrandContactResetPasswordCode();
  }

  function resetSellerPasswordCallback(e) {
    e.preventDefault();
    resetBrandContactPassword();
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-orange-500 ">
      <div className="relative z-10 short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl mx-auto xl:px-0 px-4 pb-10 lg:pb-0">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Forgot"
          isSeller={true}
        />

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Reset your password
          </p>

          <p className="text-gray-500 w-full mt-2 flex-shrink-0 leading-tight">
            {isResetCodeEmailSent
              ? "Password reset code sent. Please enter the code below."
              : "Enter the email address associated with your seller account and we'll send you a link to reset your password."}
          </p>

          {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            <TextInputField
              title="Email"
              name="email"
              type="email"
              disabled={loading || loadingResetPassword}
              error={errors.email}
              onChange={onChange}
              value={values.email}
            />
            {isResetCodeEmailSent && (
              <>
                <TextInputField
                  title="Reset code"
                  name="passwordResetCode"
                  placeholder={"______"}
                  disabled={loading || loadingResetPassword}
                  error={errors.passwordResetCode}
                  onChange={onChange}
                  value={values.passwordResetCode}
                />
                <TextInputField
                  title="New password"
                  name="newPassword"
                  type="password"
                  disabled={loading || loadingResetPassword}
                  error={errors.newPassword}
                  onChange={onChange}
                  value={values.newPassword}
                />
              </>
            )}
          </div>

          <SubmitButton
            title={!isResetCodeEmailSent ? "Generate Code" : "Confirm"}
            disabled={loading || loadingResetPassword}
            onClickFunc={(e) => {
              if (isResetCodeEmailSent) {
                resetSellerPasswordCallback(e);
              } else {
                requestSellerPasswordResetCallback(e);
              }
            }}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            <button
              onClick={(e) => {
                navigate("/loginSeller");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Return to sign in
            </button>
          </p>
        </div>

        <p className="text-gray-500 transition-all short:px-3 px-6 font-semibold mb-10 short:mb-4 text-center text-sm sm:text-base">
          <button
            className="hover:text-gray-700 font-semibold transition-all truncate"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            © The Lucky Pet
          </button>{" "}
          <span className="text-gray-500 mx-1 sm:mx-2"> - </span>
          <a
            className="hover:text-gray-700 font-semibold transition-all truncate"
            href={"mailto:info@thelucky.pet"}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
          <span className="text-gray-500 mx-1 sm:mx-2"> - </span>
          <button
            className="hover:text-gray-700 font-semibold transition-all truncate"
            onClick={(e) => {
              e.preventDefault();
              navigate("/terms");
            }}
          >
            Privacy &amp; terms
          </button>
        </p>
      </div>
    </div>
  );
}

export default ResetSellerPassword;
