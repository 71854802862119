import React, { useEffect, useState } from "react";
import { getRandomInt, useForm } from "../../util/hooks";

import { CardElement } from "@stripe/react-stripe-js";

import { useMutation } from "@apollo/client";

import CardInputField from "./CardInputField";

import { customerClient } from "../../GraphqlApolloClients";

import {
  BUY_PET_COIN_BUNDLE_MUTATION,
  FETCH_PET,
  PROCESS_BUY_PET_COIN_BUNDLE_PAYMENT_RESULT,
} from "../../util/gqlFunctions";

import SubmitButton from "./SubmitButton";

const powerupPurchaseConfirmationMessages = [
  "Excellent choice!",
  "Wise move!",
  "Now go get em!",
  "Nice choice!",
  "Solid strategy!",
];

const numOfCoinsToCost = {
  100: "1.19",
  500: "5.79",
  1000: "10.99",
  2000: "19.99",
  5000: "45.99",
};
function BuyCoinsForm({
  stripe,
  elements,
  targetCustomer,
  toggleCoinPurchaseSuccessfulForm,
  toggleBuyCoinsForm,

  isHigher,
  puzzleId,
}) {
  const [errors, setErrors] = useState({});
  const [reenforcementInd, setReenforcementInd] = useState(
    getRandomInt(powerupPurchaseConfirmationMessages.length)
  );
  useEffect(() => {
    if (reenforcementInd === null) {
      setReenforcementInd(
        getRandomInt(powerupPurchaseConfirmationMessages.length)
      );
    }
  }, [reenforcementInd]);

  const { values, setValues } = useForm(buyPetCoinBundleCallback, {
    puzzleId,
    useNewCard: true,
    numOfCoins: 500,
  });

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [cardElemIsFocused, setCardElemIsFocused] = useState(false);

  const [processBuyPetCoinBundlePaymentResult] = useMutation(
    PROCESS_BUY_PET_COIN_BUNDLE_PAYMENT_RESULT,
    {
      refetchQueries: [{ query: FETCH_PET }],

      update(
        proxy,
        {
          data: {
            processBuyPetCoinBundlePaymentResult: coinBundlePurchaseComplete,
          },
        }
      ) {
        setLoadingBuyPetCoinBundle(false);

        if (coinBundlePurchaseComplete.status === 1) {
          // toggleBuyCoinsForm(null);
          toggleCoinPurchaseSuccessfulForm();
          setErrors({});
        }
      },
      onError(err) {
        console.log(err);
        setLoadingBuyPetCoinBundle(false);

        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      client: customerClient,
    }
  );

  const [buyPetCoinBundle] = useMutation(BUY_PET_COIN_BUNDLE_MUTATION, {
    async update(_, { data: { buyPetCoinBundle: coinBundlePurchase } }) {
      if (coinBundlePurchase.paymentIntentClientSecret) {
        const result = await stripe.confirmCardPayment(
          coinBundlePurchase.paymentIntentClientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          }
        );

        if (result.error) {
          // Show error to your pet (e.g., insufficient funds)
          setErrors({
            ...errors,
            paymentError: result.error.message,
          });
          setLoadingBuyPetCoinBundle(false);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            processBuyPetCoinBundlePaymentResult({
              variables: {
                petCoinBundlePurchaseId: coinBundlePurchase.id,
              },
            });
          } else {
            setErrors({
              ...errors,
              paymentError: "- Invalid",
            });
            setLoadingBuyPetCoinBundle(false);
          }
        }
      } else {
        processBuyPetCoinBundlePaymentResult({
          variables: {
            petCoinBundlePurchaseId: coinBundlePurchase.id,
          },
        });
      }
    },
    onError(err) {
      setLoadingBuyPetCoinBundle(false);

      if (err.graphQLErrors[0]) {
        console.log(err.graphQLErrors[0].extensions.errors);

        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: customerClient,
  });

  const [loadingBuyPetCoinBundle, setLoadingBuyPetCoinBundle] = useState(false);

  function buyPetCoinBundleCallback(e) {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoadingBuyPetCoinBundle(false);

      return;
    }

    if (
      targetCustomer &&
      !isCardComplete &&
      numOfCoinsToCost[values.numOfCoins] > 0
    ) {
      setErrors({
        paymentError: isCardComplete ? null : "- Required",
        ...errors,
      });
      setLoadingBuyPetCoinBundle(false);

      return;
    }

    setLoadingBuyPetCoinBundle(true);

    buyPetCoinBundle();
  }

  return (
    <div
      className={`fixed ${
        isHigher ? "pt-12" : "pt-14"
      } xshort:pt-12 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          toggleBuyCoinsForm(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={loadingBuyPetCoinBundle}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden h-full sm:h-auto max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl  short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            toggleBuyCoinsForm(null);
          }}
          disabled={loadingBuyPetCoinBundle}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-center text-2xl font-bold w-full short:text-xl short:leading-tight">
          Tokens
        </p>
        <p className="text-gray-500 w-full text-center">
          To help you learn better and battle bigger.
        </p>

        <div className="sm:grid sm:grid-cols-2 flex flex-col w-full pb-1 sm:px-4 mt-2 items-center justify-start gap-2 ">
          {[1, 100, 500, 1000, 2000, 5000].map((numOfCoins, index) => (
            <button
              key={numOfCoins}
              onClick={(e) => {
                e.preventDefault();
                setValues({ ...values, numOfCoins: numOfCoins });
              }}
              className={`${
                values.numOfCoins === numOfCoins
                  ? "bg-gray-50 border-gray-700 "
                  : "border-gray-300 hover:border-gray-400 hover:bg-gray-50"
              } w-full group shrink-0 shadow-sm rounded-lg px-2 pr-3 sm:pr-4 sm:px-3 sm:py-1 border transition-all flex items-center justify-center space-x-2 focus:border-indigo-500 focus:ring-indigo-500`}
            >
              <svg
                className="h-6 sm:h-8 my-2"
                viewBox="0 0 800 800"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 800C608.641 800 777.778 630.863 777.778 422.222C777.778 213.581 608.641 44.4443 400 44.4443C191.36 44.4443 22.2227 213.581 22.2227 422.222C22.2227 630.863 191.36 800 400 800Z"
                  fill="#F4900C"
                />
                <path
                  d="M400 755.556C608.641 755.556 777.778 586.419 777.778 377.778C777.778 169.137 608.641 0 400 0C191.36 0 22.2227 169.137 22.2227 377.778C22.2227 586.419 191.36 755.556 400 755.556Z"
                  fill="#FFCC4D"
                />
                <path
                  d="M400 711.111C571.822 711.111 711.111 571.822 711.111 400C711.111 228.178 571.822 88.8887 400 88.8887C228.178 88.8887 88.8887 228.178 88.8887 400C88.8887 571.822 228.178 711.111 400 711.111Z"
                  fill="#FFE8B6"
                />
                <path
                  d="M400 688.889C571.822 688.889 711.111 549.6 711.111 377.778C711.111 205.956 571.822 66.667 400 66.667C228.178 66.667 88.8887 205.956 88.8887 377.778C88.8887 549.6 228.178 688.889 400 688.889Z"
                  fill="#FFAC33"
                />
                <path
                  d="M399.02 148C338.02 148 279.623 172.232 236.674 215.365C193.725 258.499 169.742 317 170.002 378C170.262 438.998 194.745 497.502 238.059 540.635C281.376 583.767 339.982 608 400.98 608C372.86 608 345.334 599.66 321.885 584.038C298.439 568.415 280.12 546.208 269.249 520.228C258.375 494.249 255.439 465.659 260.807 438.079C266.176 410.498 279.61 385.165 299.408 365.28C319.209 345.396 344.486 331.854 372.042 326.368C399.6 320.882 428.2 323.698 454.225 334.459C480.251 345.221 502.532 363.444 518.256 386.826C533.98 410.206 542.435 437.698 542.553 465.819C542.714 503.527 527.89 539.691 501.338 566.355C474.789 593.019 438.69 608 400.98 608C461.98 608 520.377 583.767 563.326 540.635C606.275 497.502 630.258 438.998 629.998 378C629.738 317 605.255 258.499 561.941 215.365C518.624 172.232 460.021 148 399.02 148Z"
                  fill="#FFD983"
                />
              </svg>
              <p className="font-semibold flex-1 text-left leading-tight sm:leading-tight sm:text-lg text-base">
                {numOfCoins} {index === 0 ? "token" : "tokens"}
              </p>

              <svg
                className="w-4 aspect-square transition-all"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  className={`${
                    values.numOfCoins === numOfCoins
                      ? "text-gray-700 "
                      : "text-gray-300"
                  } fill-current`}
                  d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
                />
                <path
                  className={`${
                    values.numOfCoins === numOfCoins
                      ? "opacity-100 text-gray-700"
                      : "opacity-0 text-gray-300"
                  } fill-current`}
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5V5Z"
                />
              </svg>
            </button>
          ))}

          <div className="col-span-2 font-semibold text-xl flex justify-between text-black px-4 w-full space-x-2 items-center">
            <p className="">Total</p>
            <p className="">
              {values.numOfCoins === 1
                ? "FREE"
                : `US$${numOfCoinsToCost[values.numOfCoins]}`}
            </p>
          </div>
        </div>

        {targetCustomer && numOfCoinsToCost[values.numOfCoins] > 0 && (
          <div className="w-full mb-6 sm:px-4">
            {/* <label
              htmlFor="paymentInfo"
              className=" sm:text-base text-sm font-semibold text-gray-700"
            >
              Payment info{" "}
              {errors && errors.paymentError && (
                <span className="text-red-500">{errors.paymentError}</span>
              )}
            </label> */}

            <CardInputField
              cardElemIsFocused={cardElemIsFocused}
              setCardElemIsFocused={setCardElemIsFocused}
              error={errors && errors.paymentError}
              setIsCardComplete={setIsCardComplete}
              disabled={false}
              title="Payment Info"
            />
          </div>
        )}
        {errors && (errors.paymentError || errors.numOfCoins) && (
          <span className="text-red-500 text-center w-full font-semibold">
            {(errors.paymentError || errors.numOfCoins).replace("-", "")}
          </span>
        )}
        <div className="flex-1"></div>
        <SubmitButton
          onClickFunc={buyPetCoinBundleCallback}
          disabled={loadingBuyPetCoinBundle}
          title={values.numOfCoins === 1 ? "Watch Ad" : "Confirm"}
          errors={errors}
        />
      </form>
    </div>
  );
}

export default BuyCoinsForm;
