import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

function PuzzlePiece({
  piece,
  onMove,
  isSelected,
  onClick,
  isSolved,
  puzzleImage,
  disabled,
  index,
}) {
  const dragPreviewRef = useRef(null);
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: disabled ? "" : "PUZZLE_PIECE",
    item: { id: piece.id, ...piece },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: disabled ? "" : "PUZZLE_PIECE",
    drop: (draggedItem) => {
      onMove(draggedItem.id, piece.id);
    },
  });

  const handleClick = (e) => {
    e.preventDefault();

    onClick(piece.id);
  };
  useEffect(() => {
    if (previewRef && dragPreviewRef.current) {
      // Calculate the offset to center the preview
      const previewElement = dragPreviewRef.current;
      const rect = previewElement.getBoundingClientRect();

      previewRef(previewElement, {
        offsetX: rect.width / 2, // Half the width
        offsetY: rect.height / 2, // Half the height
      });
    }
  }, [previewRef]);

  const [shouldScaleUp, setShouldScaleUp] = useState(false);

  useEffect(() => {
    let timeout;
    if (isSolved && !shouldScaleUp) {
      timeout = setTimeout(() => setShouldScaleUp(true), 200 + 50 * index);
    } else if (!isSolved && shouldScaleUp) {
      setShouldScaleUp(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSolved, shouldScaleUp, index]);

  return (
    <>
      <div
        ref={(node) => dragRef(dropRef(node))}
        onClick={handleClick}
        className={`${
          (isSelected || isDragging) && !isSolved
            ? "ring-4 ring-yellow-400 ring-inset z-10"
            : ""
        } ${isSolved ? "" : " cursor-pointer"} transition-all`}
        style={{
          width: piece.width,
          height: piece.height,
          top: piece.top,
          left: piece.left,
          transform: `rotate(${piece.rotation}deg) scaleX(${
            piece.flipX
          }) scaleY(${piece.flipY}) scaleX(${
            shouldScaleUp ? 1 : 0.96
          }) scaleY(${shouldScaleUp ? 1 : 0.972})`,
          backgroundImage: `url(${puzzleImage})`,
          backgroundPosition: `-${piece.ogLeft}px -${piece.ogTop}px`,
          backgroundSize: `${piece.totalWidth}px ${piece.totalHeight}px`,
          opacity: isDragging ? 0.5 : 1,
          position: "absolute",
        }}
      ></div>

      <div
        ref={dragPreviewRef}
        className={`-top-20 -left-20 select-none w-10 rounded-full aspect-square bg-yellow-300 ring-inset ring-2 shadow ring-white transition-all z-50 absolute origin-center`}
      ></div>
    </>
  );
}

export default PuzzlePiece;
