import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";
import TextInputField from "../../components/creator/TextInputField";

import { useMutation } from "@apollo/client";
import BlueFlatGradBack from "../../components/creator/BlueFlatGradBack";
import LoginFooter from "../../components/creator/LoginFooter";
import SubmitButton from "../../components/creator/SubmitButton";
import TopBar from "../../components/creator/TopBar";
import { AdminAuthContext } from "../../context/adminAuth";
import { LOGIN_ADMIN } from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function LoginAdmin(props) {
  usePageTracking();

  let navigate = useNavigate();
  useEffect(() => {
    document.title = "Admin Login | The Lucky Pet";
  }, []);

  const [errors, setErrors] = useState({});

  const { admin, loginAdmin: loginAdminContext } = useContext(AdminAuthContext);

  useEffect(() => {
    if (admin) {
      navigate("/adminDashboard");
    }
  }, [admin, navigate]);

  const { onChange, values } = useForm(loginAdminCallback, {
    email: "",
    password: "",
  });

  const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN, {
    update(_, { data: { loginAdmin: adminData } }) {
      loginAdminContext(adminData);
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
  });

  function loginAdminCallback(e) {
    e.preventDefault();
    loginAdmin();
  }

  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-gray-50">
      <BlueFlatGradBack gradIndex={1} />
      <div
        className={`short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl grow-0 relative h-full z-10 flex flex-col items-center justify-start mx-auto xl:px-0 px-4 pb-4 lg:pb-0 min-h-screen`}
      >
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Admin Dash"
        />
        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Sign in to admin account
          </p>
          {/* <p className="text-gray-500 w-full "></p> */}

          {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
          <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6 mb-8">
            <TextInputField
              title="Email"
              name="email"
              type="email"
              error={errors.email}
              disabled={loading}
              onChange={onChange}
              value={values.email}
            />
            <TextInputField
              title="Password"
              name="password"
              type="password"
              error={errors.password}
              disabled={loading}
              onChange={onChange}
              value={values.password}
              navigate={navigate}
            />
          </div>

          <SubmitButton
            onClickFunc={loginAdminCallback}
            disabled={loading}
            title={"Continue"}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            Not an admin?{" "}
            <button
              onClick={(e) => {
                navigate("/login");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              User login
            </button>
          </p>
        </div>
        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default LoginAdmin;
