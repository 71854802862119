import { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { AdminAuthContext } from "../context/adminAuth";
import { BrandContactAuthContext } from "../context/brandContactAuth";

import { CustomerAuthContext } from "../context/customerAuth";

const usePageTracking = () => {
  const location = useLocation();
  const { customer } = useContext(CustomerAuthContext);

  const { brandContact } = useContext(BrandContactAuthContext);
  const { admin } = useContext(AdminAuthContext);

  useEffect(() => {
    if (
      (!admin && customer && !["1146747"].includes(customer.id)) ||
      (!admin && !brandContact)
    ) {
      ReactGA.initialize("G-DL4WK0GFSP");

      window.scrollTo(0, 0);

      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location, customer, admin, brandContact]);
};

export default usePageTracking;
