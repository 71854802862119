import React from "react";
import DownloadAppPopup from "../../components/creator/DownloadAppPopup";

export default function DownloadAppRedirect() {
  // const OS = getMobileOperatingSystem();
  // useEffect(() => {
  //   if (OS === "Android" || OS === "iOS") {
  //     const appStoreLink =
  //       OS === "iOS"
  //         ? "https://apps.apple.com/us/app/theluckypet/id6476206206"
  //         : "https://play.google.com/store/apps/details?id=pet.thelucky";

  //     window.location.href = appStoreLink;
  //   }
  // }, [OS]);

  return (
    <div
      className={`h-full bg-light-blue relative w-screen overflow-hidden min-h-screen`}
    >
      <DownloadAppPopup
        toggleDownloadAppPopup={() => {}}
        // title="Download the App"
        title={"App coming soon..."}
      />
    </div>
  );
}
