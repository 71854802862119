import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import {
  CREATE_FORUM_POST_MUTATION,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_PUZZLE_VIEW_BY_CUSTOMER,
  FETCH_RECOMMENDED_FORUM_POSTS,
  FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY,
} from "../../util/gqlFunctions";
import { defaultPicUrl, useForm } from "../../util/hooks";

import JustModalComments from "./JustModalComments";

function CommentsModal({
  navigate,

  setShouldShowCommentSection,
  puzzleId,
  puzzleViewId,
  targetCustomer,
  targetGuestCustomer,
  puzzle,
  shouldShowCommentSection,
  country,
}) {
  const [errors, setErrors] = useState({});
  const [lastAnswerResult, setLastAnswerResult] = useState(-1);

  const { data: { getPuzzleViewByCustomer: puzzleView } = {} } = useQuery(
    FETCH_PUZZLE_VIEW_BY_CUSTOMER,
    {
      client: targetCustomer ? customerClient : guestClient,
      variables: { puzzleId },
    }
  );

  const {
    data: { getRecommendedForumPostsBySearch: comments } = {},
    // refetch: refetchRecommendedForumPosts,
    fetchMore: fetchMoreFPosts,

    loading: loadingRecommendedForumPosts,
  } = useQuery(FETCH_RECOMMENDED_FORUM_POSTS, {
    variables: {
      forumId: null,
      puzzleId,
      puzzleViewId: puzzleView ? puzzleView.id : null,
      seenIds: [],
      folders: [],
      forumPostRepliedToId: null,
      searchTerm: null,
      type: 0,
      pageSize: 6,
    },
    fetchPolicy: "cache-and-network",

    client: targetCustomer ? customerClient : guestClient,
  });

  const { values, setValues } = useForm(createCommentCallback, {
    puzzleId: puzzle?.id,
    puzzleViewId: puzzleView ? puzzleView.id : null,
    details: "",
    forumPostRepliedToId: null,
    repliedToUsername: "",
    pageSize: 10,
    seenIds: [],
    type: 0,

    title: "",
    mediaFiles: [],
    folders: [],
    visibility: 2,
  });
  const [sentMessages, setSentMessages] = useState([]);
  const resetInterval = 1000;
  const [isMsgError, setIsMsgError] = useState(false);
  useEffect(() => {
    let timeout;
    if (isMsgError && resetInterval) {
      timeout = setTimeout(() => setIsMsgError(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isMsgError, resetInterval]);
  const [headCommentToNewReplies, setHeadCommentToNewReplies] = useState({});
  const [newComments, setNewComments] = useState([]);

  const [createForumPost, { loading: loadingCreateForumPost }] = useMutation(
    CREATE_FORUM_POST_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createForumPost: createdForumPost } }) {
        setValues({
          ...values,
          details: "",
          msg: "",
          forumPostRepliedToId: null,
          repliedToDisplayName: null,
          type: 0,
          searchTerm: null,
        });

        if (createdForumPost.type === 0) {
          setNewComments([createdForumPost, ...newComments]);
        } else {
          setHeadCommentToNewReplies({
            ...headCommentToNewReplies,
            [createdForumPost.headForumPostId]:
              headCommentToNewReplies[createdForumPost.headForumPostId] &&
              headCommentToNewReplies[createdForumPost.headForumPostId].length >
                0
                ? [
                    ...headCommentToNewReplies[
                      createdForumPost.headForumPostId
                    ],
                    createdForumPost,
                  ]
                : [createdForumPost],
          });
        }

        setErrors({});
      },
      onError(err) {
        setIsMsgError(true);

        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          // if (!targetCustomer) {
          //   navigate("/register");
          // }

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );

  const {
    data: { getSuggestedMessagesByEvent: suggestedMessages } = {},
    refetch: refetchSuggestedMessages,
    // loading: loadingSuggestedMessages,
  } = useQuery(FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY, {
    variables: {
      puzzleId: null,
      puzzleViewId: puzzleView ? puzzleView.id : null,
      seenIds: [],
      pageSize: 10,
    },
    skip:
      !puzzleView || puzzleView.answerResult === -1 || puzzleView.status !== 1
        ? true
        : false,
    client: targetCustomer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (
      puzzleView &&
      (values.puzzleViewId !== puzzleView.id ||
        values.puzzleId !== puzzleView.puzzleId)
    ) {
      setValues({
        ...values,
        puzzleViewId: puzzleView.id,
        puzzleId: puzzleView.puzzleId,
      });
    }
  }, [puzzleView, setValues, values]);

  useEffect(() => {
    if (
      puzzleView &&
      puzzleView.status === 1 &&
      puzzleView.answerResult !== lastAnswerResult
    ) {
      refetchSuggestedMessages({
        puzzleId: null,
        puzzleViewId: puzzleView.id,
        seenIds: [],
        pageSize: 10,
      });

      setSentMessages([]);
      setLastAnswerResult(puzzleView.answerResult);
    }
  }, [lastAnswerResult, puzzleView, refetchSuggestedMessages]);

  function createCommentCallback(e) {
    e.preventDefault();

    createForumPost();
  }

  return (
    <div
      className={`fixed pt-32 xshort:pt-12 sm:py-10 z-[70] overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();

          setShouldShowCommentSection(false);
        }}
        className="z-40 bg-gray-500 hide-button-flash bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden  text-center sm:max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto h-full w-full max-w-md overflow-y-auto flex flex-col items-center justify-start z-40 rounded-xl short:px-4 short:pb-4 px-4 pb-6 sm:p-4 sm:pb-4 bg-white shadow-xl rounded-b-none sm:rounded-3xl `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();

            setShouldShowCommentSection(false);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setValues({
              ...values,
              commentRepliedToId: null,
              repliedToUsername: null,
              // message:
              //   "@" + comment.commentorUsername + ": " + values.message,
            });
          }}
          className="flex items-center justify-center pb-4 short:pb-2 sm:pt-4 space-x-4 px-2 w-full short:space-x-2"
        >
          <div className="flex leading-tight items-center justify-start text-gray-700 w-2/5">
            <svg
              className="h-5 fill-current text-gray-800"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10V1C15 0.45 14.55 0 14 0ZM13 2V9H3.17L2.58 9.59L2 10.17V2H13ZM17 4H19C19.55 4 20 4.45 20 5V20L16 16H5C4.45 16 4 15.55 4 15V13H17V4Z"
              />
            </svg>

            <p className="ml-2 text-sm">
              {comments && comments.length > 0 ? comments.length : ""}
            </p>
          </div>

          <button
            onClick={(e) => {
              navigate("/topic/" + puzzle?.topicShortForm);
            }}
            className="rounded-xl hover:opacity-80 transition-all bg-blue-200 h-10 aspect-square"
          >
            <img
              src={puzzle?.topicLogoUrl || defaultPicUrl}
              className="h-10 aspect-square rounded-md mr-2 object-cover"
              alt={puzzle?.topicName}
            />
          </button>
          <div className="flex leading-tight items-center justify-end text-gray-700 w-2/5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 fill-current text-gray-800"
              viewBox="0 0 24 24"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z" />
            </svg>
            <p className="ml-2 text-sm">{puzzle?.interestingFact ? 1 : ""}</p>
          </div>
        </div>

        <JustModalComments
          navigate={navigate}
          country={country}
          comments={comments}
          values={values}
          fetchMoreParams={{
            forumId: null,
            puzzleId: null,
            puzzleViewId: puzzleView ? puzzleView.id : null,
            seenIds: [],
            folders: [],
            forumPostRepliedToId: null,
            searchTerm: null,
            type: 0,
            pageSize: 6,
          }}
          fetchMoreComments={fetchMoreFPosts}
          setValues={setValues}
          newComments={newComments}
          headCommentToNewReplies={headCommentToNewReplies}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          puzzleId={puzzleId}
          loadingComments={loadingRecommendedForumPosts}
        />
        {(targetCustomer || targetGuestCustomer) && (
          <div
            className={` ${
              puzzleView.status === 1 ? "h-9 short:h-8" : "h-0 sm:h-9 short:h-8"
            } relative shrink-0 overflow-hidden transition-all flex w-full mt-2 items-center space-x-2`}
          >
            <div className="w-full flex gap-2 snap always-stop snap-y snap-mandatory overflow-y-scroll scrollbar-hide flex-wrap grow items-center justify-start overflow-x-auto h-full short:text-sm ">
              {puzzleView &&
                puzzleView.status === 1 &&
                lastAnswerResult === puzzleView.answerResult &&
                suggestedMessages &&
                suggestedMessages.length > 0 &&
                suggestedMessages
                  .filter((msg) => !sentMessages.includes(msg))
                  .map((msg, index) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setValues({ ...values, details: values.details + msg });
                      }}
                      key={msg}
                      className="bg-gray-100 leading-tight h-full hover:bg-opacity-50 transition-all rounded-lg px-2 py-1"
                    >
                      {msg}
                    </button>
                  ))}
            </div>
          </div>
        )}
        <div
          className={`${
            isMsgError
              ? "border-red-600 motion-safe:animate-shake"
              : "border-gray-400 "
          } mt-2 rounded-xl text-gray-600 duration-700 transition-height pl-4 short:pl-3 flex items-start justify-start border w-full`}
        >
          {isMsgError && errors.message && (
            <p className="absolute text-red-600 bottom-2 font-semibold">
              {errors.message}
            </p>
          )}
          <textarea
            name={"message"}
            id={"message"}
            rows="1"
            value={values.details}
            onChange={(e) => {
              e.preventDefault();

              var newMsg = e.target.value;
              setValues({
                ...values,
                details: newMsg,
              });
            }}
            className={`${
              isMsgError ? "" : ""
            } text-lg 2xl:text-2xl short:leading-tight py-4 short:py-2 short:text-base leading-tight w-full flex-1 bg-transparent placeholder:text-gray-600 focus:outline-none`}
            placeholder={
              values.repliedToDisplayName
                ? "Replying to " + values.repliedToDisplayName
                : "Add a comment..."
            }
            disabled={loadingCreateForumPost || !shouldShowCommentSection}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === "Enter" && e.shiftKey === false) {
                e.preventDefault();
                createForumPost();
              }
            }}
            maxLength={200}
          ></textarea>
          <button
            onClick={createCommentCallback}
            className={`${
              isMsgError ? "text-red-600" : "text-black"
            } p-4 short:p-3 disabled:text-gray-300 sm:hover:opacity-50 transition-all`}
            disabled={loadingCreateForumPost}
          >
            <svg
              className="w-6 2xl:w-7 short:w-5 fill-current"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}

export default CommentsModal;
