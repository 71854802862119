import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { customerClient } from "../../GraphqlApolloClients";
import { FETCH_RECOMMENDED_PET_ACTIVITIES } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";

export default function SelectActivityWSearch({
  name,
  values,
  defaultActivity,

  setValues,

  options,
  error,
  loading,

  title,

  value,
  disabled,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [tempSearchTerm, setTempSearchTerm] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const {
    data: { getRecommendedPetActivitiesBySearch: activities } = {},
    loading: loadingActivities,
  } = useQuery(FETCH_RECOMMENDED_PET_ACTIVITIES, {
    variables: { searchTerm: searchTerm ?? "", pageSize: 10, seenIds: [] },
    client: customerClient,
    onError(err) {
      console.log("getRecommendedPetActivitiesBySearch() Error!");
      console.log(err);
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchTerm(tempSearchTerm);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [tempSearchTerm, searchTerm, setSearchTerm]);

  const [checkedDefActivity, setCheckedDefActivity] = useState(false);

  useEffect(() => {
    if (tempSearchTerm === null && defaultActivity) {
      setTempSearchTerm(defaultActivity.name);
      setCheckedDefActivity(true);
    }
  }, [tempSearchTerm, checkedDefActivity, isInputFocused, defaultActivity]);

  return (
    <div className="w-full mb-4 group relative">
      <div
        className={`${
          disabled ? "bg-gray-200" : "bg-white"
        } w-full h-12 py-2 px-2 rounded group-focus-within:rounded-b-none shadow-sm flex items-center justify-start space-x-3`}
      >
        {loadingActivities ? (
          <div className="rounded-full bg-black shrink-0 aspect-square flex items-center justify-center p-2">
            <svg
              className={`w-4 m-auto stroke-current text-white motion-safe:animate-spin`}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.636 3.636A9 9 0 0119 10"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
          </div>
        ) : values.activityLogoUrl ? (
          <img
            src={values.activityLogoUrl || defaultPicUrl}
            className="object-cover rounded shrink-0 h-8 aspect-square"
            alt={values.activityName || "Stashactivity"}
          />
        ) : (
          <div className="rounded-full bg-black shrink-0  h-8 aspect-square flex items-center justify-center p-2">
            <svg
              className="w-4 text-white fill-current"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5508 8.83346C16.5508 12.6075 13.4913 15.6669 9.71731 15.6669C5.9433 15.6669 2.88385 12.6075 2.88385 8.83346C2.88385 5.05945 5.9433 2 9.71731 2C13.4913 2 16.5508 5.05945 16.5508 8.83346ZM14.5529 16.2271C13.1637 17.1375 11.5023 17.6669 9.71731 17.6669C4.83873 17.6669 0.88385 13.7121 0.88385 8.83346C0.88385 3.95488 4.83873 0 9.71731 0C14.5959 0 18.5508 3.95488 18.5508 8.83346C18.5508 11.204 17.617 13.3564 16.0973 14.943L21.484 20.3297L20.0697 21.7439L14.5529 16.2271Z"
              />
            </svg>
          </div>
        )}

        <input
          className={`${
            disabled ? "bg-gray-200" : "bg-white"
          } text-xl sm:text-2xl w-full focus:outline-none`}
          onChange={(e) => {
            setTempSearchTerm(e.target.value);
            if (values.activityName && values.activityName !== e.target.value) {
              setValues({
                ...values,
                petActivityId: null,
                activityLogoUrl: null,
                activityName: "",
              });
            }
          }}
          onFocus={(e) => {
            setIsInputFocused(true);
          }}
          disabled={disabled}
          value={tempSearchTerm || ""}
        />
      </div>
      {activities && activities.length > 0 && isInputFocused && !disabled && (
        <div className="w-full group-focus-within:block hidden top-full absolute bg-gray-50 overflow-hidden z-10 shadow-xl inset-x-0 rounded-b max-h-48">
          {activities.map((activity, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setTempSearchTerm(activity.name);
                setIsInputFocused(false);

                setValues({
                  ...values,
                  petActivityId: activity.id,
                  activityLogoUrl: activity.logoUrl,
                  activityName: activity.name,
                });
              }}
              className={`${
                values.petActivityId === activity.id
                  ? "bg-purple-500 text-white"
                  : ""
              } flex items-center hover:bg-purple-800 hover:text-white w-full transition-all justify-start p-2 space-x-3`}
            >
              <div className="rounded-full aspect-square shrink-0 bg-black flex items-center justify-center ">
                <img
                  src={activity.logoUrl}
                  className="object-cover rounded shrink-0 h-8 aspect-square"
                  alt={activity.name}
                />
              </div>
              <p className="text-xl text-left truncate">{activity.name}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
