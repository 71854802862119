import React, { useContext, useEffect, useState } from "react";
import lucky from "../../images/lucky.png";
import usePageTracking from "../../util/usePageTracking";

import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
// import  BlueFullGradBack from "../../components/creator/BlueFullGradBack";
import LoginFooter from "../../components/creator/LoginFooter";
import SubmitButton from "../../components/creator/SubmitButton";
import TextInputField from "../../components/creator/TextInputField";
import TopBar from "../../components/creator/TopBar";
import { BrandContactAuthContext } from "../../context/brandContactAuth";
import { REGISTER_BRAND_CONTACT } from "../../util/gqlFunctions";
import { useForm } from "../../util/hooks";

function RegisterSeller(props) {
  usePageTracking();

  const { brandContact, loginBrandContact } = useContext(
    BrandContactAuthContext
  );

  let navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const { joinedFor, prevPath } = useParams();

  useEffect(() => {
    if (brandContact) {
      navigate("/business/dashboard");
    }
  }, [brandContact, navigate, joinedFor]);

  useEffect(() => {
    document.title = "Sign Up | The Lucky Pet";
  }, []);

  const { onChange, values } = useForm(signupBrandContactFunc, {
    email: "",
    name: "",

    password: "",

    businessName: "",
    businessWeb: "",

    country: "",

    gender: null,
    siteId: 1,
  });

  //creating function to load ip address from the API
  const [registerBrandContact, { loading }] = useMutation(
    REGISTER_BRAND_CONTACT,
    {
      update(_, { data: { registerBrandContact: brandContactData } }) {
        setErrors({});

        if (brandContactData.token) {
          loginBrandContact(brandContactData);
        }
      },
      onError(err) {
        if (err.graphQLErrors[0]) {
          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
    }
  );

  function signupBrandContactFunc(e) {
    e.preventDefault();
    registerBrandContact();
  }
  return (
    <div className="w-screen overflow-x-hidden h-full min-h-screen bg-orange-500">
      <img
        src={lucky}
        alt="Lucky the Kitten"
        className="shrink-0 sm:w-auto rotate-3 w-52 absolute hidden sm:block bottom-0 inset-auto"
      />
      <div
        className={`short:max-w-xl lg:max-w-xl 2xl:max-w-2xl 3xl:max-w-3xl grow-0 relative h-full z-10 flex flex-col items-center justify-start mx-auto xl:px-0 px-4 pb-4 lg:pb-0 min-h-screen`}
      >
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          page="Signup"
          prevPath={prevPath}
          isSeller={true}
        />

        <div className="w-full pt-5 px-5 pb-3 sm:pt-10 sm:px-10 sm:pb-6 mb-6 sm:mb-10 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl shadow-2xl">
          <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
            Create a business account
          </p>
          {/* {targetGuestCustomer && targetGuestCustomer.balance ? (
            <p className="text-gray-500 w-full short:text-sm short:leading-tight leading-tight">
              All your stats from guest mode, including your{" "}
              <span className=" font-semibold">
                {targetGuestCustomer.balance} tokens
              </span>
              , will be transferred to your official account.
              {/* Enjoy 100 extra
              tokens as well! 
            </p>
          ) : (
            <></>
          )} */}

          {/* <p className="text-gray-500 w-full short:text-sm short:leading-tight leading-tight">
            Already have one? Sign in
          </p> */}

          <div className="w-full flex flex-col items-start justify-start space-y-6 my-4 short:mb-10 xshort:mb-6 sm:my-6">
            <TextInputField
              title="Your name"
              name="name"
              error={errors.name}
              disabled={loading}
              onChange={onChange}
              value={values.name}
            />
            <TextInputField
              title="Email"
              name="email"
              type="email"
              error={errors.email}
              disabled={loading}
              onChange={onChange}
              value={values.email}
            />

            <TextInputField
              title="Password"
              name="password"
              type="password"
              error={errors.password}
              disabled={loading}
              onChange={onChange}
              value={values.password}
            />
            <TextInputField
              title="Business name"
              name="businessName"
              error={errors.businessName}
              disabled={loading}
              onChange={onChange}
              value={values.businessName}
            />

            <TextInputField
              title="Business website (optional)"
              name="businessWeb"
              type={"url"}
              error={errors.businessWeb}
              disabled={loading}
              onChange={onChange}
              value={values.businessWeb}
              prefix="https://"
            />
          </div>

          <SubmitButton
            onClickFunc={async (e) => {
              e.preventDefault();
              registerBrandContact({
                variables: {
                  ...values,
                },
              });
            }}
            disabled={loading}
            // disabled={loading || !values.verificationCode}
            isNotLoading={!loading}
            title={"Create account"}
            errors={errors}
          />

          <p className="text-center text-gray-600 mt-4 sm:mb-0 mb-2 text-sm sm:text-base">
            Have an account?{" "}
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate("/loginSeller");
              }}
              className="text-blue-600 hover:text-black transition-all"
            >
              Sign in
            </button>
          </p>
        </div>

        <LoginFooter navigate={navigate} />
      </div>
    </div>
  );
}

export default RegisterSeller;
