import React from "react";

import PuzzlePiece from "./PuzzlePiece";

function PuzzleBoard({
  selectedPiece,

  handlePieceClick,
  swapPiece,
  pieces,
  isSolved,
  puzzleImage,
  disabled,
  boardRef,
}) {
  return (
    <div>
      {pieces.map((piece, index) => (
        <PuzzlePiece
          index={index}
          key={piece.id}
          puzzleImage={puzzleImage}
          piece={piece}
          onMove={swapPiece}
          isSolved={isSolved}
          isSelected={piece.id === selectedPiece}
          onClick={() => {
            handlePieceClick(piece.id);
          }}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

export default PuzzleBoard;
