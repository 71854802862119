import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";

import { customerClient, guestClient } from "../../GraphqlApolloClients";
import { defaultPicUrl, useForm } from "../../util/hooks";

import {
  CREATE_FORUM_POST_MUTATION,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_PUZZLE_VIEW_BY_CUSTOMER,
  FETCH_RECOMMENDED_FORUM_POSTS,
  FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY,
} from "../../util/gqlFunctions";
import HorAdSmallBox from "./HorAdSmallBox";
import JustWideComments from "./JustWideComments";
import ProductAdBox from "./ProductAdBox";

function PuzzleWideCommentSection({
  puzzleId,

  navigate,
  customer,
  targetCustomer,
  targetGuestCustomer,
  puzzle,
  country,
  shouldShowCommentSection,
  setShouldShowCommentSection,
  narrowHorAd,
  regularHorAd,
  targetAdBookmark,
  livesCount,
  size,
  togglePurchasePowerUpForm,
  productAds,
}) {
  const [errors, setErrors] = useState({});
  const {
    // subscribeToMore: subscribeToMoreQView,
    data: { getPuzzleViewByCustomer: puzzleView } = {},
    // loading: loadingPostView,
    // refetch: refetchPostView,
  } = useQuery(FETCH_PUZZLE_VIEW_BY_CUSTOMER, {
    client: targetCustomer ? customerClient : guestClient,

    variables: { puzzleId },
  });

  const {
    data: { getRecommendedForumPostsBySearch: forumPosts } = {},
    // refetch: refetchRecommendedForumPosts,
    fetchMore: fetchMoreFPosts,

    loading: loadingRecommendedForumPosts,
  } = useQuery(FETCH_RECOMMENDED_FORUM_POSTS, {
    variables: {
      forumId: null,
      puzzleId,
      seenIds: [],
      folders: [],
      forumPostRepliedToId: null,
      searchTerm: null,
      type: 0,
      pageSize: 6,
    },
    fetchPolicy: "cache-and-network",

    client: customer ? customerClient : guestClient,
  });

  const { values, setValues } = useForm(createCommentCallback, {
    puzzleId: puzzle?.id,
    puzzleViewId: puzzleView ? puzzleView.id : null,
    details: "",
    forumPostRepliedToId: null,
    repliedToUsername: "",
    pageSize: 10,
    seenIds: [],
    type: 0,

    title: "",
    mediaFiles: [],
    folders: [],
    visibility: 2,
  });

  const inputRef = useRef();

  // useEffect(() => {
  //   if (inputRef.current && size && size.width >= 640) {
  //     inputRef.current.focus();
  //   }
  // });

  const [sentMessages, setSentMessages] = useState([]);
  const resetInterval = 1000;
  const [isMsgError, setIsMsgError] = useState(false);
  useEffect(() => {
    let timeout;
    if (isMsgError && resetInterval) {
      timeout = setTimeout(() => setIsMsgError(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isMsgError, resetInterval]);
  const [headCommentToNewReplies, setHeadCommentToNewReplies] = useState({});
  const [newComments, setNewComments] = useState([]);

  const [createForumPost, { loading: loadingCreateForumPost }] = useMutation(
    CREATE_FORUM_POST_MUTATION,
    {
      refetchQueries: [
        { query: FETCH_CUSTOMER },
        { query: FETCH_GUEST_CUSTOMER },
      ],
      update(_, { data: { createForumPost: createdForumPost } }) {
        setValues({
          ...values,
          details: "",
          msg: "",
          forumPostRepliedToId: null,
          repliedToDisplayName: null,
          type: 0,
          searchTerm: null,
        });

        if (createdForumPost.type === 0) {
          setNewComments([createdForumPost, ...newComments]);
        } else {
          setHeadCommentToNewReplies({
            ...headCommentToNewReplies,
            [createdForumPost.headForumPostId]:
              headCommentToNewReplies[createdForumPost.headForumPostId] &&
              headCommentToNewReplies[createdForumPost.headForumPostId].length >
                0
                ? [
                    ...headCommentToNewReplies[
                      createdForumPost.headForumPostId
                    ],
                    createdForumPost,
                  ]
                : [createdForumPost],
          });
        }

        setErrors({});
      },
      onError(err) {
        setIsMsgError(true);

        console.log(err);

        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          // if (!targetCustomer) {
          //   navigate("/register");
          // }

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: targetCustomer ? customerClient : guestClient,
    }
  );
  function createCommentCallback(e) {
    e.preventDefault();

    createForumPost();
  }

  const {
    data: { getSuggestedMessagesByEvent: suggestedMessages } = {},

    loading: loadingSuggestedMessages,
  } = useQuery(FETCH_SUGGESTED_MSGS_BY_EVENT_QUERY, {
    variables: {
      puzzleId: null,
      puzzleViewId: puzzleView ? puzzleView.id : null,
      seenIds: [],
      pageSize: 10,
    },
    skip:
      !puzzleView || puzzleView.answerResult === -1 || puzzleView.status !== 1
        ? true
        : false,
    client: targetCustomer ? customerClient : guestClient,
  });

  useEffect(() => {
    if (
      puzzleView &&
      (values.puzzleViewId !== puzzleView.id ||
        values.puzzleId !== puzzleView.puzzleId)
    ) {
      setValues({
        ...values,
        puzzleViewId: puzzleView.id,
        puzzleId: puzzleView.puzzleId,
      });
    }
  }, [puzzleView, setValues, values]);

  return (
    <div className="flex z-10 pl-4 xshort:pb-14 short:pb-24 pb-28 sm:pb-24 2xl:pb-28 shrink flex-col relative h-full items-center justify-end w-full">
      {shouldShowCommentSection ? (
        <>
          <div
            onClick={() => {
              navigate("/shop");
            }}
            className={`bg-lighter-blue hide-button-flash text-left hover:bg-white transition-all cursor-text space-x-2 rounded-lg text-black sm:pl-4 px-3 sm:px-4 mb-2 flex items-center justify-start border w-full`}
          >
            <svg
              className="fill-current w-5 short:w-4"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
              />
            </svg>

            <p
              className={`text-lg py-3 sm:py-4 font-bold leading-none w-full flex-1 bg-transparent text-gray-500 focus:outline-none`}
            >
              Explore products
              {/* for Lucky */}
            </p>
          </div>

          {productAds && productAds.length > 0 ? (
            <div className="flex z-10 mb-4 w-full shrink-0 items-start justify-start space-x-4 overflow-x-auto">
              {productAds.map((productAd, index) => (
                <ProductAdBox
                  key={productAd.id}
                  product={productAd}
                  size={size}
                  customer={targetCustomer}
                />
              ))}
            </div>
          ) : (
            <></>
          )}

          <JustWideComments
            newComments={newComments}
            comments={forumPosts}
            country={country}
            fetchMoreParams={{
              forumId: null,
              puzzleId: puzzle?.id,

              puzzleViewId: puzzleView ? puzzleView.id : null,
              seenIds: [],
              folders: [],
              forumPostRepliedToId: null,
              searchTerm: null,
              type: 0,
              pageSize: 6,
            }}
            fetchMoreComments={fetchMoreFPosts}
            navigate={navigate}
            loadingComments={loadingRecommendedForumPosts}
            setValues={setValues}
            values={values}
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            puzzleId={puzzleId}
            setSentMessages={setSentMessages}
            headCommentToNewReplies={headCommentToNewReplies}
          />
        </>
      ) : (
        <>
          <div
            onClick={() => {
              navigate("/shop");
            }}
            className={`bg-lighter-blue hide-button-flash text-left hover:bg-white transition-all cursor-text space-x-2 rounded-lg text-black sm:pl-4 px-3 sm:px-4 flex items-center justify-start border w-full`}
          >
            <svg
              className="fill-current w-5 short:w-4"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8482 12.8482C9.74407 13.5763 8.42152 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 8.72211 13.3781 10.299 12.3467 11.5183L16.4142 15.5858L15 17L10.8482 12.8482Z"
              />
            </svg>

            <p
              className={`text-lg py-3 sm:py-4 font-bold leading-none w-full flex-1 bg-transparent text-gray-500 focus:outline-none`}
            >
              Explore products
              {/* for Lucky */}
            </p>
          </div>
          <div className="my-4 rounded-lg overflow-y-auto flex-1 grid grid-cols-3 gap-3 grid-flow-row">
            <button
              onClick={() => {
                navigate("/shop/food");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Food
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/treats");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Treats
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/toys");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Toys
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/clothing");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Clothing
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/supplies");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Supplies
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/pharmacy");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Pharmacy
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/litter");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Litter
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/shop/collars");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                Collars &amp; Leashes
              </p>
            </button>

            <button
              onClick={() => {
                navigate("/shop");
              }}
              className="w-full group"
            >
              <div className="w-full rounded-lg group-hover:shadow-xl transition-all overflow-hidden aspect-square">
                <img src={defaultPicUrl} alt="Stranger Things" />
              </div>
              <p className="truncate text-center font-semibold text-black mt-1 2xl:text-lg">
                All
              </p>
            </button>
          </div>
        </>
      )}

      {shouldShowCommentSection && (targetCustomer || targetGuestCustomer) && (
        <div
          className={`h-9 short:h-8 relative shrink-0 overflow-hidden transition-all flex w-full mb-2 items-center space-x-2`}
        >
          <div className="w-full flex gap-2 snap always-stop snap-y snap-mandatory overflow-y-scroll scrollbar-hide flex-wrap grow items-center justify-start overflow-x-auto h-full short:text-sm ">
            {puzzleView &&
              puzzleView.status === 1 &&
              suggestedMessages &&
              !loadingSuggestedMessages &&
              suggestedMessages.length > 0 &&
              suggestedMessages
                .filter((msg) => !sentMessages.includes(msg))
                .map((msg, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      setValues({ ...values, details: values.details + msg });
                    }}
                    key={msg}
                    className="bg-white leading-tight h-full hover:bg-opacity-50 transition-all rounded-lg px-2 py-1"
                  >
                    {msg}
                  </button>
                ))}
          </div>
          <svg
            className="h-full shrink-0"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z"
              fill="white"
            />
          </svg>
        </div>
      )}
      {!targetCustomer || !targetCustomer.isPaidPetSubscriber ? (
        1 === 2 && !targetCustomer && size.width >= 768 ? (
          <div className="flex w-full shrink-0 mb-1 sm:mb-2 aspect-[58/7] h-auto items-center justify-center space-x-3">
            <a
              href="https://play.google.com/store/apps/details?id=pet.thelucky"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full pl-5 pr-6 bg-black hover:bg-gray-700 transition-all font-normal text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="0 0 384 512"
                  className="aspect-square w-10 2xl:w-12"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate">
                  Download on the
                </div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-normal -mt-1">
                  App Store
                </div>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=pet.thelucky"
              target="_blank"
              rel="noreferrer"
              className="flex w-full h-full bg-black hover:bg-gray-700 transition-all text-white rounded-lg items-center justify-center"
            >
              <div className="mr-3">
                <svg
                  viewBox="30 336.7 120.9 129.2"
                  className="aspect-square w-8 2xl:w-12"
                >
                  <path
                    fill="#FFD400"
                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                  />
                  <path
                    fill="#FF3333"
                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                  />
                  <path
                    fill="#48FF48"
                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                  />
                  <path
                    fill="#3BCCFF"
                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs 2xl:text-base truncate font-normal">
                  GET IT ON
                </div>
                <div className="text-xl 2xl:text-2xl truncate font-semibold font-normal -mt-1">
                  Google Play
                </div>
              </div>
            </a>
          </div>
        ) : (
          <HorAdSmallBox
            horAdLink={narrowHorAd?.link || null}
            areCommentsInFocus={false}
            isOutOfLives={livesCount === 0}
            targetCustomer={targetCustomer}
            targetGuestCustomer={targetGuestCustomer}
            targetAdBookmark={targetAdBookmark}
            horAdUrl={
              // shouldShowCommentSection
              //   ?
              narrowHorAd?.narrowHorAdUrl || null
              // : narrowHorAd?.narrowHorAdUrl || null
            }
            adId={
              // shouldShowCommentSection
              //   ?
              narrowHorAd?.id || null
              // : narrowHorAd?.id || null
            }
          />
        )
      ) : (
        <></>
      )}

      <div
        className={`${
          isMsgError
            ? "border-red-600 motion-safe:animate-shake"
            : "border-black "
        } ${
          shouldShowCommentSection ? "" : "h-0"
        } overflow-hidden sm:border-opacity-100 rounded-xl text-black duration-700 transition-height pl-4 short:pl-3 flex items-start justify-start border w-full`}
      >
        {isMsgError && errors.message && (
          <p className="absolute text-red-600 bottom-2 font-semibold">
            {errors.message}
          </p>
        )}
        <textarea
          ref={inputRef}
          name={"message"}
          id={"message"}
          rows="3"
          value={values.details}
          onChange={(e) => {
            e.preventDefault();

            var newMsg = e.target.value;
            setValues({
              ...values,
              details: newMsg,
            });
          }}
          className={`${
            isMsgError ? "" : ""
          } text-lg 2xl:text-2xl py-4 leading-tight w-full flex-1 bg-transparent placeholder:text-gray-700 focus:outline-none`}
          placeholder={
            values.repliedToDisplayName
              ? "Replying to " + values.repliedToDisplayName
              : "Add a comment..."
          }
          disabled={loadingCreateForumPost || !shouldShowCommentSection}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              createForumPost();
            }
          }}
          maxLength={200}
        ></textarea>
        <button
          onClick={createCommentCallback}
          className={`${
            isMsgError ? "text-red-600" : "text-black"
          } p-4  hover:opacity-50 transition-all`}
          disabled={loadingCreateForumPost}
        >
          <svg
            className="w-6 2xl:w-7 short:w-5 fill-current"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M26 0L19.5 24L10.6936 16.1029L19.1458 7.12036L7.81625 15.0044L0 13.0909L26 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default PuzzleWideCommentSection;
