import { useQuery } from "@apollo/client";
import React from "react";
import { useLocation } from "react-router-dom";
import { brandContactClient, customerClient } from "../../GraphqlApolloClients";
import { FETCH_NUM_UNCLICKED_NOTIFICATIONS } from "../../util/gqlFunctions";
import { defaultPicUrl } from "../../util/hooks";
import Logo from "./Logo";
import PlayerCoinCounter from "./PlayerCoinCounter";

export default function TopBar({
  activityShortForm,
  orgShortForm,
  navigate,
  toggleBuyCoinsForm,
  targetPet,
  setShouldShowMiniNavBar,
  setIsFirstTime,
  targetCustomer,
  targetGuestCustomer,
  seller,
  customer,
  toggleBuySubscriptionForm,
  page,
  activityName,
  activityLogoUrl,
  orgLogoUrl,
  toggleMultiTopicBattleForm,
  toggleOneTopicBattleForm,
  activityId,
  forumTitle,
  forumLogoUrl,
  orgNameForUrl,
  prevPath,
  postNumber,
  values,
  setValues,
  isSeller,
}) {
  const location = useLocation();
  const {
    data: { getNumUnclickedNotifications: numUnclickedNotifications } = 0,
  } = useQuery(FETCH_NUM_UNCLICKED_NOTIFICATIONS, {
    client: isSeller ? brandContactClient : customerClient,
    fetchPolicy: "cache-and-network",
  });

  return (
    <div
      className={`${
        page === "CreatePost" ||
        page === "Notifications" ||
        page === "Friends" ||
        (page === "Memos" && (orgShortForm || activityShortForm)) ||
        (page === "Comms" && forumTitle)
          ? ""
          : "space-x-2 sm:space-x-4"
      } relative z-20 ${
        page === "Profile" || page === "Activity" || page === "Organization"
          ? "px-2 sm:px-5"
          : page === "CreatePost"
          ? "px-2 py-4 sm:px-2 sm:pb-6"
          : page === "Account" ||
            page === "Login" ||
            page === "Signup" ||
            page === "Forgot" ||
            page === "Friends" ||
            page === "Onboarding" ||
            page === "Notifications" ||
            page === "Admin Dash" ||
            page === "Seller Dash"
          ? "px-2 py-4 sm:px-5 sm:pb-6"
          : "px-6 sm:px-5"
      } text-black flex items-center justify-between w-full`}
    >
      {page === "Profile" ||
      page === "Activity" ||
      page === "Organization" ||
      page === "CreatePost" ||
      page === "Notifications" ||
      page === "Account" ||
      (page === "Memos" && (orgShortForm || activityShortForm)) ||
      (page === "Comms" && forumTitle) ||
      (page === "Friends" && (orgShortForm || activityShortForm)) ? (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (page === "Comms" && forumTitle && !postNumber) {
              navigate("/comms");
            } else if (
              page === "CreatePost" &&
              (values?.city || values?.country)
            ) {
              setValues({ ...values, city: null, country: null });
            } else if (location.key === "default") {
              navigate("/");
            } else {
              navigate(-1, { state: { from: location } });
            }
          }}
          className="transition-all h-[1.9rem] origin-left pr-2 rounded-full outline-none hover:opacity-50 focus:outline-none"
        >
          <svg
            className="h-4 fill-current"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
          </svg>
        </button>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (location.key === "default") {
              navigate("/");
            } else {
              navigate("/", { state: { from: location } });
            }
          }}
          className="transition-all outline-none hover:opacity-50 py-1 focus:outline-none"
        >
          <Logo dimensions={"h-5 sm:h-6 xl:h-5"} />
        </button>
      )}

      {forumTitle ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={forumLogoUrl}
              alt={forumTitle}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            <p className="font-semibold hidden sm:block truncate whitespace-nowrap">
              {forumTitle}
            </p>
          </div>
        </>
      ) : activityName ? (
        <>
          <div className="sm:text-lg flex items-center justify-start truncate 2xl:text-2xl text-white  overflow-hidden motion-safe:animate-fadeIn relative space-x-2 font-semibold transition-all leading-tight flex-1 sm:ml-2">
            <img
              src={activityLogoUrl}
              alt={activityName}
              className="border-white h-7 sm:h-8 group-hover:opacity-50 transition-all bg-white short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-md sm:rounded-lg object-cover aspect-square"
            />
            {page !== "CreatePost" && page !== "Friends" && (
              <p className="font-semibold hidden sm:block truncate whitespace-nowrap">
                {activityName}
              </p>
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {customer || seller ? (
        <div className=" flex items-center justify-end sm:space-x-4 space-x-2">
          {/* {(page === "Memos" || page === "Search") && (
            <button
              onClick={(e) => {
                navigate("/search");
              }}
              className={`group leading-tight text-center text-sm sm:hidden font-semibold ${
                page === "Search" ? "hidden" : ""
              } hover:bg-opacity-70 transition-all bg-lighter-blue relative aspect-square h-[1.9rem] sm:h-7 flex text-white  items-center justify-center shrink-0 rounded-full py-0.5 sm:text-base `}
            >
              <svg
                className=" aspect-square h-4  "
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 27L20.9583 20.9583M24.2222 13.1111C24.2222 19.2476 19.2476 24.2222 13.1111 24.2222C6.97461 24.2222 2 19.2476 2 13.1111C2 6.97461 6.97461 2 13.1111 2C19.2476 2 24.2222 6.97461 24.2222 13.1111Z"
                  stroke="#1E1E1E"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )} */}

          {/* {customer && page !== "Friends" && page !== "CreatePost" && (
            <button
              onClick={(e) => {
                navigate(
                  "/post" + (activityShortForm ? "/" + activityShortForm : "")
                );
              }}
              className="group relative h-[1.9rem] sm:h-auto hidden sm:flex text-black  transition-all items-center justify-center shrink-0 py-0.5 text-sm sm:text-base "
            >
              <div className="bg-yellow-400 space-x-1 aspect-square h-5 sm:h-6 px-2  opacity-100 group-hover:opacity-60 flex items-center justify-center rounded-full border-2 border-black">
                <p className="text-sm font-medium">Post</p>
                <svg
                  className=" aspect-square h-3 fill-current text-gray-800"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5 12.5714H13.0714V22H9.92857V12.5714H0.5V9.42857H9.92857V0H13.0714V9.42857H22.5V12.5714Z" />
                </svg>
              </div>
            </button>
          )} */}

          {/* {page !== "Friends" && (
            <button
              onClick={(e) => {
                e.preventDefault();

                toggleBuySubscriptionForm(0);
              }}
              className="text-lg 2xl:text-2xl text-black hover:text-gray-700 overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-full"
            >
              <p className="font-semibold whitespace-nowrap">Shop</p>
            </button>
          )} */}

          {customer && page !== "Friends" && page !== "CreatePost" && (
            <a
              href="/registerSeller"
              rel="noreferrer"
              className="text-lg 2xl:text-2xl text-black hover:text-dark-blue overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-full"
            >
              <p className="font-semibold whitespace-nowrap">Advertise</p>
            </a>
          )}

          {customer &&
            targetCustomer &&
            !targetCustomer.isPaidPetSubscriber &&
            page !== "Friends" && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  toggleBuySubscriptionForm(0);
                }}
                className="text-lg 2xl:text-2xl text-black hover:text-dark-blue overflow-hidden motion-safe:animate-fadeIn relative hidden sm:flex space-x-2 items-center justify-center font-semibold transition-all py-1 leading-tight rounded-full"
              >
                <p className="font-semibold whitespace-nowrap">Unlock Pro</p>
              </button>
            )}
          {numUnclickedNotifications > 0 &&
          page !== "TKL" &&
          page !== "Battle" &&
          page !== "Notifications" ? (
            <button
              onClick={(e) => {
                navigate("/notifications");
              }}
              className="group text-center text-sm font-semibold bg-red-600 hover:bg-opacity-70 transition-all relative aspect-square h-[1.9rem] sm:h-7 flex text-white  items-center justify-center shrink-0 rounded-full py-0.5 sm:text-base "
            >
              <p className="text-center leading-none sm:h-5 m-auto text-black">
                {numUnclickedNotifications}
              </p>

              <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
                <svg
                  className="h-1.5 rotate-180 fill-current text-gray-800"
                  viewBox="0 0 20 10"
                >
                  <path d="M0 0L20 0L10 10Z" />
                </svg>
                <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
                  Notifications
                </span>
              </div>
            </button>
          ) : (
            <></>
          )}

          {seller ? (
            <></>
          ) : (
            <PlayerCoinCounter
              targetCustomer={targetCustomer || targetGuestCustomer}
              toggleBuyCoinsForm={toggleBuyCoinsForm}
            />
          )}
          <button
            className={`${
              targetCustomer
                ? targetCustomer.username === "lucky"
                  ? "bg-blue-300"
                  : // : targetCustomer.petSubscriptionType === 0
                    // ? "bg-yellow-300"
                    // : targetCustomer.petSubscriptionType === 1
                    // ? "bg-red-400"
                    "bg-black"
                : "bg-black"
            } group hover:bg-opacity-30 flex-shrink-0 transition-all overflow-hidden p-[.2rem] 2xl:p-1.5 rounded-full aspect-square w-8 flex items-center justify-center 2xl:w-14`}
            onClick={(e) => {
              e.preventDefault();
              setIsFirstTime(false);
              setShouldShowMiniNavBar(true);
            }}
          >
            <img
              src={
                targetCustomer
                  ? targetCustomer.profilePicUrl
                  : seller
                  ? seller.profilePicUrl
                  : defaultPicUrl
              }
              alt={
                targetCustomer
                  ? targetCustomer.username
                  : seller
                  ? seller.username
                  : "Knight"
              }
              className="border-white group-hover:bg-white transition-all bg-black short:border-[1.5px] border-[1.75px] 2xl:border-[2px] box-border rounded-full object-cover aspect-square"
            />
          </button>
        </div>
      ) : (
        <>
          <div className="flex-1"></div>

          {(page === "Memos" || page === "Search" || page === "TKL") && (
            <a
              href="/registerSeller"
              rel="noreferrer"
              className="group relative aspect-square h-[1.9rem] sm:h-auto flex text-black  transition-all items-center justify-center shrink-0 rounded-full py-0.5 text-sm sm:text-base "
            >
              <svg
                className="fill-current h-5 sm:h-6 opacity-100 group-hover:opacity-60"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.927 23.84C13.289 23.945 12.635 24 11.968 24C5.362 24 0 18.623 0 12C0 5.377 5.362 0 11.968 0C18.573 0 23.935 5.377 23.935 12C23.935 12.671 23.88 13.329 23.774 13.971L21.857 13.296C21.912 12.872 21.941 12.439 21.941 12C21.941 6.481 17.472 2 11.968 2C6.463 2 1.995 6.481 1.995 12C1.995 17.519 6.463 22 11.968 22C12.418 22 12.862 21.97 13.296 21.912L13.927 23.84ZM12.754 13.839C12.512 13.942 12.247 14 11.968 14C10.867 14 9.973 13.104 9.973 12C9.973 10.896 10.867 10 11.968 10C13.068 10 13.962 10.896 13.962 12C13.962 12.211 13.93 12.414 13.869 12.604L23.602 16.03L19.94 17.956L24 22.129L22.126 23.956L18.037 19.741L15.87 23.362L12.754 13.839ZM12.661 19.97C12.432 19.99 12.201 20 11.968 20C7.564 20 3.989 16.415 3.989 12C3.989 7.585 7.564 4 11.968 4C16.371 4 19.946 7.585 19.946 12C19.946 12.207 19.938 12.412 19.923 12.615L18.944 12.271L18.949 12C18.949 8.137 15.821 5 11.968 5C8.115 5 4.986 8.137 4.986 12C4.986 15.863 8.115 19 11.968 19L12.34 18.99L12.661 19.97ZM11.686 16.992C9.065 16.846 6.981 14.665 6.981 12C6.981 9.24 9.215 7 11.968 7C14.573 7 16.715 9.008 16.935 11.564L15.875 11.191C15.501 9.37 13.893 8 11.968 8C9.766 8 7.978 9.792 7.978 12C7.978 13.996 9.439 15.652 11.346 15.952L11.686 16.992Z"
                />
              </svg>

              <div className="sm:flex hidden font-semibold flex-col absolute items-center justify-center top-full left-1/2 transform -translate-x-1/2 whitespace-nowrap text-center text-xs text-white opacity-0 group-hover:opacity-100 z-10 transition-opacity duration-300">
                <svg
                  className="h-1.5 rotate-180 fill-current text-gray-800"
                  viewBox="0 0 20 10"
                >
                  <path d="M0 0L20 0L10 10Z" />
                </svg>
                <span className="bg-gray-800 px-2 py-1  rounded-md shadow">
                  Advertise
                </span>
              </div>
            </a>
          )}

          <button
            onClick={(e) => {
              if (page === "Login" || page === "Search") {
                if (page !== "Seller Dash" && isSeller) {
                  navigate("/registerSeller");
                } else {
                  navigate(
                    "/register" + (prevPath ? "/redirect/" + prevPath : "")
                  );
                }
              } else if (
                page === "Signup" ||
                page === "Forgot" ||
                page === "Admin Dash" ||
                page === "Seller Dash"
              ) {
                if (page !== "Seller Dash" && isSeller) {
                  navigate("/loginSeller");
                } else {
                  navigate(
                    "/login" + (prevPath ? "/redirect/" + prevPath : "")
                  );
                }
              } else if (page === "Puzzles") {
                if (activityShortForm) {
                  navigate("/register/redirect/puzzles>" + activityShortForm);
                } else if (orgNameForUrl) {
                  navigate("/register/redirect/puzzles>org>" + orgNameForUrl);
                } else {
                  navigate("/register/redirect/puzzles");
                }
              } else if (page === "Organization") {
                navigate("/register/redirect/org>" + orgNameForUrl);
              } else if (page === "Profile") {
                navigate("/register/redirect/pet>" + targetCustomer.username);
              } else if (page === "Activity") {
                navigate("/register/redirect/activity>" + activityShortForm);
              } else {
                navigate("/register/redirect/puzzles");
              }
            }}
            className="text-sm  h-[1.9rem] sm:text-base group flex-shrink-0 flex hover:bg-white transition-all items-center justify-center space-x-2 bg-lighter-blue rounded-full backdrop-blur-sm pl-4 pr-3 py-1 sm:h-full"
          >
            <p className="font-semibold">
              {page === "Admin Dash"
                ? "User Login"
                : page === "Seller Dash"
                ? "User Login"
                : page === "Login"
                ? "Sign up instead"
                : page === "Signup" || page === "Forgot"
                ? "Sign in instead"
                : "Sign in"}
            </p>
            <svg
              className="h-2.5 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        </>
      )}
    </div>
  );
}
