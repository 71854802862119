import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function RefreshPuzzlesBox({
  puzzleIdToScrollIntoView,
  refetchRecommendedPuzzles,
  topicShortForm,
  pageSize,
  seenIds,
  setPuzzleIdToScrollIntoView,
  loadingRecommendedPs,
  setLoadingRecommendedPs,
  setWantToScrollToPId,
}) {
  const { ref: boxRef, inView: isBoxVisible } = useInView({
    threshold: 0.7,
  });

  useEffect(() => {
    let timeout;
    if (isBoxVisible) {
      timeout = setTimeout(async () => {
        if (isBoxVisible && !loadingRecommendedPs) {
          setLoadingRecommendedPs(true);

          const result = await refetchRecommendedPuzzles({
            topicShortForm,
            pageSize,
            seenIds,
            clickedPuzzleId: null,
          });

          if (result && result.data) {
            setLoadingRecommendedPs(false);
            if (
              result.data.getRecommendedPuzzles &&
              result.data.getRecommendedPuzzles.length > 0
            ) {
              setPuzzleIdToScrollIntoView(
                result.data.getRecommendedPuzzles[0].id
              );
            } else {
              setPuzzleIdToScrollIntoView(null);
            }

            setWantToScrollToPId(null);
          }
        }
      }, 200);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    isBoxVisible,
    topicShortForm,
    setPuzzleIdToScrollIntoView,
    pageSize,

    seenIds,
    setWantToScrollToPId,
    refetchRecommendedPuzzles,
    loadingRecommendedPs,
    setLoadingRecommendedPs,
  ]);

  return (
    <div
      ref={boxRef}
      className="w-full short:-scroll-m-[4.5rem] -scroll-m-[6.5rem] sm:-scroll-m-20 short:h-7 h-10 snap-start bg-white py-2 text-black shrink-0 rounded-2xl sm:rounded-3xl  flex items-center justify-center"
    >
      <svg
        className={`motion-safe:animate-spin text-blue-600 m-auto h-4 short:h-3 fill-current shrink-0 xl:h-4`}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.34455 1.65463C7.48546 0.796078 6.30643 0.263184 4.99706 0.263184C2.37832 0.263184 0.263184 2.38292 0.263184 5.00003C0.263184 7.61713 2.37832 9.73687 4.99706 9.73687C7.207 9.73687 9.0496 8.227 9.5769 6.18424H8.34455C7.85872 7.56384 6.54342 8.55266 4.99706 8.55266C3.03597 8.55266 1.44221 6.95989 1.44221 5.00003C1.44221 3.04016 3.03597 1.44739 4.99706 1.44739C5.98057 1.44739 6.85744 1.85595 7.49731 2.50134L5.58954 4.40792H9.73687V0.263184L8.34455 1.65463Z" />
      </svg>
    </div>
  );
}
