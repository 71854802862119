import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";

import React, { useState } from "react";
import { adminClient } from "../../GraphqlApolloClients";

import { useForm } from "../../util/hooks";

import ProductImagesGallery from "./ProductImagesGallery";
import ProductVideoInputField from "./ProductVideoInputField";

import {
  APPROVE_PRODUCT_MUTATION,
  EDIT_PRODUCT_MUTATION,
  FETCH_ALL_PRODUCT_IMAGES,
  FETCH_PRODUCTS,
  MOVE_PRODUCT_IMAGE_IN_PRODUCT_MUTATION,
} from "../../util/gqlFunctions";
import TextInputField from "./TextInputField";

const productCategories = [
  "Electronics",
  "Apps & Games",
  "Books",
  "Home & Kitchen",
  "Beauty & Personal Care",
  "Toys",
  "Sports & Outdoors",
  "Clothing, Shoes & Jewelry",
  "Pet Supplies",
  "Grocery & Gourmet Food",
  "Automotive",
  "Tools & Home Improvement",
  "Health & Household",
];
// const topics = [
//   "General",
//   "History",
//   "Geography",
//   "Pop Culture",
//   "Sports",
//   "Art",
//   "Music",
//   "Film",
//   "Television",
//   "Video Games",
//   "Literature",
//   "Technology",
//   "Science",
//   "Space",
//   "Math",
//   "Nature",
//   "Animals",
//   "Food",
//   "Cooking",
//   "Culture",
//   "Cars",
//   "Fashion",
//   "Celebrities",
//   "Holidays",
//   "Mythology",
//   "Language",
//   "Business",
//   "Economics",
//   "Politics",
//   "Government",
//   "Current Events",
//   "Law",
//   "Criminology",
//   "Psychology",
//   "Sociology",
//   "Medicine",
// ];

const countries = [
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Chile",
  "China",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "China",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Italy",
  "Japan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malaysia",
  "Malta",
  "Mexico",
  "Netherlands",
  "New Zealand",
  "Norway",
  "Pakistan",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Serbia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Thailand",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
];

function EditProduct({ targetProduct, setTargetProduct }) {
  const [errors, setErrors] = useState({});

  const [inputValue, setInputValue] = useState("");
  const [categoryInputValue, setCategoryInputValue] = useState("");

  const { onChange, values, setValues, onChangeMedia, onChangeNumber } =
    useForm(editProductCallback, {
      productId: targetProduct.id,
      fieldsToEdit: [],
      ...targetProduct,
      discount: targetProduct.discount || 0,
      discountedPrice: targetProduct.discountedPrice || 0,
      usualPrice: targetProduct.usualPrice || 0,
      adType: targetProduct.adType,
      adVideoUrl: targetProduct.adVideoUrl,
    });

  const [previewVideoURL, setPreviewVideoURL] = useState("");

  const [editProduct, { loading: loadingEditProduct }] = useMutation(
    EDIT_PRODUCT_MUTATION,
    {
      refetchQueries: [{ query: FETCH_PRODUCTS }],
      update(_, { data: { editProduct: editedProduct } }) {
        setValues({
          ...values,
          ...editedProduct,
          fieldsToEdit: [],
          discount: editedProduct.discount || 0,
          discountedPrice: editedProduct.discountedPrice || 0,
          usualPrice: editedProduct.usualPrice,
          adType: editedProduct.adType,
          adVideoUrl: editedProduct.adVideoUrl,
        });
        setPreviewVideoURL(null);

        // setTargetProduct(null);
        setErrors({});
      },
      onError(err) {
        console.log(err);
        if (err.graphQLErrors[0]) {
          console.log(err.graphQLErrors[0].extensions.errors);

          setErrors({
            ...err.graphQLErrors[0].extensions.errors,
          });
        }
      },
      variables: values,
      client: adminClient,
    }
  );

  function setFieldsToEdit(name) {
    if (
      values.fieldsToEdit &&
      values.fieldsToEdit.includes(name) &&
      targetProduct
    ) {
      if (
        name.includes("Amount") ||
        name.includes("Price") ||
        name === "discount"
      ) {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetProduct[name]
            ? Math.floor(targetProduct[name] / 100)
            : 10,
        });
      } else {
        setValues({
          ...values,
          fieldsToEdit: [],
          [name]: targetProduct[name],
        });
      }
    } else {
      setValues({
        ...values,
        fieldsToEdit: [name],
      });
    }
  }

  function editProductCallback(e) {
    e.preventDefault();
    editProduct();
  }

  const {
    data: { getAllProductImages: allProductImages } = {},
    loading: loadingProductImages,
    // subscribeToMore,
  } = useQuery(FETCH_ALL_PRODUCT_IMAGES, {
    client: adminClient,
  });

  const [shouldOpenAddImages, setShouldOpenAddImages] = useState(false);

  const [
    moveProductImageInProduct,
    { loading: loadingMoveProductImageInProduct },
  ] = useMutation(MOVE_PRODUCT_IMAGE_IN_PRODUCT_MUTATION, {
    refetchQueries: [
      { query: FETCH_ALL_PRODUCT_IMAGES },
      { query: FETCH_PRODUCTS },
    ],
    update(_, { data: { moveProductImageInProduct: movedProductImage } }) {
      targetProduct = { ...targetProduct, ...movedProductImage };
      setErrors({});
    },
    onError(err) {
      console.log(err);
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  const handleChangeDateTime = (event) => {
    event.preventDefault();

    // Get the selected date and time in the user's timezone
    const localDate = moment(event.target.value).toDate();

    // Convert the local date and time to UTC
    const utcDate = moment.utc(localDate);

    // Save the UTC date and time to the state
    setValues({ ...values, startSaleAt: utcDate });
  };

  const displayStartSaleAt = () => {
    // Format and display the startSaleAt value in the user's timezone
    return moment(values.startSaleAt).local().format("YYYY-MM-DDTHH:mm:ss");
  };

  const [approveProduct] = useMutation(APPROVE_PRODUCT_MUTATION, {
    refetchQueries: [{ query: FETCH_PRODUCTS }],
    update(_, { data: { approveProduct: approvedProduct } }) {
      // product = { ...product, ...approvedProduct };
      console.log(errors);
    },
    onError(err) {
      console.log(err);
      setTargetProduct(null);
      // setValues({ ...values, shouldDelete: false });
      if (err.graphQLErrors[0]) {
        setErrors({
          ...err.graphQLErrors[0].extensions.errors,
        });
      }
    },
    variables: values,
    client: adminClient,
  });

  return (
    <div className="fixed pt-16 sm:py-10 z-50 overscroll-contain w-screen h-screen max-h-screen max-w-screen overflow-hidden flex items-end justify-end sm:items-center sm:justify-center">
      {!loadingProductImages && shouldOpenAddImages && (
        <ProductImagesGallery
          productId={targetProduct.id}
          allProductImages={allProductImages}
          setShouldOpenAddImages={setShouldOpenAddImages}
        />
      )}

      <button
        onClick={(e) => {
          e.preventDefault();
          setErrors({});
          setTargetProduct(null);
        }}
        className="z-40 bg-gray-500 hide-button-flash overscroll-contain bg-opacity-10 motion-safe:animate-fadeInFast flex items-center justify-center inset-0 absolute backdrop-blur-sm"
        disabled={false}
      />
      <form
        // onSubmit={buyBandCallback}
        className={`overflow-x-hidden max-h-full flex-shrink motion-safe:animate-fadeUpFast overscroll-contain sm:m-auto w-full max-w-xl overflow-y-auto flex flex-col items-start justify-start z-40 rounded-xl px-6 pb-6 sm:p-8 bg-white shadow-xl rounded-b-none sm:rounded-3xl short:h-full short:px-4 short:pb-4 `}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setErrors({});
            setTargetProduct(null);
          }}
          className="w-full pt-4 short:pt-3 short:pb-1 pb-2 sm:hidden"
        >
          <div className="rounded-full bg-gray-200 flex-shrink-0 h-1 mx-auto w-10 mb-2"></div>
        </button>

        <p className="text-gray-700 text-2xl font-bold w-full short:text-xl short:leading-tight">
          {targetProduct.title}
        </p>
        <p className="text-gray-500 w-full ">Edit product info</p>

        {/* <div className="mt-4 w-full flex items-start justify-start flex-col">
            <p className="font-medium">Full name</p>

          </div> */}
        <div className="w-full flex flex-col items-start justify-start space-y-6 mt-6">
          <div className="w-full flex space-x-4 items-center snap-x snap-mandatory justify-start rounded-2xl overflow-x-auto">
            {targetProduct.imageUrls &&
              targetProduct.imageUrls.length > 0 &&
              targetProduct.imageUrls.map((imageUrl, index) => (
                <div className="relative snap-center w-3/4 rounded-2xl shrink-0 overflow-hidden">
                  <img
                    src={imageUrl}
                    className="w-full aspect-video object-contain mr-2"
                    alt={targetProduct.title}
                  />
                  <div className="absolute bottom-0 inset-x-0 flex items-center justify-center">
                    <button
                      disabled={loadingMoveProductImageInProduct || index === 0}
                      className="w-1/3 py-3 group disabled:opacity-0 disabled:hover:bg-transparent hover:bg-white/30 transition-all"
                      onClick={(e) => {
                        e.preventDefault();
                        moveProductImageInProduct({
                          variables: {
                            productId: targetProduct.id,
                            targetIndex: index,
                            moveDirection: -1,
                          },
                        });
                      }}
                    >
                      <svg
                        className="h-6 group-disabled:opacity-0 fill-current text-gray-600 m-auto"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.5 7L9.5 12L14.5 17V7Z" />
                      </svg>
                    </button>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        moveProductImageInProduct({
                          variables: {
                            productId: targetProduct.id,
                            targetIndex: index,
                            moveDirection: 0,
                          },
                        });
                      }}
                      className="w-1/3 py-3 group disabled:opacity-0 disabled:hover:bg-transparent hover:bg-white/30 transition-all"
                      disabled={loadingMoveProductImageInProduct}
                    >
                      {loadingMoveProductImageInProduct ? (
                        <svg
                          className={`h-6 text-gray-600 stroke-current m-auto motion-safe:animate-spin`}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.636 3.636A9 9 0 0119 10"
                            strokeWidth={2}
                            strokeLinecap="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="h-6 fill-current text-gray-600 m-auto"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.9 21 6 20.1 6 19V7Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      disabled={
                        loadingMoveProductImageInProduct ||
                        index === targetProduct.imageUrls.length - 1
                      }
                      className="w-1/3 py-3 group disabled:opacity-0 disabled:hover:bg-transparent hover:bg-white/30 transition-all"
                      onClick={(e) => {
                        e.preventDefault();
                        moveProductImageInProduct({
                          variables: {
                            productId: targetProduct.id,
                            targetIndex: index,
                            moveDirection: 1,
                          },
                        });
                      }}
                    >
                      <svg
                        className="h-6 group-disabled:opacity-0 fill-current text-gray-600 m-auto"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.5 17L14.5 12L9.5 7V17Z" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            <button
              onClick={(e) => {
                e.preventDefault();
                setShouldOpenAddImages(true);
              }}
              className={`${
                targetProduct.imageUrls && targetProduct.imageUrls.length > 0
                  ? "w-3/4"
                  : "w-full"
              } shrink-0 aspect-video snap-center hover:text-gray-500 text-gray-400 flex items-center justify-center bg-gray-50 border border-gray-200 rounded-2xl transition-all hover:bg-gray-200 `}
            >
              <svg
                className="w-10 fill-current"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
              </svg>
            </button>
          </div>

          <TextInputField
            title="Title"
            name="title"
            type="text"
            error={errors.title}
            disabled={loadingEditProduct}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
            value={values.title}
          />
          {/* <TextInputField
            title="Unit description"
            name="unitDescription"
            type="text"
            maxLen={10}
            error={errors.unitDescription}
            disabled={loadingEditProduct}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
            value={values.unitDescription}
          /> */}

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="categories"
                className="font-semibold text-gray-700 "
              >
                Categories{" "}
                {errors.categories &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("categories"))) && (
                    <span className="text-red-500">{errors.categories}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["categories"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("categories") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("categories");
                      setCategoryInputValue("");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                      setCategoryInputValue("");
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            {values.categories && values.categories.length > 0 && (
              <div
                className={`gap-2 mb-2 flex-wrap flex items-center justify-start mt-2`}
              >
                {values.categories.map((category, index) => (
                  <div
                    key={index}
                    className="rounded-full flex items-center justify-start border-blue-600 bg-blue-50 pl-4 overflow-hidden py-1"
                  >
                    <p>{category}</p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        const categoriesCopy = values.categories
                          ? [...values.categories]
                          : [];
                        if (categoriesCopy[index]) {
                          categoriesCopy.splice(index, 1);
                        }
                        setValues({ ...values, categories: categoriesCopy });
                      }}
                      disabled={
                        loadingEditProduct ||
                        (values.fieldsToEdit &&
                          !values.fieldsToEdit.includes("categories"))
                      }
                      className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all pl-2 pr-3"
                    >
                      <svg
                        className="w-4 fill-current"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            <input
              className={`sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
              type={"text"}
              value={categoryInputValue}
              onChange={(e) => {
                const value = e.target.value;
                setCategoryInputValue(value);
              }}
              placeholder={""}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("categories"))
              }
              maxLength={200}
              list={"productCategories"}
            />
            {categoryInputValue ? (
              <div className="relative w-full">
                <div className="max-h-20 w-full overflow-y-auto absolute bg-white">
                  {productCategories
                    .filter((category) =>
                      category
                        .toLowerCase()
                        .includes(categoryInputValue.toLowerCase())
                    )
                    .map((category, index) => (
                      <button
                        key={index}
                        onClick={(e) => {
                          if (values.categories) {
                            setValues({
                              ...values,
                              categories: [...values.categories, category],
                            });
                          } else {
                            setValues({
                              ...values,
                              categories: [category],
                            });
                          }
                          setCategoryInputValue("");
                        }}
                        className="w-full text-lg hover:bg-gray-50 text-left p-2"
                      >
                        {category}
                      </button>
                    ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <TextInputField
            title="Short description"
            name="shortDesc"
            type="text"
            maxLen={200}
            error={errors.shortDesc}
            disabled={loadingEditProduct}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
            value={values.shortDesc}
          />
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="condition"
                className="font-semibold text-gray-700 "
              >
                Condition{" "}
                {errors.condition &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("condition"))) && (
                    <span className="text-red-500">{errors.condition}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["condition"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("condition") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("condition");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="condition"
              name="condition"
              // autoComplete=""
              value={values.condition}
              onChange={onChange}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("condition"))
              }
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {["New", "Used"].map((condition, index) => (
                <option key={index} value={condition}>
                  {condition}
                </option>
              ))}
            </select>
          </div>

          <TextInputField
            title="In stock"
            name="originalStock"
            type="number"
            min={1}
            max={10000}
            value={values.originalStock}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.originalStock}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />

          <TextInputField
            title="SKU Number"
            name="skuNum"
            type="text"
            maxLen={20}
            error={errors.skuNum}
            disabled={loadingEditProduct}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
            value={values.skuNum}
          />

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="highlights"
                className="font-semibold text-gray-700 "
              >
                Highlights (optional){" "}
                {errors.highlights &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("highlights"))) && (
                    <span className="text-red-500">{errors.highlights}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["highlights"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("highlights") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("highlights");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            {values.highlights &&
              values.highlights.map((highlight, index) => (
                <div className="flex items-center w-full justify-start space-x-2">
                  <p>•</p>
                  <input
                    type={"text"}
                    value={highlight}
                    onChange={(e) => {
                      var curHighlights = [...values.highlights];
                      curHighlights[index] = e.target.value;
                      setValues({ ...values, highlights: curHighlights });
                    }}
                    className={`sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
                    placeholder={""}
                    disabled={
                      loadingEditProduct ||
                      (values.fieldsToEdit &&
                        !values.fieldsToEdit.includes("highlights"))
                    }
                    maxLength={200}
                  />
                </div>
              ))}
          </div>

          <TextInputField
            title="More details"
            name="moreDetails"
            value={values.moreDetails}
            onChange={onChange}
            type="multiline"
            error={errors.moreDetails}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
            maxLen={300}
          />

          <TextInputField
            title="Usual link"
            name="usualLink"
            type={"url"}
            error={errors.usualLink}
            disabled={loadingEditProduct}
            onChange={onChange}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            value={values.usualLink}
            editFunc={editProduct}
            prefix="https://"
          />

          <TextInputField
            title="Usual price"
            name="usualPrice"
            type="money"
            // min={15 * 100}
            // max={2000 * 100}
            value={values.usualPrice}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.usualPrice}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />

          <TextInputField
            title="Discount (%)"
            name="discountPercentage"
            type="number"
            min={10}
            max={60}
            value={values.discountPercentage}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.discountPercentage}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />

          <div className="w-full flex items-start justify-start space-x-4">
            <TextInputField
              title="Discount"
              type="money"
              value={values.discount.toFixed(2)}
              disabled={true}
            />
            <TextInputField
              title="Discounted price"
              type="money"
              value={values.discountedPrice.toFixed(2)}
              disabled={true}
            />
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="shippingFromCountry"
                className="font-semibold text-gray-700 "
              >
                Shipping from{" "}
                {errors.shippingFromCountry &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("shippingFromCountry"))) && (
                    <span className="text-red-500">
                      {errors.shippingFromCountry}
                    </span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["shippingFromCountry"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("shippingFromCountry") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("shippingFromCountry");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="shippingFromCountry"
              name="shippingFromCountry"
              autoComplete="country-name"
              value={values.shippingFromCountry}
              onChange={onChange}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("shippingFromCountry"))
              }
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {countries.map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>
          </div>

          <TextInputField
            title="Weight (g)"
            name="weight"
            type="number"
            min={1}
            max={20000}
            value={values.weight}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.weight}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />

          <TextInputField
            title="Length (cm)"
            name="breadth"
            type="number"
            min={1}
            max={600}
            value={values.breadth}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.breadth}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />
          <TextInputField
            title="Width (cm)"
            name="width"
            type="number"
            min={1}
            max={600}
            value={values.width}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.width}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />
          <TextInputField
            title="Height (cm)"
            name="height"
            type="number"
            min={1}
            max={600}
            value={values.height}
            onChange={onChange}
            onChangeNumber={onChangeNumber}
            error={errors.height}
            disabled={loadingEditProduct}
            setFieldsToEdit={setFieldsToEdit}
            fieldsToEdit={values.fieldsToEdit}
            editFunc={editProduct}
          />
          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="startSaleAt"
                className="font-semibold text-gray-700 "
              >
                Sale start time{" "}
                {errors.startSaleAt &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("startSaleAt"))) && (
                    <span className="text-red-500">{errors.startSaleAt}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["startSaleAt"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("startSaleAt") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("startSaleAt");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            <input
              type="datetime-local"
              id="datetime"
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
              value={displayStartSaleAt()}
              onChange={handleChangeDateTime}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("startSaleAt"))
              }
            />
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="saleLength"
                className="font-semibold text-gray-700 "
              >
                Sale length{" "}
                {errors.saleLength &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("saleLength"))) && (
                    <span className="text-red-500">{errors.saleLength}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["saleLength"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("saleLength") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("saleLength");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="saleLength"
              name="saleLength"
              // autoComplete=""
              value={values.saleLength}
              onChange={onChange}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit &&
                  !values.fieldsToEdit.includes("saleLength"))
              }
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              {["1 hour", "6 hours", "1 day", "1 week"].map(
                (saleLength, index) => (
                  <option key={index} value={saleLength}>
                    {saleLength}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label
                htmlFor="highlights"
                className="font-semibold text-gray-700 "
              >
                Delivery and return policies (optional){" "}
                {errors.deliveryAndReturnPolicies &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes(
                        "deliveryAndReturnPolicies"
                      ))) && (
                    <span className="text-red-500">
                      {errors.deliveryAndReturnPolicies}
                    </span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["deliveryAndReturnPolicies"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("deliveryAndReturnPolicies") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("deliveryAndReturnPolicies");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            {values.deliveryAndReturnPolicies &&
              values.deliveryAndReturnPolicies.map((highlight, index) => (
                <div className="flex items-center w-full justify-start space-x-2">
                  <p>•</p>
                  <input
                    type={"text"}
                    value={highlight}
                    onChange={(e) => {
                      var curDeliveryAndReturnPolicies = [
                        ...values.deliveryAndReturnPolicies,
                      ];
                      curDeliveryAndReturnPolicies[index] = e.target.value;
                      setValues({
                        ...values,
                        deliveryAndReturnPolicies: curDeliveryAndReturnPolicies,
                      });
                    }}
                    className={`sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
                    placeholder={""}
                    disabled={
                      loadingEditProduct ||
                      (values.fieldsToEdit &&
                        !values.fieldsToEdit.includes(
                          "deliveryAndReturnPolicies"
                        ))
                    }
                    maxLength={200}
                  />
                </div>
              ))}
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="faqs" className="font-semibold text-gray-700 ">
                FAQs (optional){" "}
                {errors.faqs &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("faqs"))) && (
                    <span className="text-red-500">{errors.faqs}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["faqs"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("faqs") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("faqs");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            {values.faqs &&
              values.faqs.map((highlight, index) => (
                <div className="flex items-center w-full justify-start space-x-2">
                  <p>•</p>
                  <input
                    type={"text"}
                    value={highlight}
                    onChange={(e) => {
                      var curFaqs = [...values.faqs];
                      curFaqs[index] = e.target.value;
                      setValues({ ...values, faqs: curFaqs });
                    }}
                    className={`sm:text-lg mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
                    placeholder={""}
                    disabled={
                      loadingEditProduct ||
                      (values.fieldsToEdit &&
                        !values.fieldsToEdit.includes("faqs"))
                    }
                    maxLength={200}
                  />
                </div>
              ))}
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="tags" className="font-semibold text-gray-700 ">
                Tags (optional){" "}
                {errors.tags &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("tags"))) && (
                    <span className="text-red-500">{errors.tags}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["tags"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("tags") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("tags");
                      setInputValue("");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      editProduct();
                      setInputValue("");
                    }}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>

            {values.tags && values.tags.length > 0 && (
              <div
                className={`gap-2 mb-2 flex-wrap flex items-center justify-start mt-2`}
              >
                {values.tags.map((tag, index) => (
                  <div
                    key={index}
                    className="rounded-full lowercase flex items-center justify-start border-blue-600 bg-blue-50 pl-4 overflow-hidden py-1"
                  >
                    <p>{tag}</p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        const tagsCopy = values.tags ? [...values.tags] : [];
                        if (tagsCopy[index]) {
                          tagsCopy.splice(index, 1);
                        }
                        setValues({ ...values, tags: tagsCopy });
                      }}
                      disabled={
                        loadingEditProduct ||
                        (values.fieldsToEdit &&
                          !values.fieldsToEdit.includes("tags"))
                      }
                      className="text-gray-400 disabled:opacity-50 hover:text-gray-500 transition-all pl-2 pr-3"
                    >
                      <svg
                        className="w-4 fill-current"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
            <input
              className={`sm:text-lg mt-1 lowercase w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-200 border focus:border-indigo-500 focus:ring-indigo-500`}
              type={"text"}
              value={inputValue}
              onChange={(e) => {
                const value = e.target.value;
                setInputValue(value);
                const tags = value.split(",").map((tag) => tag.trim());
                if (tags.length > 1) {
                  if (values.tags) {
                    const newTags = [...values.tags, ...tags].filter(
                      (item, pos, self) => self.indexOf(item) === pos
                    );
                    setValues({
                      ...values,
                      tags: newTags.slice(0, 4),
                    });
                  } else {
                    setValues({
                      ...values,
                      tags: tags.slice(0, 4),
                    });
                  }
                  setInputValue("");
                }
              }}
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" ||
                  event.key === "," ||
                  event.key === "."
                ) {
                  event.preventDefault();
                  const tag = inputValue.trim();
                  if (tag) {
                    if (values.tags) {
                      if (values.tags.includes(tag)) {
                        setInputValue("");
                      } else {
                        setValues({ ...values, tags: [...values.tags, tag] });
                      }
                    } else {
                      setValues({ ...values, tags: [tag] });
                    }
                    setInputValue("");
                  }
                } else if (event.key === "Backspace" && !inputValue) {
                  event.preventDefault();
                  const tagsCopy = values.tags ? [...values.tags] : [];
                  tagsCopy.pop();

                  setValues({ ...values, tags: tagsCopy });
                }
              }}
              placeholder={""}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit && !values.fieldsToEdit.includes("tags"))
              }
              maxLength={200}
            />
          </div>

          <div className="w-full">
            <div className="flex items-center justify-between text-sm sm:text-base">
              <label htmlFor="adType" className="font-semibold text-gray-700 ">
                Ad type during Lucky Pet Games{" "}
                {errors.adType &&
                  (!values.fieldsToEdit ||
                    (values.fieldsToEdit &&
                      values.fieldsToEdit.includes("adType"))) && (
                    <span className="text-red-500">{errors.adType}</span>
                  )}
              </label>
              {values.fieldsToEdit.length === 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setValues({
                      ...values,
                      fieldsToEdit: ["adType"],
                    });
                  }}
                  className="text-blue-500 transition-all font-semibold hover:text-blue-700"
                >
                  Edit
                </button>
              )}
              {values.fieldsToEdit.includes("adType") && (
                <div className="flex items-center justify-end space-x-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldsToEdit("adType");
                    }}
                    className="text-red-500 transition-all font-semibold hover:text-red-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editProductCallback}
                    className="text-green-500 transition-all font-semibold hover:text-green-700"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <select
              id="adType"
              name="adType"
              // autoComplete=""
              value={values.adType}
              onChange={(e) => {
                onChange(e, true);
              }}
              disabled={
                loadingEditProduct ||
                (values.fieldsToEdit && !values.fieldsToEdit.includes("adType"))
              }
              className="sm:text-lg disabled:bg-gray-50 mt-1 w-full shadow-sm flex-1 px-3 py-2 rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value={null}>None</option>
              <option value={0}>Image ad (+$0.71)</option>
              <option value={1}>Video ad (+$3.02)</option>
            </select>
          </div>

          {values.adType === 1 && (
            <ProductVideoInputField
              title="Ad video"
              name="adVideoFile"
              onChangeMedia={onChangeMedia}
              value={values.adVideoUrl}
              editFunc={editProduct}
              values={values}
              disabled={
                loadingEditProduct
                // ||
                // (values.fieldsToEdit && !values.fieldsToEdit.includes("adType"))
              }
              shouldEdit={true}
              setPreviewVideoURL={setPreviewVideoURL}
              previewVideoURL={previewVideoURL}
              error={errors.adVideoFile}
            />
          )}

          {(targetProduct.status === 0 || targetProduct.status === 1) && (
            <button
              onClick={(e) => {
                e.preventDefault();
                approveProduct();
              }}
              className="border rounded-md uppercase font-semibold border-gray-200 py-2 w-full text-center hover:bg-gray-50 transition-all"
            >
              Approve product
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default EditProduct;
