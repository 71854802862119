import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

export default function MultiMediaInputField({
  title,
  value,
  name,
  onChangeMedia,
  editFunc,
  error,
  shouldEdit,
  values,
  previewMediaURLs,
  setPreviewMediaURLs,
  disabled,
  isAttachmentBtn,
  previewMediaNames,
  setPreviewMediaNames,
  setValues,
  pet,
}) {
  const onDrop = async (files) => {
    if (!disabled && files && files.length > 0) {
      let uploadFiles = [];
      let tempFileUrls = [];
      let tempFileNames = [];

      for (let i = 0; i < files.length; ++i) {
        const mediaTempUrl = URL.createObjectURL(files[i]);
        tempFileUrls.push(mediaTempUrl);
        uploadFiles.push(files[i]);
        tempFileNames.push(files[i].name);
      }

      // todo: keep track of the file types
      setPreviewMediaURLs([...previewMediaURLs, ...tempFileUrls]);
      setPreviewMediaNames([...previewMediaNames, ...tempFileNames]);

      setValues({
        ...values,

        [name]: [...values[name], ...uploadFiles],
      });

      if (shouldEdit) {
        editFunc({
          variables: {
            [name]: [...values[name], ...uploadFiles],
            fieldsToEdit: [name],
          },
        });
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept:
      pet && pet.isPaidPetSubscriber
        ? {
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
            "image/jpg": [".jpg"],
            "video/mp4": [".mp4"],
            "video/mov": [".mov"],
          }
        : {
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
            "image/jpg": [".jpg"],
          },

    // maxSize: 104857600, //10 MB
    multiple: true,
  });

  useEffect(() => {
    if (value) {
      setPreviewMediaURLs(value);
      setPreviewMediaNames(value);
    }
  }, [value, setPreviewMediaURLs, setPreviewMediaNames]);

  // useEffect(() => {
  //   if (value) {
  //     setPreviewVideoURL(value);
  //   }
  // }, [value]);

  return isAttachmentBtn ? (
    <button
      onClick={(e) => {
        e.preventDefault();
      }}
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      type="button"
      className={`${
        isDragActive ? "bg-blue-700 animate-pulse" : ""
      } pt-2 pb-4 pr-2 focus:outline-none`}
    >
      <input {...getInputProps()} />

      <svg
        className="w-5"
        viewBox="0 0 26 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9998 11.6667H6.58317C4.00484 11.6667 1.9165 9.57834 1.9165 7.00001C1.9165 4.42168 4.00484 2.33334 6.58317 2.33334H21.1665C22.7765 2.33334 24.0832 3.64001 24.0832 5.25001C24.0832 6.86001 22.7765 8.16668 21.1665 8.16668H8.9165C8.27484 8.16668 7.74984 7.64168 7.74984 7.00001C7.74984 6.35834 8.27484 5.83334 8.9165 5.83334H19.9998V4.08334H8.9165C7.3065 4.08334 5.99984 5.39001 5.99984 7.00001C5.99984 8.61001 7.3065 9.91668 8.9165 9.91668H21.1665C23.7448 9.91668 25.8332 7.82834 25.8332 5.25001C25.8332 2.67168 23.7448 0.583344 21.1665 0.583344H6.58317C3.0365 0.583344 0.166504 3.45334 0.166504 7.00001C0.166504 10.5467 3.0365 13.4167 6.58317 13.4167H19.9998V11.6667Z"
          fill="white"
        />
      </svg>
    </button>
  ) : (
    <div className="flex w-full items-start shrink-0 flex-col justify-start space-y-2 sm:text-base text-sm">
      {title && (
        <label className="font-semibold whitespace-nowrap truncate text-gray-700">
          {title} {error && <span className="text-red-500">{error}</span>}
        </label>
      )}
      <button
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
        type="button"
        className={`${
          isDragActive ? "bg-blue-700 animate-pulse" : ""
        } w-full group relative flex-shrink-0 transition-all h-12 snap-center hover:text-gray-500 text-gray-400 flex items-center justify-center  border border-gray-200 rounded-md hover:bg-white/80 bg-white overflow-hidden p-2`}
      >
        <input {...getInputProps()} />

        <div className="inset-0  m-auto items-center flex justify-center absolute transition-opacity ">
          <svg
            className="h-4 m-auto fill-current text-gray-400 group-hover:text-gray-500 transition-all"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 13V7H14L7 0L0 7H4V13H10ZM7 2.83L9.17 5H8V11H6V5H4.83L7 2.83ZM14 17V15H0V17H14Z"
            />
          </svg>
        </div>

        {/* {(previewVideoURL || value) && (
          <video
            className="flex-grow-0 w-full h-full top-0 object-contain z-10"
            // controls={false}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={previewVideoURL || value} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )} */}
      </button>

      {(previewMediaURLs || value) &&
        (previewMediaURLs || value).length > 0 && (
          <div className="w-full h-full flex items-start justify-start space-x-3 overflow-x-auto ">
            {(previewMediaURLs || value).map((media, index) => (
              <div className="h-full ">
                <div className="relative  bg-white border-gray-200 border hover:bg-white/80 h-36 sm:h-40 aspect-[3/4] rounded-lg overflow-hidden shrink-0">
                  {media.includes(".mp4") || media.includes(".mov") ? (
                    <video
                      className="object-contain h-full"
                      // controls={false}
                      autoPlay={true}
                      muted={true}
                      loop={true}
                    >
                      <source src={media} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      className="h-full object-cover w-full shrink-0"
                      src={media}
                      alt={name}
                    />
                  )}

                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      let tempPreviewMediaURLs = [...previewMediaURLs];
                      let tempPreviewMediaNames = [...previewMediaNames];

                      tempPreviewMediaURLs.splice(index, 1);
                      tempPreviewMediaNames.splice(index, 1);

                      setPreviewMediaURLs(tempPreviewMediaURLs);
                      setPreviewMediaNames(tempPreviewMediaNames);
                    }}
                    className="bg-red-600 absolute top-1 right-2 aspect-square group p-1 rounded-full"
                  >
                    <svg
                      className="w-1.5 h-1.5 text-gray-700 group-hover:opacity-100  transition-all sm:opacity-0 fill-current "
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
                    </svg>
                  </button>
                </div>

                <div className="mt-1 flex items-center justify-between space-x-2 ">
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      // Ensure there is a previous image to swap with
                      if (index > 0) {
                        // Copy arrays to avoid mutating state directly
                        let tempPreviewMediaURLs = [...previewMediaURLs];
                        let tempPreviewMediaNames = [...previewMediaNames];

                        // Swap the current image with the previous one in the array
                        [
                          tempPreviewMediaURLs[index],
                          tempPreviewMediaURLs[index - 1],
                        ] = [
                          tempPreviewMediaURLs[index - 1],
                          tempPreviewMediaURLs[index],
                        ];

                        [
                          tempPreviewMediaNames[index],
                          tempPreviewMediaNames[index - 1],
                        ] = [
                          tempPreviewMediaNames[index - 1],
                          tempPreviewMediaNames[index],
                        ];

                        // Update state with the modified arrays
                        setPreviewMediaURLs(tempPreviewMediaURLs);
                        setPreviewMediaNames(tempPreviewMediaNames);
                      }
                    }}
                    className="disabled:opacity-20"
                    disabled={index === 0}
                  >
                    <svg
                      className="w-3 h-3 rotate-180 hover:text-gray-500 text-gray-700 transition-all fill-current "
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.5 10L5.5 5L0.5 0V10Z" />
                    </svg>
                  </button>
                  <p className="truncate text-center text-sm flex-1">
                    {index + 1}
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      // Ensure there is a next image to swap with
                      if (index < previewMediaURLs.length - 1) {
                        // Copy arrays to avoid mutating state directly
                        let tempPreviewMediaURLs = [...previewMediaURLs];
                        let tempPreviewMediaNames = [...previewMediaNames];

                        // Swap the current image with the next one in the array
                        [
                          tempPreviewMediaURLs[index],
                          tempPreviewMediaURLs[index + 1],
                        ] = [
                          tempPreviewMediaURLs[index + 1],
                          tempPreviewMediaURLs[index],
                        ];

                        [
                          tempPreviewMediaNames[index],
                          tempPreviewMediaNames[index + 1],
                        ] = [
                          tempPreviewMediaNames[index + 1],
                          tempPreviewMediaNames[index],
                        ];

                        // Update state with the modified arrays
                        setPreviewMediaURLs(tempPreviewMediaURLs);
                        setPreviewMediaNames(tempPreviewMediaNames);
                      }
                    }}
                    className="disabled:opacity-20"
                    disabled={
                      ((previewMediaURLs || value)?.length || 1) - 1 === index
                    }
                  >
                    <svg
                      className="w-3 h-3 hover:text-gray-500 text-gray-700 transition-all fill-current "
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0.5 10L5.5 5L0.5 0V10Z" />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}
