import React, { useEffect, useLayoutEffect, useRef } from "react";

import { useInView } from "react-intersection-observer";
import CommentBox from "./CommentBox";

function JustWideComments({
  targetCustomer,
  comments,
  setValues,
  values,
  navigate,
  country,
  fetchMoreParams,

  shouldOpenQRCodeBox,
  loadingComments,
  toggleShowQRCode,
  showedQRCodeOnce,
  closedQRCodeOnce,

  newComments,
  headCommentToNewReplies,
  fetchMoreComments,
}) {
  const commentsContainerRef = useRef();

  useLayoutEffect(() => {
    // Scroll to the bottom after rendering
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      commentsContainerRef.current.scrollTop = 0;
    }
  }, [comments]);

  useEffect(() => {
    if (
      comments &&
      comments.length > 0 &&
      commentsContainerRef &&
      commentsContainerRef.current
    ) {
      var timer = setTimeout(() => {
        // Scroll to the bottom after a delay

        commentsContainerRef.current.scrollTop = 0;
      }, 200); // Adjust the delay time as needed
    }
    return () => clearTimeout(timer);
  }, [comments]);
  const [fetchMoreCommentsRef, isFetchMoreCommentsVisible] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (
      !loadingComments &&
      isFetchMoreCommentsVisible &&
      comments &&
      comments.length > 10
    ) {
      // console.log("ji");
      const newSeenIds = comments.map((f) => f.id);

      // setValues({ ...values, seenIds: newSeenIds });
      fetchMoreComments({
        variables: {
          ...fetchMoreParams,
          seenIds: newSeenIds,
        },

        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newCommentRecs =
            fetchMoreResult.getRecommendedForumPostsBySearch;

          return {
            ...prev,

            getRecommendedForumPostsBySearch: [...comments, ...newCommentRecs],
          };
        },
      });
    }
  }, [
    isFetchMoreCommentsVisible,

    fetchMoreComments,
    comments,
    fetchMoreParams,
    loadingComments,
  ]);

  return (
    <div className="relative h-full overflow-x-hidden overflow-y-auto flex w-full text-black flex-col items-start">
      <div
        ref={commentsContainerRef}
        className="mb-2 short:mb-2 motion-safe:animate-fadeIn scroll-smooth rounded-xl h-full overflow-y-auto flex w-full text-black flex-col items-start"
      >
        {(comments && comments.length > 0
          ? [...newComments, ...comments]
          : [...newComments]
        ).map((comment, index) => (
          <div
            className="flex items-center justify-start w-full flex-col"
            key={comment.id}
          >
            {comment.details ? (
              <CommentBox
                comment={comment}
                targetCustomer={targetCustomer}
                index={index}
                values={values}
                setValues={setValues}
                navigate={navigate}
                newReplies={headCommentToNewReplies[comment.id] || []}
                isFloating={true}
              />
            ) : (
              <></>
            )}
          </div>
        ))}

        <div ref={fetchMoreCommentsRef}></div>
      </div>
    </div>
  );
}

export default JustWideComments;
