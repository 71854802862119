import React, { useContext, useEffect } from "react";
import usePageTracking from "../../util/usePageTracking";

import { useNavigate } from "react-router";

import { CustomerAuthContext } from "../../context/customerAuth";

function LogoutCustomer(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  useEffect(() => {
    if (!customer) {
      navigate("/login");
    }
  }, [customer, navigate]);

  useEffect(() => {
    document.title = "Logout | The Lucky Pet";
  }, []);

  useEffect(() => {
    if (customer) {
      logout();
    }
  }, [logout, customer]);

  return (
    <div className="w-screen overflow-hidden relative h-full min-h-screen bg-black"></div>
  );
}

export default LogoutCustomer;
