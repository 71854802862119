import { useQuery } from "@apollo/client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { useNavigate, useParams } from "react-router";
import backgroundMusic from "../../music/backgroundMusic.mp3";
import usePageTracking from "../../util/usePageTracking";

import Confetti from "react-confetti";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import GenericPaymentSuccessfulForm from "../../components/creator/GenericPaymentSuccessfulForm";
import PowerUpPaymentSuccessfulForm from "../../components/creator/PowerUpPaymentSuccessfulForm";
import PurchasePowerUpForm from "../../components/creator/PurchasePowerUpForm";
import {
  defaultPicUrl,
  generateGreetings,
  getNumOfPlayIconsToShow,
  useForm,
  useWindowSize,
} from "../../util/hooks";

import BuyCoinsForm from "../../components/creator/BuyCoinsForm";
import TNReviewForm from "../../components/creator/TNReviewForm";

import CodeForm from "../../components/creator/CodeForm";
import CommentsModal from "../../components/creator/CommentsModal";
import EmptyPuzzleBox from "../../components/creator/EmptyPuzzleBox";
import HorAdOverlayBox from "../../components/creator/HorAdOverlayBox";
import MiniNavBar from "../../components/creator/MiniNavBar";
import MobileFooter from "../../components/creator/MobileFooter";
import PuzzleWideCommentSection from "../../components/creator/PuzzleWideCommentSection";
import RefreshPuzzlesBox from "../../components/creator/RefreshPuzzlesBox";
import TopBar from "../../components/creator/TopBar";

import BuyPetSubscriptionForm from "../../components/creator/BuyPetSubscriptionForm";
import PuzzleBox from "../../components/creator/PuzzleBox";
import PuzzlePlayIcon from "../../components/creator/PuzzlePlayIcon";
import { CustomerAuthContext } from "../../context/customerAuth";
import { GuestAuthContext } from "../../context/guestAuth";
import { customerClient, guestClient } from "../../GraphqlApolloClients";
import {
  FETCH_AD_BOOKMARK_BY_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_GUEST_CUSTOMER,
  FETCH_RECOMMENDED_PUZZLES,
  FETCH_RELEVANT_AD,
  FETCH_RELEVANT_PRODUCT_ADS,
  FETCH_SHOULD_ASK_FOR_REVIEW,
} from "../../util/gqlFunctions";

function Puzzles(props) {
  usePageTracking();

  let navigate = useNavigate();

  const { values, setValues } = useForm(() => {}, {
    country: "",

    inviteCode: "",
  });

  const { customer, logoutCustomer: logout } = useContext(CustomerAuthContext);
  const { loginGuest: loginGuestContext, guest } = useContext(GuestAuthContext);
  const { puzzleId } = useParams();

  const size = useWindowSize();

  const isChubby = false;

  const [loadingRecommendedPs, setLoadingRecommendedPs] = useState(false);

  const [subPopupReason, setSubPopupReason] = useState(null);

  const [initialClickedPId, setInitialClickedPId] = useState(puzzleId || null);
  const {
    data: { getCustomer: targetCustomer } = {},
    loading: loadingCustomerInfo,
    refetch: refetchCustomer,
  } = useQuery(FETCH_CUSTOMER, {
    client: customerClient,
    // fetchPolicy: "network-only",
  });
  const [wantToScrollToPId, setWantToScrollToPId] = useState(null);
  const [puzzleIdToScrollIntoView, setPuzzleIdToScrollIntoView] =
    useState(null);
  const [pFetchErrShownAtPId, setPFetchErrShownAtPId] = useState(null);

  const {
    data: { getRecommendedPuzzles: recommendedPuzzles } = {},
    refetch: refetchRecommendedPuzzles,
    loading: loadingRecommendedPuzzles,
  } = useQuery(FETCH_RECOMMENDED_PUZZLES, {
    variables: {
      petActivityShortForm: null,
      pageSize: getNumOfPlayIconsToShow(size) + 1,
      seenIds: [],
      clickedPuzzleId: puzzleId,
    },
    client: customer ? customerClient : guestClient,

    onCompleted: (data) => {
      if (wantToScrollToPId) {
        setPuzzleIdToScrollIntoView(wantToScrollToPId);
      }
      setLoadingRecommendedPs(false);
    },
    skip: loadingCustomerInfo,
    // errorPolicy:"none",
    onError: (err) => {
      console.log(err);

      setLoadingRecommendedPs(false);

      if (err.graphQLErrors[0]) {
        if (
          err.graphQLErrors[0].extensions.errors &&
          err.graphQLErrors[0].extensions.errors.subErr
        ) {
          console.log("err");
          setSubPopupReason(err.graphQLErrors[0].extensions.errors.subErr);
        }

        if (
          err.graphQLErrors[0].extensions.errors &&
          err.graphQLErrors[0].extensions.errors.pFetchErr
        ) {
          setPuzzleFetchErr(err.graphQLErrors[0].extensions.errors.pFetchErr);
          setPFetchErrShownAtPId(puzzleIdInView);
          setIsPFetchErrShown(true);
        }
      }

      if (targetCustomer) {
        toggleBuySubscriptionForm(0);
      } else {
        navigate("/register");
      }
    },
  });

  useEffect(() => {
    if (
      targetCustomer &&
      (!targetCustomer.curRegistrationStep ||
        targetCustomer.curRegistrationStep < 3)
    ) {
      navigate("/completeRegistration/" + targetCustomer.curRegistrationStep);
    }
  }, [targetCustomer, navigate]);

  useEffect(() => {
    document.title = "Puzzles | The Lucky Pet";
  }, []);

  const [isPlayingBGMusic, setIsBGMusicPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlayingBGMusic) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlayingBGMusic]);
  useEffect(() => {
    audioRef.current.volume = 0.4;
  }, []);

  useEffect(() => {
    if (targetCustomer && targetCustomer.username === "lucky") {
      setIsBGMusicPlaying(false);
    }
  }, [targetCustomer]);

  const [shouldOpenPuzzles, setShouldOpenPuzzles] = useState(false);
  const [shouldOpenAddPetModal, setShouldOpenAddPetModal] = useState(false);

  useEffect(() => {
    if (
      !shouldOpenPuzzles &&
      recommendedPuzzles &&
      recommendedPuzzles.length > 0
    ) {
      setInitialClickedPId(null);
      setShouldOpenPuzzles(true);
    }
  }, [
    recommendedPuzzles,
    setShouldOpenPuzzles,
    shouldOpenPuzzles,
    initialClickedPId,
  ]);

  const [checkedIsCustomerLoggedIn, setCheckedIsCustomerLoggedIn] =
    useState(false);

  useEffect(() => {
    if (
      (customer &&
        !loadingCustomerInfo &&
        !targetCustomer &&
        !checkedIsCustomerLoggedIn) ||
      (targetCustomer && targetCustomer.isBlocked)
    ) {
      logout();
      setCheckedIsCustomerLoggedIn(true);
    }
  }, [
    customer,
    loadingCustomerInfo,
    targetCustomer,
    logout,
    checkedIsCustomerLoggedIn,
  ]);

  const resetInterval = 1000;

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const [puzzleFetchErr, setPuzzleFetchErr] = useState(false);
  const [isPFetchErrShown, setIsPFetchErrShown] = useState(false);

  useEffect(() => {
    let timeout;
    if (puzzleFetchErr && pFetchErrShownAtPId && isPFetchErrShown) {
      timeout = setTimeout(() => setIsPFetchErrShown(false), 4000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [puzzleFetchErr, resetInterval, isPFetchErrShown, pFetchErrShownAtPId]);

  const [seenIds, setSeenIds] = useState([]);
  const stripe = useStripe();
  const elements = useElements();

  const [puzzleIdInView, setPuzzleIdInView] = useState(null);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Check out #TheLuckyPet\n\n";

    if (navigator.share) {
      navigator
        .share({
          title: "Play The Lucky Pet!",
          text: textToShare,
          url: "http://thelucky.pet/",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const {
    data: { getShouldAskForReview: shouldAskForReview } = {},

    refetch: refetchShouldAskForReview,
  } = useQuery(FETCH_SHOULD_ASK_FOR_REVIEW, {
    client: customer ? customerClient : guestClient,
    variables: { askingFromLoc: 0, siteId: 1 },
  });

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const [shouldShowCommentSection, setShouldShowCommentSection] =
    useState(false);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);

  const { data: { getRelevantAd: regularHorAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 6, siteId: 1 },
    }
  );

  const { data: { getRelevantAd: regularVerAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 7, siteId: 1 },
    }
  );

  const { data: { getAdBookmarkByCustomer: regularHorAdBookmark } = {} } =
    useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
      client: customer ? customerClient : guestClient,

      variables: { adId: regularHorAd ? regularHorAd.id : "" },
    });
  const { data: { getAdBookmarkByCustomer: regularVerAdBookmark } = {} } =
    useQuery(FETCH_AD_BOOKMARK_BY_CUSTOMER, {
      client: customer ? customerClient : guestClient,

      variables: {
        adId: regularVerAd ? regularVerAd.id : "",
      },
    });

  const { data: { getAdBookmarkByCustomer: targetAdBookmark } = {} } = useQuery(
    FETCH_AD_BOOKMARK_BY_CUSTOMER,
    {
      client: customer ? customerClient : guestClient,

      variables: { adId: regularHorAd ? regularHorAd.id : "" },
    }
  );

  const { data: { getRelevantAd: narrowHorAd } = {} } = useQuery(
    FETCH_RELEVANT_AD,
    {
      client: customer ? customerClient : guestClient,
      variables: { format: 0, siteId: 1 },
    }
  );

  const { data: { getAdBookmarkByCustomer: narrowAdBookmark } = {} } = useQuery(
    FETCH_AD_BOOKMARK_BY_CUSTOMER,
    {
      client: customer ? customerClient : guestClient,
      variables: {
        adId: narrowHorAd ? narrowHorAd.id : "",
      },
    }
  );

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);

  const { data: { getRelevantProductAds: productAds } = {} } = useQuery(
    FETCH_RELEVANT_PRODUCT_ADS,
    {
      client: customer ? customerClient : guestClient,
    }
  );

  const [shouldOpenProInviteCodeForm, setShouldOpenProInviteCodeForm] =
    useState(false);
  const [powerUpToPurchase, setPowerUpToPurchase] = useState(null);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [shouldOpenJoinedProPlanPopup, setShouldOpenJoinedProPlanPopup] =
    useState(false);
  const [petSubscriptionType, setSubscriptionType] = useState(null);

  const [
    shouldOpenSubPaymentSuccessfulForm,
    setShouldOpenSubPaymentSuccessfulForm,
  ] = useState(false);
  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);

  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);
  const [shouldOpenBuyCoinsForm, setShouldOpenBuyCoinsForm] = useState(false);
  const [
    shouldOpenCoinPurchaseSuccessfulForm,
    setShouldOpenCoinPurchaseSuccessfulForm,
  ] = useState(false);
  const [shouldOpenAdPopup, setShouldOpenAdPopup] = useState(false);

  const [shouldShowBattleLeaderboard, setShouldShowBattleLeaderboard] =
    useState(false);

  const togglePurchasePowerUpForm = (powerUpToPurchase) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setPowerUpToPurchase(powerUpToPurchase);
  };

  const toggleProInviteCodeForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenProInviteCodeForm(!shouldOpenProInviteCodeForm);
  };

  const toggleJoinedProPlanPopup = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setPowerUpToPurchase(null);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenJoinedProPlanPopup(!shouldOpenJoinedProPlanPopup);
  };

  const toggleAdPopup = (shouldOpen) => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenAdPopup(shouldOpen);
  };
  // console.log("hi");
  useEffect(() => {
    let timeout;
    if (shouldOpenAdPopup) {
      timeout = setTimeout(() => {
        if (shouldOpenAdPopup) {
          toggleAdPopup(false);
        }
      }, 20000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldOpenAdPopup]);

  const toggleBuyCoinsForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenBuyCoinsForm(!shouldOpenBuyCoinsForm);
  };

  const toggleCoinPurchaseSuccessfulForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenCoinPurchaseSuccessfulForm(
      !shouldOpenCoinPurchaseSuccessfulForm
    );
  };

  const togglePaymentSuccessfulForm = () => {
    setPowerUpToPurchase(null);
    setShouldOpenSubPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    console.log(shouldShowBattleLeaderboard);
    console.log(targetAdBookmark);
    setShouldOpenPaymentSuccessfulForm(!shouldOpenPaymentSuccessfulForm);
  };
  const [viewedReviewForm, setViewedReviewForm] = useState(false);

  const toggleBuySubscriptionForm = useCallback(
    (petSubscriptionType) => {
      setShouldOpenSubPaymentSuccessfulForm(false);
      setPowerUpToPurchase(null);
      setShouldOpenPaymentSuccessfulForm(false);
      setShouldOpenReviewSentForm(false);
      setShouldOpenCoinPurchaseSuccessfulForm(false);
      setShouldOpenBuyCoinsForm(false);
      setShouldOpenAdPopup(false);
      setShouldShowMiniNavBar(false);
      setShouldShowBattleLeaderboard(false);
      setShouldOpenProInviteCodeForm(false);

      setShouldOpenAddPetModal(false);

      setSubscriptionType(petSubscriptionType);

      if (
        petSubscriptionType === null &&
        subPopupReason === "You've reached 6 posts on The Lucky Pet."
      ) {
        navigate(-1);
      }

      if (
        petSubscriptionType === null &&
        !viewedReviewForm &&
        shouldAskForReview
      ) {
        setShouldOpenReviewForm(true);
      } else {
        setShouldOpenReviewForm(false);
      }
    },
    [viewedReviewForm, shouldAskForReview, subPopupReason, navigate]
  );
  const location = useLocation();
  const toggleAddPetModal = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenSubPaymentSuccessfulForm(false);

    setShouldOpenAddPetModal(!shouldOpenAddPetModal);
  };

  const toggleSubPaymentSuccessfulForm = () => {
    setSubscriptionType(null);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenSubPaymentSuccessfulForm(!shouldOpenSubPaymentSuccessfulForm);
  };

  const toggleReviewForm = useCallback(() => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewSentForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  }, [shouldOpenReviewForm]);

  const toggleReviewSentForm = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  const toggleShouldShowCommentSection = () => {
    setShouldOpenSubPaymentSuccessfulForm(false);
    setPowerUpToPurchase(null);
    setShouldOpenPaymentSuccessfulForm(false);
    setSubscriptionType(null);
    setShouldOpenReviewForm(false);
    setShouldOpenCoinPurchaseSuccessfulForm(false);
    setShouldOpenBuyCoinsForm(false);
    setShouldOpenAdPopup(false);
    setShouldShowMiniNavBar(false);
    setShouldShowBattleLeaderboard(false);
    setShouldOpenProInviteCodeForm(false);

    setShouldOpenAddPetModal(false);

    setShouldShowCommentSection(!shouldShowCommentSection);
  };

  const {
    data: { getGuestCustomer: targetGuestCustomer } = {},
    refetch: refetchGuestCustomer,
  } = useQuery(FETCH_GUEST_CUSTOMER, {
    client: guestClient,
  });

  const [indexVisible, setIndexVisible] = useState(0);

  const [initializedMuteVals, setInitializedMuteVals] = useState(false);
  useEffect(() => {
    if (!initializedMuteVals && (targetCustomer || targetGuestCustomer)) {
      setShouldPlaySoundEffects(
        (targetCustomer || targetGuestCustomer).areSoundEffectsMuted === true
          ? false
          : true
      );
      setInitializedMuteVals(true);
    }
  }, [
    targetGuestCustomer,
    shouldPlaySoundEffects,
    initializedMuteVals,
    targetCustomer,
  ]);

  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (!finishedLoading) {
      if (
        !loadingCustomerInfo &&
        !loadingRecommendedPuzzles &&
        !loadingRecommendedPs
      ) {
        setFinishedLoading(true);
      }
    }
  }, [
    finishedLoading,
    loadingCustomerInfo,
    loadingRecommendedPuzzles,
    loadingRecommendedPs,
  ]);

  const [lastPuzzleId, setLastPuzzleId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (
        recommendedPuzzles &&
        recommendedPuzzles.length > 0 &&
        puzzleIdInView ===
          recommendedPuzzles[recommendedPuzzles.length - 1].id &&
        recommendedPuzzles[recommendedPuzzles.length - 1].id !== lastPuzzleId &&
        !loadingRecommendedPs &&
        (targetCustomer || targetGuestCustomer)?.highestPuzzleLevel >=
          recommendedPuzzles[recommendedPuzzles.length - 1].level
      ) {
        setLoadingRecommendedPs(true);

        const result = await refetchRecommendedPuzzles({
          petActivityShortForm: null,
          pageSize: getNumOfPlayIconsToShow(size) + 1,
          seenIds,
          clickedPuzzleId: puzzleIdInView,
        });

        if (result && result.data) {
          setLoadingRecommendedPs(false);

          if (
            result.data.getRecommendedPuzzles &&
            result.data.getRecommendedPuzzles.length > 0
          ) {
            setLastPuzzleId(puzzleIdInView);
            setPuzzleIdToScrollIntoView(puzzleIdInView);
          } else {
            setLastPuzzleId(null);
            setPuzzleIdToScrollIntoView(null);
          }
        }
      }
    }

    fetchData();
  }, [
    recommendedPuzzles,
    loadingRecommendedPs,
    refetchRecommendedPuzzles,
    lastPuzzleId,
    puzzleIdInView,
    seenIds,
    size,
    targetCustomer,
    targetGuestCustomer,
  ]);

  useEffect(() => {
    if (
      recommendedPuzzles &&
      recommendedPuzzles.length > 0 &&
      puzzleIdInView &&
      !seenIds.includes(puzzleIdInView) &&
      shouldOpenPuzzles
    ) {
      setSeenIds([puzzleIdInView, ...seenIds]);
    }
  }, [puzzleIdInView, recommendedPuzzles, seenIds, shouldOpenPuzzles]);

  useEffect(() => {
    if (
      indexVisible >= 0 &&
      recommendedPuzzles &&
      recommendedPuzzles.length > 0 &&
      recommendedPuzzles[indexVisible] &&
      recommendedPuzzles[indexVisible].id !== puzzleIdInView &&
      !puzzleIdToScrollIntoView &&
      !loadingRecommendedPs &&
      !loadingRecommendedPuzzles
    ) {
      setPuzzleIdInView(recommendedPuzzles[indexVisible].id);
    }
  }, [
    puzzleIdInView,
    setPuzzleIdInView,
    loadingRecommendedPs,
    puzzleIdToScrollIntoView,
    recommendedPuzzles,
    indexVisible,
    loadingRecommendedPuzzles,
  ]);

  const [timeZone, setTimeZone] = useState("");

  // Get the user's time zone on component mount
  useEffect(() => {
    if (!timeZone) {
      const date = new Date();
      let timezone = date.toString().match(/([A-Z]+[+-][0-9]+.*)/)[1];
      timezone = timezone.split(" (")[1].split(")")[0];
      setTimeZone(timezone);
    }
  }, [timeZone]);

  return (
    <div
      className={`${
        ((size.width >= 1024 && regularHorAd) ||
          (size.width < 1024 && regularVerAd)) &&
        shouldOpenAdPopup
          ? "pointer-events-none"
          : ""
      } bg-light-blue w-screen overflow-hidden relative h-full min-h-screen`}
      // style={{ marginTop: `-${getKeyboardHeight()}px` }}
    >
      <script src="http://192.168.0.37:8097"></script>
      <audio ref={audioRef} src={backgroundMusic} loop volume={0.1} />

      {shouldShowCommentSection && puzzleIdInView && size.width < 640 && (
        <CommentsModal
          puzzleId={puzzleIdInView}
          customer={customer}
          navigate={navigate}
          targetGuestCustomer={targetGuestCustomer}
          targetCustomer={targetCustomer}
          puzzle={
            recommendedPuzzles && recommendedPuzzles.length > 0
              ? recommendedPuzzles[indexVisible]
              : null
          }
          country={values.country}
          shouldShowCommentSection={shouldShowCommentSection}
          setShouldShowCommentSection={setShouldShowCommentSection}
        />
      )}
      {shouldShowMiniNavBar && targetCustomer && (
        <MiniNavBar
          toggleAddPetModal={toggleAddPetModal}
          targetCustomer={targetCustomer}
          isSharingInviteToApps={isSharingInviteToApps}
          isSharingToApps={isSharingToApps || isSharingInviteToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
        />
      )}

      {shouldOpenPaymentSuccessfulForm && targetCustomer && (
        <PowerUpPaymentSuccessfulForm
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          targetCustomer={targetCustomer}
        />
      )}

      {powerUpToPurchase !== null && stripe && elements ? (
        <PurchasePowerUpForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          togglePaymentSuccessfulForm={togglePaymentSuccessfulForm}
          powerUpToPurchase={powerUpToPurchase}
          quizId={null}
          // puzzleId={curQuestion ? curQuestion.id : null}
          togglePurchasePowerUpForm={togglePurchasePowerUpForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenBuyCoinsForm && stripe && elements ? (
        <BuyCoinsForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          toggleCoinPurchaseSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
          shouldOpenBuyCoinsForm={shouldOpenBuyCoinsForm}
          quizId={null}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
        />
      ) : (
        <></>
      )}

      {shouldOpenReviewForm && (targetCustomer || targetGuestCustomer) ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetCustomer={targetCustomer}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      {petSubscriptionType !== null && stripe && elements && targetCustomer ? (
        <BuyPetSubscriptionForm
          stripe={stripe}
          elements={elements}
          targetCustomer={targetCustomer}
          togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
          petSubscriptionType={petSubscriptionType}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
          subPopupReason={subPopupReason}
        />
      ) : (
        <></>
      )}

      {shouldOpenProInviteCodeForm && targetCustomer && (
        <CodeForm
          title="Lucky Pet Pro Code"
          name="inviteCode"
          isCodeBoxOpen={shouldOpenProInviteCodeForm}
          setIsCodeBoxOpen={setShouldOpenProInviteCodeForm}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}

      {shouldOpenAddPetModal && targetCustomer && (
        <CodeForm
          title="Add Pet"
          // linkCaption={"Click here"}
          // caption={"Want to create a new pet?"}
          name="inviteCode"
          isCodeBoxOpen={shouldOpenAddPetModal}
          setIsCodeBoxOpen={setShouldOpenAddPetModal}
          typeOfCode={0}
          values={values}
          setValues={setValues}
          toggleJoinedProPlanPopup={toggleJoinedProPlanPopup}
          toggleProInviteCodeForm={toggleProInviteCodeForm}
        />
      )}
      {shouldOpenJoinedProPlanPopup && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleJoinedProPlanPopup}
            targetCustomer={targetCustomer}
            message={"Welcome to Lucky Pet Pro, " + targetCustomer.name + "!"}
            buttonMsg={"Thanks!"}
            popUpTitle={
              "Joined " +
              (targetCustomer.petSubscriptionType === 0
                ? "Individual"
                : targetCustomer.petSubscriptionType === 1
                ? "Duo"
                : targetCustomer.petSubscriptionType === 2
                ? "Family"
                : "Club") +
              " Successfully!"
            }
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}
      {shouldOpenSubPaymentSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleSubPaymentSuccessfulForm}
            targetCustomer={targetCustomer}
            message={"Welcome to Lucky Pet Pro, " + targetCustomer.name + "."}
            buttonMsg={"Thanks!"}
          />
          <div className="absolute z-[90] pointer-events-none inset-0">
            <Confetti
              width={size.width}
              height={size.height}
              recycle={false}
              numberOfPieces={size.width >= 640 ? 1500 : 1000}
              tweenDuration={30 * 1000}
              gravity={0.3}
            />
          </div>
        </>
      )}

      {shouldOpenCoinPurchaseSuccessfulForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleCoinPurchaseSuccessfulForm}
            targetCustomer={targetCustomer}
            message={
              "Tokens added to stash. Good luck, " + targetCustomer.name + "!"
            }
            buttonMsg={"Awesome!"}
            popUpTitle={"You're all set!"}
          />
        </>
      )}

      {shouldOpenReviewSentForm && targetCustomer && (
        <>
          <GenericPaymentSuccessfulForm
            togglePaymentSuccessfulForm={toggleReviewSentForm}
            targetCustomer={targetCustomer}
            popUpTitle={"Thank you!"}
            message={"This will help improve the game :)"}
            buttonMsg={"Awesome!"}
          />
        </>
      )}

      <div
        className={`grow-0 h-screen z-10 flex flex-col items-center justify-start max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:px-0 pt-4 xshort:pt-2 sm:pt-6 2xl:pt-12`}
      >
        <TopBar
          activityShortForm={null}
          navigate={navigate}
          toggleBuyCoinsForm={toggleBuyCoinsForm}
          targetCustomer={targetCustomer}
          targetGuestCustomer={targetGuestCustomer}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
          customer={customer}
          toggleBuySubscriptionForm={toggleBuySubscriptionForm}
          page="Memos"
        />

        <div
          className={`flex z-10 w-full pt-4 sm:pt-6 short:pt-2 items-start justify-center flex-1 h-full`}
        >
          <div className={`relative overflow-visible mt-10 h-full`}>
            <button
              onClick={(e) => {
                navigate("/search", { state: { from: location } });
              }}
              className="shrink-0 cursor-pointer group lg:absolute bottom-0 flex-col flex items-center justify-end overflow-hidden h-full right-full mx-1 md:mx-2 top-0"
            >
              <p
                className={`text-left font-semibold leading-none group-hover:opacity-70 transition-all mb-2 2xl:mb-4 text-sm sm:text-base 2xl:text-lg text-black block rotate-180 whitespace-nowrap`}
                style={{ writingMode: "vertical-rl" }}
              >
                Good {generateGreetings(targetCustomer)}
                {targetCustomer
                  ? ", " + targetCustomer.name.split(" ")[0]
                  : customer
                  ? ""
                  : ", friend"}{" "}
                - You have 945 activities to try
              </p>

              <div className="short:w-[1.25rem] w-[1.35rem] sm:w-7 2xl:w-10 aspect-square flex items-center justify-center border 2xl:border-2 border-black rounded-full overflow-hidden -rotate-90 transition-all shrink-0">
                <img
                  src={defaultPicUrl}
                  alt="Lucky"
                  className="object-cover group-hover:opacity-70 transition-all w-full h-full"
                />
              </div>
              <div
                className={`2xl:h-52 h-48 short:h-40 xshort:h-36 bg-gradient-to-b from-black to-transparent w-[1px] 2xl:w-[2px]`}
              ></div>
            </button>
          </div>
          <div
            className={`w-full scrollbar-hide h-full xshort:pb-14 short:pb-24 pb-28 sm:pb-24 2xl:pb-28 xshort:space-y-5 short:space-y-12 space-y-16 sm:space-y-10 snap-mandatory snap-y rounded-t-2xl flex flex-col items-center justify-start overflow-y-auto`}
          >
            <RefreshPuzzlesBox
              puzzleIdToScrollIntoView={puzzleIdToScrollIntoView}
              refetchRecommendedPuzzles={refetchRecommendedPuzzles}
              petActivityShortForm={null}
              pageSize={getNumOfPlayIconsToShow(size) + 1}
              seenIds={[]}
              setLoadingRecommendedPs={setLoadingRecommendedPs}
              loadingRecommendedPs={loadingRecommendedPs}
              setPuzzleIdToScrollIntoView={setPuzzleIdToScrollIntoView}
              setWantToScrollToPId={setWantToScrollToPId}
            />

            {recommendedPuzzles && recommendedPuzzles.length > 0 ? (
              <>
                {recommendedPuzzles.map((recommendedPuzzle, index) =>
                  !indexVisible || Math.abs(index - indexVisible) <= 1 ? (
                    <PuzzleBox
                      pFetchErrShownAtPId={pFetchErrShownAtPId}
                      setSubPopupReason={setSubPopupReason}
                      puzzleFetchErr={puzzleFetchErr}
                      timeZone={timeZone}
                      customer={customer}
                      guest={guest}
                      isPFetchErrShown={isPFetchErrShown}
                      puzzle={recommendedPuzzle}
                      loadingRecommendedPs={loadingRecommendedPs}
                      index={index}
                      puzzleIdToScrollIntoView={puzzleIdToScrollIntoView}
                      setIndexVisible={setIndexVisible}
                      indexVisible={indexVisible}
                      setLoadingRecommendedPs={setLoadingRecommendedPs}
                      refetchShouldAskForReview={refetchShouldAskForReview}
                      togglePurchasePowerUpForm={togglePurchasePowerUpForm}
                      key={recommendedPuzzle.id + index}
                      shouldShowCommentSection={shouldShowCommentSection}
                      targetCustomer={targetCustomer}
                      shouldPlaySoundEffects={shouldPlaySoundEffects}
                      country={values.country}
                      shouldAskForReview={shouldAskForReview}
                      viewedReviewForm={viewedReviewForm}
                      toggleReviewForm={toggleReviewForm}
                      setViewedReviewForm={setViewedReviewForm}
                      puzzleIdInView={puzzleIdInView}
                      setPuzzleIdInView={setPuzzleIdInView}
                      setPuzzleIdToScrollIntoView={setPuzzleIdToScrollIntoView}
                      toggleShouldShowCommentSection={
                        toggleShouldShowCommentSection
                      }
                      refetchCustomer={refetchCustomer}
                      refetchGuestCustomer={refetchGuestCustomer}
                      shouldOpenAdPopup={
                        ((size.width >= 1024 && regularHorAd) ||
                          (size.width < 1024 && regularVerAd)) &&
                        shouldOpenAdPopup
                      }
                      loginGuest={loginGuestContext}
                      targetGuestCustomer={targetGuestCustomer}
                      setShouldOpenAdPopup={setShouldOpenAdPopup}
                      isChubby={isChubby}
                      toggleBuySubscriptionForm={toggleBuySubscriptionForm}
                    />
                  ) : (
                    <EmptyPuzzleBox
                      key={recommendedPuzzle.id + index}
                      loadingRecommendedPs={loadingRecommendedPs}
                      index={index}
                      puzzle={recommendedPuzzle}
                      puzzleIdToScrollIntoView={puzzleIdToScrollIntoView}
                      setIndexVisible={setIndexVisible}
                      indexVisible={indexVisible}
                    />
                  )
                )}
                <EmptyPuzzleBox
                  title="WOW"
                  caption="Thank you for playing 🙏"
                  topicName={""}
                />
                <EmptyPuzzleBox
                  title="WOW"
                  caption="New levels coming soon..."
                  topicName={""}
                />
              </>
            ) : recommendedPuzzles && recommendedPuzzles.length <= 1 ? (
              <>
                <EmptyPuzzleBox
                  title="WOW"
                  caption="Thank you for playing 🙏"
                  topicName={""}
                />

                <EmptyPuzzleBox
                  title="WOW"
                  caption="New levels coming soon..."
                  topicName={""}
                />
              </>
            ) : (
              <>
                <EmptyPuzzleBox />
                <EmptyPuzzleBox />
              </>
            )}
          </div>
          {size.width >= 1024 && (
            <PuzzleWideCommentSection
              productAds={productAds}
              size={size}
              togglePurchasePowerUpForm={togglePurchasePowerUpForm}
              puzzleId={puzzleIdInView}
              customer={customer}
              navigate={navigate}
              targetAdBookmark={narrowAdBookmark}
              targetGuestCustomer={targetGuestCustomer}
              targetCustomer={targetCustomer}
              puzzle={
                recommendedPuzzles &&
                recommendedPuzzles.find((rP) => rP.id === puzzleIdInView)
              }
              narrowHorAd={narrowHorAd}
              country={values.country}
              shouldShowCommentSection={shouldShowCommentSection}
              setShouldShowCommentSection={setShouldShowCommentSection}
              regularHorAd={regularHorAd}
            />
          )}

          <div
            className={`relative overflow-visible h-full mt-10 short:mt-5 sm:mt-8 xshort:mt-10`}
          >
            <div className="lg:absolute left-0 h-full">
              <div className="flex-col relative flex items-center justify-start h-full ">
                <button
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!loadingRecommendedPs) {
                      setLoadingRecommendedPs(true);

                      const result = await refetchRecommendedPuzzles({
                        petActivityShortForm: null,
                        pageSize: getNumOfPlayIconsToShow(size) + 1,
                        seenIds,
                        clickedPuzzleId: null,
                      });

                      if (result && result.data) {
                        setLoadingRecommendedPs(false);

                        if (
                          result.data.getRecommendedPuzzles &&
                          result.data.getRecommendedPuzzles.length > 0
                        ) {
                          setPuzzleIdToScrollIntoView(
                            result.data.getRecommendedPuzzles[0].id
                          );
                          setWantToScrollToPId(null);
                        } else {
                          setPuzzleIdToScrollIntoView(null);
                          setWantToScrollToPId(null);
                        }
                      }
                    }
                  }}
                  className="w-4 2xl:w-6 hover:bg-white transition-all shrink-0 aspect-square sm:w-5 flex items-center justify-center border border-black rounded-full "
                >
                  <svg
                    className={`${
                      loadingRecommendedPuzzles || loadingRecommendedPs
                        ? "motion-safe:animate-spin"
                        : ""
                    } m-auto h-2 sm:h-2.5 2xl:h-3`}
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.34455 1.65463C7.48546 0.796078 6.30643 0.263184 4.99706 0.263184C2.37832 0.263184 0.263184 2.38292 0.263184 5.00003C0.263184 7.61713 2.37832 9.73687 4.99706 9.73687C7.207 9.73687 9.0496 8.227 9.5769 6.18424H8.34455C7.85872 7.56384 6.54342 8.55266 4.99706 8.55266C3.03597 8.55266 1.44221 6.95989 1.44221 5.00003C1.44221 3.04016 3.03597 1.44739 4.99706 1.44739C5.98057 1.44739 6.85744 1.85595 7.49731 2.50134L5.58954 4.40792H9.73687V0.263184L8.34455 1.65463Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-1 sm:h-1.5 bg-black"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-0.5 sm:h-1 bg-black mt-0.5"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-[0.2rem] sm:h-0.5 bg-black mt-0.5"></div>
                <div className="w-[1px] 2xl:w-[2px] shrink-0 h-full bg-gradient-to-b from-black via-black/80 to-transparent  absolute top-[2.9rem]"></div>

                <div className="z-10 flex-col relative flex items-center justify-center">
                  {recommendedPuzzles && recommendedPuzzles.length > 0
                    ? recommendedPuzzles
                        .filter(
                          (rQ, index) => index <= getNumOfPlayIconsToShow(size)
                        )
                        .map((recommendedPuzzle, index) => (
                          <PuzzlePlayIcon
                            key={recommendedPuzzle.id + index}
                            puzzle={recommendedPuzzle}
                            index={index}
                            setPuzzleFetchErr={setPuzzleFetchErr}
                            lastPuzzleId={
                              index > 0 && recommendedPuzzles[index - 1]
                                ? recommendedPuzzles[index - 1].id
                                : 0
                            }
                            setIsPFetchErrShown={setIsPFetchErrShown}
                            indexVisible={indexVisible}
                            puzzleIdInView={puzzleIdInView}
                            puzzleIdToScrollIntoView={puzzleIdToScrollIntoView}
                            setPFetchErrShownAtPId={setPFetchErrShownAtPId}
                            targetCustomer={targetCustomer}
                            setPuzzleIdToScrollIntoView={
                              setPuzzleIdToScrollIntoView
                            }
                            setWantToScrollToPId={setWantToScrollToPId}
                            seenIds={
                              recommendedPuzzles &&
                              recommendedPuzzles.length > 0
                                ? recommendedPuzzles.map((val, index) => val.id)
                                : []
                            }
                            setLoadingRecommendedPs={setLoadingRecommendedPs}
                            loadingRecommendedPs={loadingRecommendedPs}
                            petActivityShortForm={null}
                            disabled={
                              loadingRecommendedPs || puzzleIdToScrollIntoView
                                ? true
                                : false
                            }
                            pageSize={getNumOfPlayIconsToShow(size) + 1}
                            wantToScrollToPId={wantToScrollToPId}
                            refetchRecommendedPuzzles={
                              refetchRecommendedPuzzles
                            }
                          />
                        ))
                    : [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                        16, 17, 18,
                      ]
                        .filter(
                          (rQ, index) => index <= getNumOfPlayIconsToShow(size)
                        )
                        .map((emptyQ, index) => (
                          <div
                            className={`px-1 md:px-2 group ${
                              index === 0
                                ? "pb-1.5 2xl:pb-2"
                                : "py-1.5 2xl:py-2"
                            }`}
                            key={index}
                          >
                            <div className="rounded-md sm:rounded-lg group bg-black flex items-center justify-center relative border-black box-border overflow-hidden border 2xl:border-2 short:w-[1.25rem] w-[1.35rem] sm:w-7 2xl:w-10 aspect-square">
                              <img
                                src={defaultPicUrl}
                                alt={"Default topic"}
                                className="w-full h-full   object-cover group-hover:opacity-80 transition-all"
                              ></img>
                            </div>
                          </div>
                        ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileFooter
          navigate={navigate}
          page="focus"
          finishedLoading={finishedLoading}
        />
      </div>
      {/* ) : (
        <></>
      )} */}

      {((size.width >= 1024 && regularHorAd) ||
        (size.width < 1024 && regularVerAd)) &&
        shouldOpenAdPopup && (
          <HorAdOverlayBox
            targetCustomer={targetCustomer}
            targetAdBookmark={
              size.width >= 1024 ? regularHorAdBookmark : regularVerAdBookmark
            }
            toggleAdPopup={toggleAdPopup}
            overlayAd={size.width >= 1024 ? regularHorAd : regularVerAd}
            shouldOpenAdPopup={shouldOpenAdPopup}
            values={values}
            isOutOfLives={false}
            endTime={new Date(new Date().getTime() + 20 * 1000)}
            imgUrl={
              size.width >= 1024
                ? regularHorAd.businessLogoUrl
                : regularVerAd.businessLogoUrl
            }
            alt={
              size.width >= 1024
                ? regularHorAd.businessName
                : regularVerAd.businessName
            }
            width={size.width > 1536 ? 250 : 200}
            lastXSeconds={20 * 1000}
          />
        )}
    </div>
  );
}

export default Puzzles;
