import moment from "moment";
import React, { useEffect, useRef } from "react";

import { useInView } from "react-intersection-observer";

import { defaultPicUrl } from "../../util/hooks";

function AdminBreedBox({
  index,
  breed,
  breedIndexToScrollIntoView,
  setBreedIndexInView,
  disabled,
  setTargetBreed,
}) {
  const breedRef = useRef();

  const {
    ref: boxRef,
    inView: isBoxVisible,
    // entry,
  } = useInView({
    /* Optional options */
    threshold: 0.7,
  });

  useEffect(() => {
    if (index === breedIndexToScrollIntoView) {
      breedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [breedIndexToScrollIntoView, index]);

  useEffect(() => {
    if (isBoxVisible && !disabled) {
      setBreedIndexInView(index);
    }
  }, [isBoxVisible, disabled, index, setBreedIndexInView]);

  return (
    <div ref={breedRef} className="w-full shrink-0">
      <div
        ref={boxRef}
        className="w-full sm:px-10 px-5 snap-center shrink-0 pb-5 pt-4 sm:pb-10 sm:pt-9 flex items-center justify-start flex-col bg-white rounded-xl sm:rounded-3xl short:px-2 short:py-2"
      >
        <div className="flex items-center space-x-2 justify-start w-full truncate">
          <img
            src={breed.logoUrl || defaultPicUrl}
            className="h-16 short:h-12 aspect-square object-cover object-center origin-center rounded-full mr-2 short:mr-1"
            alt={breed.name}
          />

          <div className="flex-1 w-full flex flex-col items-start justify-start truncate">
            <p className="font-semibold text-gray-700 text-xl short:text-lg leading-tight short:leading-tight w-full truncate ">
              {breed.name}
              {breed.provinceShortForm ? ", " + breed.provinceShortForm : ""}
              {breed.countryShortForm ? ", " + breed.countryShortForm : ""}
            </p>
            <p className=" text-gray-500 leading-tight">
              Last edited {moment(breed.lastEditedAt).fromNow()}
            </p>
          </div>
        </div>
        {/* <div className="py-2 px-4 text-gray-500 bg-gray-50 font-semibold flex sm:text-lg justify-between space-x-4 items-center shadow-sm mt-4 w-full rounded-lg border-gray-200 border">
          <p>ID</p>
          <p>{breed.id}</p>
        </div> */}

        <div className="mt-4 text-center sm:text-lg flex items-center  text-gray-600 justify-start w-full overflow-hidden rounded-lg border-gray-200 border">
          <button
            // onClick={copyTrackingLink}
            onClick={(e) => {
              e.preventDefault();
              setTargetBreed(breed);
            }}
            className={`flex-1 px-8 short:px-4 flex items-center justify-center relative font-semibold border-gray-200 hover:bg-gray-50  transition-all`}
          >
            <p className={`py-3`}>Edit </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminBreedBox;
