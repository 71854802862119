import React, { useContext, useEffect, useState } from "react";
import usePageTracking from "../../util/usePageTracking";
//import  FeatureSummary from "../../components/creator/FeatureSummary";
//import  Footer from "../../components/creator/Footer";
//import  LinksAndNext from "../../components/creator/LinksAndNext";
//import  SliderBar from "../../components/creator/SliderBar";
import { useNavigate } from "react-router";

import { useQuery } from "@apollo/client";

import { brandContactClient } from "../../GraphqlApolloClients";

import BrandContactFooter from "../../components/creator/BrandContactFooter";
import EditSellerAd from "../../components/creator/EditSellerAd";
import { BrandContactAuthContext } from "../../context/brandContactAuth";

import SellerMiniNavBar from "../../components/creator/SellerMiniNavBar";
import SellerNav from "../../components/creator/SellerNav";
import TNReviewForm from "../../components/creator/TNReviewForm";
import TopBar from "../../components/creator/TopBar";
import {
  FETCH_ADS_BY_BIZ,
  FETCH_BUSINESSES_BY_BRAND_CONTACT,
  FETCH_SELLER,
} from "../../util/gqlFunctions";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function SellerDashboard(props) {
  usePageTracking();

  let navigate = useNavigate();
  const { brandContact, logoutBrandContact: logout } = useContext(
    BrandContactAuthContext
  );
  useEffect(() => {
    if (!brandContact) {
      navigate("/loginSeller");
    }
  }, [brandContact, navigate]);
  useEffect(() => {
    document.title = "Seller Dashboard | The Lucky Pet";
  }, []);
  // const pageTopRef = useRef();

  // const [targetProduct, setTargetProduct] = useState(null);

  const [targetAd, setTargetAd] = useState(null);

  const [isFirstTime, setIsFirstTime] = useState(true);

  const [shouldShowMiniNavBar, setShouldShowMiniNavBar] = useState(false);

  const { data: { getBrandContact: sellerInfo } = {}, loading: loadingSeller } =
    useQuery(FETCH_SELLER, {
      client: brandContactClient,
      fetchPolicy: "cache-and-network",
    });

  const [checkedIsSellerLoggedIn, setCheckedIsSellerLoggedIn] = useState(false);

  useEffect(() => {
    if (
      brandContact &&
      !loadingSeller &&
      !sellerInfo &&
      !checkedIsSellerLoggedIn
    ) {
      logout();

      setCheckedIsSellerLoggedIn(true);
    }
  }, [
    brandContact,
    loadingSeller,
    sellerInfo,
    logout,
    checkedIsSellerLoggedIn,
  ]);

  // const newMonthlyEarningsSummaryRef = useRef();

  // const [
  //   monthlyEarningsSummaryIndexToScrollIntoView,
  //   setMonthlyEarningsSummaryIndexToScrollIntoView,
  // ] = useState(null);
  // const [
  //   monthlyEarningsSummaryIndexInView,
  //   setMonthlyEarningsSummaryIndexInView,
  // ] = useState(0);

  const {
    data: { getBusinessesByBrandContact: businessesByBrandContact } = {},
    // loading: loadingBusinessesByBrandContact,
  } = useQuery(FETCH_BUSINESSES_BY_BRAND_CONTACT, {
    client: brandContactClient,
    variables: { brandContactId: brandContact ? brandContact.id : null },
    fetchPolicy: "cache-and-network",
  });

  // const {
  //   data: { getSellerMonthlyEarnings: sellerMonthlyEarnings } = {},
  //   loading: loadingSellerMonthlyEarnings,
  // } = useQuery(FETCH_SELLER_MONTHLY_EARNINGS, {
  //   client: brandContactClient,
  //   variables: {
  //     brandContactId: brandContact ? brandContact.id : null,
  //     businessId:
  //       businessesByBrandContact && businessesByBrandContact.length > 0
  //         ? businessesByBrandContact[0].id
  //         : null,
  //   },
  //   fetchPolicy: "cache-and-network",
  // });

  // const [createProduct, { loading: loadingCreateProduct }] = useMutation(
  //   CREATE_PRODUCT,
  //   {
  //     refetchQueries: [
  //       {
  //         query: FETCH_PRODUCTS_BY_BIZ,
  //         variables: {
  //           businessId:
  //             businessesByBrandContact && businessesByBrandContact.length > 0
  //               ? businessesByBrandContact[0].id
  //               : null,
  //         },
  //       },
  //     ],
  //     update(_, { data: { createProduct: product } }) {},
  //     onError(err) {
  //       console.log(err);
  //       console.log(errors);

  //       if (err.graphQLErrors[0]) {
  //         setErrors({
  //           ...err.graphQLErrors[0].extensions.errors,
  //         });
  //       }
  //     },
  //     client: brandContactClient,
  //     variables: {
  //       businessId:
  //         businessesByBrandContact && businessesByBrandContact.length > 0
  //           ? businessesByBrandContact[0].id
  //           : null,
  //     },
  //   }
  // );

  // const {
  //   data: { getProductsByBiz: products } = {},
  //   loading: loadingProducts,
  // } = useQuery(FETCH_PRODUCTS_BY_BIZ, {
  //   client: brandContactClient,
  //   variables: {
  //     businessId:
  //       businessesByBrandContact && businessesByBrandContact.length > 0
  //         ? businessesByBrandContact[0].id
  //         : null,
  //   },
  // });

  // function createProductCallback(e) {
  //   e.preventDefault();
  //   createProduct();
  // }

  const { data: { getAdsByBiz: ads } = {} } = useQuery(FETCH_ADS_BY_BIZ, {
    client: brandContactClient,
    variables: {
      businessId:
        businessesByBrandContact && businessesByBrandContact.length > 0
          ? businessesByBrandContact[0].id
          : null,
    },
  });

  const [isCaptionCopied, setIsCaptionCopied] = useState(null);
  const [isSharingToApps, setIsSharingToApps] = useState(false);
  const resetInterval = 1000;

  const [showComingSoon, setShowComingSoon] = useState(false);

  useEffect(() => {
    let timeout;
    if (showComingSoon && resetInterval) {
      timeout = setTimeout(() => setShowComingSoon(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showComingSoon, resetInterval]);

  useEffect(() => {
    let timeout;
    if (isCaptionCopied && resetInterval) {
      timeout = setTimeout(() => setIsCaptionCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCaptionCopied, resetInterval]);
  const [initializedMuteVals, setInitializedMuteVals] = useState(false);

  const [shouldPlaySoundEffects, setShouldPlaySoundEffects] = useState(false);

  useEffect(() => {
    if (!initializedMuteVals && sellerInfo) {
      setShouldPlaySoundEffects(
        sellerInfo.areSoundEffectsMuted === true ? false : true
      );
      setInitializedMuteVals(true);
    }
  }, [shouldPlaySoundEffects, initializedMuteVals, sellerInfo]);

  const [isInviteCopied, setIsInviteCopied] = useState(null);
  const [isSharingInviteToApps, setIsSharingInviteToApps] = useState(false);

  useEffect(() => {
    let timeout;
    if (isInviteCopied && resetInterval) {
      timeout = setTimeout(() => setIsInviteCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInviteCopied, resetInterval]);

  const inviteFriends = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var textToShare = "Advertise on #TheLuckyPet\n\n";
    console.log(isSharingInviteToApps);
    textToShare += "thelucky.pet/business";

    if (navigator.share) {
      navigator
        .share({
          title: "Play Lucky Pet!",
          text: textToShare,
          url: "http://thelucky.pet/",
        })
        .then(() => {
          setIsInviteCopied(true);

          setIsSharingInviteToApps(true);
        })
        .catch((error) => {
          setIsSharingInviteToApps(false);
          navigator.clipboard.writeText(textToShare);

          setIsInviteCopied(true);
        });
    } else {
      navigator.clipboard.writeText(textToShare);
      setIsInviteCopied(true);
    }
  };

  const [shouldOpenReviewForm, setShouldOpenReviewForm] = useState(false);
  const [shouldOpenPaymentSuccessfulForm, setShouldOpenPaymentSuccessfulForm] =
    useState(false);
  const [shouldOpenReviewSentForm, setShouldOpenReviewSentForm] =
    useState(false);

  const toggleReviewForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldOpenReviewSentForm(false);
    setShouldShowMiniNavBar(false);
    console.log(shouldOpenPaymentSuccessfulForm);

    setShouldOpenReviewForm(!shouldOpenReviewForm);
  };

  const toggleReviewSentForm = () => {
    setShouldOpenPaymentSuccessfulForm(false);
    setShouldShowMiniNavBar(false);
    setShouldOpenReviewForm(false);

    setShouldOpenReviewSentForm(!shouldOpenReviewSentForm);
  };

  return (
    <div
      // ref={pageTopRef}
      className="w-screen overflow-x-hidden h-full min-h-screen bg-orange-500"
    >
      {targetAd !== null && ads && ads.length > 0 && ads[targetAd] && (
        <EditSellerAd targetAd={ads[targetAd]} setTargetAd={setTargetAd} />
      )}

      {shouldShowMiniNavBar && sellerInfo && (
        <SellerMiniNavBar
          targetSeller={sellerInfo}
          business={
            businessesByBrandContact ? businessesByBrandContact[0] : null
          }
          isSharingToApps={isSharingToApps}
          isShareCopied={isCaptionCopied || isInviteCopied}
          setIsSharingToApps={setIsSharingToApps}
          navigate={navigate}
          isInviteCopied={isInviteCopied}
          inviteFriends={inviteFriends}
          toggleReviewForm={toggleReviewForm}
          shouldShowMiniNavBar={shouldShowMiniNavBar}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          logout={logout}
          setShouldPlaySoundEffects={setShouldPlaySoundEffects}
          shouldPlaySoundEffects={shouldPlaySoundEffects}
        />
      )}

      {shouldOpenReviewForm && sellerInfo ? (
        <TNReviewForm
          shouldOpenReviewForm={shouldOpenReviewForm}
          toggleReviewForm={toggleReviewForm}
          targetSeller={sellerInfo}
          toggleReviewSentForm={toggleReviewSentForm}
        />
      ) : (
        <></>
      )}

      <div className="relative min-h-screen z-10 sm:px-0 sm:max-w-5xl 2xl:max-w-7xl 3xl:max-w-8xl xl:px-0 px-4 mx-auto pb-10 lg:pb-0 ">
        <TopBar
          topicShortForm={null}
          topicId={null}
          navigate={navigate}
          seller={sellerInfo}
          page="Seller Dash"
          setShouldShowMiniNavBar={setShouldShowMiniNavBar}
          setIsFirstTime={setIsFirstTime}
        />

        {/* <div className="relative mb-8">
          <button
            onClick={(e) => {
              e.preventDefault();
              setMonthlyEarningsSummaryIndexToScrollIntoView(
                monthlyEarningsSummaryIndexInView - 1
              );
            }}
            disabled={
              loadingSeller ||
              loadingSellerMonthlyEarnings ||
              monthlyEarningsSummaryIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setMonthlyEarningsSummaryIndexToScrollIntoView(
                monthlyEarningsSummaryIndexInView + 1
              );
            }}
            disabled={
              sellerMonthlyEarnings &&
              monthlyEarningsSummaryIndexInView >=
                sellerMonthlyEarnings.length - 1
                ? true
                : loadingSeller || loadingSellerMonthlyEarnings
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-16 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {sellerInfo &&
              sellerMonthlyEarnings &&
              sellerMonthlyEarnings.length > 0 &&
              sellerMonthlyEarnings.map((sellerMonthlyEarning, index) => (
                <SellerMonthlyEarningBox
                  key={sellerMonthlyEarning.id}
                  index={index}
                  sellerMonthlyEarning={sellerMonthlyEarning}
                  sellerInfo={sellerInfo}
                  monthlyEarningsSummaryIndexToScrollIntoView={
                    monthlyEarningsSummaryIndexToScrollIntoView
                  }
                  setMonthlyEarningsSummaryIndexToScrollIntoView={
                    setMonthlyEarningsSummaryIndexToScrollIntoView
                  }
                  numOfPendingJobs={sellerMonthlyEarnings.length}
                  setMonthlyEarningsSummaryIndexInView={
                    setMonthlyEarningsSummaryIndexInView
                  }
                  disabled={loadingSeller || loadingSellerMonthlyEarnings}
                />
              ))}

            {(!sellerMonthlyEarnings || sellerMonthlyEarnings.length === 0) && (
              <NewSellerMonthlyEarningBox
                newMonthlyEarningsSummaryRef={newMonthlyEarningsSummaryRef}
                index={0}
                monthlyEarningsSummaryIndexInView={
                  monthlyEarningsSummaryIndexInView
                }
                setMonthlyEarningsSummaryIndexInView={
                  setMonthlyEarningsSummaryIndexInView
                }
                disabled={loadingSeller || loadingSellerMonthlyEarnings}
                monthlyEarningsSummaryIndexToScrollIntoView={
                  monthlyEarningsSummaryIndexToScrollIntoView
                }
              />
            )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto pb-4 sm:space-x-4">
              {sellerMonthlyEarnings &&
                sellerMonthlyEarnings.length > 1 &&
                sellerMonthlyEarnings.map((sellerMonthlyEarning, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMonthlyEarningsSummaryIndexToScrollIntoView(index);
                    }}
                    className={`${
                      index === monthlyEarningsSummaryIndexInView
                        ? "bg-blue-500"
                        : "bg-gray-300 hover:bg-blue-300"
                    } aspect-ratio w-2 h-2 rounded-full transition-colors`}
                    key={sellerMonthlyEarning._id}
                  ></button>
                ))}
            </div>
          </div>
        </div> */}
        {/* 
        {businessesByBrandContact &&
        businessesByBrandContact[0] &&
        !businessesByBrandContact[0].sentShoutoutRequest ? (
          <button
            onClick={(e) => {
              navigate("/sellerShoutout/" + businessesByBrandContact[0].id);
            }}
            className="w-full group text-2xl hover:bg-opacity-60 transition-all font-semibold mb-10 sm:mb-20 -mt-20 snap-center shrink-0 py-3 sm:py-4 flex items-center justify-center space-x-2 bg-yellow-300 rounded-full sm:rounded-full"
          >
            <p className={`py-2`}>Get a shoutout</p>
            <svg
              className="h-3 fill-current"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" -translate-x-1 group-hover:translate-x-0 group-disabled:group-hover:-translate-x-1 transition-all"
                d="M5.33198 0L4 1.175L8.32659 5L4 8.825L5.33198 10L11 5L5.33198 0Z"
              />
              <rect
                className="opacity-0 group-hover:opacity-100 group-disabled:group-hover:opacity-0 transition-all"
                y="4"
                width="9"
                height="2"
              />
            </svg>
          </button>
        ) : (
          <></>
        )} */}

        <div className="w-full mb-16 flex items-start justify-start lg:space-x-4">
          <SellerNav
            page="Home"
            setShowComingSoon={setShowComingSoon}
            showComingSoon={showComingSoon}
          />
          <div className="flex-1">
            <div className="w-full flex pt-5 sm:pt-8  items-center justify-start flex-col bg-white rounded-xl sm:rounded-xl">
              <p className="text-gray-400 w-full text-center">
                {months[0]} 2024
              </p>

              <p className="my-4 font-bold text-5xl">0 views</p>

              <div className="mt-4 border-t font-semibold text-gray-600 border-gray-200 w-full flex  overflow-hidden shadow-sm rounded-lg ">
                <div className="w-full space-x-2 flex items-center justify-center text-center py-3 border-r border-gray-200 transition-all">
                  <svg
                    className="h-5 stroke-gray-400"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 22L34 34M2 2L16.14 35.94L21.16 21.16L35.94 16.14L2 2Z"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <p>{0} Clicks</p>
                </div>

                <div className="w-full space-x-2 flex items-center justify-center text-center py-3 transition-all">
                  <svg
                    className="h-5 stroke-gray-400"
                    viewBox="0 0 48 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2H10L15.36 28.78C15.5429 29.7008 16.0438 30.5279 16.7751 31.1166C17.5064 31.7053 18.4214 32.018 19.36 32H38.8C39.7386 32.018 40.6536 31.7053 41.3849 31.1166C42.1162 30.5279 42.6171 29.7008 42.8 28.78L46 12H12M20 42C20 43.1046 19.1046 44 18 44C16.8954 44 16 43.1046 16 42C16 40.8954 16.8954 40 18 40C19.1046 40 20 40.8954 20 42ZM42 42C42 43.1046 41.1046 44 40 44C38.8954 44 38 43.1046 38 42C38 40.8954 38.8954 40 40 40C41.1046 40 42 40.8954 42 42Z"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {/* <p>{sellerMonthlyEarning.numOfProductsSold} Returns</p> */}
                  <p>{0} Orders</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="relative mb-16">
          <p className="text-gray-700 text-2xl short:px-2 px-5 sm:px-10 mb-5 font-bold w-full">
            Products{" "}
            {products && products.length > 0 ? "(" + products.length + ")" : ""}
          </p>
          <button
            onClick={(e) => {
              e.preventDefault();
              setAdIndexToScrollIntoView(productIndexInView - 1);
            }}
            disabled={
              loadingSeller ||
              loadingProducts ||
              loadingAds ||
              productIndexInView <= 0
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 right-full mr-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pr-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15.705 7.41L14.295 6L8.295 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
            </svg>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              console.log(productIndexToScrollIntoView);
              setProductIndexToScrollIntoView(productIndexInView + 1);
            }}
            disabled={
              products && productIndexInView >= products.length - 1
                ? true
                : loadingSeller || loadingProducts || loadingAds
                ? true
                : false
            }
            className="absolute disabled:opacity-0 aspect-square h-14 z-10 mt-14 left-full ml-6 rounded-full hover:bg-gray-200 transition-all"
          >
            <svg
              className="text-gray-400 fill-current mx-auto pl-1"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.295 16.59L12.875 12L8.295 7.41L9.705 6L15.705 12L9.705 18L8.295 16.59Z" />
            </svg>
          </button>
          <div className="relative w-full flex gap-6 sm:gap-10 snap-x snap-mandatory overflow-x-auto">
            {sellerInfo &&
              products &&
              products.length > 0 &&
              products.map((product, index) => (
                <SellerProductBox
                  key={index}
                  index={index}
                  product={product}
                  sellerInfo={sellerInfo}
                  adIndexToScrollIntoView={adIndexToScrollIntoView}
                  setProductIndexInView={setProductIndexInView}
                  setProductIndexToScrollIntoView={
                    setProductIndexToScrollIntoView
                  }
                  setTargetProduct={setTargetProduct}
                  productIndexInView={productIndexInView}
                  disabled={loadingSeller || loadingProducts || loadingAds}
                  navigate={navigate}
                />
              ))}

            {(!products || products.length === 0) && (
              <NewSellerProductBox
                createProductCallback={createProductCallback}
                disabled={loadingSeller || loadingSellerMonthlyEarnings}
              />
            )}
          </div>

          <div className="flex items-center justify-between px-4 sm:px-10 w-full mt-5">
            <div className="flex items-center justify-start space-x-3 overflow-x-auto mr-4 py-6 sm:space-x-4">
              {products &&
                products.length > 1 &&
                products.map((product, index) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setAdIndexToScrollIntoView(index);
                    }}
                    className={`${
                      index === productIndexInView
                        ? "bg-blue-500"
                        : "bg-gray-300 hover:bg-blue-300"
                    } shrink-0 aspect-ratio w-2 h-2 rounded-full transition-colors`}
                    key={index}
                  ></button>
                ))}
            </div>

            <button
              onClick={(e) => {
                e.preventDefault();
                createProduct();
              }}
              disabled={loadingCreateProduct}
              className="flex items-center shrink-0 justify-between group space-x-4"
            >
              <p className="text-gray-600 whitespace-nowrap font-bold group-hover:text-gray-900 transition-all">
                Add product
              </p>

              <div className="w-14 h-14 shadow-lg rounded-full bg-blue-700 group-hover:bg-blue-900 transition-all">
                {loadingCreateProduct ? (
                  <svg
                    className={`p-4 text-white stroke-current m-auto motion-safe:animate-spin`}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.636 3.636A9 9 0 0119 10"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    className=" text-white fill-current p-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                  </svg>
                )}
              </div>
            </button>
          </div>
        </div> */}
      </div>

      <BrandContactFooter
        navigate={navigate}
        logout={logout}
        brandContact={brandContact}
        keepDefaultColor={true}
        accentColor={7}
        // keepDefaultColor={true}
        targetBrandContact={
          businessesByBrandContact && businessesByBrandContact[0]
            ? businessesByBrandContact[0]
            : brandContact
        }
      />
    </div>
  );
}

export default SellerDashboard;
